export default {
  computed: {
    timeZones() {
      return [
        {
          value: -1,
          label: 'Timezone Not Set',
        },
        {
          value: 10,
          label: 'Hawaiian Standard Time',
        },
        {
          value: 9,
          label: 'Alaskan Standard Time',
        },
        {
          value: 8,
          label: 'Pacific Standard Time',
        },
        {
          value: 7,
          label: 'Mountain Standard Time',
        },
        {
          value: 6,
          label: 'Central Standard Time',
        },
        {
          value: 5,
          label: 'Eastern Standard Time',
        },
      ];
    },
  },
};
