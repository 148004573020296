import _ from '@/utils/store-helpers';
import { axiosInstance } from '@/plugins/axios';

export const state = {
  channels: [],
  leadchannels: [],
  dashboardchannels: [],
  leadChannelCollection: [],
  dayCapMin: {
    sun: 1,
    mon: 1,
    tue: 1,
    wed: 1,
    thu: 1,
    fri: 1,
    sat: 1,
  },
  loading: false,
  dashboardLoading: false,
  detailLoading: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
  },
  dashboardPagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
  },
};

export const getters = {
  loading: (state) => state.loading,
  detailLoading: (state) => (id) => state.detailLoading.includes(id),
  channels: (state) => state.channels,
  channelGroups: (state) => state.channelGroups,
  leadchannels: (state) => state.leadchannels,
  dashboardchannels: (state) => state.dashboardchannels,

  leadChannelCollection: (state) => state.leadChannelCollection,

  webChannels: (state) => state.channels.filter((c) => c.source === 'Web'),
  webDashboardChannels: (state) =>
    state.dashboardchannels.filter((c) => c.source === 'Web'),

  callChannels: (state) => state.channels.filter((c) => c.source === 'Call'),
  callDashboardChannels: (state) =>
    state.dashboardchannels.filter((c) => c.source === 'Call'),

  totalRecords: (state, getters) => ({
    Web: getters.webChannels.length,
    Call: getters.callChannels.length,
  }),
  totalRecordsDashboard: (state, getters) => ({
    Web: getters.webDashboardChannels.length,
    Call: getters.callDashboardChannels.length,
  }),

  hasWebChannel: (state) => state.channels.some((c) => c.source === 'Web'),

  hasCallChannel: (state) => state.channels.some((c) => c.source === 'Call'),

  hasWebLeadChannel: (state) =>
    state.leadchannels.some((c) => c.source === 'Web'),

  hasCallLeadChannel: (state) =>
    state.leadchannels.some((c) => c.source === 'Call'),

  hasdashboardWebChannel: (state) =>
    state.dashboardchannels.some((c) => c.source === 'Web'),

  hasdashboardCallChannel: (state) =>
    state.dashboardchannels.some((c) => c.source === 'Call'),

  activeChannels: (state) =>
    state.channels.filter((c) =>
      ['ACTIVE', 'DAY CAP', 'WEEKLY_CAP', 'DAYCAPPED', 'WEEKCAPPED'].includes(
        c.status.toUpperCase()
      )
    ),

  channelsByType(state) {
    return (leadType) => {
      const channels = state.channels.filter((c) => c.source === leadType);
      const { currentPage, pageSize } = state.pagination;
      const nextIndex = (currentPage - 1) * pageSize;
      let endIndex = nextIndex + pageSize;
      if (endIndex > channels.length) {
        endIndex = channels.length;
      }
      return channels.slice(nextIndex, endIndex);
    };
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setDashboardLoading: _.set('dashboardLoading'),
  SET_DETAIL_LOADING(state, { id, newValue }) {
    state.detailLoading = [
      ...state.detailLoading.filter((x) => x !== id),
      ...(newValue ? [id] : []),
    ];
  },
  SET_CHANNEL_OVERVIEW(state, newValue) {
    state.channels = newValue;
  },

  SET_LEAD_CHANNEL(state, newValue) {
    state.leadchannels = newValue;
  },

  SET_CHANNELS(state, newValue) {
    state.dashboardchannels = newValue;
  },
  ADD_LEAD_CHANNEL_DETAIL(state, newDetail) {
    state.leadChannelCollection = [
      ...state.leadChannelCollection.filter((x) => x.id !== newDetail.id),
      newDetail,
    ];
  },

  RESET_LeadChannelcollection(state, newValue) {
    state.leadChannelCollection = newValue;
  },
  SET_DAY_CAP_MINS(state, newValue) {
    state.dayCapMin = newValue;
  },
  setCurrentPage(state, pageNumber) {
    state.pagination.currentPage = pageNumber;
  },
  setDashboardCurrentPage(state, pageNumber) {
    state.dashboardPagination.currentPage = pageNumber;
  },

  setTotalPages(state, totalPages) {
    state.pagination.totalPages = totalPages;
  },

  setTotalPagesDashboard(state, totalPages) {
    state.pagination.totalPages = totalPages;
  },
};

export const actions = {
  async getDashboardChannelOverview({ commit, dispatch, state }, clientId) {
    const response = await dispatch('request/get', {
      url: `/api/LeadType/dashboard/${clientId}`,
      errorMessage: 'Failed to get channels.',
      loadingMutation: 'channels/setDashboardLoading',
    });

    if (!response) return;

    const { data: dashboardchannels } = response;
    commit('SET_CHANNELS', dashboardchannels);

    const pageCount = Math.ceil(
      dashboardchannels.length / state.dashboardPagination.pageSize
    );
    dispatch('setTotalPagesDashboard', pageCount);

    return dashboardchannels;
  },

  async getChannelOverview({ commit, dispatch, state }, { clientId }) {
    const response = await dispatch('request/get', {
      url: `/api/LeadType/client/${clientId}?noGeoMaps=true`,
      errorMessage: 'Failed to get channels.',
      loadingMutation: 'channels/setLoading',
    });

    if (!response) return;

    const { data: channels } = response;

    commit('SET_CHANNEL_OVERVIEW', channels);
    const pageCount = Math.ceil(channels.length / state.pagination.pageSize);
    dispatch('setTotalPages', pageCount);

    return channels;
  },

  async getLeadChannelOverview({ commit, dispatch }, { clientId }) {
    const response = await dispatch('request/get', {
      url: `/api/LeadType/leadchannels/${clientId}`,
      errorMessage: 'Failed to get channels.',
      loadingMutation: 'channels/setLoading',
    });

    if (!response) return;

    const { data: leadChannels } = response;

    commit('SET_LEAD_CHANNEL', leadChannels);

    return leadChannels;
  },
  async getLeadChannelDetail({ commit, dispatch }, channelId) {
    commit('SET_DETAIL_LOADING', {
      id: channelId,
      newValue: true,
    });

    const response = await dispatch('request/get', {
      url: `/api/LeadType/details/${channelId}`,
      errorMessage: 'Failed to get channels.',
    });

    if (!response) return;

    const { data: leadChannelDetail } = response;

    commit('ADD_LEAD_CHANNEL_DETAIL', leadChannelDetail);

    commit('SET_DETAIL_LOADING', {
      id: channelId,
      newValue: false,
    });

    return leadChannelDetail;
  },
  getCapMinimums({ commit }, packageId) {
    return axiosInstance
      .get(`/api/LeadType/${packageId}/caps/min`)
      .then((response) => {
        var result = {
          sun: response.sunday,
          mon: response.monday,
          tue: response.tuesday,
          wed: response.wednesday,
          thu: response.thursday,
          fri: response.friday,
          sat: response.saturday,
        };
        commit('SET_DAY_CAP_MINS', result);
        return result;
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  },

  async getChannelClone({ commit, dispatch }, cloneModel) {
    const response = await dispatch('request/post', {
      url: '/api/LeadType/clone',
      data: cloneModel,
      errorMessage: 'Failed to clone channel.',
      loadingMutation: 'channels/setLoading',
    });

    if (!response) return;

    commit('leadType/SET_LEAD_TYPE', response.data, { root: true });

    if (cloneModel.callProperties.isCloning) {
      dispatch(
        'scheduler/fetchCallSchedule',
        cloneModel.callProperties.clientQuoteTypeId,
        { root: true }
      );
      dispatch(
        'callCorporateFilter/getFilters',
        {
          clientQuoteTypeId: cloneModel.callProperties.clientQuoteTypeId,
          callType: response.data.callType,
          clearIds: true,
        },
        { root: true }
      );
      commit('callCorporateFilter/setDirty', true, { root: true });
    }
    commit('setLoading', false);
  },

  async sendTestLead(_, id) {
    await axiosInstance
      .post(`api/leadType/${id}/testLead`)
      .then((response) => {
        if (response.status === 200) {
          this.dispatch('toastr/success', 'The test lead has been sent.');
        } else {
          this.dispatch(
            'toastr/error',
            'Oops there was an error sending the test lead'
          );
        }
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  },

  setCurrentPage({ commit }, pageNumber) {
    commit('setCurrentPage', pageNumber);
  },
  setdashboardCurrentPage({ commit }, pageNumber) {
    commit('setdashboardCurrentPage', pageNumber);
  },

  setTotalPages({ commit }, totalPages) {
    commit('setTotalPages', totalPages);
  },
  setTotalPagesDashboard({ commit }, totalPages) {
    commit('setTotalPagesDashboard', totalPages);
  },

  reset({ commit }) {
    commit('SET_CHANNEL_OVERVIEW', []);
    commit('SET_CHANNELS', []);
    commit('SET_LEAD_CHANNEL', []);
    commit('SET_LEAD_CHANNEL', []);
  },
  resetLeadChannel({ commit }) {
    commit('RESET_LeadChannelcollection', []);
  },
};
