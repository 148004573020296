class EventBus {
  constructor() {
    this.dictionary = [];
  }

  $emit(eventType, ...args) {
    const subscribers = this.dictionary[eventType];
    if (!subscribers) return;

    subscribers.forEach((eventHandler) => {
      eventHandler(...args);
    });
  }

  $on(eventType, eventHandler) {
    var subscriptions = this.dictionary[eventType];
    if (!subscriptions) {
      subscriptions = [];
      this.dictionary[eventType] = subscriptions;
    }

    subscriptions.push(eventHandler);
  }
}

const eventBus = new EventBus();
export default eventBus;
