import { axiosInstance } from '@/plugins/axios';
import _ from '@/utils/store-helpers';
import { handleFailedNetworkResponse } from '../helpers';

export const state = {
  cardList: [],
  statements: {},
  cancelStatements: [],
  lastStatement: null,
  isLastStatementCurrentMonthStatement: false,
  clientCancellationReasons: null,
  productData: [],
  dataLoading: true,
  productsLoading: false,
  productSettingsLoading: false,
  groupBalanceTransferLoading: false,
  groupBalanceTransfers: [],
  singleGroupBalanceTransfer: null,
  billingTypes: [],
  productTypeBalances: {
    Web: {},
    Call: {},
  },
  pendingCancellation: false,
  isProcessingCancellation: false,
  validationResult: {
    isValid: true,
    message: '',
    code: '',
  },
};

export const getters = {
  productData: (state) => state.productData,
  validationResult: (state) => state.validationResult,
  primaryCardByProductId: (state) => (productTypeId) => {
    const product = state.productData.find(
      (product) => product.productId === productTypeId
    );
    return product ? product.paymentId : null;
  },
  isPendingCancellation: (state) => state.pendingCancellation,
  isProcessingCancellation: (state) => state.isProcessingCancellation,
};

export const mutations = {
  SET_IS_PROCESSING_CANCELLATION: _.set('isProcessingCancellation', false),
  setProductsLoading: _.set('productsLoading', false),
  setProductSettingsLoading: _.set('productSettingsLoading', false),
  setProductData: _.set('productData', false),
  setGroupBalanceTransfers: _.set('groupBalanceTransfers', false),
  setSingleGroupBalanceTransfer: _.set('singleGroupBalanceTransfer', false),
  setGroupBalanceTransferLoading: _.set('groupBalanceTransferLoading', false),
  SET_CARD_LIST(state, newValue) {
    state.cardList = newValue;
  },
  SET_STATEMENT_LIST(state, newValue) {
    state.statements = newValue;
  },
  SET_LAST_STATEMENT(state, newValue) {
    state.lastStatement = newValue;
  },
  setIsLastStatementCurrentMonthStatement: _.set(
    'isLastStatementCurrentMonthStatement',
    false
  ),
  SET_CANCEL_STATEMENTS(state, newValue) {
    state.cancelStatements = newValue;
  },
  SET_CLIENT_CANCELLATION_REASONS(state, newValue) {
    state.clientCancellationReasons = newValue;
  },
  SET_GROUP_BALANCE_TRANSFER(state, newValue) {
    state.groupBalanceTransfers = newValue;
  },
  SET_SINGLE_BALANCE_TRANSFER(state, newValue) {
    state.singleGroupBalanceTransfer = newValue;
  },
  SET_BILLING_TYPE_LIST(state, newValue) {
    state.billingTypes = newValue;
  },

  SET_VALIDATION_RESULT(state, validationResult) {
    state.validationResult = validationResult;
  },

  RESET_VALIDATION_RESULT(state) {
    state.validationResult = {
      isValid: true,
      message: '',
      code: '',
    };
  },

  setProductTypeBalance(state, { productType, productTypeBalance }) {
    state.productTypeBalances[productType] = productTypeBalance;
  },

  setProductTypePaymentMethod(state, { productType, paymentMethod }) {
    if (state.productData.length === 1 && productType === 1) {
      // This occurs when the client has only calls.
      // In this case the only item in the array is for calls and it's trying to update
      // the second one.
      state.productData[productType - 1].paymentTerms = paymentMethod;
    } else {
      state.productData[productType].paymentTerms = paymentMethod;
    }
  },

  setProductTypePaymentCard(state, { productType, paymentCardId }) {
    if (state.productData.length === 1 && productType === 1) {
      // This occurs when the client has only calls.
      // In this case the only item in the array is for calls and it's trying to update
      // the second one.
      state.productData[productType - 1].paymentId = paymentCardId;
    } else {
      state.productData[productType].paymentId = paymentCardId;
    }
  },

  setProductTypeSpendLimit(state, { productType, spendLimitVal }) {
    state.productData[productType].spendLimit = spendLimitVal;
  },

  setActiveCardInList(state, activeCardId) {
    // Update all cards in list to REMOVED

    state.cardList.forEach((el) => {
      el.status = 'REMOVED';
    });

    // Find new record and update to ACTIVE
    const objIndex = state.cardList.findIndex(
      (obj) => obj.paymentId === activeCardId
    );

    state.cardList[objIndex].status = 'ACTIVE';
  },
  SET_PENDING_CANCELLATION(state, cancellation) {
    state.pendingCancellation = cancellation;
  },
};

export const actions = {
  getCards({ commit }, { id, noCache = false }) {
    return axiosInstance
      .get(`/api/billing/${id}/cards`, {
        params: {
          'no-cache': noCache,
        },
      })
      .then((response) => {
        const data = response.data;
        commit('SET_CARD_LIST', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        commit('SET_CARD_LIST', []);
      });
  },
  getStatements({ commit }, { clientId, from, to, pagination }) {
    return axiosInstance
      .get(`/api/billing/${clientId}/statements`, {
        params: {
          from: from,
          to: to,
          page: pagination.currentPage,
          pageSize: pagination.pageSize,
          sortBy: pagination.sortBy,
        },
      })
      .then((response) => {
        var data = response.data;
        commit('SET_STATEMENT_LIST', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        commit('SET_STATEMENT_LIST', []);
      });
  },
  getLastStatement({ commit }, { clientId }) {
    return axiosInstance
      .get(`/api/billing/${clientId}/last-statement`)
      .then((response) => {
        var data = response.data;

        commit('SET_LAST_STATEMENT', data);
        var currentDate = new Date();
        var statementDate = new Date(data.createDate);
        if (
          currentDate.getMonth() === statementDate.getMonth() &&
          currentDate.getFullYear() === statementDate.getFullYear()
        ) {
          commit('setIsLastStatementCurrentMonthStatement', true);
        }

        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        commit('setIsLastStatementCurrentMonthStatement', true);
      });
  },
  getCancelStatements({ commit }, { clientId }) {
    return axiosInstance
      .get(`/api/billing/${clientId}/cancelstatementsummaries`)
      .then((response) => {
        var data = response.data;
        commit('SET_CANCEL_STATEMENTS', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        commit('SET_CANCEL_STATEMENTS', []);
      });
  },
  setCardAsPrimary({ commit }, { clientId, paymentServiceId }) {
    return axiosInstance
      .post(`/api/billing/${clientId}/card/set-primary`, {
        paymentServiceId: paymentServiceId,
      })
      .then((response) => {
        var data = response.data;
        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        return false;
      });
  },
  getClientCancellationReasons({ commit }, { clientId }) {
    return axiosInstance
      .get(`/api/billing/${clientId}/cancel-client-reasons`)
      .then((response) => {
        var data = response.data;
        commit('SET_CLIENT_CANCELLATION_REASONS', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        commit('SET_CLIENT_CANCELLATION_REASONS', '');
      });
  },
  async getProductData({ commit, rootState }, { clientId }) {
    clientId = clientId || rootState.client.client.id;
    try {
      commit('RESET_VALIDATION_RESULT');
      commit('setProductsLoading', true);

      var response = await axiosInstance.get(`/api/Products/${clientId}`);

      commit('setProductData', response.data.products);
      commit('SET_VALIDATION_RESULT', response.data.validationResult);
      commit('setProductsLoading', false);

      return response.data;
    } catch (error) {
      console.error(error);
      handleFailedNetworkResponse(this.dispatch, error);
      commit('setProductData', []);
      commit('setProductsLoading', false);
    }
  },
  async saveProductData({ commit }, { productData, clientId }) {
    try {
      commit('setProductSettingsLoading', true);
      var results = await axiosInstance.put(
        `/api/Products/${clientId}`,
        productData
      );
      var data = results.data;
      commit('setProductSettingsLoading', false);

      if (productData.paymentTerms) {
        commit('setProductTypePaymentMethod', {
          productType: productData.productId - 1,
          paymentMethod: productData.paymentTerms,
        });
      }

      if (productData.paymentId) {
        commit('setProductTypePaymentCard', {
          productType: productData.productId - 1,
          paymentCardId: productData.paymentId,
        });

        if (productData.productId === 1) {
          commit('setActiveCardInList', productData.paymentId);
        }
      }

      if (productData.spendLimit) {
        commit('setProductTypeSpendLimit', {
          productType: productData.productId - 1,
          spendLimitVal: productData.spendLimit,
        });
      }

      return data;
    } catch (error) {
      console.error(error);

      handleFailedNetworkResponse(this.dispatch, error);
      commit('setProductSettingsLoading', false);
    }
  },
  async getGroupBalances({ commit }, { clientId, pagination }) {
    try {
      const response = await axiosInstance.get(
        `/api/billing/${clientId}/groupbalancetransfer`,
        {
          params: {
            page: pagination.currentPage,
            pageSize: pagination.pageSize,
            sortBy: pagination.sortBy,
          },
        }
      );
      const data = response.data;
      commit('SET_GROUP_BALANCE_TRANSFER', data);
      return data;
    } catch (error) {
      console.error(error);
      handleFailedNetworkResponse(this.dispatch, error);
      commit('SET_GROUP_BALANCE_TRANSFER', null);
    }
  },
  async getSingleBalanceTransfer({ commit }, { clientId, groupTransaction }) {
    try {
      const response = await axiosInstance.get(
        `/api/billing/${clientId}/GroupBalanceTransfer/${groupTransaction.clientId}`
      );
      const data = response.data;
      commit('SET_SINGLE_BALANCE_TRANSFER', data);
      return data;
    } catch (error) {
      console.error(error);
      handleFailedNetworkResponse(this.dispatch, error);
      commit('SET_SINGLE_BALANCE_TRANSFER', null);
    }
  },

  async transferBalance({ commit }, { clientId, balanceTransfer }) {
    try {
      await axiosInstance.post(
        `/api/billing/${clientId}/GroupBalanceTransfer`,
        balanceTransfer
      );
    } catch (error) {
      console.error(error);
      handleFailedNetworkResponse(this.dispatch, error);
    }
  },
  getServiceTypeList({ commit }) {
    return axiosInstance
      .get(`/api/billing/billingTypes`)
      .then((response) => {
        var data = response.data;
        commit('SET_BILLING_TYPE_LIST', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
        commit('SET_BILLING_TYPE_LIST', []);
      });
  },

  async getBalanceByProductType({ commit, rootState }, productType) {
    try {
      const response = await axiosInstance.get(
        `/api/billing/${rootState.client.client.id}/balance/${productType}`
      );

      if (response instanceof Error) {
        // TODO: handle error
      }

      commit('setProductTypeBalance', {
        productType,
        productTypeBalance: response.data,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      handleFailedNetworkResponse(this.dispatch, error);
      commit('setProductTypeBalance', {
        productType,
        productTypeBalance: 0,
      });
    }
  },
  async refund({ commit }, { card, serviceTypeId }) {
    try {
      var response = await axiosInstance.post(
        `/api/billing/refund/${serviceTypeId}`,
        card
      );

      return response;
    } catch (error) {
      if (!error.response) {
        // network error
        this.dispatch('toastr/error', 'Error: Network Error');
      } else {
        this.dispatch(
          'toastr/error',
          'Oops there was an error with your payment: ' + error.response.data
        );
      }
      console.error(error);
    }
  },
  async removeTransaction({ commit }, removalDetails) {
    alert(removalDetails.amount);
  },
  async getAuthNetData({ commit }) {
    var data = {};
    await axiosInstance
      .get(`/api/billing/authnet`)
      .then((response) => {
        data = {
          clientKey: response.data.clientKey,
          apiLoginID: response.data.apiLoginID,
        };
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
      });

    return data;
  },
  async updatePendingCancellation({ commit }, clientId) {
    let pendingCancellation = false;

    await axiosInstance
      .get(`/api/billing/${clientId}/is-cancellation-scheduled`)
      .then((response) => {
        pendingCancellation = response.data;
      })
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
      });

    commit('SET_PENDING_CANCELLATION', pendingCancellation);
    return pendingCancellation;
  },
  async cancelPendingCancellation({ commit }, clientId) {
    const response = await axiosInstance
      .post(`api/billing/${clientId}/cancel-cancellation`)
      .then((response) => response.data)
      .catch((err) => {
        console.error(err);
        handleFailedNetworkResponse(this.dispatch, err);
      });

    this.dispatch('toastr/success', response);
    commit('SET_PENDING_CANCELLATION', false);
    return response;
  },
  async cancelClient({ commit }, cancellationData) {
    await commit('SET_IS_PROCESSING_CANCELLATION', true);
    let cancelResult = false;

    const response = await axiosInstance
      .post(
        `/api/Billing/${cancellationData.clientId}/cancel-client`,
        cancellationData
      )
      .catch((err) => {
        if (!err.response) {
          // network error
          this.dispatch('toastr/error', 'Error: Network error');
        } else {
          handleFailedNetworkResponse(this.dispatch, err);
        }
      });

    if (response.status === 200) {
      this.dispatch('toastr/success', response.data);
      await commit('SET_PENDING_CANCELLATION', true);
      cancelResult = true;
    } else {
      this.dispatch(
        'toastr/error',
        'Oops there was an error canceling this client: ' + response.data
      );
    }

    await commit('SET_IS_PROCESSING_CANCELLATION', false);

    return cancelResult;
  },
  reset({ commit }) {
    commit('SET_CARD_LIST', []);
    commit('SET_CANCEL_STATEMENTS', []);
    commit('SET_GROUP_BALANCE_TRANSFER', []);
    commit('setProductData', []);
    commit('setProductTypeBalance', {
      productType: 'Web',
      productTypeBalance: 0,
    });
    commit('setProductTypeBalance', {
      productType: 'Call',
      productTypeBalance: 0,
    });
    commit('SET_STATEMENT_LIST', []);
    commit('SET_PENDING_CANCELLATION', false);
  },
};
