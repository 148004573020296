import { store } from '@/store';
import uuidv4 from 'uuid/v4';

export default [
  // Admin Dashbaord
  {
    path: '/ad',
    name: 'admin_dashboard',
    component: () => import('@/pages/AdminDashboard'),
    meta: {
      authRequired: true,
      requiredRoles: [
        'Everest-Client-Services',
        'Everest-Corporate-Bucket-Account-Creator',
      ],
      beforeResolve(routeTo, routeFrom, next) {
        // clear out other data
        store.dispatch('client/reset');
        store.dispatch('channels/reset');
        store.dispatch('leads/reset');
        store.dispatch('billing/reset');
        store.dispatch('transactions/reset');
        store.dispatch('vendors/reset');
        store.dispatch('leadCredit/reset');
        store.dispatch('calls/reset');

        next();
      },
    },
  },
  // Leads
  {
    path: '/client',
    component: () =>
      import(/* webpackChunkName: "client" */ '@/pages/leads/Root'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (routeFrom.path === '/ad') {
          // clear out other data
          store.dispatch('client/reset');
          store.dispatch('channels/reset');
          store.dispatch('leads/reset');
          store.dispatch('billing/reset');
          store.dispatch('transactions/reset');
          store.dispatch('vendors/reset');
          store.dispatch('leadCredit/reset');
          store.dispatch('calls/reset');
          store.dispatch('channelGroups/resetGroups');
        }
        return store
          .dispatch('client/fetchClient', {
            clientId: routeTo.params.clientId,
          })
          .then((client) => {
            routeTo.params.client = client;
            next();
          })
          .catch((error) => {
            if (error === 401) {
              next({
                name: '403',
              });
            } else {
              const errorId = uuidv4();

              next({
                name: '500',
                params: {
                  errorId,
                },
              });
            }
          });
      },
    },

    props: (route) => ({
      client: route.params.client,
    }),
    children: [
      // Account page
      {
        path: ':clientId',
        name: 'client_root',
        meta: {
          async beforeResolve(routeTo, routeFrom, next) {
            if (routeTo.params.clientId) {
              // Get client
              store.dispatch('channelGroups/resetGroups');
              await store.dispatch('client/fetchClient', {
                clientId: routeTo.params.clientId,
              });
            }
            next();
          },
        },
        component: () =>
          import(/* webpackChunkName: "client" */ '@/pages/Dashboard'),
      },
      // Account
      {
        path: ':clientId/account',
        name: 'client_account',
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            store
              .dispatch('client/getClientAccountDetails', {
                clientId: routeTo.params.client.id,
              })
              .then((accountDetails) => {
                routeTo.params.accountDetails = accountDetails;
                next();
              });
          },
        },
        props: (route) => ({
          accountDetails: route.params.accountDetails,
        }),
        component: () =>
          import(/* webpackChunkName: "client" */ '@/pages/agent/Index'),
      },
      {
        path: ':clientId/pause',
        name: 'vacation_pause',
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            next();
          },
        },
        component: () =>
          import(/* webpackChunkName: "client" */ '@/pages/agent/Pause'),
      },
      // Leads
      {
        path: ':clientId/leads',
        name: 'client_leads',
        meta: {
          beforeResolve(routeTo, routeFrom, next) {
            next();
          },
        },
        component: () =>
          import(/* webpackChunkName: "leads" */ '@/pages/leads/Leads'),
      },
      // Leads Detail
      {
        path: ':clientId/leads/:id/details',
        name: 'client_leads_detail',
        component: () =>
          import(/* webpackChunkName: "leads" */ '@/pages/leads/Detail'),
        meta: {
          async beforeResolve(routeTo, routeFrom, next) {
            try {
              let leadResponse = null;

              leadResponse = await store
                .dispatch('leadDetail/getLead', {
                  leadId: routeTo.params.id,
                  leadType: routeTo.query.leadType,
                })
                .catch((err) => err);

              routeTo.params.lead = leadResponse;

              let packet = null;

              if (routeTo.query.leadType.toLowerCase() === 'call') {
                packet = await store.dispatch('leadDetail/getCallLeadPacket', {
                  outboundCallSid: routeTo.query.sid,
                });
              } else {
                packet = await store.dispatch('leadDetail/getLeadPacket', {
                  sessionId: leadResponse.sessionId,
                });
              }

              routeTo.params.packet = packet;

              next();
            } catch (err) {
              console.error(err);
              next();
            }
          },
        },
        props: (route) => ({
          lead: route.params.lead,
          packet: route.params.packet,
          leadId: route.params.id,
          leadType: route.query.leadType,
          clientId: route.params.clientId,
        }),
      },
      // State farm subsidies
      {
        path: ':clientId/stateFarmSubsidies',
        name: 'state_farm_subsidies',
        component: () =>
          import(
            /* webpackChunkName: "subsidy" */ '@/pages/subsidy/StateFarmSubsidies'
          ),
      },
      {
        path: ':clientId/leads/channels',
        name: 'leads_channels',
        meta: {
          authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            store.dispatch('clientRules/getClientRulesList').then(() => next());
          },
        },
        component: () =>
          import(/* webpackChunkName: "leads" */ '@/pages/leads/Channels'),
        props: (route) => {
          return {
            startingProductType: route.params.startingProductType,
          };
        },
      },
    ],
  },
  {
    path: '/staff-tools/:clientId?',
    name: 'StaffOnlyUtilities',
    component: () => import('@/pages/staff/StaffOnlyUtilities'),
    meta: {
      authRequired: true,
      requiredRoles: ['Everest-Client-Services'],
      async beforeResolve(routeTo, routeFrom, next) {
        if (routeTo.params.clientId) {
          // Get client
          await store.dispatch('client/fetchClient', {
            clientId: routeTo.params.clientId,
          });
        }
        next();
      },
    },
  },
  {
    path: '/leads/channeledit/:clientId/:clientQuoteTypeId',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('client/fetchClient', {
            clientId: routeTo.params.clientId,
          })
          .then((client) => {
            routeTo.params.client = client;
            next();
          });
      },
    },
    name: 'leads_channel_edit',
    component: () =>
      import(/* webpackChunkName: "leads_edit" */ '@/pages/leads/EditChannel'),
    props: (route) => ({
      client: route.params.client,
    }),
  },

  {
    path: '/leads/create-channel/:clientId',
    name: 'leads_channel_add',
    component: () =>
      import(/* webpackChunkName: "leads_edit" */ '@/pages/leads/AddChannel'),
  },

  {
    path: '/leads/create-channel/:clientId/:serviceType',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (
          routeTo.params.serviceType.toLocaleLowerCase() ===
          'CallCampaign'.toLocaleLowerCase()
        ) {
          routeTo.params.serviceType = 'call';
          routeTo.params.stateOverridesEnabled = true;
        }

        store
          .dispatch('client/fetchClient', {
            clientId: routeTo.params.clientId,
          })
          .then((client) => {
            routeTo.params.client = client;
            next();
          });
      },
    },
    name: 'all_leads_channel_add',
    props: true,
    component: () =>
      import(/* webpackChunkName: "leads_edit" */ '@/pages/leads/AddChannel'),
  },

  {
    path: '/leads/create-channel-lut/:clientId',
    name: 'channel_add_lut',
    component: () =>
      import(/* webpackChunkName: "leads" */ '@/pages/leads/AddChannelLut'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('client/fetchClient', {
            clientId: routeTo.params.clientId,
          })
          .then((client) => {
            routeTo.params.client = client;
            next();
          });
      },
    },
  },

  // Billing
  {
    path: '/billing',
    component: () =>
      import(/* webpackChunkName: "billing" */ '@/pages/billing/Billing'),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          .dispatch('client/fetchClient', {
            clientId: routeTo.params.clientId,
          })
          .then((client) => {
            routeTo.params.client = client;
            next();
          });
      },
    },

    props: (route) => ({
      client: route.params.client,
    }),
    children: [
      {
        path: '/billing/:clientId',
        name: 'billing',
        component: () =>
          import(/* webpackChunkName: "billing" */ '@/pages/billing/Billing'),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  // Support
  {
    path: '/support',
    name: 'client_Support',
    component: () =>
      import(/* webpackChunkName: "support" */ '@/pages/Support'),
    meta: {
      authRequired: true,
    },
  },
  // Subsidy
  {
    path: '/subsidy',
    name: 'subsidy',
    component: () => import('@/pages/subsidy/Index'),
    meta: {
      authRequired: true,
    },
  },
  // Health
  {
    path: '/health',
    name: 'health',
    component: () => import('@/pages/global/Health'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/logged-out',
    name: 'logged_out',
    component: () =>
      import(
        /* webpackChunkName: "loggedout" */ /* webpackMode: "lazy" */ '@/pages/global/LoggedOut'
      ),
    meta: {
      authRequired: false,
    },
  },

  // Client Dashboard
  {
    path: '/cd/:client_id',
    name: 'client_dashboard',
    component: () => import('@/pages/Dashboard'),
    meta: {
      requiredRoles: ['Everest'],
      authRequired: true,
    },
  },
  // Sign up
  {
    path: '/signup/:ioId?',
    name: 'signup',
    component: () => import('@/pages/signup/SetupContract'),
    meta: {
      authRequired: true,
      requiredRoles: ['Everest-Sales', 'Everest-Staff'],
      async beforeResolve(routeTo, routeFrom, next) {
        // Clear out old client data
        store.dispatch('client/clearClient');

        if (routeTo.params.ioId) {
          // Get an existing IO object from Id param
          await store
            .dispatch('signup/getSignupInfo', routeTo.params.ioId)
            .catch(() => {
              next({
                name: 'signup',
              });
            });
        } else if (routeTo.query.agency) {
          // Get a new IO object from salesforce query params
          await store.dispatch('signup/createSignupInfo', routeTo.query);
          next({
            name: 'signup',
            params: {
              ioId: store.state.signup.signupInfo.id,
            },
          });
        }
        next();
      },
    },
    props: (route) => ({
      ioId: route.params.ioId,
    }),
  },
  {
    path: '/signup/sign-contract/:ioId',
    name: 'sign_contract',
    component: () => import('@/pages/signup/SignContract.vue'),
    meta: {
      authRequired: false,
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('signup/getContractInfo', routeTo.params.ioId);
        next();
      },
      refreshOnServiceWorkerControllerChanged: false,
    },
  },
  // Root
  {
    path: '/',
    name: 'landing_page',
    component: () => import('@/pages/global/_Loading'),
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const { clientId } = store.state.auth.user;
        const requiredRoles = ['Everest-Client-Services'];
        if (store.getters['auth/userInRoles'](requiredRoles)) {
          next({
            name: 'admin_dashboard',
          });
        } else if (clientId) {
          next({
            name: 'client_root',
            params: {
              clientId,
            },
          });
        } else {
          store.dispatch('auth/login');
        }
      },
    },
  },
  {
    path: '/review-terms/:clientId',
    name: 'review_terms',
    component: () => import('@/pages/agent/ReviewContractTerms.vue'),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        const clientId = routeTo.params.clientId;

        await store.dispatch('client/fetchIsReviewTermsRequired', clientId);

        const termsData = await store.dispatch(
          'client/fetchReviewTermsData',
          clientId
        );

        routeTo.params.clientId = clientId;
        routeTo.params.termsData = termsData;
        next();
      },
    },
    props: (route) => ({
      clientId: route.params.clientId,
      termsData: route.params.termsData,
    }),
  },
  // Credit Dialer
  {
    path: '/creditDialer',
    name: 'creditDialer',
    component: () => import('@/pages/creditDialer/Index.vue'),
    meta: {
      authRequired: true,
      requiredRoles: ['Everest-Staff'],
    },
  },
  // Package / Product Manager
  {
    path: '/product-admin',
    name: 'ProductAdmin',
    component: () => import('@/pages/staff/ProductAdmin.vue'),
    meta: {
      authRequired: true,
      requiredRoles: ['Everest-Product'],
    },
  },

  // Secondary Territories Bulk Opt-In
  {
    path: '/territories-opt-in',
    name: 'SecondaryTerritoriesOptIn',
    component: () => import('@/pages/staff/SecondaryTerritoriesOptIn.vue'),
    meta: {
      authRequired: true,
      requiredRoles: ['Everest-Client-Services-Admin', 'Everest-Sales-Manager'],
      async beforeResolve(routeTo, routeFrom, next) {
        const isTerritoryExpansionEnabled =
          store.getters['featureFlagStore/getFlag']('TerritoryExpansion');

        // Redirect to 404 if feature flag not enabled
        if (isTerritoryExpansionEnabled) {
          next();
        } else {
          next({ name: '404' });
        }
      },
    },
  },

  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/global/403.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: require('@/pages/global/404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/500/:errorId',
    name: '500',
    component: () => import('@/pages/global/500.vue'),
    props: (route) => ({
      errorId: route.params.errorId,
    }),
  },

  {
    path: '/:catchAll(.*)',
    redirect: '404',
  },
];
