import { axiosInstance } from '@/plugins/axios';
import moment from 'moment';

const InProgressStatus = 'InProgress';
const CompleteStatus = 'Complete';
const ErrorStatus = 'Error';

// initial state
export const state = {
  currentAgentImportStatus: '',
  activeAgentImportStatus: '',
  terminatedAgentImportStatus: '',
  currentAgentImportStatusTime: '',
  cooperateEnabledStatus: 'UNKNOWN',
  thisDate: moment().format('YYYY/MM/DD HH:mm:ss'),
  loading: false,
};

// getters
export const getters = {
  loading: (state) => state.loading,
};

// actions
export const actions = {
  // I think this is just a duplicate of the feature flag and might make no sense to have
  IsFarmersCooperateEnabled({ commit }) {
    return axiosInstance
      .get(`/api/FarmersVendorImport/is-farmers-cooperate-enabled`)
      .then((response) => {
        var data = response.data;
        commit('SET_IS_COOPERATE_ENABLED', data);
        return data;
      })
      .catch((err) => {
        console.error(err);
        this.dispatch('toastr/error', err.response.data);
        commit('SET_IS_COOPERATE_ENABLED', 'ERROR');
      });
  },

  CurrentAgentImportStatus({ commit }) {
    return axiosInstance
      .get(`/api/FarmersVendorImport/current-agent-import-status`)
      .then((response) => {
        if (
          response != null &&
          response.data != null &&
          response.data.activeAgentImportStatus != null &&
          response.data.terminatedAgentImportStatus != null
        ) {
          var data = response.data;
          commit('SET_CURRENT_IMPORT_STATUS', data);

          commit('SET_CURRENT_IMPORT_STATUS_TIME', data.processingDate);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        this.dispatch('toastr/error', err.response.data);
        commit('SET_CURRENT_IMPORT_STATUS', 'Error');
      });
  },

  ImportFarmersAgents({ commit, dispatch }) {
    return axiosInstance
      .post(`/api/FarmersVendorImport/import-farmers-agents`, {
        dateContent: state.thisDate,
      })
      .then((response) => {
        const data = response.data;

        commit('SET_CURRENT_IMPORT_STATUS', data);
      })
      .catch((err) => {
        dispatch(
          'toastr/error', 
          'Error(s): ' + err.response.data,
          { root: true }
        );
      });
  },
};

// mutations
export const mutations = {
  SET_LOADING_STATUS(state, newValue) {
    state.loading = newValue;
  },
  SET_CURRENT_IMPORT_STATUS(state, newValue) {
    if (
      newValue.activeAgentImportStatus === InProgressStatus ||
      newValue.terminatedAgentImportStatus === InProgressStatus
    ) {
      state.currentAgentImportStatus = 'In progress';
    } else if (
      newValue.activeAgentImportStatus === CompleteStatus &&
      newValue.terminatedAgentImportStatus === CompleteStatus
    ) {
      state.currentAgentImportStatus = 'Complete';
    } else if (
      newValue.activeAgentImportStatus === '' &&
      newValue.terminatedAgentImportStatus === ''
    ) {
      state.currentAgentImportStatus = 'Not started';
    } else if (
      newValue.activeAgentImportStatus === ErrorStatus ||
      newValue.terminatedAgentImportStatus === ErrorStatus
    ) {
      state.currentAgentImportStatus = 'Error';
    }

    state.activeAgentImportStatus = newValue.activeAgentImportStatus;
    state.terminatedAgentImportStatus = newValue.terminatedAgentImportStatus;
  },

  SET_CURRENT_IMPORT_STATUS_TIME(state, newValue) {
    state.currentAgentImportStatusTime = newValue;
  },

  SET_IS_COOPERATE_ENABLED(state, newValue) {
    state.cooperateEnabledStatus = newValue;
  },
};
