<template>
  <el-row>
    <span class="add-payment-title">Add new payment method</span>
    <card-form
      v-if="!hasMaxCards"
      ref="cardForm"
      class="pt-2"
      :is-edit-card-mode="false"
      :show-primary-select="false"
      @cancel="handleClose"
      @save="handleSave"
    />
    <div v-else>
      <div id="max-card-text" class="text-center">
        You have reached the maximum number of credit cards. Customer Service
        will reach out to you and assist for further action.
      </div>

      <div class="text-left pb-4">
        <el-button type="primary" @click="handleClose">Back</el-button>
      </div>
    </div>
  </el-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { axiosInstance } from '@/plugins/axios';
import CardForm from '@/components/billing/CardForm';

export default {
  name: 'AddPaymentMethod',
  components: {
    CardForm,
  },
  props: {
    clientId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('reactivation', ['creditCards', 'productTypes']),
    ...mapGetters('auth', ['isStaff']),

    hasMaxCards() {
      return this.creditCards?.length >= 10;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions('reactivation', [
      'getReactivationPaymentInfoAsync',
      'updatePaymentIdForProductAsync',
    ]),

    initData() {
      if (this.hasMaxCards) {
        this.sendMaxCardEmail();
      } else {
        this.$refs.cardForm.setCard({});
      }
    },
    handleClose() {
      this.$emit('close');
    },
    async handleSave(card) {
      // Update payment info
      await this.getReactivationPaymentInfoAsync(this.clientId);

      const cardId = card.paymentId;

      // Add card to each product
      for (const product of this.productTypes) {
        await this.updatePaymentIdForProductAsync({ cardId, product });
      }

      this.$emit('close');
    },
    async sendMaxCardEmail() {
      if (this.isStaff) {
        // Don't send email alert if staff
        return;
      }

      await axiosInstance
        .post('api/reactivation/cs-alert', {
          clientId: this.clientId,
          message: 'Client attempted to add new card with 10 cards on file',
        })
        .catch((err) => {
          this.$store.dispatch('toastr/error', 'Error: Network Error');
          console.error(err);
          return Promise.reject(err);
        });
    },
  },
};
</script>

<style scoped>
.add-payment-title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
}

#max-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
}
</style>
