// eslint-disable-next-line
import axiosInstance from '@/plugins/axios';

export const state = {};

export const getters = {};

export const actions = {};

export const mutations = {};
