import axios from 'axios';
import authService from '@/services/auth.service';
import errorTracker from './errorTracker';

const defaultOptions = {
  baseURL: process.env.VUE_APP_API_URL,
  crossDomain: true,
  headers: {},

  // request interceptor handler
  reqHandleFunc: async (config) => {
    const token = await authService.getToken();

    if (!token) {
      throw new axios.Cancel('Bad token');
    }

    config.headers.common.Authorization = `Bearer ${token}`;

    return config;
  },
  reqErrorFunc: async (error) => {
    throw error;
  },

  // response interceptor handler
  resHandleFunc: (response) => response,
  resErrorFunc: (error) => {
    if (error.message === 'Bad token') {
      console.error('Bad token');
      authService.reset();
      authService.login();
      return Promise.reject(error);
    }

    if (!error.response) {
      console.error('Network error. Check your connection.');
      return Promise.reject(error);
    }

    const authHeader = error.response.headers?.['www-authenticate'];
    if (authHeader === 'Bearer error="invalid_token"') {
      console.error('Bad token provided', error);
      authService.login();
      return;
    }

    errorTracker.trackException({ exception: error });

    return Promise.reject(error);
  },
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
  async (config) => defaultOptions.reqHandleFunc(config),
  async (error) => defaultOptions.reqErrorFunc(error)
);

axiosInstance.interceptors.response.use(
  (response) => defaultOptions.resHandleFunc(response),
  (error) => defaultOptions.resErrorFunc(error)
);

export default axiosInstance;

const axiosAnonymous = axios.create(defaultOptions);

export { axiosInstance, axiosAnonymous };
