const useViewSizes = {
  methods: {
    onSizeUpdate() {
      this.$store.dispatch('responsive/setWidth', window.innerWidth)
    },
  },
  mounted() {
    window.addEventListener('resize', this.onSizeUpdate)
    this.onSizeUpdate()
  },
  unmounted() {
    window.removeEventListener('resize', this.onSizeUpdate)
  },
}

export default useViewSizes
