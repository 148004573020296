import _ from '@/utils/store-helpers';

export const state = {
  all: [],
  isSearching: false,
  isLoading: false,
  filters: [],
  priceModifiers: [],
  excludableClients: [],
  vendors: [],
  editingProduct: null,
  deepSearchIds: [],
};

export const getters = {
  packages: (state) => state.all,

  isSearching: (state) => state.isSearching,

  isLoading: (state) => state.isLoading,

  filters: (state) => state.filters,

  priceModifiers: (state) => state.priceModifiers,

  excludableClients: (state) => state.excludableClients,

  vendors: (state) => state.vendors,

  editingProduct: (state) => state.editingProduct,

  deepSearchIds: (state) => state.deepSearchIds,
};

export const mutations = {
  setAll: _.set('all'),
  setIsSearching: _.set('isSearching'),
  setIsLoading: _.set('isLoading'),
  setFilters: _.set('filters'),
  setPriceModifiers: _.set('priceModifiers'),
  setEditingProduct: _.set('editingProduct'),
  setExcludableClients: _.set('excludableClients'),
  setVendors: _.set('vendors'),
  setDeepSearchIds: _.set('deepSearchIds'),
};

export const actions = {
  async fetchProductSummaries({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/packages',
      errorMessage: 'Failed to get products summary.',
      loadingMutation: 'packages/setIsSearching',
    });

    if (!response) return;

    commit('setAll', response.data);
  },

  async fetchProduct({ commit, dispatch }, packageId) {
    const response = await dispatch('request/get', {
      url: `/api/packages/${packageId}`,
      errorMessage: 'Failed to get product.',
      loadingMutation: 'packages/setIsLoading',
    });

    if (!response) return;

    return response.data;
  },

  async getEditingProduct({ commit, dispatch }, packageId) {
    const product = await dispatch('fetchProduct', packageId);

    dispatch('setEditingProduct', product);
  },

  setEditingProduct({ commit }, product) {
    commit('setEditingProduct', product);
  },

  async createProduct({ commit, dispatch }, product) {
    const response = await dispatch('request/post', {
      url: '/api/packages',
      data: product,
      errorMessage: 'Failed to create product.',
      loadingMutation: 'packages/setIsLoading',
    });

    if (!response) return false;

    dispatch('fetchProductSummaries');
    return true;
  },

  async updateProduct({ commit, dispatch }, product) {
    const response = await dispatch('request/put', {
      url: `/api/packages/${product.id}`,
      data: product,
      errorMessage: 'Failed to update product.',
      loadingMutation: 'packages/setIsLoading',
    });

    if (!response) return false;

    dispatch('fetchProductSummaries');
    return true;
  },

  async searchDeep({ commit, dispatch }, searchText) {
    const response = await dispatch('request/get', {
      url: '/api/packages/deep',
      params: { searchText },
      errorMessage: 'Failed to search for given Id.',
      loadingMutation: 'packages/setIsSearching',
    });

    if (!response) return;

    commit('setDeepSearchIds', response.data);
  },

  async clearDeepSearch({ commit }) {
    commit('setDeepSearchIds', []);
  },

  async deleteProduct({ dispatch }, productId) {
    const response = await dispatch('request/delete', {
      url: `/api/packages/${productId}`,
      errorMessage: 'Failed to delete product.',
      loadingMutation: 'packages/setIsLoading',
    });

    if (!response) return;

    dispatch('fetchProductSummaries');
  },

  async getFilters({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/packages/filters',
      errorMessage: 'Failed to get filters.',
    });

    if (!response) return;

    commit('setFilters', response.data);
  },

  async getPriceModifiers({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/packages/price-modifiers',
      errorMessage: 'Failed to get price modifiers.',
    });

    if (!response) return;

    commit('setPriceModifiers', response.data);
  },

  async getExcludableClients({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/packages/excludeable/clients',
      errorMessage: 'Failed to get excludable clients.',
    });

    if (!response) return;

    commit('setExcludableClients', response.data);
  },

  async getVendors({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/vendors',
      errorMessage: 'Failed to get vendors.',
    });

    if (!response) return;

    commit('setVendors', response.data);
  },
};
