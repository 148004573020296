import { axiosInstance } from '@/plugins/axios';

import _ from '@/utils/store-helpers';

// initial state
export const state = {
  searchResults: [],
  isSearching: false,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
  },
  sortBy: '',
  includeCancel: false,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  setSearchResults: _.set('searchResults', false),
  setIsSearching: _.set('isSearching', false),
  setPagination: _.set('pagination', false),
  setCurrentPage(state, newValue) {
    state.pagination.currentPage = newValue;
  },
  setPageSize(state, newValue) {
    state.pagination.pageSize = newValue;
  },
  setSortBy: _.set('sortBy', false),
  setIncludeCancel(state, newValue) {
    state.includeCancel = newValue;
  },
};

// actions
export const actions = {
  async searchForClient({ commit, state }, search = null) {
    try {
      commit('setIsSearching', true);

      var results = await axiosInstance.get(`/api/clients/search`, {
        params: {
          q: search,
          page: state.pagination.currentPage,
          pageSize: state.pagination.pageSize,
          sortBy: state.sortBy,
          includeCancel: state.includeCancel,
        },
      });

      var data = results.data;

      commit('setSearchResults', data.items);
      commit('setPagination', data.metaData);

      commit('setIsSearching', false);
      return data;
    } catch (error) {
      console.error(error);
      commit('setIsSearching', false);
    }
  },
};
