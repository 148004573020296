/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {},
    registered(registration) {
      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update()
      }, 1000 * 60 * 60) // hourly checks
    },
    cached() {},
    updatefound() {},
    updated(registration) {
      document.dispatchEvent(
        new CustomEvent('swUpdated', {
          detail: registration,
        })
      )
    },
    offline() {},
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
