export default class WebLeadIntervalMap {
  constructor(schedules = [], step = '00:30:00') {
    this._step = step
    this._schedules = schedules
    this._showInput = this._showInput.bind(this)
    this._hideInput = this._hideInput.bind(this)
    this._submitCell = this._submitCell.bind(this)
    this.init()
  }

  get step() {
    return this._step
  }

  static get stepOptions() {
    return [
      { label: '30 Min', value: '00:30:00' },
      { label: '1 Hour', value: '01:00:00' },
    ]
  }

  /*
    Code taken from Calls IntervalMap and reworked to fit Web Leads
  }
  */
  init() {
    const step = this._step
    // We don't want inactive days!
    const dayPartSchedules = this._schedules.reduce((dayParts, schedule) => {
      if (schedule.isActive) {
        dayParts.push(...schedule.dayPartSchedules)
      }
      return dayParts
    }, [])

    // Build the map
    const allTimeKeys = []
    for (const dayPart of dayPartSchedules) {
      let currentInterval = dayPart.dayPartCap.start

      while (currentInterval) {
        if (!this[currentInterval]) {
          this[currentInterval] = Array(7).fill('-')
          allTimeKeys.push(currentInterval)
        }
        this[currentInterval][dayPart.dayPartCap.dayIndex] = 0
        currentInterval = WebLeadIntervalMap.nextTime(
          currentInterval,
          step,
          dayPart.dayPartCap.end
        )
        if (currentInterval === dayPart.dayPartCap.end) {
          break
        }
      }

      for (const intervalCap of dayPart.intervalCaps) {
        this[intervalCap.start][dayPart.dayPartCap.dayIndex] +=
          intervalCap.limit
      }
    }

    // Sort the time keys for rendering
    this.keys = allTimeKeys.sort((a, b) => {
      const aSecs = WebLeadIntervalMap.toSeconds(a)
      const bSecs = WebLeadIntervalMap.toSeconds(b)
      return aSecs > bSecs ? 1 : -1
    })
  }

  buildTableBody() {
    const buildCol = (dayIndex, startTime) => `
      <td
        ${dayIndex === 7 ? 'last-col' : ''}
        data-col-index="${dayIndex === 0 ? 7 : dayIndex}"
        data-day="${dayIndex}"
        data-start="${startTime}"
        class="${this[startTime][dayIndex] === '-' ? 'disabled' : ''}"
      >
        <span class="interval-table-label">
          ${this[startTime][dayIndex]}
        </span>
      </td>
    `

    let intervalRows = ''
    let rowCount = 0

    if (this.keys.length === 0) {
      intervalRows = '<tr><td colspan="8">(No Active Days)</td></tr>'
    } else {
      // Build the table rows from keys.
      if (!this['00:00:00']) {
        intervalRows += `
          <tr>
            <th></th>
            <td colspan="7"><small class="text-muted">(No Prior Entries)</small></td>
          </tr>
          `
      }

      const stepSeconds = WebLeadIntervalMap.toSeconds(this._step)
      let priorSeconds = WebLeadIntervalMap.toSeconds(this.keys[0]) - stepSeconds
      const lastKey = this.keys[this.keys.length - 1]
      for (const interval of this.keys) {
        const nextStepSeconds = priorSeconds + stepSeconds
        const actualNextSeconds = WebLeadIntervalMap.toSeconds(interval)
        if (actualNextSeconds > nextStepSeconds) {
          if (actualNextSeconds > nextStepSeconds + stepSeconds) {
            intervalRows += `
              <tr>
                <th>...</th>
                <td colspan="7"><small class="text-muted">(Collapsed)</small></td>
              </tr>
              `
          } else {
            const disabledHour = new Date(nextStepSeconds * 1000)
              .toISOString()
              .substr(11, 8)
            this[disabledHour] = Array(7).fill('-')
            intervalRows += `
            <tr>
              <th>${WebLeadIntervalMap.formatTime(disabledHour, false, true)}</th>
              ${buildCol(1, disabledHour)}
              ${buildCol(2, disabledHour)}
              ${buildCol(3, disabledHour)}
              ${buildCol(4, disabledHour)}
              ${buildCol(5, disabledHour)}
              ${buildCol(6, disabledHour)}
              ${buildCol(0, disabledHour)}
            </tr>
            `
            delete this[disabledHour]
          }
        }
        rowCount++
        intervalRows += `
        <tr
          ${interval === lastKey ? 'last-row' : ''}
          data-row-index="${rowCount}"
        >
          <th>${WebLeadIntervalMap.formatTime(interval, false, true)}</th>
          ${buildCol(1, interval)}
          ${buildCol(2, interval)}
          ${buildCol(3, interval)}
          ${buildCol(4, interval)}
          ${buildCol(5, interval)}
          ${buildCol(6, interval)}
          ${buildCol(0, interval)}
        </tr>
        `
        priorSeconds = actualNextSeconds
      }

      intervalRows += `
        <tr>
          <th></th>
          <td colspan="7"><small class="text-muted">(No Later Entries)</small></td>
        </tr>
        `
    }

    const tbody = document.createElement('tbody')
    tbody.innerHTML = intervalRows
    const activeCells = tbody.querySelectorAll(
      '[data-start]:not([class*="disabled"])'
    )
    for (const cell of activeCells) {
      cell.addEventListener('click', this._showInput)
    }

    return tbody
  }

  _submitCell(e) {
    const ESC = 27
    const ENTER = 13
    const TAB = 9

    if (e.keyCode === ENTER) {
      this._hideInput(e.currentTarget.parentNode)
    } else if (e.keyCode === TAB) {
      const nextCell = this._nextCell(e.currentTarget, e.shiftKey)
      nextCell.click()
    } else if (e.keyCode === ESC) {
      this._hideInput(e.currentTarget.parentNode, true)
    } else {
      return
    }

    e.preventDefault()
    return false
  }

  _nextCell(currentTarget, flowBack = false) {
    if (
      currentTarget.nodeName !== 'INPUT' ||
      currentTarget.parentNode.nodeName !== 'TD' ||
      currentTarget.parentNode.parentNode.nodeName !== 'TR' ||
      currentTarget.parentNode.parentNode.parentNode.nodeName !== 'TBODY'
    ) {
      throw new Error(
        '_nextCell has been broken by changes. Please check the structure as expected above.'
      )
    }
    const lastRowIndex = +currentTarget.parentNode.parentNode.parentNode
      .querySelector('[last-row]')
      .getAttribute('data-row-index')
    let nextRowIndex =
      +currentTarget.parentNode.parentNode.getAttribute('data-row-index')
    let nextColIndex = +currentTarget.parentNode.getAttribute('data-col-index')
    for (let i = 0; i < 1000; i++) {
      if (flowBack) {
        nextRowIndex--
        if (nextRowIndex < 0) {
          nextRowIndex = lastRowIndex
          nextColIndex--
          if (nextColIndex < 1) {
            nextColIndex = 7
          }
        }
      } else {
        nextRowIndex++
        if (nextRowIndex > lastRowIndex) {
          nextRowIndex = 0
          nextColIndex++
          if (nextColIndex > 7) {
            nextColIndex = 1
          }
        }
      }

      const nextColCandidate =
        currentTarget.parentNode.parentNode.parentNode.querySelector(
          `tr[data-row-index="${nextRowIndex}"]>td[data-col-index="${nextColIndex}"]:not([class*="disabled"])`
        )

      if (nextColCandidate !== null) {
        return nextColCandidate
      }
    }
    throw new Error('Failed to find next cell')
  }

  _hideInput(cell, isCancelling = false) {
    if (cell instanceof FocusEvent) {
      cell = cell.currentTarget.parentNode
    }
    const label = cell.children[0]
    const input = cell.children[1]

    const labelText = label.innerText.trim()
    if (isCancelling) {
      input.value = labelText
    } else if (input.value !== labelText) {
      const dayIndex = +cell.getAttribute('data-day')
      const start = cell.getAttribute('data-start')
      this[start][dayIndex] = +input.value
      this.onSubmit({
        limit: +input.value,
        dayIndex: dayIndex,
        start: start,
      })
    }

    label.innerText = input.value

    input.classList.add('d-none')
    label.classList.remove('d-none')

    cell.addEventListener('click', this._showInput)
    input.removeEventListener('keydown', this._submitCell)
    input.removeEventListener('blur', this._hideInput)

    input.parentNode.removeChild(input)

    this._lastCell = null
  }

  _showInput(e) {
    const cell = e.currentTarget
    cell.innerHTML += `
    <input
      type="number"
      value="${
        this[cell.getAttribute('data-start')][cell.getAttribute('data-day')]
      }"
      min="0"
      class="interval-table-input d-none"
    />`
    const label = cell.children[0]
    const input = cell.children[1]

    if (this._lastCell) {
      this._hideInput(this._lastCell)
    }

    label.classList.add('d-none')
    input.classList.remove('d-none')

    cell.removeEventListener('click', this._showInput)
    input.addEventListener('keydown', this._submitCell)
    input.addEventListener('blur', this._hideInput)

    input.focus()
    input.select()

    this._lastCell = cell
  }

  static calculateStep(cap) {
    const startSeconds = WebLeadIntervalMap.toSeconds(cap.start)
    const endSeconds = WebLeadIntervalMap.toSeconds(cap.end)
    const diffSeconds = endSeconds - startSeconds
    const intervalStep = new Date(diffSeconds * 1000)
      .toISOString()
      .substr(11, 8)
    return intervalStep
  }

  static parseTime(time) {
    const values = (time || '').split(':')

    if (values.length >= 2) {
      const hours = parseInt(values[0], 10)
      const minutes = parseInt(values[1], 10)

      let seconds = 0
      if (values.length > 2) {
        seconds = parseInt(values[2], 10)
      }

      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      }
    }

    if (values.length === 1) {
      const minutes = parseInt(values[0], 10)
      return {
        hours: Math.floor(minutes / 60),
        minutes: minutes % 60,
        total: minutes,
      }
    }

    return {
      hours: 0,
      minutes: 0,
      total: 0,
    }
  }

  static formatTime(time, incSeconds = false, incAmPm = false) {
    if (typeof time === 'string') {
      time = WebLeadIntervalMap.parseTime(time)
    }

    const suffix = time.hours >= 12 && time.hours < 24 ? ' PM' : ' AM'

    if (time.hours > 12 && incAmPm) {
      time.hours -= 12
    } else if (time.hours === 0 && incAmPm) {
      time.hours = 12
    }

    const hours = time.hours < 10 ? '0' + time.hours : time.hours
    const minutes = time.minutes < 10 ? '0' + time.minutes : time.minutes
    const seconds = time.seconds < 10 ? '0' + time.seconds : time.seconds

    return (
      hours +
      ':' +
      minutes +
      (incSeconds ? ':' + seconds : '') +
      (incAmPm ? suffix : '')
    )
  }

  static compareTime(time1, time2) {
    const value1 = WebLeadIntervalMap.parseTime(time1)
    const value2 = WebLeadIntervalMap.parseTime(time2)
    const minutes1 = value1.minutes + value1.hours * 60
    const minutes2 = value2.minutes + value2.hours * 60

    if (minutes1 === minutes2) {
      return 0
    }

    return minutes1 > minutes2 ? 1 : -1
  }

  static nextTime(time, step, max = '24:00:00') {
    const timeValue = WebLeadIntervalMap.parseTime(time)
    const stepValue = WebLeadIntervalMap.parseTime(step)

    const next = {
      hours: timeValue.hours,
      minutes: timeValue.minutes,
      seconds: 0,
    }

    next.minutes += stepValue.minutes
    next.hours += stepValue.hours
    next.hours += Math.floor(next.minutes / 60)
    next.minutes = next.minutes % 60

    const nextTime = WebLeadIntervalMap.formatTime(next, true)

    if (WebLeadIntervalMap.compareTime(nextTime, max) > 0) {
      return max
    }

    return nextTime
  }

  static toSeconds(time) {
    if (typeof time === 'string') {
      time = WebLeadIntervalMap.parseTime(time)
    }
    const minutes = time.minutes + time.hours * 60
    const seconds = time.seconds + minutes * 60
    return seconds
  }

  static getTimeRange(start, end, step) {
    const result = []

    if (start && end && step) {
      let current = start
      while (WebLeadIntervalMap.compareTime(current, end) <= 0) {
        const option = {
          label: WebLeadIntervalMap.formatTime(current, false, true),
          value: current,
        }
        result.push(option)
        if (current === end) break
        current = WebLeadIntervalMap.nextTime(current, step, end)
      }
    }

    return result
  }
}
