import _ from '@/utils/store-helpers';

export const state = {
  lookupValues: [],
  isLoading: false,
  sortBy: '',
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
  },
};
export const getters = {
  isLoading: (state) => state.isLoading,
  lookupValues: (state) => state.lookupValues,
  currentPage: (state) => state.pagination.currentPage,
  pagination: (state) => state.pagination,
};

export const mutations = {
  setLoading: _.set('isLoading'),
  setLookupValue: _.set('lookupValues'),
  setPagination: _.set('pagination'),
  SET_PAGE(state, pageNumber) {
    state.pagination.currentPage = pageNumber;
  },
  SET_PAGE_SIZE(state, pageSize) {
    state.pagination.pageSize = pageSize;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
};

export const actions = {
  async getLookupValues({ dispatch, commit, state }) {
    const response = await dispatch('request/get', {
      url: 'api/lookupvalue',
      errorMessage: 'failed to get lookup values',
      loadingMutation: 'lookupValue/setLoading',
      params: { ...state.pagination, sortBy: state.sortBy },
    });
    if (!response) {
      return;
    }
    commit('setLookupValue', response.data.items);
    commit('setPagination', response.data.metaData);
  },
  async setPage({ dispatch, commit }, pageNumber) {
    commit('SET_PAGE', pageNumber);
    dispatch('getLookupValues');
  },
  async setSortBy({ dispatch, commit }, sortBy) {
    commit('SET_SORT_BY', sortBy);
    dispatch('getLookupValues');
  },
  async setPageSize({ dispatch, commit }, pageSize) {
    commit('SET_PAGE_SIZE', pageSize);
    dispatch('getLookupValues');
  },
  async save({ dispatch, commit }, lookupValue) {
    const response = await dispatch('request/post', {
      url: 'api/lookupValue/',
      errorMessage: 'failed to save lookup values',
      loadingMutation: 'lookupValue/setLoading',
      params: lookupValue,
    });
    return response;
  },
};
