import _ from '@/utils/store-helpers';
import { axiosInstance } from '@/plugins/axios';

export const state = {
  calls: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
  },
  sortBy: '-PRIMARY_PHONE',
  loading: false,
  visibleDateRange: [
    /* From */
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    /* To */
    dateRangeEnd(),
  ],
};

function dateRangeEnd() {
  var d = new Date();
  d.setHours(23, 59, 59, 999);
  return d;
}

export const actions = {
  async getCalls({ commit }, { clientId, search, from, to }) {
    commit('setLoading', true);

    var params = {
      ClientId: clientId,
      pageNum: state.pagination.currentPage,
      PageSize: state.pagination.pageSize,
      from,
      to,
    };

    if (search != null && search !== '') {
      params.search = search;
    }

    if (state.sortBy != null && state.sortBy !== '') {
      params.sortBy = state.sortBy;
    }

    const response = await axiosInstance
      .get(`api/leads/calls/search`, {
        params: params,
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
    commit('setCalls', response.data.items || []);
    commit('setPagination', response.data.metaData);
    commit('setLoading', false);

    return response.data;
  },

  setCurrentPage({ commit, dispatch }, { pageNumber, getCallsData }) {
    commit('setCurrentPage', pageNumber);
    dispatch('getCalls', getCallsData);
  },

  setSortBy({ commit, dispatch }, { sortBy, getCallsData }) {
    commit('setSortBy', sortBy);
    dispatch('getCalls', getCallsData);
  },

  setVisibleDateRange({ commit }, dateRange) {
    commit('setVisibleDateRange', dateRange);
  },

  reset({ commit }) {
    commit('setCalls', []);
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setCalls: _.set('calls'),
  setPagination: _.set('pagination'),
  setSortBy: _.set('sortBy'),
  setVisibleDateRange: _.set('visibleDateRange'),

  setCurrentPage(state, newValue) {
    state.pagination.currentPage = newValue;
  },

  setPageSize(state, newValue) {
    state.pagination.pageSize = newValue;
  },
};
