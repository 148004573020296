import axiosInstance from '@/plugins/axios';
import { SchedulerService } from './schedulerService';

export class ChannelGroupService {
  constructor() {
    this.schedulerService = new SchedulerService();
  }

  async upsertSubscriptionGroup(client, group, channelIds, oldGroup, callback) {
    const upsertGroup = {
      subscriptionGroupId: group.subscriptionGroupId,
      groupName: group.groupName,
      clientId: client.id,
      subscriptions: [],
      isIntervalEnabled: group.isIntervalEnabled,
    };

    let addedChannelIds = [];
    let removedChannelIds = [];
    // if oldgroup null means new item
    if (oldGroup) {
      upsertGroup.subscriptions = oldGroup.subscriptions;
      addedChannelIds = channelIds.filter((channelId) => {
        return !oldGroup.subscriptions.some((sub) => {
          return sub.clientQuoteTypeId === channelId;
        });
      });

      removedChannelIds = oldGroup.subscriptions.reduce((removedIds, sub) => {
        if (!channelIds.includes(sub.clientQuoteTypeId)) {
          removedIds.push(sub.clientQuoteTypeId);
        }
        return removedIds;
      }, []);
    } else {
      // id for new group should be null.
      upsertGroup.subscriptionGroupId = null;
      addedChannelIds = channelIds;
    }

    const response = await axiosInstance
      .post(`api/leadtype/calls/subscriptiongroup`, upsertGroup)
      .catch((err) => err);
    const groupId = response.data;

    if (response instanceof Error) {
      console.error(response);
      if (callback) callback(new Error('Invalid response'));
      return false;
    }

    if (addedChannelIds.length > 0) {
      await this.addChannelsToGroup(addedChannelIds, groupId, callback);
    }

    if (removedChannelIds.length > 0) {
      await this.removeChannelsFromGroup(removedChannelIds, groupId);
    }
    // Set id from API value.
    group.subscriptionGroupId = groupId;
    await this.schedulerService.saveChannelGroupSchedules(group, callback);
  }

  async addChannelsToGroup(channelIds, groupId, callback) {
    const method = 'post';
    await this.addRemoveChannelsFromGroup(
      channelIds,
      groupId,
      method,
      callback
    );
  }

  async removeChannelsFromGroup(channelIds, groupId, callback) {
    const method = 'delete';
    await this.addRemoveChannelsFromGroup(
      channelIds,
      groupId,
      method,
      callback
    );
  }

  async addRemoveChannelsFromGroup(channelIds, groupId, method, callback) {
    const query = channelIds.map((id) => `ids=${id}`).join('&');
    const response = await axiosInstance
      .get(`/api/leadtype/callTypes?${query}`)
      .catch((err) => err);

    if (response instanceof Error) {
      console.error(response);
      if (callback) callback(new Error(response));
      return;
    }

    const channelCallTypes = response.data;

    for (const channelId of Object.keys(channelCallTypes)) {
      if (method === 'post') {
        await axiosInstance
          .post(
            `api/leadtype/calls/subscription/add/${channelId}/${groupId}/${channelCallTypes[channelId]}`
          )
          .catch((err) => err);
      } else {
        await axiosInstance
          .delete(
            `api/leadtype/calls/removesubscription/${channelId}/${groupId}/${channelCallTypes[channelId]}`
          )
          .catch((err) => err);
      }
    }
  }

  async fetchGroups(clientId, callback) {
    const response = await axiosInstance
      .get(`api/leadtype/${clientId}/calls/subscriptiongroup`)
      .catch((err) => err);

    if (response instanceof Error || response.data === '') {
      // TODO: Error Handling
      console.error(response);
      if (callback) callback(new Error('Failed to get channel groups'));
      return [];
    }
    return response.data;
  }

  async deleteSubscriptionGroup(groupId, callback) {
    const response = await axiosInstance
      .delete(`api/leadtype/calls/subscriptiongroup/${groupId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      if (callback) callback(new Error('Failed to delete Subscription Group'));
    }
  }

  async deleteChannelFromGroup(groupId, subscription) {
    const callTypesResponse = await axiosInstance
      .get(`/api/leadtype/callTypes?ids=${subscription.clientQuoteTypeId}`)
      .catch((err) => err);

    if (callTypesResponse instanceof Error) {
      // TODO: Error handling
      console.error(callTypesResponse);
      return;
    }

    const callType = callTypesResponse.data[subscription.clientQuoteTypeId];

    const removeResponse = await axiosInstance
      .delete(
        `api/leadtype/calls/removesubscription/${subscription.clientQuoteTypeId}/${groupId}/${callType}`
      )
      .catch((err) => err);

    if (removeResponse instanceof Error) {
      // TODO: Error handling
      console.error(removeResponse);
    }
  }
}
