import { rootErrors } from '@/services/auth.service';

const errorHandler = (err, vm, info) => {
  console.error(err, vm, info);
};

export default {
  errorHandler,
};

window.onerror = (msg, url, line, col, error) => {
  try {
    if (msg ?? '' === 'ResizeObserver loop limit exceeded') {
      return;
    }
    const errorDetails = { msg, url, line, col, error };
    console.error(errorDetails);
    rootErrors.push(errorDetails);
  } catch (error) {
    console.error(error);
  }
};

window.addEventListener('unhandledrejection', function (event) {
  rootErrors.push({ event });
});

/**
 * This is an attempt to diagnose a redirect loop in the login system.
 * auth.service.js@login will increment callbackCount on loginRedirect.
 * If the page successfully loads then we're not in a redirect loop.
 * rootErrors is pushed to on unhandled errors, used to diagnose redirects.
 */

const { sessionStorage } = window;
if (!sessionStorage.callbackCount) {
  sessionStorage.callbackCount = 0;
}

window.onload = () => {
  sessionStorage.callbackCount = 0;
};
