import _ from '@/utils/store-helpers';
import { groupNumbers } from '@/plugins/filters';
import packagePriorities from '@/components/signup/LookupTool/packagePriorities';
import { consolidateGeoMapEstimates } from '@/components/signup/LookupTool/consolidateGeoMapEstimates';

export const state = {
  baseState: null,
  baseStateAbbreviation: null,
  countiesInBaseState: [],
  countiesInBaseStateError: null,
  quoteTypeState: {
    /*
    For now, quoteTypeId will be the code name ("AUTO", "HOME", etc) A.K.A. "LeadTypeCode".
    [quoteTypeId]: {
      id: string
      label: string,
      serviceId: string,
      packages: [],
      packagesError: string,
      noPackagesAvailable: boolean,
      geoMaps: [],
      customGeoMaps: [],
      customRadiusGeoMaps: [],
      customCountyGeoMaps: [],
      addedGeosMap: {
        [packageId]: geoMap[],
      },
      removedGeosMap: {
        [packageId]: geoMap[],
      },
      packageGeosMap: {},
      selectedEstimates: {
        [packageId]: {
          [region]: geoMap[]
        }
      },
      geoMapEstimateErrors: {
        [packageId]: {
          [regionKey]: geoMap[],
        },
      },
      countyZipGeosMap: {
        [countyFipsCode]: geoMap[],
      },
    }
  */
  },
  loading: false,
  addLoading: false,
  removeLoading: false,
  addRadiusLoading: false,
  addCountiesLoading: false,
  addListLoading: false,
  lastParentCompany: null,
  lastBaseZip: null,
  lastQuoteType: null,
  lastProductType: null,
  currentVolume: null,
  accountReps: [],
};

export const getters = {
  countiesInBaseState: (state) => {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.countiesInBaseState)) {
      retVal[quoteTypeId] = state.countiesInBaseState[quoteTypeId];
    }

    return retVal;
  },
  numCountiesInBaseState: (state) => {
    if (state.countiesInBaseState) {
      return Object.keys(state.countiesInBaseState).length;
    } else {
      return -1;
    }
  },
  quoteTypesLoaded: (state) => Object.keys(state.quoteTypeState).length > 0,
  packageIdsByQuoteType: (state) => {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      retVal[quoteTypeId] = state.quoteTypeState[quoteTypeId].packages.map(
        (pkg) => pkg.id
      );
    }

    return retVal;
  },
  sortedPackagesByQuoteType(state, getters, rootState, rootGetters) {
    const parentCompany = rootGetters['signup/parentCompany'];
    const priorities = packagePriorities[parentCompany] || [];

    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const packages = [...state.quoteTypeState[quoteTypeId].packages].sort(
        (a, b) => {
          const aPriority = priorities.indexOf(a.id.toUpperCase());
          const bPriority = priorities.indexOf(b.id.toUpperCase());
          if (aPriority === -1 || bPriority === -1) {
            return 1;
          }
          return aPriority - bPriority;
        }
      );

      retVal[quoteTypeId] = packages;
    }

    return retVal;
  },
  zipGeosByQuoteType: (state) => {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      retVal[quoteTypeId] = state.quoteTypeState[quoteTypeId].geoMaps;
    }

    return retVal;
  },
  countyGeosByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const nearbyFips = getters.zipGeosByQuoteType[quoteTypeId]
        .filter((x) => x.radiusBoundary > 0 && x.radiusBoundary <= 10)
        .map((x) => x.countyFipsCode);

      const uniqueNearbyFips = [...new Set(nearbyFips)];
      const countyGeos = uniqueNearbyFips.map((fips) => ({
        ...getters.zipGeosByQuoteType[quoteTypeId].find(
          (x) => x.countyFipsCode === fips
        ),
        zipCode: null,
        radiusBoundary: 0,
      }));

      retVal[quoteTypeId] = countyGeos.concat(
        state.quoteTypeState[quoteTypeId].customCountyGeoMaps
      );
    }

    return retVal;
  },
  stateGeosByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const stateNames = getters.zipGeosByQuoteType[quoteTypeId].map(
        (geo) => geo.stateName
      );

      const uniqueStateNames = [...new Set(stateNames)];
      const stateGeos = uniqueStateNames.map((stateName) => ({
        ...getters.zipGeosByQuoteType[quoteTypeId].find(
          (x) => x.stateName === stateName
        ),
        countyName: null,
        countyFipsCode: null,
        zipCode: null,
        radiusBoundary: 0,
      }));

      retVal[quoteTypeId] = stateGeos;
    }

    return retVal;
  },
  allGeosByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      retVal[quoteTypeId] = [
        ...getters.zipGeosByQuoteType[quoteTypeId],
        ...getters.countyGeosByQuoteType[quoteTypeId],
        ...getters.stateGeosByQuoteType[quoteTypeId],
      ];
    }

    return retVal;
  },
  allCountyZipGeosByQuoteType(state) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      retVal[quoteTypeId] = Object.values(
        state.quoteTypeState[quoteTypeId].countyZipGeosMap
      ).flatMap((x) => x);
    }

    return retVal;
  },
  radiusBoundariesByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const radiusBoundaries = state.quoteTypeState[quoteTypeId].geoMaps.map(
        (geoMap) => geoMap.radiusBoundary
      );

      retVal[quoteTypeId] = [...new Set(radiusBoundaries)]
        .filter((x) => x > 0)
        .sort((a, b) => a - b);
    }

    return retVal;
  },
  regionHeadersByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const headers = [];

      if (state.quoteTypeState[quoteTypeId].customGeoMaps.length > 0) {
        headers.push('Custom');
      }
      for (const boundary of getters.radiusBoundariesByQuoteType[quoteTypeId]) {
        headers.push(`${groupNumbers(boundary)} Miles`);
      }
      for (const { countyName } of getters.countyGeosByQuoteType[quoteTypeId]) {
        headers.push(`${countyName} County`);
      }
      for (const { stateName } of getters.stateGeosByQuoteType[quoteTypeId]) {
        headers.push(`${stateName} State`);
      }

      retVal[quoteTypeId] = headers;
    }

    return retVal;
  },
  regionHeadersDisplayByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const headers = [];

      if (state.quoteTypeState[quoteTypeId].customGeoMaps.length > 0) {
        headers.push('Custom');
      }
      for (const boundary of getters.radiusBoundariesByQuoteType[quoteTypeId]) {
        headers.push(`${groupNumbers(boundary)} Miles`);
      }
      for (const { countyName, stateName } of getters.countyGeosByQuoteType[
        quoteTypeId
      ]) {
        if (stateName) {
          headers.push(`${countyName} County ${stateName} State`);
        } else {
          headers.push(`${countyName} County`);
        }
      }
      for (const { stateName } of getters.stateGeosByQuoteType[quoteTypeId]) {
        headers.push(`${stateName} State`);
      }

      retVal[quoteTypeId] = headers;
    }

    return retVal;
  },
  geoEstimateMapByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      const estimateMap = {};

      const packageGeos = Object.entries(
        state.quoteTypeState[quoteTypeId].packageGeosMap
      );

      for (const [packageId, geoMaps] of packageGeos) {
        // Have to use local boundaries, so we guarantee the boundary has already been loaded from the estimate endpoint.
        // The radiusBoundaries getter has all radius boundaries, regardless of whether or not estimates have been loaded yet.
        const boundaries = geoMaps.map((x) => x.radiusBoundary);
        const uniqueBoundaries = [...new Set(boundaries)].filter((x) => x > 0);
        // We have custom zip geos contained inside a county that shouldn't be included in the radii.
        const boundedGeos = geoMaps.filter(
          (x) =>
            (x.zipCode && x.radiusBoundary > 0) || !x.zipCode || x.isCustomZip
        );
        for (const geoMap of boundedGeos) {
          if (geoMap.isCustomZip) {
            addToEstimateMap(estimateMap, packageId, 'Custom', geoMap);
          } else if (geoMap.zipCode) {
            uniqueBoundaries.forEach((radiusBoundary) => {
              if (geoMap.radiusBoundary > radiusBoundary) return;
              const regionKey = `${groupNumbers(radiusBoundary)} Miles`;
              addToEstimateMap(estimateMap, packageId, regionKey, geoMap);
            });
          } else if (geoMap.countyName) {
            const regionKey = `${geoMap.countyName} County`;
            addToEstimateMap(estimateMap, packageId, regionKey, geoMap);
          } else {
            const regionKey = `${geoMap.stateName} State`;
            addToEstimateMap(estimateMap, packageId, regionKey, geoMap);
          }
        }
      }

      retVal[quoteTypeId] = estimateMap;
    }

    return retVal;
  },
  consolidatedEstimatesByQuoteType(state, getters) {
    const retVal = {};

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      retVal[quoteTypeId] = consolidateGeoMapEstimates(
        state.quoteTypeState[quoteTypeId].packages,
        state.quoteTypeState[quoteTypeId].selectedEstimates,
        state.quoteTypeState[quoteTypeId].addedGeosMap,
        state.quoteTypeState[quoteTypeId].removedGeosMap
      );
    }

    return retVal;
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setAddLoading: _.set('addLoading'),
  setRemoveLoading: _.set('removeLoading'),
  setAddRadiusLoading: _.set('addRadiusLoading'),
  setAddCountiesLoading: _.set('addCountiesLoading'),
  setAddListLoading: _.set('addListLoading'),
  setBaseState: _.set('baseState'),
  setBaseStateAbbreviation: _.set('baseStateAbbreviation'),
  setCountiesInBaseState: _.set('countiesInBaseState'),
  setCountiesInBaseStateError: _.set('countiesInBaseStateError'),
  setLastParentCompany: _.set('lastParentCompany'),
  setLastBaseZip: _.set('lastBaseZip'),
  setLastQuoteType: _.set('lastQuoteType'),
  setLastProductType: _.set('lastProductType'),
  setCurrentVolume: _.set('currentVolume'),
  setAccountReps: _.set('accountReps'),

  RESET(state) {
    state.baseState = null;
    state.baseStateAbbreviation = null;
    state.countiesInBaseState = [];
    state.countiesInBaseStateError = null;
    state.addRadiusLoading = false;
    state.addCountiesLoading = false;
    state.addListLoading = false;

    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      state.quoteTypeState[quoteTypeId] = {
        ...state.quoteTypeState[quoteTypeId],
        packages: [],
        packagesError: null,
        noPackagesAvailable: false,
        geoMaps: [],
        customGeoMaps: [],
        customRadiusGeoMaps: [],
        customCountyGeoMaps: [],
        addedGeosMap: {},
        removedGeosMap: {},
        packageGeosMap: {},
        selectedEstimates: {},
        geoMapEstimateErrors: {},
        countyZipGeosMap: {},
      };
    }
  },
  RESET_COUNTIES_IN_BASE_STATE(state) {
    state.countiesInBaseState = [];
  },
  RESET_GEO_MAP_ESTIMATES(state, { quoteTypeId }) {
    state.quoteTypeState[quoteTypeId].packageGeosMap = {};
    state.quoteTypeState[quoteTypeId].geoMapEstimateErrors = {};
  },
  RESET_GEO_MAP_ESTIMATE_ERRORS(state, { quoteTypeId }) {
    state.quoteTypeState[quoteTypeId].geoMapEstimateErrors = {};
  },
  SET_AVAILABLE_QUOTE_TYPES(state, { quoteTypes, resetPreviousState }) {
    for (const quoteType of quoteTypes) {
      let initialQuoteTypeState = buildInitialQuoteTypeState(quoteType);

      if (!resetPreviousState && state.quoteTypeState[quoteType.leadTypeCode]) {
        // Keep some properties from previous state.
        initialQuoteTypeState = {
          ...initialQuoteTypeState,
          ...state.quoteTypeState[quoteType.leadTypeCode],
          addedGeosMap: {},
          removedGeosMap: {},
        };
      }

      state.quoteTypeState[quoteType.leadTypeCode] = initialQuoteTypeState;
    }
  },
  SET_PACKAGES(state, { quoteTypeId, packages }) {
    state.quoteTypeState[quoteTypeId].packages = packages;
  },
  SET_NO_PACKAGES_AVAILABLE(state, { quoteTypeId, noPackagesAvailable }) {
    state.quoteTypeState[quoteTypeId].noPackagesAvailable = noPackagesAvailable;
  },
  SET_PACKAGES_ERROR(state, { quoteTypeId, packagesError }) {
    state.quoteTypeState[quoteTypeId].packagesError = packagesError;
  },
  SET_CUSTOM_GEO_MAPS(state, { quoteTypeId, geoMaps }) {
    state.quoteTypeState[quoteTypeId].customGeoMaps = geoMaps;
  },
  SET_CUSTOM_COUNTY_GEO_MAPS(state, { quoteTypeId, customCountyGeoMaps }) {
    state.quoteTypeState[quoteTypeId].customCountyGeoMaps = customCountyGeoMaps;
  },
  ADD_GEO_MAPS(state, { quoteTypeId, geoMaps }) {
    const mergedGeos = mergeGeoMaps(
      state.quoteTypeState[quoteTypeId].geoMaps,
      geoMaps
    );
    state.quoteTypeState[quoteTypeId].geoMaps = mergedGeos;
  },
  ADD_CUSTOM_RADIUS_GEO_MAPS(state, { quoteTypeId, geoMaps }) {
    state.quoteTypeState[quoteTypeId].customRadiusGeoMaps = [
      ...state.quoteTypeState[quoteTypeId].customRadiusGeoMaps,
      ...geoMaps,
    ];
  },
  ADD_CUSTOM_COUNTY_GEO_MAPS(state, { quoteTypeId, geoMaps }) {
    state.quoteTypeState[quoteTypeId].customCountyGeoMaps = [
      ...state.quoteTypeState[quoteTypeId].customCountyGeoMaps,
      ...geoMaps,
    ];
  },
  ADD_PACKAGE_GEOS_MAP(state, { quoteTypeId, packageId, geoMaps }) {
    const mergedGeos = mergeGeoMaps(
      state.quoteTypeState[quoteTypeId].packageGeosMap[packageId],
      geoMaps
    );
    state.quoteTypeState[quoteTypeId].packageGeosMap[packageId] = mergedGeos;
  },
  DELETE_PACKAGE_GEO_MAPS(state, { quoteTypeId, packageId }) {
    delete state.quoteTypeState[quoteTypeId].packageGeosMap[packageId];
  },
  TOGGLE_SELECTED_GEO_MAPS(
    state,
    { quoteTypeId, packageId, regionKey, geoMaps }
  ) {
    if (
      state.quoteTypeState[quoteTypeId]?.selectedEstimates[packageId][regionKey]
    ) {
      delete state.quoteTypeState[quoteTypeId].selectedEstimates[packageId][
        regionKey
      ];
    } else {
      state.quoteTypeState[quoteTypeId].selectedEstimates[packageId][
        regionKey
      ] = geoMaps;
    }
  },
  UPDATE_SELECTED_GEO_MAPS(
    state,
    { quoteTypeId, packageId, regionKey, geoMaps }
  ) {
    state.quoteTypeState[quoteTypeId].selectedEstimates[packageId][regionKey] =
      geoMaps;
  },
  DELETE_SELECTED_GEO_MAP_REGION(state, { quoteTypeId, packageId, regionKey }) {
    delete state.quoteTypeState[quoteTypeId].selectedEstimates[packageId][
      regionKey
    ];
  },
  SWITCH_SELECTED_REGIONS(
    state,
    { quoteTypeId, packageId, regionKey, regionMap }
  ) {
    const isMultiState =
      Object.keys(regionMap).filter((x) => x.endsWith('State')).length > 1;

    // This mutation assumes we're limited to 1 state and is a wedge until we get full state/county reduction implemented.
    // 20210917 : wmw : updated.  With the addition of the ability to cross state lines I'm checking for multiple states
    // and handling them reasonably appropriately.

    let deleteKeys;

    if (regionKey === 'Custom') {
      // Custom column is compatible with all regions.
      return;
    } else if (regionKey.endsWith('State')) {
      // We're selecting a State, so clear out everything else.

      // if multiple states...
      if (isMultiState) {
        // add all the "miles"

        deleteKeys = Object.keys(regionMap).filter(
          (x) => x !== regionKey && x.endsWith('Miles')
        );

        // walk the "county"s and if their state is the same as the region key then add them

        for (const regionName of Object.keys(regionMap).filter((x) =>
          x.endsWith('County')
        )) {
          if (
            regionKey.startsWith(regionMap[regionName].geoMaps[0].stateName)
          ) {
            deleteKeys.push(regionName);
          }
        }
      } else {
        deleteKeys = Object.keys(regionMap).filter(
          (x) => x !== regionKey && x !== 'Custom'
        );
      }
    } else {
      // We're not selecting a State, so only clear the State.

      // if multiple states...
      if (isMultiState && regionKey.endsWith('County')) {
        // use regionKey to pull data from regionMap and identify state name.  Only delete that state

        deleteKeys = Object.keys(regionMap).filter((x) =>
          x.startsWith(regionMap[regionKey].geoMaps[0].stateName)
        );
      } else {
        deleteKeys = Object.keys(regionMap).filter((x) => x.endsWith('State'));
      }
    }
    deleteKeys.forEach((regionKey) => {
      if (
        state.quoteTypeState[quoteTypeId]?.selectedEstimates[packageId][
          regionKey
        ]
      ) {
        delete state.quoteTypeState[quoteTypeId].selectedEstimates[packageId][
          regionKey
        ];
        delete state.quoteTypeState[quoteTypeId].addedGeosMap[packageId];
        delete state.quoteTypeState[quoteTypeId].removedGeosMap[packageId];
      }
    });
  },
  CANCEL_SELECTED_PACKAGES(state, { quoteTypeId, packageId }) {
    delete state.quoteTypeState[quoteTypeId].addedGeosMap[packageId];
    delete state.quoteTypeState[quoteTypeId].removedGeosMap[packageId];
    state.quoteTypeState[quoteTypeId].selectedEstimates[packageId] = {};
  },
  RESET_SELECTED_ESTIMATES(state, { quoteTypeId }) {
    state.quoteTypeState[quoteTypeId].addedGeosMap = {};
    state.quoteTypeState[quoteTypeId].removedGeosMap = {};
    for (const pkg of state.quoteTypeState[quoteTypeId].packages) {
      state.quoteTypeState[quoteTypeId].selectedEstimates[pkg.id] = {};
    }
  },
  ADD_ADDED_GEOS_MAP(state, { quoteTypeId, packageId, geoMap }) {
    if (!state.quoteTypeState[quoteTypeId].addedGeosMap[packageId]) {
      state.quoteTypeState[quoteTypeId].addedGeosMap[packageId] = [];
    }
    state.quoteTypeState[quoteTypeId].addedGeosMap[packageId].push(geoMap);
  },
  ADD_REMOVED_GEOS_MAP(state, { quoteTypeId, geoMap, packageId }) {
    if (!state.quoteTypeState[quoteTypeId].removedGeosMap[packageId]) {
      state.quoteTypeState[quoteTypeId].removedGeosMap[packageId] = [];
    }

    state.quoteTypeState[quoteTypeId].removedGeosMap[packageId].push(geoMap);
  },
  REMOVE_REMOVED_GEOS_MAP(state, { quoteTypeId, zipCode, packageId }) {
    state.quoteTypeState[quoteTypeId].removedGeosMap[packageId] =
      state.quoteTypeState[quoteTypeId].removedGeosMap[packageId].filter(
        (x) => x.zipCode !== zipCode
      );
  },
  REMOVE_ADDED_GEOS_MAP(state, { quoteTypeId, packageId, geoMap }) {
    state.quoteTypeState[quoteTypeId].addedGeosMap[packageId] =
      state.quoteTypeState[quoteTypeId].addedGeosMap[packageId].filter(
        (x) => x.zipCode !== geoMap.zipCode
      );
  },
  SET_GEO_MAP_ESTIMATE_ERROR(
    state,
    { quoteTypeId, packageId, regionKey, geoMaps }
  ) {
    if (!state.quoteTypeState[quoteTypeId].geoMapEstimateErrors[packageId]) {
      state.quoteTypeState[quoteTypeId].geoMapEstimateErrors[packageId] = {};
    }
    state.quoteTypeState[quoteTypeId].geoMapEstimateErrors[packageId][
      regionKey
    ] = geoMaps;
  },
  SET_COUNTY_ZIP_GEOS(state, { quoteTypeId, geoMaps }) {
    const { countyFipsCode } = geoMaps[0];
    state.quoteTypeState[quoteTypeId].countyZipGeosMap[countyFipsCode] =
      geoMaps;
  },
  RESET_CUSTOM_GEO_MAPS(state, { quoteTypeId }) {
    state.quoteTypeState[quoteTypeId].customGeoMaps = [];
    for (const pkg of state.quoteTypeState[quoteTypeId].packages) {
      delete state.quoteTypeState[quoteTypeId].selectedEstimates[pkg.id].Custom;
      state.quoteTypeState[quoteTypeId].packageGeosMap[pkg.id] =
        state.quoteTypeState[quoteTypeId].packageGeosMap[pkg.id]?.filter(
          (geoMap) => !geoMap.isCustomZip
        );
    }
  },
  RESET_CUSTOM_RADII(state, { quoteTypeId }) {
    const customRadiusKeys = state.quoteTypeState[
      quoteTypeId
    ].customRadiusGeoMaps.map((r) => `${r.radiusBoundary} Miles`);
    const customRadii = state.quoteTypeState[
      quoteTypeId
    ].customRadiusGeoMaps.map((r) => r.radiusBoundary);
    state.quoteTypeState[quoteTypeId].customRadiusGeoMaps = [];
    state.quoteTypeState[quoteTypeId].geoMaps = state.quoteTypeState[
      quoteTypeId
    ].geoMaps.filter((g) => !customRadii.includes(g.radiusBoundary));
    for (const pkg of state.quoteTypeState[quoteTypeId].packages) {
      for (const key of Object.keys(
        state.quoteTypeState[quoteTypeId].selectedEstimates[pkg.id]
      )) {
        if (customRadiusKeys.includes(key)) {
          delete state.quoteTypeState[quoteTypeId].selectedEstimates[pkg.id][
            key
          ];
        }
      }
    }
  },
  RESET_CUSTOM_COUNTIES(state, { quoteTypeId }) {
    const customCountyKeys = state.quoteTypeState[
      quoteTypeId
    ].customCountyGeoMaps.map((c) => `${c.countyName} County`);
    state.quoteTypeState[quoteTypeId].customCountyGeoMaps = [];
    for (const pkg of state.quoteTypeState[quoteTypeId].packages) {
      for (const key of Object.keys(
        state.quoteTypeState[quoteTypeId].selectedEstimates[pkg.id]
      )) {
        if (customCountyKeys.includes(key)) {
          delete state.quoteTypeState[quoteTypeId].selectedEstimates[pkg.id][
            key
          ];
        }
      }
    }
  },
};

export const actions = {
  async getAvailableQuoteTypes({ commit, dispatch, state }, parentCompany) {
    await dispatch('deselectAllPackages');

    const response = await dispatch('request/get', {
      url: `/api/leadtype/quoteTypes/${parentCompany}`,
      loadingMutation: 'lookupTool/setLoading',
      errorMessage: false,
      onError: (error) => {
        console.error(error);
        // TODO(gh): Set an error in state that we can display in the UI.
      },
    });

    if (!response) {
      return;
    }

    commit('SET_AVAILABLE_QUOTE_TYPES', {
      quoteTypes: response.data,
      resetPreviousState: state.lastParentCompany !== parentCompany,
    });
  },

  async setAvailableQuoteTypes(
    { commit, dispatch, state },
    { parentCompany, quoteTypes }
  ) {
    await dispatch('deselectAllPackages');

    commit('SET_AVAILABLE_QUOTE_TYPES', {
      quoteTypes,
      resetPreviousState: true,
    });
  },

  async getDetailedRegionPackageData(
    { commit, dispatch, getters, state },
    {
      parentCompany,
      zipCode,
      quoteTypeId,
      productType,
      packageId,
      overStateLines,
    }
  ) {
    commit('RESET');

    // If this action is dispatched from the LUT used for creating new channels and not editing an existing channel, cache some info.
    if (!packageId) {
      commit('setLastParentCompany', parentCompany);
      commit('setLastBaseZip', zipCode);
      commit('setLastQuoteType', quoteTypeId);
      commit('setLastProductType', productType);
    }

    commit('setAddCountiesLoading', true);

    const errorMessage = 'Failed to get package regions.';

    let queryParams = !packageId ? '' : `?packageId=${packageId}`;

    if (overStateLines) {
      if (queryParams.length > 1) {
        queryParams = queryParams + `&overStateLines=${overStateLines}`;
      } else {
        queryParams = `?overStateLines=${overStateLines}`;
      }
    }

    const response = await dispatch('request/get', {
      url: `/api/territory/lutpackageregion/${parentCompany}/${zipCode}/${quoteTypeId}/${productType}${queryParams}`,
      loadingMutation: 'lookupTool/setLoading',
      errorMessage,
      onError: (error) => {
        console.error(error);
        commit('SET_PACKAGES_ERROR', { quoteTypeId, errorMessage });
      },
    });

    if (!response) return;

    if (response.data.packages.length === 0) {
      commit('SET_NO_PACKAGES_AVAILABLE', {
        quoteTypeId,
        noPackagesAvailable: true,
      });
      return;
    }

    commit('SET_PACKAGES', { quoteTypeId, packages: response.data.packages });
    commit('setBaseState', response.data.stateName);
    commit('setBaseStateAbbreviation', response.data.stateCode);
    commit('RESET_SELECTED_ESTIMATES', { quoteTypeId });

    dispatch('addGeoMaps', { quoteTypeId, geoMaps: response.data.zipcodes });
    dispatch('getAllGeoMapEstimates', { quoteTypeId });
  },

  addGeoMaps({ commit, dispatch, getters, state }, { quoteTypeId, geoMaps }) {
    commit('ADD_GEO_MAPS', { quoteTypeId, geoMaps });

    for (const countyGeo of getters.countyGeosByQuoteType[quoteTypeId]) {
      if (
        !state.quoteTypeState[quoteTypeId].countyZipGeosMap[
          countyGeo.countyFipsCode
        ]
      ) {
        dispatch('fetchCountyZipGeos', { quoteTypeId, countyGeo });
      }
    }
  },

  updateSelectedGeos(
    { commit, getters, state },
    { quoteTypeId, packageId, regionKey }
  ) {
    const { geoMaps } =
      getters.geoEstimateMapByQuoteType[quoteTypeId][packageId][regionKey];

    commit('TOGGLE_SELECTED_GEO_MAPS', {
      quoteTypeId,
      packageId,
      regionKey,
      geoMaps,
    });
    // This is a temporary wedge until we properly handle reducing states to counties.
    const regionMap = getters.geoEstimateMapByQuoteType[quoteTypeId][packageId];
    commit('SWITCH_SELECTED_REGIONS', {
      quoteTypeId,
      packageId,
      regionKey,
      regionMap,
    });
  },

  deselectAllPackages({ dispatch, state }) {
    for (const quoteTypeId of Object.keys(state.quoteTypeState)) {
      for (const packageId of Object.keys(
        state.quoteTypeState[quoteTypeId].selectedEstimates
      )) {
        dispatch('cancelSelectedPackage', { quoteTypeId, packageId });
      }
    }
  },

  cancelSelectedPackage({ commit }, { quoteTypeId, packageId }) {
    commit('CANCEL_SELECTED_PACKAGES', { quoteTypeId, packageId });
  },

  getAllGeoMapEstimates({ commit, dispatch, getters }, { quoteTypeId }) {
    commit('RESET_GEO_MAP_ESTIMATES', { quoteTypeId });

    for (const packageId of getters.packageIdsByQuoteType[quoteTypeId]) {
      dispatch('getGeoMapEstimate', {
        quoteTypeId,
        packageId,
        geoMaps: getters.allGeosByQuoteType[quoteTypeId],
      });
    }
  },

  getGeoMapEstimate(
    { commit, dispatch, state },
    // A region key should be specified when fetching estimates for a specific region.
    { quoteTypeId, packageId, geoMaps, regionKey = 'all' }
  ) {
    const newGeoMaps = geoMaps.filter(
      (geoMap) =>
        !state.quoteTypeState[quoteTypeId].packageGeosMap[packageId]?.some(
          (packageGeo) => {
            return (
              packageGeo.countyFipsCode === geoMap.countyFipsCode &&
              packageGeo.countyName === geoMap.countyName &&
              packageGeo.isCustomZip === geoMap.isCustomZip &&
              packageGeo.radiusBoundary === geoMap.radiusBoundary &&
              packageGeo.stateCode === geoMap.stateCode &&
              packageGeo.stateName === geoMap.stateName &&
              packageGeo.zipCode === geoMap.zipCode
            );
          }
        )
    );

    if (newGeoMaps.length === 0) {
      return;
    }

    const requestPromise = dispatch('request/post', {
      url: '/api/territory/lutgeoestimate',
      data: { packageId, geoMaps: newGeoMaps },
      loadingMutation: 'lookupTool/setLoading',
      errorMessage: false,
      onError: (error) => {
        console.error(error);
        commit('SET_GEO_MAP_ESTIMATE_ERROR', {
          quoteTypeId,
          packageId,
          regionKey,
          geoMaps: newGeoMaps,
        });
      },
    });

    requestPromise.then((response) => {
      if (!response) return;
      commit('ADD_PACKAGE_GEOS_MAP', { quoteTypeId, ...response.data });
    });
  },

  async getCountiesByStateAbbreviationWithDistanceFromZip(
    { commit, dispatch, getters },
    { stateAbbreviation, zipCode }
  ) {
    commit('setCountiesInBaseStateError', null);

    const errorMessage = `Failed to get counties in ${stateAbbreviation}.`;

    const response = await dispatch('request/get', {
      url: `/api/territory/countiesWithDistance/${stateAbbreviation}/${zipCode}`,
      errorMessage,
      loadingMutation: 'lookupTool/setAddCountiesLoading',
      onError: (error) => {
        console.error(error);
        commit('setCountiesInBaseStateError', errorMessage);
      },
    });

    if (!response) {
      return;
    }

    const counties = response.data.map((countyData) => ({
      countyName: countyData.countyName,
      countyFipsCode: countyData.countyFipsCode,
      stateCode: countyData.stateAbbr,
      stateName: countyData.stateName,
      estimate: 0,
      radiusBoundary: 0,
      zipCode: null,
      isCustomZip: false,
      milesAway: countyData.milesAway,
      direction: countyData.direction,
    }));

    commit('setCountiesInBaseState', counties);
  },

  async getCountiesByZipCodeWithDistanceFromZip(
    { commit, dispatch, getters },
    { zipCode }
  ) {
    // get count of items in countiesInBaseState

    commit('RESET_COUNTIES_IN_BASE_STATE');

    commit('setCountiesInBaseStateError', null);

    const errorMessage = `Failed to get counties around ${zipCode}.`;

    const response = await dispatch('request/get', {
      url: `/api/territory/countiesWithDistanceZipOnly/${zipCode}`,
      errorMessage,
      loadingMutation: 'lookupTool/setAddCountiesLoading',
      onError: (error) => {
        console.error(error);
        commit('setCountiesInBaseStateError', errorMessage);
      },
    });

    if (!response) {
      return;
    }

    const counties = response.data.map((countyData) => ({
      countyName: countyData.countyName,
      countyFipsCode: countyData.countyFipsCode,
      stateCode: countyData.stateAbbr,
      stateName: countyData.stateName,
      estimate: 0,
      radiusBoundary: 0,
      zipCode: null,
      isCustomZip: false,
      milesAway: countyData.milesAway,
      direction: countyData.direction,
    }));

    commit('setCountiesInBaseState', counties);
  },

  async addCustomRadius(
    { commit, dispatch, getters },
    { quoteTypeId, radius, zipCode }
  ) {
    if (getters.radiusBoundariesByQuoteType[quoteTypeId].includes(radius)) {
      dispatch('toastr/info', 'Radius Already Exists', { root: true });
      return true;
    }

    const response = await dispatch('request/get', {
      url: `/api/territory/getzipsinradius/${zipCode}/${radius}`,
      loadingMutation: 'lookupTool/setAddRadiusLoading',
      errorMessage: 'Failed to set custom zip radius',
      onError: console.error,
    });

    if (!response) {
      return false;
    }

    const { data: geoMaps } = response;

    commit('ADD_GEO_MAPS', { quoteTypeId, geoMaps });
    commit('ADD_CUSTOM_RADIUS_GEO_MAPS', { quoteTypeId, geoMaps });

    const regionKey = `${groupNumbers(radius)} Miles`;
    for (const packageId of getters.packageIdsByQuoteType[quoteTypeId]) {
      dispatch('getGeoMapEstimate', {
        quoteTypeId,
        packageId,
        regionKey,
        geoMaps,
      });
    }

    return true;
  },

  async addCustomList(
    { commit, dispatch, getters },
    { quoteTypeId, zipCodes }
  ) {
    if (zipCodes.length === 0) {
      commit('RESET_CUSTOM_GEO_MAPS', { quoteTypeId });
      return;
    }

    const response = await dispatch('request/post', {
      url: '/api/territory/custom-zips',
      data: zipCodes,
      loadingMutation: 'lookupTool/setAddListLoading',
      errorMessage: 'Failed to add custom list.',
      onError: console.error,
    });

    if (!response) {
      return false;
    }

    if (zipCodes.length !== response.data.length) {
      // Need to compare arrays and display list of ones missing from response.data

      const badZipcodeList = zipCodes.filter(function (searchedZips) {
        return (
          response.data.filter(function (returnedZips) {
            return returnedZips.zipCode === searchedZips;
          }).length === 0
        );
      });

      dispatch(
        'toastr/warning',
        'The following zipcodes could not be found: ' +
          badZipcodeList.join(', '),
        {
          root: true,
        }
      );
    }

    if (state.quoteTypeState[quoteTypeId].customGeoMaps.length > 0) {
      commit('RESET_CUSTOM_GEO_MAPS', { quoteTypeId });
    }

    const { data: geoMaps } = response;
    const regionKey = 'Custom';

    commit('SET_CUSTOM_GEO_MAPS', { quoteTypeId, geoMaps });

    for (const packageId of getters.packageIdsByQuoteType[quoteTypeId]) {
      dispatch('getGeoMapEstimate', {
        quoteTypeId,
        packageId,
        regionKey,
        geoMaps,
      });
    }

    return true;
  },

  async addCustomCounties(
    { commit, dispatch, getters },
    { quoteTypeId, counties }
  ) {
    commit('ADD_CUSTOM_COUNTY_GEO_MAPS', { quoteTypeId, geoMaps: counties });

    for (const packageId of getters.packageIdsByQuoteType[quoteTypeId]) {
      dispatch('getGeoMapEstimate', {
        quoteTypeId,
        packageId,
        geoMaps: counties,
      });
    }

    for (const countyGeo of counties) {
      dispatch('fetchCountyZipGeos', { quoteTypeId, countyGeo });
    }
  },

  retryGeoMapEstimateErrors(
    { commit, dispatch, getters, state },
    { quoteTypeId }
  ) {
    const errorEntries = Object.entries(
      state.quoteTypeState[quoteTypeId].geoMapEstimateErrors
    );
    for (const [packageId, regionMap] of errorEntries) {
      if (regionMap.all) {
        dispatch('getGeoMapEstimate', {
          quoteTypeId,
          packageId,
          geoMaps: getters.allGeosByQuoteType[quoteTypeId],
        });
      } else {
        const regionMap =
          state.quoteTypeState[quoteTypeId].geoMapEstimateErrors[packageId];
        const regionMapEntries = Object.entries(regionMap);
        for (const [regionKey, geoMaps] of regionMapEntries) {
          dispatch('getGeoMapEstimate', {
            quoteTypeId,
            packageId,
            regionKey,
            geoMaps,
          });
        }
      }
    }
    commit('RESET_GEO_MAP_ESTIMATE_ERRORS', { quoteTypeId });
  },

  async fetchAddedGeo(
    { commit, dispatch, state },
    { quoteTypeId, zipCode, packageId }
  ) {
    const isRemoved = state.quoteTypeState[quoteTypeId].removedGeosMap[
      packageId
    ]?.some((x) => x.zipCode === zipCode);
    if (isRemoved) {
      commit('REMOVE_REMOVED_GEOS_MAP', { quoteTypeId, packageId, zipCode });
      return;
    }

    const responses = await Promise.all([
      dispatch('request/post', {
        url: '/api/territory/lutgeoestimate',
        data: {
          packageId,
          geoMaps: [{ zipCode }],
        },
        loadingMutation: 'lookupTool/setAddLoading',
        errorMessage: 'Failed to get added estimate.',
        onError: console.error,
      }),
      dispatch('request/get', {
        url: `/api/territory/zipcode/${zipCode}`,
        loadingMutation: 'lookupTool/setAddLoading',
        errorMessage: 'Failed to get added zip code.',
        onError: console.error,
      }),
    ]);

    const estimateResponse = responses[0];

    if (!estimateResponse) return;

    const zipCodeResponse = responses[1];

    if (!zipCodeResponse) return;

    const { stateName } = zipCodeResponse.data[0];
    const regionKey = `${stateName} State`;
    const isStateSelected =
      !!state.quoteTypeState[quoteTypeId].selectedEstimates[packageId][
        regionKey
      ];

    if (isStateSelected) {
      dispatch(
        'toastr/warning',
        'Added zip is already included in a selected state.',
        { root: true }
      );
      return;
    }

    commit('ADD_ADDED_GEOS_MAP', {
      quoteTypeId,
      packageId,
      geoMap: {
        ...estimateResponse.data.geoMaps[0],
        countyFipsCode: zipCodeResponse.data[0].countyCode,
        countyName: zipCodeResponse.data[0].countyName,
        stateCode: zipCodeResponse.data[0].stateAbbr,
        stateName: zipCodeResponse.data[0].stateName,
      },
    });
  },

  async removeGeo(
    { commit, dispatch, state },
    { quoteTypeId, zipCode, packageId }
  ) {
    const packageGeo = state.quoteTypeState[quoteTypeId].packageGeosMap[
      packageId
    ].find((x) => x.zipCode === zipCode);

    if (packageGeo) {
      commit('ADD_REMOVED_GEOS_MAP', {
        quoteTypeId,
        geoMap: packageGeo,
        packageId,
      });
    } else {
      const addedGeo = state.quoteTypeState[quoteTypeId].addedGeosMap[
        packageId
      ].find((x) => x.zipCode === zipCode);

      if (addedGeo) {
        commit('REMOVE_ADDED_GEOS_MAP', {
          quoteTypeId,
          geoMap: addedGeo,
          packageId,
        });
      }
    }
  },

  async reduceCountyGeo(
    { dispatch, commit, getters },
    { quoteTypeId, zipCode, packageId }
  ) {
    commit('setRemoveLoading', true);

    const countyZipGeo = getters.allCountyZipGeosByQuoteType[quoteTypeId].find(
      (x) => x.zipCode === zipCode
    );

    const countyZipGeos =
      state.quoteTypeState[quoteTypeId].countyZipGeosMap[
        countyZipGeo.countyFipsCode
      ];

    const response = await dispatch('request/post', {
      url: '/api/territory/lutgeoestimate',
      data: {
        packageId,
        geoMaps: countyZipGeos,
      },
      errorMessage: false,
      onError: console.error,
    });

    if (!response) {
      commit('setRemoveLoading', false);
      return;
    }

    commit('ADD_REMOVED_GEOS_MAP', {
      quoteTypeId,
      packageId,
      geoMap: countyZipGeo,
    });

    const regionKey = `${countyZipGeo.countyName} County`;
    const { geoMaps } = response.data;
    commit('ADD_PACKAGE_GEOS_MAP', { quoteTypeId, packageId, geoMaps });
    commit('UPDATE_SELECTED_GEO_MAPS', {
      quoteTypeId,
      packageId,
      regionKey,
      geoMaps,
    });

    commit('setRemoveLoading', false);
  },

  async fetchCountyZipGeos({ dispatch, commit }, { quoteTypeId, countyGeo }) {
    const { stateCode, countyFipsCode } = countyGeo;
    const response = await dispatch('request/get', {
      url: `/api/territory/states/${stateCode}/${countyFipsCode}`,
      errorMessage: 'Failed to get county zip geos.',
      onError: console.error,
    });

    if (!response) {
      return;
    }

    const geoMaps = response.data.map((x) => ({
      stateCode: x.stateAbbr,
      stateName: x.stateName,
      countyName: x.countyName,
      countyFipsCode: x.countyCode,
      zipCode: x.zipCode,
      estimate: 0,
      radiusBoundary: 0,
    }));

    commit('SET_COUNTY_ZIP_GEOS', { quoteTypeId, geoMaps });
  },

  async submitChannelEstimate({ commit, dispatch, getters, rootState }) {
    const clientId = rootState.signup.signupInfo.client.id;

    const channels = Object.values(
      getters.consolidatedEstimatesByQuoteType
    ).flatMap((estimates) =>
      Object.values(estimates).map((details) => ({
        packageId: details.package.id,
        estimate: details.geos.reduce((sum, geo) => (sum += geo.estimate), 0),
        geoTerritories: details.geos,
      }))
    );

    const response = await dispatch('request/post', {
      url: '/api/territory/estimatedChannel',
      data: {
        clientId,
        channels,
      },
      loadingMutation: 'lookupTool/setLoading',
      errorMessage: 'Failed to get added estimate.',
      onError: console.error,
    });

    if (!response) {
      return false;
    }

    for (const channel of response.data) {
      commit('signup/ADD_CHANNEL', channel, { root: true });
      await dispatch('signup/saveSignupBlob', null, { root: true });
    }

    return true;
  },

  // Version of estimate handler for existing clients vs IOs
  async createNewChannelsFromEstimate(
    { commit, dispatch, getters, rootState },
    { upsellRep, upsellPromo }
  ) {
    const clientId = rootState.client.client.id;

    const channels = Object.values(
      getters.consolidatedEstimatesByQuoteType
    ).flatMap((estimates) =>
      Object.values(estimates).map((details) => ({
        packageId: details.package.id,
        estimate: details.geos.reduce((sum, geo) => (sum += geo.estimate), 0),
        geoTerritories: details.geos,
      }))
    );

    const response = await dispatch('request/post', {
      url: '/api/territory/createLutChannels',
      data: {
        clientId,
        channels,
        upsellRep,
        upsellPromo,
      },
      loadingMutation: 'lookupTool/setLoading',
      errorMessage: 'Failed to create new channels.',
      onError: console.error,
    });

    if (!response) {
      return false;
    }

    return true;
  },

  resetCustomEstimates({ commit }, { quoteTypeId }) {
    commit('RESET_CUSTOM_RADII', { quoteTypeId });
    commit('RESET_CUSTOM_COUNTIES', { quoteTypeId });
    commit('RESET_CUSTOM_GEO_MAPS', { quoteTypeId });
  },

  async getCurrentVolume({ commit, dispatch, state }, clientQuoteTypeId) {
    const response = await dispatch('request/get', {
      url: `/api/leads/${clientQuoteTypeId}/lead-count`,
      loadingMutation: 'lookupTool/setLoading',
      errorMessage: false,
      onError: (error) => {
        console.error(error);
      },
    });

    if (!response) {
      return;
    }

    commit('setCurrentVolume', response.data.leadCount);
  },

  async getAccountReps({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/Clients/ACCOUNT_REPS/options',
      loadingMutation: 'lookupTool/setLoading',
      onError: console.error,
    });

    commit('setAccountReps', response.data);
  },
};

function mergeGeoMaps(currentGeos, addedGeos) {
  if (!currentGeos) {
    return addedGeos;
  }

  // Get the addedGeos where they don't exist in currentGeos.
  // If higher radius comes in, it's ignored because we already have it in a lower radius.
  const newGeos = addedGeos.filter((added) => {
    const existing = currentGeos.find(
      (existing) =>
        (added.zipCode && added.zipCode === existing.zipCode) ||
        (!added.zipCode &&
          !existing.zipCode &&
          added.countyFipsCode === existing.countyFipsCode)
    );

    return (
      !existing ||
      (existing && added.isCustomZip) ||
      (added.radiusBoundary > 0 &&
        added.radiusBoundary <= existing.radiusBoundary)
    );
  });

  // Existing geoMaps with updated geos filtered out.
  const existingGeos = currentGeos.filter((existing) =>
    newGeos.every(
      (updated) =>
        updated.zipCode !== existing.zipCode ||
        (!updated.zipCode &&
          !existing.zipCode &&
          updated.countyFipsCode !== existing.countyFipsCode) ||
        (updated.zipCode === existing.zipCode &&
          (updated.isCustomZip || existing.isCustomZip))
    )
  );

  return existingGeos.concat(newGeos);
}

function addToEstimateMap(estimateMap, packageId, regionKey, geoMap) {
  if (!estimateMap[packageId]) {
    estimateMap[packageId] = {};
  }

  if (!estimateMap[packageId][regionKey]) {
    estimateMap[packageId][regionKey] = {
      estimate: 0,
      geoMaps: [],
    };
  }

  estimateMap[packageId][regionKey].estimate += geoMap.estimate;
  estimateMap[packageId][regionKey].geoMaps.push(geoMap);
}

function buildInitialQuoteTypeState(quoteType) {
  return {
    id: quoteType.quoteTypeId,
    label: quoteType.leadTypeLabel,
    serviceId: quoteType.serviceId,
    packages: [],
    packagesError: null,
    noPackagesAvailable: false,
    geoMaps: [],
    customGeoMaps: [],
    customRadiusGeoMaps: [],
    customCountyGeoMaps: [],
    addedGeosMap: {},
    removedGeosMap: {},
    packageGeosMap: {},
    selectedEstimates: {},
    geoMapEstimateErrors: {},
    countyZipGeosMap: {},
  };
}
