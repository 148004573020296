<template>
  <el-alert
    v-if="showAlert"
    title="Office Closure"
    class="mb-5"
    type="info"
    @close="closed"
  >
    The Client Services team is out of the office for an off-site company event
    Wed 6/19. We will return for normal business hours Thu 6/20 and respond to
    messages at that time. Thank you for your business!
  </el-alert>
</template>

<script>
export default {
  name: 'TempAnnouncement',
  computed: {
    showAlert() {
      var hasClosedAlert = localStorage.getItem('showAlert')
      if (hasClosedAlert === null) {
        hasClosedAlert = false
      }

      var today = new Date()
      var start = new Date(2019, 5, 18)
      var end = new Date(2019, 5, 20)

      if (!hasClosedAlert && today >= start && today <= end) {
        return true
      }

      return false
    },
  },
  methods: {
    closed() {
      localStorage.setItem('showAlert', true)
    },
  },
}
</script>
