import { axiosInstance } from '@/plugins/axios';
import _ from '@/utils/store-helpers';

export class filter {
  constructor({ filterTypeId, filterTypeName, operator, value }) {
    this.subscriptionFilterId = -1;
    this.subscriptionFilterTypeOperator = operator;
    this.subscriptionFilterTypeName = filterTypeName;
    this.subscriptionFilterTypeId = filterTypeId;
    this.subscriptionFilterTypeInputValue = value;
    this.subscriptionFilterTypeInputLookup = '';
  }
}

const getInitialState = () => ({
  isIntervalEnabled: false,
  clientQuoteTypeId: null,
  callType: null,
  loading: true,
  dirty: false,
  filters: [],
  filterTypes: [],
  filterTypesOperators: [],
});

export const state = getInitialState();

export const actions = {
  async getFilters(
    { commit, dispatch },
    { clientQuoteTypeId, callType, clearIds = false }
  ) {
    commit('setLoading', true);
    var method = 'SubscriptionFilters';
    if (callType === 'ColdTransfer') method = 'TazSubscriptionFilters';
    const response = await axiosInstance
      .get(`api/LeadType/Get${method}/${clientQuoteTypeId}/`)
      .catch((err) => err);

    if (response instanceof Error) {
      dispatch(
        'toastr/error',
        `Failed to get schedule. Please check your connection and try again.`,
        {
          root: true,
        }
      );
      // TODO: This should dispatch an action that will log this error.
      commit('setLoading', false);
      return;
    }

    if (clearIds) {
      response.data.subscriptionFilters = response.data.subscriptionFilters.map(
        (filter) => ({
          ...filter,
          subscriptionFilterId: null,
        })
      );
    }

    commit('setFilters', response.data.subscriptionFilters);
    commit('setfilterTypesOperators', response.data.subscriptionFilterTypes);
    commit('setFilterTypes', response.data.subscriptionFilterTypes);
    commit('setCallType', callType);
    commit('setLoading', false);
    commit('setClientQuoteTypeId', clientQuoteTypeId);
    commit('setCallType', callType);
  },

  async saveFilters({ commit, dispatch, state }, clientQuoteTypeId) {
    var saveSucess = true;
    if (!state.dirty) return saveSucess;

    commit('setLoading', true);
    const method =
      state.callType === 'ColdTransfer'
        ? 'TazSubscriptionFilter'
        : 'SubscriptionFilter';

    const response = await axiosInstance
      .post(`api/LeadType/Upsert${method}/${clientQuoteTypeId}`, state.filters)
      .catch((err) => err);

    if (response instanceof Error || response.status !== 200) {
      saveSucess = false;
      dispatch(
        'toastr/error',
        'Failed to save filter. Please check your connection and try again.',
        {
          root: true,
        }
      );
    }

    commit('setLoading', false);
    commit('setDirty', !saveSucess);

    return saveSucess;
  },

  addFilter(
    { commit },
    { index, filterId, filterTypeId, filterTypeName, operator, value }
  ) {
    commit('addFilter', {
      index,
      filterId,
      filterTypeId,
      filterTypeName,
      operator,
      value,
    });
    commit('setDirty', true);
  },

  removeFilter({ commit }, index) {
    if (index < 0) return;
    commit('removeFilter', index);
    commit('setDirty', true);
  },

  resetState({ commit }) {
    commit('resetState');
  },
};

export const mutations = {
  setFilters: _.set('filters'),
  setLoading: _.set('loading'),
  setClientQuoteTypeId: _.set('clientQuoteTypeId'),
  setCallType: _.set('callType'),
  setfilterTypesOperators: _.set('filterTypesOperators'),

  setFilterTypes(state, filterTypes) {
    state.filterTypes.length = 0;
    for (var filterType of filterTypes) {
      if (
        state.filterTypes.filter(
          (m) =>
            m.subscriptionFilterTypeName ===
            filterType.subscriptionFilterTypeName
        ).length === 0
      ) {
        state.filterTypes.push({
          subscriptionFilterTypeId: filterType.subscriptionFilterTypeID,
          subscriptionFilterTypeName: filterType.subscriptionFilterTypeName,
        });
      }
    }
  },
  removeFilter(state, index) {
    state.filters.splice(index, 1);
  },

  addFilter(
    state,
    { index, filterId, filterTypeId, filterTypeName, operator, value }
  ) {
    if (index > -1) {
      state.filters[index].subscriptionFilterId = filterId;
      state.filters[index].subscriptionFilterTypeId = filterTypeId;
      state.filters[index].subscriptionFilterTypeOperator = operator;
      state.filters[index].subscriptionFilterTypeName = filterTypeName;
      state.filters[index].subscriptionFilterTypeInputValue = value;
      state.filters[index].subscriptionFilterTypeInputLookup = '';
    } else {
      var filter = {};
      filter.subscriptionFilterId = -1;
      filter.subscriptionFilterTypeId = filterTypeId;
      filter.subscriptionFilterTypeName = filterTypeName;
      filter.subscriptionFilterTypeOperator = operator;
      filter.subscriptionFilterTypeInputValue = value;
      filter.subscriptionFilterTypeInputLookup = '';

      state.filters.push(filter);
    }
  },

  setDirty(state, isDirty) {
    state.dirty = isDirty;
  },

  resetState(state) {
    const initialState = getInitialState();
    Object.keys(state).forEach((key) => {
      state[key] = initialState[key];
    });
  },
};
