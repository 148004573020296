<template>
  <div v-if="false" />
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Toastr',
  computed: {
    ...mapState('toastr', ['message']),
  },
  watch: {
    message: {
      immediate: true,
      handler({ message, type, config }) {
        if (!message) return;

        this.$notify({
          message,
          type,
          duration: 5000,
          title: type[0].toUpperCase() + type.substring(1),
          ...config,
        });

        this.clearMessage();
      },
    },
  },
  methods: {
    ...mapActions('toastr', ['clearMessage']),
  },
};
</script>
