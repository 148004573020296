export default {
  methods: {
    convertTerritoryToGeoMap(territory) {
      let geoMapType

      if (territory.zipCode) {
        geoMapType = 'ZipCode'
      } else if (territory.countyName) {
        geoMapType = 'County'
      } else {
        geoMapType = 'State'
      }

      return {
        type: geoMapType,
        stateAbbreviation: territory.stateAbbr || territory.abbreviation,
        county: territory.countyName,
        zipCode: territory.zipCode,
      }
    },
  },
}
