<template>
  <el-row :class="stepClass">
    <el-col>
      <div class="title-area">
        <h1 class="page-title">Select Which Channels You Would Like To Reactivate</h1>
      </div>

      <div v-loading="isLoading" class="channel-selection-table" :style="rowCountStyle">
        <div></div>
        <div class="product-col"></div>
        <div class="select-col"><h4>Reactivate</h4></div>
        <div class="name-col"><h4>Channel Name</h4></div>
        <div class="price-col"><h4>Lead Price*</h4></div>
        <div
          v-for="(product, i) in products" :key="i"
          :class="productRowClass(product.productType)"
          :data-product="product.productType.toLowerCase()"
        >
          <div class="product-col" @click="toggleProductExpando(product.productType)">
            <h4>{{ getProductName(product.productType) }} <el-icon>
              <ArrowDown v-if="productIsExpanded[product.productType]" class="arrow-icon" />
              <ArrowRight v-else class="arrow-icon" />
            </el-icon></h4>
          </div>
          <ol v-if="productIsExpanded[product.productType]" class="channel-list">
            <li v-for="(channel, n) in product.channels" :key="channel.id" class="channel-row" :data-row-key="(callChannelRowOffset(product.productType) + n)">
              <div class="select-col">
                <el-tooltip
                  content="The channel cannot be reactivated without updating the filter package."
                  placement="top"
                  :disabled="channel.hasActivePackage"
                >
                  <el-checkbox
                    :disabled="!channel.hasActivePackage"
                    :checked="isChannelActive(product.productType, channel.id)"
                    @change="toggleActiveChannel(product.productType, channel.id, $event)" />
                </el-tooltip>
              </div>
              <div class="name-col">
                <router-link
                  class="channel-name-link"
                  :to="createSelectChannelLink(channel.id)"
                >
                  <span @click="requestModalClose">{{ channel.name }}</span>
                </router-link>
              </div>
              <div class="price-col">{{ formattedPrice(channel.price) }}</div>
            </li>
          </ol>
          <div v-else class="channel-count">{{ getProductChannelCountMsg(product.productType) }}</div>
        </div>
      </div>

      <div class="step-footer">
        <div class="footnote">* The reactivation process does not provide promotions and/or discounts. Please contact our Sales department at (855) 891-7072 or QuoteWizardSalesManagers@lendingtree.com to be contacted by a Sales professional and learn about our current promotional offers.</div>

        <div class="button-area">
          <el-button
            :disabled="noChannelsSelected"
            class="my-2"
            type="primary"
            @click="$emit('click-next')"
          >Next</el-button>
        </div>
      </div>

      <div :class="{ 'hidden-warning': (!noChannelsSelected || isLoading) }">
        <ul class="text-danger">
          <li>No channels selected for reactivation. At least one must be selected.</li>
        </ul>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ArrowDown, ArrowRight } from '@element-plus/icons-vue'

export default {
  name: 'ChannelSelection',
  components: {
    ArrowDown,
    ArrowRight,
  },
  data: () => ({
    productIsExpanded: {
      Web: false,
      Call: false,
    },
  }),
  computed: {
    ...mapGetters('reactivation', [
      'products',
      'isChannelActive',
      'noChannelsSelected',
      'getActiveChannelCountForProduct',
      'getWebChannelCount',
      'getCallChannelCount',
    ]),
    ...mapGetters('client', [
      'clientId'
    ]),
    ...mapGetters('responsive', ['isAtMostMD', 'isAtLeastLG']),

    stepClass() {
      return {
        'channel-selection-step': true,
        wide: this.isAtLeastLG,
        narrow: this.isAtMostMD,
      };
    },
    rowCountStyle() {
      return `--web-count: ${this.getWebChannelCount}; --call-count: ${this.getCallChannelCount}`
    },
    isLoading() {
      return this.products.length === 0;
    },
  },
  created() {
  },
  methods: {
    ...mapActions('reactivation', [
      'setChannelActiveForProduct'
    ]),

    formattedPrice(price) {
      return `$${price.toFixed(2)}`;
    },
    toggleActiveChannel(productType, channelId, isActive) {
      this.setChannelActiveForProduct({
        productType,
        channelId,
        isActive
      });
    },
    createSelectChannelLink(channelId) {
      return {
        name: 'leads_channel_edit',
        params: {
          clientId: this.clientId,
          clientQuoteTypeId: channelId,
        }
      }
    },
    getProductName: (product) => {
      return product === 'Web'
        ? "Web Leads"
        : "Call Leads";
    },
    expandoIconClass(product) {
      return this.productIsExpanded[product]
        ? 'el-icon-arrow-down'
        : 'el-icon-arrow-right';
    },
    toggleProductExpando(product) {
      this.productIsExpanded[product] = !this.productIsExpanded[product];
    },
    productRowClass(product) {
      return {
        'product-row': true,
        open: this.productIsExpanded[product],
      };
    },
    requestModalClose() {
      this.$emit('request-close');
    },
    callChannelRowOffset(productType) {
      if (productType === 'Web')  {
        return 0;
      }

      return this.getWebChannelCount % 2
        ? 1
        : 0;
    },
    getProductChannelCountMsg(productType) {
      const activeChannelCount = this.getActiveChannelCountForProduct(productType);
      const pluralization = activeChannelCount === 1
        ? ''
        : 's';
      return `${activeChannelCount} Channel${pluralization} Reactivating`
    }
  }
};
</script>

<style lang="scss" scoped>
.arrow-icon {
  cursor: pointer;
}

.channel-selection-step {
  padding: 0 5em 5em 5em;
  // Part of the close button is not clickable without margin-top.
  margin-top: 5em;

  &.wide {
    padding: 0 5vw 5em 5vw;
  }

  &.narrow {
    padding: 0 0 2em 0;
  }

  &.half {
    display: grid;
    justify-content: center;
  }
}

.title-area {
  margin-bottom: 5em;

  .page-title {
    margin-bottom: 25px;
  }
}

.channel-selection-table {
  display: grid;
  grid-template-columns: 1fr 180px 100px minmax(300px, max-content) 180px 1fr;
  grid-template-rows: auto;
  justify-items: center;
}

.product-row {
  grid: subgrid / subgrid;
  display: grid;
  grid-column: 2 / 6;
  grid-row: var(--starting-row, 2) / calc(var(--row-count, 1) + var(--starting-row, 2));
  border-top: 1px solid rgb(220, 223, 230);
  align-items: center;

  &[data-product="web"] {
    --row-count: var(--web-count);
    --starting-row: 2;
  }

  &[data-product="call"] {
    --row-count: var(--call-count);
    --starting-row: calc(var(--web-count) + 2);
  }

  &:last-child {
    border-bottom: 1px solid rgb(220, 223, 230);
  }

  &.open .product-col {
    padding-top: 0;
    padding-bottom: 0;

    h4 {
      border-bottom: 1px solid rgb(220, 223, 230);
    }
  }

  .product-col {
    justify-self: center;
    padding: 10px 1rem 10px 0.5rem;
    width: 100%;

    h4 {
      margin: 0;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 2fr 1fr;

      i {
        justify-self: end;
      }
    }
  }
}

.channel-row {
  grid: subgrid / subgrid;
  display: grid;
  grid-column: 2 / span 4;
  justify-items: center;
  align-items: center;
  border-top: 1px solid rgb(220, 223, 230);

  &:first-child {
    border-top: none;
  }

  &[data-row-key$='1'],
  &[data-row-key$='3'],
  &[data-row-key$='5'],
  &[data-row-key$='7'],
  &[data-row-key$='9'] {
    background: #FAFAFA;
  }

  &:hover {
    background-color: #F5F7FA;
  }
}

.channel-list {
  margin: 0;
  padding: 0;
  display: contents;
}

.channel-count {
  grid-column: 2 / 5;
}

.button-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.hidden-warning {
  visibility: hidden;
}

.channel-name-link {
  display: inline-block;
  color: teal;
  text-decoration: none;
  line-height: 1;
  font-size: 14px;
  padding: 12px 0px;
}

.select-col :deep(label) {
  margin: 0;
}

.step-footer {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  margin-top: 2em;

  .footnote {
    grid-column: 2;
    font-style: italic;
  }
}
</style>
