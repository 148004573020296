export default {
  data() {
    return {
      creditRequestReasons: {
        BADCONTACT: 'Bad Contact / Lead Info',
        CONTACT_DISCONNECTED: 'Disconnected Number',
        CONTACT_FAX: 'Number was Fax Machine',
        CONTACT_NOSUCHPERSON: 'No Such Person At Number',
        CONTACT_FAKENAME: 'Fake Name',
        DIDNOTREQUEST: 'Consumer did not request lead',
        NOTINTENDED: 'Filled Out Form In Error',
        OTHER: 'Bad Contact / Lead Info',
        LANGUAGEBARRIER: 'Language Barrier',

        INTENT: 'Consumer Intent',
        INCENTIVE: 'Consumer claimed incentive for filling out form',
        INCENTIVE_CASH:
          'Consumer claimed incentive/reward for filling out form: Cash',
        INCENTIVE_SWEEP:
          'Consumer claimed incentive/reward for filling out form: Sweepstakes',
        INCENTIVE_GAME:
          'Consumer claimed incentive/reward for filling out form: Online Gaming',
        INCENTIVE_PROD:
          'Consumer claimed incentive/reward for filling out form: Product Giveaway',
        LEADINFOAGED: 'Lead information was aged',

        LEADDIST: 'Lead Distribution Issue / Duplicate',
        DUPLICATE_QW30: 'I received lead in last 30 days from QuoteWizard',
        DUPLICATE_AGENCY: 'Lead went to other agent(s) in my company',
        DUPLICATE_OTHER: 'I received this lead from another lead provider',
        DUPLICATE_OTHER_NETQUOTE:
          'I received this lead from another lead provider: Netquote',
        DUPLICATE_OTHER_INSUREME:
          'I received this lead from another lead provider: InsureMe',
        DUPLICATE_OTHER_ALLWEB:
          'I received this lead from another lead provider: AllWeb',
        DUPLICATE_OTHER_INSWEB:
          'I received this lead from another lead provider: InsWeb',
        DUPLICATE_OTHER_HTQUOTES:
          'I received this lead from another lead provider: HomeTown Quotes',
        DUPLICATE_OTHER_IAGENTS:
          'I received this lead from another lead provider: InsuranceAgents',
        DUPLICATE_OTHER_ILEADS:
          'I received this lead from another lead provider: InsuranceLeads',
        DUPLICATE_OTHER_QSCOUT:
          'I received this lead from another lead provider: QuoteScout',
        DUPLICATE_OTHER_AGNTNSDR:
          'I received this lead from another lead provider: AgentInsider',
        DUPLICATE_OTHER_RELERT:
          'I received this lead from another lead provider: RateElert',
        POLICYHOLDER: 'Consumer already has a policy with my company',

        FILTER: 'Did not meet my filters',
        FILTER_HOME_NO_MOBILE: 'Mobile Home',
        OTHER_GENERAL: 'Other',
        DUPLICATE: 'Duplicate lead',
        INCENTIVE_NOTINTENDED: 'Filled Out Form In Error',
      },
    }
  },
}
