<template>
  <div id="app">
    <notification-display-manager :is-inactive="isInactive" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import {
  inactiveUserTimeThreshold,
  userActivityThrottlerTime,
} from '@/app.config';
import useViewSizes from '@/utils/mixins/useViewSizes';
import NotificationDisplayManager from '@/components/notifications/NotificationDisplayManager.vue';

export default {
  name: 'ClientAdminApp',
  components: {
    NotificationDisplayManager,
  },
  mixins: [useViewSizes],
  data: () => ({
    registration: null,
    refreshing: false,
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Don't automatically refresh if the route specifies not to.
        if (
          this.refreshing ||
          this.$route.meta.refreshOnServiceWorkerControllerChanged === false
        ) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  mounted() {
    this.$loadScript(process.env.VUE_APP_AUTHORIZE_NET_URL)
      .then(() => {
        // Script is loaded, do something
      })
      .catch((err) => {
        console.error(err);
      });
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    this.deactivateActivityTracker();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;

      this.$toasted.show('A new version of Client Admin is available', {
        theme: 'toasted-primary',
        position: 'bottom-right',
        duration: null,
        action: {
          text: 'Refresh',
          onClick: (e, toastObject) => {
            if (!this.registration || !this.registration.waiting) {
              return;
            }
            this.registration.waiting.postMessage('skipWaiting');
            toastObject.goAway(0);
          },
        },
      });
    },
    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('scroll', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
      window.addEventListener('resize', this.userActivityThrottler);
      document.addEventListener('visibilitychange', this.tabVisibilityChange);
    },

    deactivateActivityTracker() {
      window.removeEventListener('mousemove', this.userActivityThrottler);
      window.removeEventListener('scroll', this.userActivityThrottler);
      window.removeEventListener('keydown', this.userActivityThrottler);
      window.removeEventListener('resize', this.userActivityThrottler);
      document.removeEventListener(
        'visibilitychange',
        this.tabVisibilityChange
      );
    },

    tabVisibilityChange() {
      this.isInactive = document.hidden;
    },

    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, inactiveUserTimeThreshold);
    },

    userActivityThrottler() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, userActivityThrottlerTime);
      }
    },

    inactiveUserAction() {
      this.isInactive = true;
    },
  },
};
</script>

<style lang="scss">
@import '~nprogress/nprogress.css';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import './assets/theme/bundle.scss';
</style>
