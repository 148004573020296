module.exports = {
  title: 'Client Admin',
  description: 'QuoteWizard Client Admin Tools',
  maxPauseHours: process.env.VUE_APP_MAX_PAUSE_HOURS,
  weeklyDayCapFactor: process.env.VUE_APP_WEEKLY_DAY_CAP_FACTOR,
  callsApiUrl: process.env.VUE_APP_CALL_CENTER_API,
  creditCallUrl: process.env.VUE_APP_CREDIT_CALL_API,
  b2cRedirectUrl: process.env.VUE_APP_REDIRECT_URL,
  b2cClientId: process.env.VUE_APP_B2C_CLIENT_ID,
  b2cAppId: process.env.VUE_APP_B2C_APP_ID,
  everestApiUrl: process.env.VUE_APP_API_URL,
  environment: process.env.NODE_ENV,
  notificationCheckTime: process.env.VUE_APP_NOTIFICATION_CHECK_TIME,
  inactiveUserTimeThreshold: process.env.VUE_APP_INACTIVE_USER_TIME_THRESHOLD,
  userActivityThrottlerTime: process.env.VUE_APP_USER_ACTIVITY_THROTTLER_TIME,
  defaultWeeklyCap: process.env.VUE_APP_DEFAULT_WEEKLY_CAP,
  buildNumber: process.env.VUE_APP_BUILD_NUMBER,
};
