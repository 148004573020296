export default {
  methods: {
    getCardIcon(cardType) {
      switch (cardType) {
        case 'VISA':
        case 'AMEX':
          return 'fab fa-cc-' + cardType.toLowerCase()
        case 'DISC':
          return 'fab fa-cc-discover'
        case 'MAST':
          return 'fab fa-cc-mastercard'
        default:
          return 'fas fa-credit-card'
      }
    },
  },
}
