export default {
  data() {
    return {
      clientLevels: {
        BASIC: {
          label: 'Basic',
          enabled: false,
        },
        BASIC_50: {
          label: 'Basic 50',
          enabled: false,
        },
        BASIC_100: {
          label: 'Basic 100',
          enabled: false,
        },
        BRONZE: {
          label: 'Bronze',
          enabled: true,
        },
        SILVER: {
          label: 'Silver',
          enabled: true,
        },
        GOLD: {
          label: 'Gold',
          enabled: false,
        },
        PLATINUM: {
          label: 'Platinum',
          enabled: true,
        },
        DIAMOND: {
          label: 'Diamond',
          enabled: true,
        },
        ELITE: {
          label: 'Elite',
          enabled: true,
        },
      },
    };
  },
  computed: {
    enabledClientLevels() {
      const enabledLevels = {};
      for (const level in this.clientLevels) {
        if (this.clientLevels[level].enabled) {
          enabledLevels[level] = this.clientLevels[level];
        }
      }
      return enabledLevels;
    },
  },
};
