import _ from '@/utils/store-helpers';

export const state = {
  width: 0,
  size: '',
  isNavDrawerOpen: false,
  isSidebarOpen: false,
  scrollLocks: [],
};

export const getters = {
  isXS: (state) => state.size === 'xs',
  isMD: (state) => state.size === 'md',
  isAtLeastMD: (state) => ['md', 'lg', 'xl'].includes(state.size),
  isAtMostMD: (state) => ['md', 'xs'].includes(state.size),
  isLG: (state) => state.size === 'lg',
  isAtLeastLG: (state) => ['lg', 'xl'].includes(state.size),
  isXL: (state) => state.size === 'xl',
  isSidebarOpen: (state) => state.isSidebarOpen,
  isNavDrawerOpen: (state) => state.isNavDrawerOpen,
};

const className = 'scroll-lock';
// lockInfo should contain a name for lock and a boolean value
export const actions = {
  setScrollLock: ({ state, commit }, lockInfo) => {
    // Handle existing locks
    commit('updateScrollLock', lockInfo);

    // Don't turn off if another source wants it
    if (!lockInfo.value && state.scrollLocks.every((x) => !x.value)) {
      return;
    }

    // Update lock
    const pageClassList = document.getElementsByTagName('html')[0]?.classList;

    if (!pageClassList) {
      // Theoretically this should never be hit but, just in case
      return;
    }

    const exists = pageClassList.contains(className);
    if (exists && !lockInfo.value) {
      pageClassList.remove(className);
    } else if (!exists && lockInfo.value) {
      pageClassList.add(className);
    }
    // Elsewise nothing should need to change
  },
  setWidth: ({ commit, state }, newWidth) => {
    if (newWidth === state.width) {
      return;
    }

    let newSize = 'md';

    if (newWidth <= 500) {
      newSize = 'xs';
    } else if (newWidth >= 1200) {
      newSize = 'xl';
    } else if (newWidth > 1000) {
      newSize = 'lg';
    }

    commit('setIsSidebarOpen', false);
    commit('setSize', newSize);
    commit('setWidth', newWidth);
  },
  flipSideBarOpenState: ({ commit, dispatch, state }) => {
    commit('setIsSidebarOpen', !state.isSidebarOpen);
    dispatch('setScrollLock', {
      name: 'sidebar',
      value: state.isSidebarOpen,
    });
  },
  flipNavMenuOpenState: ({ commit, dispatch, state }) => {
    commit('setIsNavDrawerOpen', !state.isNavDrawerOpen);
    dispatch('setScrollLock', {
      name: 'navDrawer',
      value: state.isNavDrawerOpen,
    });
  },
  closeAllMenus: ({ commit }) => {
    commit('resetScrollLocks');
    commit('setIsNavDrawerOpen', false);
    commit('setIsSidebarOpen', false);
  },
};

export const mutations = {
  setWidth: _.set('width'),
  setSize: _.set('size'),
  setIsSidebarOpen: _.set('isSidebarOpen'),
  setIsNavDrawerOpen: _.set('isNavDrawerOpen'),
  updateScrollLock: (state, lockInfo) => {
    state.scrollLocks = [
      state.scrollLocks.filter((x) => x.name !== lockInfo.name),
      lockInfo,
    ];
  },
  resetScrollLocks: (state) => {
    state.scrollLocks = [];
  },
};
