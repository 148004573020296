export default {
  methods: {
    getPackageBasePrice(quoteType) {
      switch (quoteType.toUpperCase()) {
        case 'HOME':
          return 15.0
        case 'RENTER':
          return 9.0
        case 'HEALTH':
          return 8.0
        case 'MEDSUPP':
          return 12.0
        default:
          return 11.25
      }
    },
  },
}
