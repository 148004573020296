<template>
  <NavHeaderedPage>
    <el-container>
      <el-main>
        <TempAnnouncement />
        <slot />
      </el-main>
    </el-container>
  </NavHeaderedPage>
</template>

<script>
import NavHeaderedPage from '@/layouts/NavHeaderedPage.vue';
import TempAnnouncement from '@/components/agent/TempAnnouncement.vue';

export default {
  name: 'MainLayout',
  components: {
    NavHeaderedPage,
    TempAnnouncement,
  },
};
</script>
