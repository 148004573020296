import { axiosInstance } from '@/plugins/axios';
import { callsApiUrl } from '@/app.config';
import errorTracker from '@/plugins/errorTracker';

export const state = {
  transfers: [],
};

export const getters = {};

export const mutations = {
  SET_TRANSFERS(state, transfers) {
    state.transfers = transfers;
  },
};

export const actions = {
  async getTransfersList({ commit, dispatch, rootState }) {
    const response = await axiosInstance
      .get(
        `${callsApiUrl}/getbyclientid?client_id=${rootState.client.client.id}`
      )
      .catch((err) => err);

    if (response instanceof Error) {
      if (!response.response) {
        dispatch('toastr/error', 'Network Error', {
          root: true,
        });
        return;
      }

      dispatch('toastr/error', response.response.data.message, {
        root: true,
      });
      return;
    }

    const transfers = JSON.parse(response.data);
    commit('SET_TRANSFERS', transfers);
  },

  async getTransfersFromTonquin({ commit, dispatch, rootState }) {
    const response = await axiosInstance
      .get(`/api/Leads/transfers/${rootState.client.client.id}`)
      .catch((err) => err);

    if (response instanceof Error) {
      if (!response.response) {
        dispatch('toastr/error', 'Network Error', {
          root: true,
        });
        return;
      }

      dispatch('toastr/error', response.response.data.message, {
        root: true,
      });
      return;
    }

    if (Array.isArray(response.data)) {
      commit('SET_TRANSFERS', response.data);
    }
  },
};
