import _ from '@/utils/store-helpers';

export const state = {
  clientRules: [],
  clientRuleTypes: [],
  loading: false,
};

export const getters = {
  loading: (state) => state.loading,
  clientRuleTypes: (state) => state.clientRuleTypes,

  clientRules: (state, _getters, rootState) => {
    return state.clientRules.map((rule) => {
      const channel = rootState.channels.channels.find((channel) => {
        return channel.id === rule.clientQuoteTypeId;
      });

      return {
        ...rule,
        ruleName: channel?.name,
      };
    });
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setClientRules: _.set('clientRules'),
  setClientRuleTypes: _.set('clientRuleTypes'),
};

export const actions = {
  async getClientRulesList({ commit, dispatch, rootState }) {
    dispatch(
      'channels/getChannelOverview',
      { clientId: rootState.client.client.id },
      { root: true }
    );

    const response = await dispatch('request/get', {
      url: '/api/ClientRule',
      errorMessage: 'Failed to get client rules list.',
      loadingMutation: 'clientRules/setLoading',
    });

    if (!response) return;

    commit('setClientRules', response.data);
  },

  async getClientRuleTypesList({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/ClientRule/types',
      errorMessage: 'Failed to get client rule types.',
      loadingMutation: 'clientRules/setLoading',
    });

    if (!response) return;

    commit('setClientRuleTypes', response.data);
  },

  async createClientRule({ dispatch }, rule) {
    const response = await dispatch(`request/${rule.id ? 'put' : 'post'}`, {
      url: `/api/ClientRule`,
      data: rule,
      errorMessage: 'Failed to create client rule.',
      successMessage: 'Rule successfully created.',
      loadingMutation: 'clientRules/setLoading',
    });

    if (!response) return false;

    dispatch('getClientRulesList');

    return true;
  },

  async deleteClientRule({ dispatch, rootGetters, state }, rule) {
    const response = await dispatch('request/delete', {
      url: '/api/ClientRule',
      data: rule,
      errorMessage: 'Failed to delete rule.',
      successMessage: 'Rule successfully deleted.',
      loadingMutation: 'clientRules/setLoading',
    });

    if (!response) return;

    dispatch('getClientRulesList');
  },
};
