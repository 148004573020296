<template>
  <HeaderedPageLayout>
    <template #[mainNavSlot]>
      <el-menu
        :router="true"
        active-text-color="#ffd04b"
        background-color="#0a1d28"
        :mode="menuMode"
        text-color="#fff"
        class="b-none"
        :class="mainNavMenuClass"
        :menu-trigger="menuTriggerMode"
        :unique-opened="true"
        :ellipsis="false"
      >
        <el-menu-item
          v-if="hasClient"
          :route="{
            name: 'client_root',
            params: { clientId },
          }"
          index="client_root"
        >
          Dashboard
        </el-menu-item>

        <el-sub-menu v-if="hasClient" index="Lead Management">
          <template #title> Lead Management </template>
          <el-menu-item
            :route="{
              name: 'leads_channels',
              params: { clientId },
            }"
            index="leads_channels"
            >Lead Channels</el-menu-item
          >
          <el-menu-item
            :route="{
              name: 'client_leads',
              params: { clientId },
            }"
            index="client_leads"
          >
            Leads
          </el-menu-item>
        </el-sub-menu>

        <el-menu-item
          v-if="hasClient"
          :route="{
            name: 'billing',
            params: { clientId },
          }"
          index="billing"
        >
          Payments &amp; Billing
        </el-menu-item>

        <el-sub-menu v-if="hasClient && roleLink" index="Resources">
          <template #title> Resources </template>
          <el-menu-item index="agent_education">
            <a :href="roleLink" target="_blank =" style="text-decoration: none"
              >Agent Education</a
            >
          </el-menu-item>
          <el-menu-item
            v-if="client.parentCompany === 'STATEFARM'"
            :route="{
              name: 'state_farm_subsidies',
              params: { clientId },
            }"
            index="state_farm_subsidies"
            target="_blank"
          >
            State Farm Subsidies
          </el-menu-item>
        </el-sub-menu>

        <el-menu-item
          :route="{
            name: 'client_Support',
          }"
          index="Support"
        >
          Support
        </el-menu-item>

        <el-sub-menu
          v-if="isClientServices || isProductViewer"
          index="staff utils"
        >
          <template #title> Staff Utilities </template>
          <el-menu-item
            v-if="isClientServices"
            :route="{
              name: 'StaffOnlyUtilities',
              params: { clientId },
            }"
            index="StaffOnlyUtilities"
          >
            Staff Only Utilities
          </el-menu-item>
          <el-menu-item
            v-if="isClientServices"
            :route="{ name: 'signup' }"
            index="signup"
          >
            Signup IO
          </el-menu-item>
          <el-menu-item
            v-if="isProductViewer"
            :route="{ name: 'ProductAdmin' }"
            index="ProductAdmin"
          >
            Product Admin
          </el-menu-item>
          <el-menu-item
            v-if="
              isTerritoryExpansionEnabled &&
              (isClientServicesAdmin || isSalesManager)
            "
            :route="{ name: 'SecondaryTerritoriesOptIn' }"
            index="SecondaryTerritoriesOptIn"
          >
            Secondary Territories Opt-In
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </template>
    <template #header>
      <el-menu
        :router="true"
        active-text-color="#ffd04b"
        background-color="#0a1d28"
        mode="horizontal"
        text-color="#fff"
        class="b-none"
        :menu-trigger="menuTriggerMode"
        :unique-opened="true"
        :class="iconNavMenuClass"
        :ellipsis="false"
      >
        <el-sub-menu index="alerts" class="icon">
          <template #title>
            <el-badge
              :value="trayNotificationCount"
              :type="notificationBadgeColor"
              class="tray-badge"
            >
              <i class="fal fa-bell"></i>
            </el-badge>
          </template>

          <notification-tray />
        </el-sub-menu>

        <el-sub-menu index="user actions">
          <template #title>
            <i class="fal fa-user"></i>
          </template>
          <li v-if="user" class="pr-0 p-2 d-flex leading-none">
            <span class="avatar avatar-blue">
              <i class="far fa-user-crown"></i>
            </span>
            <span class="ml-2">
              <span class="text-white">{{ user.name }}</span>
              <small class="text-muted d-block mt-1">{{ user.email }}</small>
            </span>
          </li>
          <li class="divider"></li>
          <template v-if="hasClient">
            <el-menu-item-group
              :title="client.fullName"
              class="mr-0 client-name"
            >
              <el-menu-item
                :route="{
                  name: 'client_account',
                  params: { clientId },
                }"
                index="client_account"
              >
                Profile / Account Info
              </el-menu-item>
            </el-menu-item-group>
          </template>
          <li class="divider"></li>
          <el-menu-item-group>
            <el-menu-item>
              <a @click="logout"> Log Out </a>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </template>
    <slot />
  </HeaderedPageLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { authComputed, authMethods } from '@/store/helpers';
import HeaderedPageLayout from '@/layouts/HeaderedPage.vue';
import NotificationTray from '@/components/notifications/types/NotificationTray.vue';

export default {
  name: 'NavHeaderedPage',
  components: {
    HeaderedPageLayout,
    NotificationTray,
  },
  data: () => ({
    roleLinks: {
      farmers:
        'https://cloud.sending.quotewizard.com/agent-education-program/index?carrier=farmers',
      stateFarm:
        'https://cloud.sending.quotewizard.com/agent-education-program/index?carrier=state-farm',
      other:
        'https://cloud.sending.quotewizard.com/agent-education-program/index',
    },
  }),
  computed: {
    ...authComputed,
    ...mapGetters('client', ['hasClient', 'clientId']),
    ...mapGetters('notifications', ['trayNotificationCount']),
    ...mapGetters('responsive', ['isMD', 'isLG', 'isAtMostMD']),
    ...mapGetters('featureFlagStore', ['getFlag']),
    isTerritoryExpansionEnabled() {
      return this.getFlag('TerritoryExpansion');
    },
    mainNavSlot() {
      return this.isAtMostMD ? 'nav-drawer' : 'hidable-header';
    },
    mainNavMenuClass() {
      return {
        contained: this.isMD,
        'contained-lg': this.isLG,
      };
    },
    iconNavMenuClass() {
      return {
        'contained-lg': this.isLG,
      };
    },
    menuMode() {
      return this.isAtMostMD ? 'vertical' : 'horizontal';
    },
    menuTriggerMode() {
      return this.isAtMostMD ? 'click' : 'hover';
    },
    notificationBadgeColor() {
      return this.trayNotificationCount > 0 ? 'danger' : 'info';
    },
    roleLink() {
      if (this.client.parentCompany === 'STATEFARM') {
        return this.roleLinks.stateFarm;
      }

      if (this.client.parentCompany === 'FARMERS') {
        return this.roleLinks.farmers;
      }

      return this.roleLinks.other;
    },
  },
  methods: {
    ...authMethods,
  },
};
</script>

<style>
.el-menu-item {
  background-color: rgb(10, 29, 40);
}

header.min .el-menu--horizontal .el-submenu__title {
  padding-right: 0;
}

.el-menu--popup-bottom-start {
  margin-top: 0px;
}

.el-menu .el-menu-item a,
.el-menu .el-menu-item a:hover {
  color: inherit;
}
</style>

<style scoped>
.client-name:deep() .el-menu-item-group__title {
  padding-left: 10px !important;
  font-size: 16px;
}

.el-menu--popup li.divider {
  border-bottom: 1px solid rgb(128, 128, 128);
  padding: 0 10px;
}

.contained:deep() > li {
  width: 50%;
  margin: 0 auto;
}

.el-menu.contained-lg:deep() > li,
.el-menu.contained-lg:deep() > li .el-submenu__title {
  padding-right: 0;
  font-size: 0.75rem;
}

.b-none {
  border: none !important;
}

.tray-badge:deep() > sup {
  top: 0.9rem !important;
  right: 1.7rem !important;
}

.icon {
  text-align: center;
}
</style>
