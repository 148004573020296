export default {
  data() {
    return {
      trumpFlags: [
        {
          value: -1,
          label: 'Lowest',
        },
        {
          value: 0,
          label: 'Standard',
        },
        {
          value: 1,
          label: 'Plus',
        },
        {
          value: 2,
          label: 'Highest',
        },
      ],
    }
  },
}
