import { axiosInstance } from '@/plugins/axios';

import _ from '@/utils/store-helpers';
import moment from 'moment';

// initial state
export const state = {
  searchResults: [],
  transactionTypes: [],
  promos: [],
  isSearching: false,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
  },
  sortBy: '-createDate',
  to: new Date(),
  from: moment().startOf('month').toDate(),
};

// getters
export const getters = {};

// mutations
export const mutations = {
  setPromos: _.set('promos', false),
  setServices: _.set('services', false),
  setTransactionTypes: _.set('transactionTypes', false),
  setSearchResults: _.set('searchResults', false),
  setIsSearching: _.set('isSearching', false),
  setPagination: _.set('pagination', false),

  setCurrentPage(state, newValue) {
    state.pagination.currentPage = newValue;
  },
  setPageSize(state, newValue) {
    state.pagination.pageSize = newValue;
  },
  setSortBy: _.set('sortBy', false),
  setTo(state, newValue) {
    state.to = newValue;
  },
  setFrom(state, newValue) {
    state.from = newValue;
  },
};

// actions
export const actions = {
  async getTransactions({ commit, state, dispatch }, search) {
    const response = await dispatch('request/get', {
      url: `/api/billing/${search.clientId}/transactions`,
      params: {
        q: search.search,
        from: search.from,
        to: search.to,
        page: state.pagination.currentPage,
        pageSize: state.pagination.pageSize,
        sortBy: state.sortBy,
      },
      errorMessage: 'Failed to load transactions',
      loadingMutation: 'transactions/setIsSearching',
    });
    if (!response) {
      commit('setSearchResults', []);
      return;
    }
    commit('setSearchResults', response.data.items);
    commit('setPagination', response.data.metaData);
  },
  async createTransactions(
    { commit, dispatch },
    {
      amount,
      clientId,
      description,
      service,
      createDate,
      type,
      promoCode,
      cardCheckNumber,
    }
  ) {
    try {
      var results = await axiosInstance.post(
        `/api/billing/${clientId}/transactions`,
        {
          Amount: amount,
          ClientId: clientId,
          CreateDate: createDate,
          Description: description,
          Service: service,
          TypeCode: type,
          CardCheckNumber: cardCheckNumber,
          PromoCode: promoCode,
        }
      );
      const data = results.data;

      dispatch('toastr/success', 'Transaction successfully created', {
        root: true,
      });

      return data;
    } catch (error) {
      console.error(error);
      commit('setIsSearching', false);
      dispatch(
        'toastr/error',
        'Failed to create transaction: ' + error.response.data,
        { root: true }
      );
    }
  },
  getGroupDebtReplacementTransactions: async function (
    { commit, state, dispatch },
    { clientId, transactionId, amount }
  ) {
    try {
      const response = await dispatch('request/get', {
        url: `/api/billing/${clientId}/transactions/getGroupDebtReplacementTransactions/${transactionId}`,
        params: {
          amount: amount,
        },
      });
      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      dispatch('toastr/error', 'Error: Getting transactions..', { root: true });
    }
  },
  replaceTransaction: async function (
    { commit, state, dispatch },
    { clientId, transactionId, amount }
  ) {
    try {
      const response = await dispatch('request/post', {
        url: `/api/billing/${clientId}/transactions/replaceTransaction/${transactionId}`,
        params: {
          amount: amount,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      dispatch('toastr/error', 'Error: Unable to remove transaction.', {
        root: true,
      });
    }
    dispatch('toastr/success', 'Transaction removed.');
  },

  async createServiceTransfer(
    { commit },
    { amount, clientId, description, source, createDate, target }
  ) {
    try {
      var results = await axiosInstance.post(
        `/api/billing/${clientId}/transfers`,
        {
          Amount: amount,
          ClientId: clientId,
          CreateDate: createDate,
          Description: description,
          Source: source,
          Target: target,
        }
      );
      var data = results.data;
      return data;
    } catch (error) {
      console.error(error);
      commit('setIsSearching', false);
    }
  },
  async getTransactionTypes({ commit }) {
    try {
      var results = await axiosInstance.get(`/api/billing/transactions/types`);
      commit('setTransactionTypes', results.data);
      return results.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getPromos({ commit }) {
    try {
      var results = await axiosInstance.get(`/api/clients/promos`);
      commit('setPromos', results.data);
      return results.data;
    } catch (error) {
      console.error(error);
    }
  },

  reset({ commit }) {
    commit('setSearchResults', []);
    commit('setPagination', {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalRecords: 0,
      },
    });
    commit('setPromos', []);
  },
};
