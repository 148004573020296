export function processTerritory(workingData, geoMaps) {
  let zipExists = false
  let countyExists = false
  let stateExists = false

  const newTerritory = {
    county: '',
    stateAbbreviation: '',
    type: '',
    zipCode: null,
  }

  if (workingData.zipCode) {
    zipExists = geoMaps.some((o) => {
      return (
        (o.type === 'ZipCode' && o.zipCode === workingData.zipCode) ||
        (o.type === 'County' &&
          o.county.toUpperCase() === workingData.countyName.toUpperCase()) ||
        (o.type === 'State' &&
          o.stateAbbreviation.toUpperCase() ===
            workingData.stateAbbr.toUpperCase())
      )
    })

    newTerritory.county = workingData.countyName
    newTerritory.stateAbbreviation = workingData.stateAbbr
    newTerritory.type = 'ZipCode'
    newTerritory.zipCode = workingData.zipCode
  } else if (workingData.countyName) {
    // TODO : Need to add reverse check. If county see if there are zips present
    countyExists = geoMaps.some((o) => {
      return (
        (o.type === 'County' &&
          o.county.toUpperCase() === workingData.countyName.toUpperCase() &&
          o.stateAbbreviation.toUpperCase() ===
            workingData.stateAbbr.toUpperCase()) ||
        (o.type === 'State' &&
          o.stateAbbreviation.toUpperCase() ===
            workingData.stateAbbr.toUpperCase())
      )
    })

    newTerritory.county = workingData.countyName
    newTerritory.stateAbbreviation = workingData.stateAbbr
    newTerritory.type = 'County'
    newTerritory.zipCode = null
  } else {
    // TODO : Need to add reverse check. If state see if there are counties or zips present
    stateExists = geoMaps.some((o) => {
      return (
        o.type === 'State' &&
        o.stateAbbreviation.toUpperCase() ===
          workingData.abbreviation.toUpperCase()
      )
    })

    newTerritory.county = null
    newTerritory.stateAbbreviation = workingData.abbreviation
    newTerritory.type = 'State'
    newTerritory.zipCode = null
  }

  return {
    newTerritory,
    zipExists,
    countyExists,
    stateExists,
  }
}

export function buildTerrCollection(geoMaps) {
  const terrCollection = {}
  const terrCounts = {
    states: 0,
    counties: 0,
    zipcodes: 0,
  }

  const grouped = groupBy(geoMaps.slice(), (geo) => geo.type)

  if (grouped.get('State')) {
    grouped.get('State').forEach(({ stateAbbreviation }) => {
      terrCollection[stateAbbreviation] = {
        EntireState: true,
      }
      terrCounts.states++
    })
  }

  if (grouped.get('County')) {
    const groupedCounties = groupBy(
      grouped.get('County').slice(),
      (geo) => geo.stateAbbreviation
    )

    groupedCounties.forEach((counties, key) => {
      terrCollection[key] = {
        EntireState: terrCollection[key]?.EntireState || false,
        NumCounties: counties.length,
      }
      terrCounts.counties += counties.length
    })
  }

  if (grouped.get('ZipCode')) {
    const groupedZips = groupBy(
      grouped.get('ZipCode').slice(),
      (geo) => geo.stateAbbreviation
    )

    groupedZips.forEach((zips, key, map) => {
      if (key in terrCollection) {
        terrCollection[key].NumZips = zips.length
      } else {
        terrCollection[key] = {
          EntireState: false,
          NumZips: zips.length,
        }
      }

      terrCounts.zipcodes += zips.length
    })
  }

  return { terrCollection, terrCounts }
}

// A similar form of this logic exists in the Tonquin API in TerritoryManager.BuildChannelAsync.
// Any changes to this logic should be synced with the API.
export function convertLutGeoMapsToGeoMaps(lutGeoMaps) {
  return lutGeoMaps.map((lutGeo) => ({
    stateAbbreviation: lutGeo.stateCode || null,
    county: lutGeo.countyName?.toUpperCase() || null,
    zipCode: lutGeo.zipCode || null,
    type: getLutGeoType(lutGeo),
  }))
}

function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

function getLutGeoType(lutGeoMap) {
  switch (true) {
    case !!lutGeoMap.zipCode:
      return 'ZipCode'
    case !!lutGeoMap.countyName:
      return 'County'
    default:
      return 'State'
  }
}

export function getSubsidyProduct(parentCompany, isCall, quoteType, hasOwnsHomeFilter) {
    if(parentCompany === "STATEFARM") {
      if (isCall === true) {
        return "Call"
      }
      else if(quoteType === "Auto" &&  hasOwnsHomeFilter === true)
      {
        return "AutoHome"
      }  
      return quoteType
    }    
    return "";
}