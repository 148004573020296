export default {
  data() {
    return {
      companyMap: {
        '21CENTURY': '21st Century Insurance',
        AAA: 'AAA Insurance',
        AFI: 'Answer Financial',
        AIG: 'AIG Insurance',
        ALLSTATE: 'Allstate Insurance',
        AMERICANNATIONAL: 'American National Insurance',
        AMFAM: 'American Family Insurance',
        AMLI: 'Amerilife',
        ANCHOR_CLIENT: 'Anchor Client',
        BANKERS: 'Bankers Life and Casualty',
        BROOKE: 'Brooke Insurance',
        CARRIERDIRECT: 'Carrier Direct',
        COTTON: 'Cotton States',
        COUNTRYFINANCIA: 'Country Financial Insurance',
        ERIE: 'Erie Insurance',
        ESURANCE: 'Esurance.com',
        FARM_BUREAU: 'Farm Bureau',
        FARMERS: 'Farmers Insurance',
        GEICO: 'GEICO',
        HARTFORD: 'The Hartford',
        HIGHPOINT: 'High Point Insurance',
        INAD: 'Insurance Advisors Direct',
        INDEPENDENT: 'Independent Agent',
        INFINITY: 'Infinity Insurance',
        INSURANCECOM: 'insurance.com',
        LIBERTY: 'Liberty Mutual Insurance',
        MERCURY: 'Mercury Ins Co',
        METLIFE: 'MetLife Insurance',
        MUTOMA: 'Mutual Of Omaha',
        NATIONWIDE: 'Nationwide Insurance',
        NYLIFE: 'New York Life Insurance',
        PEMCO: 'Pemco',
        PROGRESSIVE: 'Progressive Insurance',
        REDIRECT: 'Redirect',
        SAFECO: 'SafeCo Insurance',
        SENTRY: 'Sentry Insurance',
        SHELTER: 'Shelter Insurance Co.',
        SIAA: 'SIAA/VIAA',
        STATEFARM: 'State Farm Insurance',
        TAG: 'Tag Insurance',
        TRAVLERS: 'Travelers',
        UNITRIN: 'Unitrin',
        USHA: 'US Health Advisors',
        WESTMUT: 'Western Mutual',
        WSL: 'Western and Southern Life',
        BRIGHTWAY: 'Brightway Insurance',
      },
    }
  },
}
