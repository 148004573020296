<template>
  <div class="ham-button" :class="hamButtonClass" @click="handleMenuOpenState">
    <span></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HamburgerMenuIcon',
  computed: {
    ...mapGetters('responsive', ['isNavDrawerOpen', 'isXS']),
    hamButtonClass() {
      const sizeClass = this.isXS ? 'small' : ''
      const drawerState = this.isNavDrawerOpen ? 'open' : ''
      return `${sizeClass} ${drawerState}`
    },
  },
  methods: {
    handleMenuOpenState() {
      this.$emit('clicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.ham-button {
  height: 3.9vw;
  width: 3.9vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;

  &.small {
    height: 7.5vw;
    width: 7.5vw;

    span {
      height: 0.9vw;

      &::after,
      &::before {
        top: 2.5vw;
        height: 0.9vw;
      }

      &::after {
        top: -2.5vw;
      }
    }
  }

  span {
    display: inline-block;
    width: 100%;
    height: 0.5vw;
    position: relative;
    border-radius: 5px;
    background: #aaaac4;
    transition: transform 0.75s ease;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 1.3vw;
      left: 0px;
      width: 100%;
      height: 0.5vw;
      border-radius: 5px;
      background: #9aa0ac;
      transform-origin: center;
      transition: top 0.3s ease-in, transform 0.5s ease-out;
      transform: rotate(0deg);
    }

    &::after {
      top: -1.3vw;
    }
  }

  &.open span {
    transform: rotate(135deg);

    &::after,
    &::before {
      top: 0;
      transform: rotate(90deg);
    }
  }
}
</style>
