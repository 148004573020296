<template>
  <div v-if="hasNotification" class="custom-wrapper">
    <div :class="{ clickable: hasUrl }" @click="handleClick">
      <el-alert :type="alertType" :closable="false" show-icon center>
        <template #title>
          <div v-if="priority.headline">
            {{ priority.headline }}
          </div>
        </template>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div @click="handlePropagation" v-html="priority.content" />
      </el-alert>
    </div>
    <!-- Required, el-alert does not allow clicking close and still persisting the alert if other notifications are pending, so we make our own close button! -->
    <span class="custom-close-button" @click="handleClose">x</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotificationBanner',
  computed: {
    ...mapGetters('notifications', ['bannerNotifications']),

    hasNotification() {
      return this.bannerNotifications && this.bannerNotifications.length > 0
    },

    hasUrl() {
      return this.hasNotification && !!this.priority.url
    },

    priority() {
      return this.bannerNotifications && this.bannerNotifications[0]
    },

    alertType() {
      return this.priority.alertType.toLowerCase()
    },
  },
  methods: {
    ...mapActions('notifications', ['dismissNotification']),

    handleClose() {
      this.dismissNotification(this.priority.id)
    },

    handleClick() {
      if (!this.priority.url) {
        return
      }

      this.$router.push(this.priority.url)
    },

    handlePropagation(e) {
      e.stopPropagation()
    },
  },
}
</script>

<style scoped>
.custom-wrapper {
  position: relative;
}

.custom-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
</style>
