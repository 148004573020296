import { mapState, mapGetters, mapActions } from 'vuex';

export const authComputed = {
  ...mapState('auth', ['user']),
  ...mapState('client', ['client']),
  ...mapGetters('auth', [
    'isLoggedIn',
    'userInRole',
    'isAdmin',
    'isAgentManager',
    'isClientServices',
    'isClientServicesAdmin',
    'isStaff',
    'isProductAdmin',
    'isProductViewer',
    'isCorporateManager',
    'isAccountCreator',
    'isSalesManager',
  ]),
};

export const authMethods = mapActions('auth', ['login', 'logout']);

export function GetTestLeadCredits() {
  return JSON.parse(`[
      {
         "createDate":"2020-11-25T10:04:20.893",
         "daysOld":22,
         "callerId":null,
         "filterList":"AUTO_TICKETS_NONE, AUTO_INSURED_1_UP, NO_AUTO_SR22, AUTO_DRIVER_AGE_25, NO_AUTO_SUSPENSION, NO_AUTO_DUI, AUTO_ACCIDENT_HOUSEHOLD_NONE",
         "id":"26954932-8C32-4301-AEC2-9C61B2D8CCCF",
         "creditRequestDate":"2020-11-30T13:16:15.093",
         "status":"success",
         "payout":15.75,
         "creditRequestReason":"CONTACT_DISCONNECTED",
         "creditRequestDescription":"",
         "primaryPhone":"7075612510",
         "ivrMatchType":null,
         "quoteTypeId":"AUTO",
         "formerInsurer":"PROGRESSIVE",
         "targusRating":null,
         "leadQuality":null,
         "firstName":"Eddy",
         "lastName":"Yung",
         "city":"Saint Louis",
         "state":"MO",
         "zip":"63136",
         "email":"zfeed3206@cqerk.com",
         "make":"RAM",
         "vendor":"BLUEINKDIGITAL",
         "quoteId":"0F4ECB57-1207-473D-B684-5B67CB620BCE",
         "parentCompany":"STATEFARM",
         "creditDaysAllowed":10,
         "bizContactPhone":"253-486-5776",
         "bizContactFirstName":"Dewayne",
         "bizContactLastName":"Olvera",
         "sessionId":"7E333405-90F1-4B9D-9FD2-B54CF32374B8",
         "level":"SILVER",
         "creditRate":14.0
      },
      {
         "createDate":"2020-11-25T10:04:20.893",
         "daysOld":22,
         "callerId":null,
         "filterList":"AUTO_TICKETS_NONE, AUTO_INSURED_1_UP, NO_AUTO_SR22, AUTO_DRIVER_AGE_25, NO_AUTO_SUSPENSION, NO_AUTO_DUI, AUTO_ACCIDENT_HOUSEHOLD_NONE",
         "id":"26954932-8C32-4301-AEC2-9C61B2D8CCCF",
         "creditRequestDate":"2020-11-30T13:16:15.093",
         "status":"success",
         "payout":15.75,
         "creditRequestReason":"CONTACT_DISCONNECTED",
         "creditRequestDescription":"",
         "primaryPhone":"360-220-7446",
         "ivrMatchType":null,
         "quoteTypeId":"AUTO",
         "formerInsurer":"PROGRESSIVE",
         "targusRating":null,
         "leadQuality":null,
         "firstName":"Eddy",
         "lastName":"Yung",
         "city":"Saint Louis",
         "state":"MO",
         "zip":"63136",
         "email":"zfeed3206@cqerk.com",
         "make":"RAM",
         "vendor":"BLUEINKDIGITAL",
         "quoteId":"0F4ECB57-1207-473D-B684-5B67CB620BCE",
         "parentCompany":"STATEFARM",
         "creditDaysAllowed":10,
         "bizContactPhone":"360-220-7446",
         "bizContactFirstName":"Dewayne",
         "bizContactLastName":"Olvera",
         "sessionId":"7E333405-90F1-4B9D-9FD2-B54CF32374B8",
         "level":"SILVER",
         "creditRate":14.0
      },
      {
         "createDate":"2020-11-25T10:04:20.893",
         "daysOld":22,
         "callerId":null,
         "filterList":"AUTO_TICKETS_NONE, AUTO_INSURED_1_UP, NO_AUTO_SR22, AUTO_DRIVER_AGE_25, NO_AUTO_SUSPENSION, NO_AUTO_DUI, AUTO_ACCIDENT_HOUSEHOLD_NONE",
         "id":"26954932-8C32-4301-AEC2-9C61B2D8CCCF",
         "creditRequestDate":"2020-11-30T13:16:15.093",
         "status":"success",
         "payout":15.75,
         "creditRequestReason":"CONTACT_DISCONNECTED",
         "creditRequestDescription":"",
         "primaryPhone":"916-210-1086",
         "ivrMatchType":null,
         "quoteTypeId":"AUTO",
         "formerInsurer":"PROGRESSIVE",
         "targusRating":null,
         "leadQuality":null,
         "firstName":"Eddy",
         "lastName":"Yung",
         "city":"Saint Louis",
         "state":"MO",
         "zip":"63136",
         "email":"zfeed3206@cqerk.com",
         "make":"RAM",
         "vendor":"BLUEINKDIGITAL",
         "quoteId":"0F4ECB57-1207-473D-B684-5B67CB620BCE",
         "parentCompany":"STATEFARM",
         "creditDaysAllowed":10,
         "bizContactPhone":"916-210-1086",
         "bizContactFirstName":"Dewayne",
         "bizContactLastName":"Olvera",
         "sessionId":"7E333405-90F1-4B9D-9FD2-B54CF32374B8",
         "level":"SILVER",
         "creditRate":14.0
      }
   ]`);
}

export function handleFailedNetworkResponse(dispatch, error){
   const message = error?.reponse?.data || "An unexpected error occurred";
   dispatch('toastr/error', message);
}
