import moment from 'moment-timezone';

export const round = (number, precision) =>
  Number(Math.round(number + 'e' + precision) + 'e-' + precision);

export function float(value, precision) {
  if (typeof precision === 'undefined') {
    precision = 1;
  }

  if (!value || isNaN(value)) {
    return value;
  } else if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return value.toFixed(precision);
}

export function percent(value, decimals) {
  if (typeof decimals === 'undefined') {
    decimals = 0;
  }

  if (!Number.isInteger(value) && value < 1) {
    value = value * 100;
  }

  return round(value, decimals) + '%';
}

export function formatDate(value, format) {
  return moment(value).format(format || 'MM-DD-YYYY');
}

export function removeItemString(value) {
  value = value.replace('item', '');
  value = value.replace('Item', '');
  value = value.replace('ITEM', '');
  return value;
}

// This is for a speific thing on lead details
export function getThousands(value) {
  value = value.toString();
  var newStr = value.substring(0, value.length - 3);
  if (newStr === '') {
    return '0';
  }

  return newStr;
}

export function formatPhoneNumber(value) {
  if (value !== null && value.length > 10) {
    // Call lead types with email addresses are sometimes being fed to the formatter.
    // In this case just grab the end digits
    value = value.slice(value.length - 10); // Outputs: 10 digit phone number
  }

  return value === null
    ? null
    : value
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function groupNumbers(value) {
  return value.toLocaleString('en', { useGrouping: true });
}

export function currencyUSD(value) {
  if (!value && value !== 0) return value;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

export function toUserDateTime(value, format, tz = 'America/New_York') {
  if (!value) {
    return '';
  }
  try {
    return moment.utc(value).tz(tz).format(format);
  } catch (error) {
    return value;
  }
}

export function pluralize(number, singular, plural) {
  return number === 1 ? singular : plural || singular + 's';
}

export function capitalize(value) {
  if (value !== null) {
    value = value.toUpperCase();
  }

  return value;
}

export function secondsToDuration(seconds){
  return new Date(seconds * 1000).toISOString().substring(11, 19)
}
