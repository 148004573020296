import _ from '@/utils/store-helpers';
import axiosInstance from '@/plugins/axios';
import errorTracker from '@/plugins/errorTracker';
import Notifications from '@/components/notifications/Notifications';

export const state = {
  loading: false,
  notifications: [],
  all: [],
  includeExpired: false,
};

export const getters = {
  loading: (state) => state.loading,

  clientNotifications: (state) => state.notifications,

  bannerNotifications: (state, { clientNotifications }) => {
    return clientNotifications.filter((n) => {
      return n.type === Notifications.types.BANNER;
    });
  },

  dialogNotifications: (state, { clientNotifications }) => {
    return clientNotifications.filter((n) => {
      return n.type === Notifications.types.DIALOG;
    });
  },

  trayNotifications: (state, { clientNotifications }) => {
    return clientNotifications.filter((n) => {
      return n.type === Notifications.types.TRAY;
    });
  },

  trayNotificationCount: (state, { trayNotifications }) => {
    return trayNotifications.length;
  },

  allNotifications: (state) => state.all,

  includeExpired: (state) => state.includeExpired,
};

export const actions = {
  async fetchAllNotifications({ commit, dispatch }, includeExpired = false) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get('/api/notification/admin', {
        params: { includeExpired },
      })
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', 'Failed to get Notifications.', { root: true });
      commit('setLoading', false);
      return;
    }

    commit('setAll', response.data);
    commit('setLoading', false);
  },

  async fetchClientNotifications({ commit, dispatch, rootGetters }) {
    if (!rootGetters['client/hasClient']) {
      return;
    }
    const response = await axiosInstance
      .get('/api/notification')
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', 'Failed to get Client Notifications.', {
        root: true,
      });
      return;
    }
    const notifications = Notifications.filterSeen(response.data);

    notifications.forEach((e) => {
      //      e.content = sanitizeHtml(e.content);
      //      e.headline = sanitizeHtml(e.headline);
      //      e.url = sanitizeHtml(e.url);
    });
    commit('setNotifications', notifications);
  },
  async upsertNotification(
    { commit, dispatch },
    { notificationModel, callback }
  ) {
    commit('setLoading', true);

    const method = notificationModel.id ? 'put' : 'post';

    // notificationModel.content = sanitizeHtml(notificationModel.content);
    // notificationModel.headline = sanitizeHtml(notificationModel.headline);
    // notificationModel.url = sanitizeHtml(notificationModel.url);

    const response = await axiosInstance[method](
      '/api/notification',
      notificationModel
    ).catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', 'Failed to create notification.', {
        root: true,
      });
      commit('setLoading', false);
      callback && callback(response);
      return;
    }

    if (method === 'put') {
      commit('UPDATE_NOTIFICATION', notificationModel);
    } else {
      commit('ADD_NOTIFICATION', response.data);
    }

    commit('setLoading', false);
    callback && callback();
  },

  async deleteNotification({ commit, dispatch }, notificationId) {
    commit('setLoading', true);

    const response = await axiosInstance
      .delete(`/api/notification/${notificationId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', 'Failed to delete notification.', {
        root: true,
      });
      commit('setLoading', false);
      return;
    }

    commit('DELETE_NOTIFICATION', notificationId);
    commit('setLoading', false);
  },

  dismissNotification({ commit, state }, notificationId) {
    Notifications.dismiss(notificationId);

    const newNotifications = Notifications.filterSeen(state.notifications);

    commit('setNotifications', newNotifications);
  },

  changeIncludeExpired({ commit, dispatch }, includeExpired) {
    commit('setIncludeExpired', includeExpired);
    dispatch('fetchAllNotifications', includeExpired);
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setNotifications: _.set('notifications'),
  setAll: _.set('all'),
  setIncludeExpired: _.set('includeExpired'),

  ADD_NOTIFICATION: (state, notification) => {
    state.all.unshift(notification);
  },

  UPDATE_NOTIFICATION: (state, notification) => {
    for (let i = 0; i < state.all.length; i++) {
      if (state.all[i].id === notification.id) {
        state.all[i] = notification;
        return;
      }
    }
  },

  DELETE_NOTIFICATION: (state, notificationId) => {
    state.all = state.all.filter((n) => n.id !== notificationId);
  },
};
