import { axiosInstance } from '@/plugins/axios';
import { convertLutGeoMapsToGeoMaps } from '@/components/channels/Territories/TerritoriesUtils';
import errorTracker from '@/plugins/errorTracker';
import _ from '@/utils/store-helpers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import campaignService from '@/services/campaignService';

export const DEFAULT_PACKAGE_ID = '00000000-0000-0000-0000-000000000000';

const medsuppHealthCompanies = [
  'INDEPENDENT',
  'MUTOMA',
  'AMERICANNATIONAL',
  'LIBERTY',
  'NYLIFE',
  'BANKERS',
  'NATIONWIDE',
  'ANCHOR_CLIENT',
  'SIAA',
];

export const getInitialState = () => ({
  accountReps: [],
  availableLutQuoteTypes: [],
  cancelSavePackages: [],
  crmTypes: [],
  inactivePackage: null,
  isLoading: false,
  leadType: {
    acceptsBundle: false,
    acceptsCombo: false,
    answerRate: 0.0,
    backfill: true,
    bidFloor: null,
    billableRate: 0.0,
    clientGeoMaps: {
      removed: [],
      added: [],
    },
    clientId: null,
    createDate: null,
    crmDelivery: [],
    currentSubsidy: 0,
    currentPromoDiscount: 0,
    customName: null,
    dayCap: 0,
    dayCapSchedule: {
      sunday: -1,
      monday: -1,
      tuesday: -1,
      wednesday: -1,
      thursday: -1,
      friday: -1,
      saturday: -1,
      weekly: -1,
    },
    discount: 0,
    effectivePayoutRate: 0,
    effectiveValue: 0.0,
    emailDelivery: [],
    enableRecording: true,
    eprMultiplier: null,
    eprResetDate: new Date(),
    estimateAmount: null,
    estimateId: 0,
    estimatedMonthlyLeads: 0,
    expansionOptOut: false,
    filter: null,
    filterList: null,
    geoMaps: [],
    id: null,
    initialPackageId: DEFAULT_PACKAGE_ID,
    isLegAnchor: false,
    lastLeadDate: '0001-01-01T00:00:00',
    maxSold: 0,
    minutesBeforeNext: 0,
    missedCallPenalty: 0.0,
    missedCallRate: 0.0,
    modifier: 0.0,
    nextActiveDate: '0001-01-01T00:00:00',
    packageId: DEFAULT_PACKAGE_ID,
    payout: 0,
    payoutDiscount: 0,
    postFailureRate: 0,
    postTemplate: '',
    phoneNumbers: [],
    priceModifiers: [],
    productSourceTypeId: 0,
    productTypeId: 1,
    quoteMode: null,
    quoteType: 'Auto',
    quoteTypeId: null,
    quoteTypeIsLocked: true,
    rank: 0,
    schedule: [],
    secondaryTerritoryExcludedZipCodes: [],
    serviceType: 'Web',
    scrubRate: 0,
    sourcePackage: null,
    status: 'Active',
    subscriptionDescription: '',
    tierMixCap: 0,
    trumpFlag: 0,
    updateDate: null,
    upsellRep: '',
    upsellPromo: '',
    weekdayNetPayout: 0,
    weekendNetPayout: 0,
  },
  leadTypes: {
    auto: { value: 'AUTO', label: 'Auto' },
    home: { value: 'HOME', label: 'Home' },
    renter: { value: 'RENTER', label: 'Renter' },
    medsupp: { value: 'MEDSUPP', label: 'Medsupp' },
    health: { value: 'HEALTH', label: 'Health' },
    life: { value: 'LIFE', label: 'Life' },
  },
  originalPackageId: '',
  packageBasePrice: 0,
  packages: [],
  stateSettingsOverrides: [],
  temporaryPauseDuration: null,
  territoryDetails: {
    secondaryTerritoryZipCodes: [],
    territorySettings: [],
  },
});

export const state = getInitialState();

export const getters = {
  filters: (state) => {
    if (state.leadType) {
      return state.leadType.filterList && state.leadType.filterList.split(', ');
    }
    return [];
  },
  hasOwnsHomeFilter: (state) =>
    state.leadType?.filterList?.includes('AUTO_OWN_HOME'),
  hasCustomPackage: (state) => {
    return state.leadType.packageId === DEFAULT_PACKAGE_ID;
  },
  loading: (state) => state.isLoading,
  getTerritories: (state) => state.leadType.geoMaps,
  selectedStates: (state) => [
    ...new Set(state.leadType.geoMaps.map((x) => x.stateAbbreviation)),
  ],
  getExcludedZips: (state) => state.leadType.secondaryTerritoryExcludedZipCodes,
  getExpansionOptOut: (state) => state.leadType.expansionOptOut,
  quoteType: (state) => state.leadType.quoteType,
  subsidyToolTipMessage: () =>
    'Applicable subsidies are based on tier, product, and the lead county as determined by State Farm.',
  isWeb: (state) => state.leadType?.serviceType === 'Web',
  isCall: (state) => state.leadType?.serviceType === 'Call',
  availableLeadTypes: (state, getters, rootState, rootGetters) => {
    const parentCompany =
      rootState.client.client?.parentCompany ||
      rootState.signup.signupInfo?.client?.parentCompany;
    const clientType =
      rootState.client.client?.clientType ||
      rootState.signup.signupInfo?.client?.clientType;
    const isCorporate = clientType === 'CORPORATE';
    const isStaffUser = rootGetters['auth/isStaff'];
    const [isWeb, isCall] = [getters.isWeb, getters.isCall];
    const { auto, home, renter, medsupp, health, life } = state.leadTypes;
    switch (true) {
      case isWeb && parentCompany === 'USHA':
        return [medsupp, health];
      case isCorporate && isStaffUser:
        return [auto, home, renter, medsupp, health, life];
      case isCorporate:
        return [auto, home, renter, medsupp, health];
      case isCall && parentCompany === 'COUNTRYFINANCIA':
        // Calls are not available to Country Financial.
        return [];
      case isCall && isStaffUser:
        return [auto, home, life];
      case isCall:
        return [auto, home];
      case isWeb && ['FARMERS', 'STATEFARM'].includes(parentCompany):
        return [auto, home, renter];
      case isWeb &&
        isStaffUser &&
        medsuppHealthCompanies.includes(parentCompany):
        return [auto, home, renter, medsupp, health, life];
      case isWeb && medsuppHealthCompanies.includes(parentCompany):
        return [auto, home, renter, medsupp, health];
      case isWeb && isStaffUser:
        return [auto, home, renter, life];
      case isWeb:
        return [auto, home, renter];
      default:
        return [];
    }
  },
  isCampaign: (state) => !!state.stateSettingsOverrides.length,
};

export const mutations = {
  setLoading: _.set('isLoading'),

  RESET_STATE(state) {
    const initialState = getInitialState();
    Object.keys(state).forEach((key) => {
      state[key] = initialState[key];
    });
  },
  SET_LEAD_TYPE(state, newValue) {
    if (newValue.isNew) {
      newValue.id = uuidv4();
    }
    newValue.emailDelivery = newValue.emailDelivery || [];
    newValue.crmDelivery = newValue.crmDelivery || [];
    newValue.phoneNumbers = newValue.phoneNumbers || [];
    state.leadType = newValue;
  },
  SET_TERRITORY_DETAILS(state, newValue) {
    state.territoryDetails = newValue;
  },
  SET_DAY_CAP(state, { day, value }) {
    state.leadType.dayCapSchedule[day.toLowerCase()] = value;
    if (day.toLowerCase() === 'weekly') {
      state.leadType.weeklyCap = value;
    }
  },
  SET_DAY_CAPS(state, dayCapSchedule) {
    state.leadType.dayCapSchedule = dayCapSchedule;
  },
  SET_CLIENT_GEOMAP(state, newValue) {
    state.leadType.clientGeoMaps.removed = newValue.removed;
    state.leadType.clientGeoMaps.added = newValue.added;
  },
  RESET_DAY_CAPS(state, resetValue) {
    const days = Object.keys(state.leadType.dayCapSchedule);
    for (const day of days) {
      if (day !== 'weekly') {
        // reset all to Monday cap value except weekly cap
        state.leadType.dayCapSchedule[day] = resetValue;
      }
    }
  },
  SET_CRM_TYPES(state, crmTypes) {
    state.crmTypes = crmTypes;
  },
  SET_CRM_DELIVERY(state, newValue) {
    state.leadType.crmDelivery = newValue;
  },
  SET_EMAIL_DELIVERY(state, emails) {
    state.leadType.emailDelivery = emails;
  },
  REMOVE_EMAIL_DELIVERY(state, index) {
    state.leadType.emailDelivery.splice(index, 1);
  },
  ADD_EMAIL_DELIVERY(state, newEmail) {
    state.leadType.emailDelivery.push(newEmail);
  },
  UPDATE_EMAIL_DELIVERY(state, { index, updatedEmail }) {
    state.leadType.emailDelivery[index] = updatedEmail;
  },
  RESET_EMAIL_DELIVERY(state) {
    if (!state.leadType.emailDelivery) {
      state.leadType.emailDelivery = [];
    } else {
      state.leadType.emailDelivery.splice(
        0,
        state.leadType.emailDelivery.length
      );
    }
  },
  REMOVE_CRM_DELIVERY(state, index) {
    state.leadType.crmDelivery.splice(index, 1);
  },
  ADD_CRM_DELIVERY(state, newCrm) {
    state.leadType.crmDelivery.push(newCrm);
  },
  UPDATE_CRM_DELIVERY(state, { index, updatedCrm }) {
    state.leadType.crmDelivery[index] = updatedCrm;
  },
  RESET_CRM_DELIVERY(state) {
    if (!state.leadType.crmDelivery) {
      state.leadType.crmDelivery = [];
    } else {
      state.leadType.crmDelivery.splice(0, state.leadType.crmDelivery.length);
    }
  },
  SET_PHONE_DELIVERY(state, phones) {
    state.leadType.phoneNumbers = phones;
  },
  REMOVE_PHONE_DELIVERY(state, index) {
    state.leadType.phoneNumbers.splice(index, 1);
  },
  ADD_PHONE_DELIVERY(state, newPhone) {
    state.leadType.phoneNumbers.push(newPhone);
  },
  UPDATE_PHONE_DELIVERY(state, { index, updatedPhone }) {
    state.leadType.phoneNumbers[index] = updatedPhone;
  },
  RESET_PHONE_DELIVERY(state) {
    if (!state.leadType.phoneNumbers) {
      state.leadType.phoneNumbers = [];
    } else {
      state.leadType.phoneNumbers.splice(0, state.leadType.phoneNumbers.length);
    }
  },
  SET_PACKAGE_ID(state, newValue) {
    state.leadType.packageId = newValue;
  },
  SET_ACCEPTS_COMBO(state, newValue) {
    state.leadType.acceptsCombo = newValue;
  },
  SET_SERVICE_TYPE(state, newValue) {
    state.leadType.serviceType = newValue;
    state.leadType.productTypeId = newValue === 'Web' ? 1 : 2;
  },
  SET_PRODUCT_TYPE_ID(state, newValue) {
    state.leadType.productTypeId = newValue;
    state.leadType.serviceType = newValue === 1 ? 'Web' : 'Call';
  },
  SET_QUOTE_TYPE(state, newValue) {
    state.leadType.quoteType = newValue;
  },
  SET_TRUMP_FLAG(state, newValue) {
    state.leadType.trumpFlag = newValue;
  },
  SET_STATUS(state, newValue) {
    state.leadType.status = newValue;
  },
  SET_CAN_PAUSE(state, canPause) {
    state.leadType.canPause = canPause;
  },
  SET_CHANNEL_TYPE(state, newValue) {
    state.leadType.productTypeId = newValue;
  },
  SET_CHANNEL_NAME(state, newValue) {
    state.leadType.customName = newValue;
  },
  SET_MIN_PRICE(state, newValue) {
    state.leadType.minPayout = newValue;
  },
  SET_MAX_PRICE(state, newValue) {
    state.leadType.maxPayout = newValue;
  },
  SET_IS_HIDE_PHONE_NUMBER(state, newValue) {
    state.leadType.isHidePhoneNumber = newValue;
  },
  SET_CHANNEL_PRICE(state, newValue) {
    state.leadType.payout = newValue;
  },
  SET_POST_TEMPLATE(state, newValue) {
    state.leadType.postTemplate = newValue;
  },
  SET_FILTER_LIST(state, newValue) {
    state.leadType.filterList = newValue;
  },
  SET_SCHEDULE(state, newValue) {
    state.leadType.schedule = newValue;
  },
  SET_MAX_SOLD(state, newValue) {
    state.leadType.maxSold = newValue;
  },
  SET_SOURCE_PACKAGE(state, { sourcePackageId }) {
    if (!state.leadType.sourcePackage) {
      state.leadType.sourcePackage = {};
    }

    const sourcePackageUpdated =
      state.leadType.sourcePackage.id !== sourcePackageId;

    state.leadType.sourcePackage.id = sourcePackageId;

    if (sourcePackageId !== null && sourcePackageId !== 'NO_PACKAGE') {
      const startDate =
        state.leadType.sourcePackage.startDate && !sourcePackageUpdated
          ? moment(state.leadType.sourcePackage.startDate)
          : moment();

      state.leadType.sourcePackage.startDate = startDate.toDate();
    }
  },
  SET_QUOTE_TYPE_LOCK(state, newValue) {
    state.leadType.quoteTypeIsLocked = newValue;
  },
  SET_BACKFILL(state, newValue) {
    state.leadType.backfill = newValue;
  },
  SET_MISSEDCALLPENALTY(state, newValue) {
    state.leadType.missedCallPenalty = newValue;
  },
  SET_PREMIUM(state, newValue) {
    state.leadType.isPremium = newValue;
  },
  SET_APPLYANSWERRATE(state, newValue) {
    state.leadType.applyAnswerRate = newValue;
  },
  SET_ENABLERECORDING(state, newValue) {
    state.leadType.enableRecording = newValue;
  },
  SET_UNANSWEREDTHRESHOLD(state, newValue) {
    state.leadType.unansweredThreshold = newValue;
  },
  SET_ENABLEAUTOPAUSE(state, newValue) {
    state.leadType.enableAutoPause = newValue;
  },
  SET_CALL_TYPE(state, newValue) {
    state.leadType.callType = newValue;
  },
  SET_TERRITORIES(state, newValue) {
    state.leadType.geoMaps = newValue;
  },
  ADD_TERRITORY(state, newValue) {
    state.leadType.geoMaps.push(newValue);
  },
  ADD_TERRITORIES(state, geoMaps) {
    state.leadType.geoMaps.push(...geoMaps);
  },
  REMOVE_TERRITORY(state, index) {
    state.leadType.geoMaps.splice(index, 1);
  },
  REMOVE_TERRITORY_STATE(state, index) {
    const tempArray = state.leadType.geoMaps.filter(
      (states) =>
        states.stateAbbreviation !==
        state.leadType.geoMaps[index].stateAbbreviation
    );

    state.leadType.geoMaps = tempArray;
  },
  REMOVE_TERRITORY_STATES(state, stateAbbrev) {
    const tempArray = state.leadType.geoMaps.filter(
      (states) => states.stateAbbreviation !== stateAbbrev
    );

    state.leadType.geoMaps = tempArray;
  },
  REMOVE_ZIPCODE(state, index) {
    state.leadType.geoMaps.splice(index, 1);
  },
  SET_CLIENT_ID(state, newValue) {
    state.leadType.clientId = newValue;
  },
  SET_MODIFIER(state, newValue) {
    state.leadType.modifier = newValue;
  },
  SET_BILLABLE_TIME(state, newValue) {
    state.leadType.billTimeThreshold = newValue;
  },
  SET_COMMERCIAL_NAME(state, newValue) {
    state.leadType.commercialName = newValue;
  },
  SET_EPR(state, date) {
    state.leadType.eprResetDate = date;
  },
  SET_CANCEL_SAVE_PACKAGES(state, packages) {
    state.cancelSavePackages = packages;
  },
  SET_PACKAGES(state, packages) {
    state.packages = packages;
  },
  ADD_PRICE_MODIFIER(state, priceModifier) {
    const modifierToAdd = {
      id: null,
      packagePriceModifierId: priceModifier.packagePriceModifierId,
      clientQuoteTypeId: priceModifier.clientQuoteTypeId,
      price: priceModifier.price,
    };
    if (state.leadType.priceModifiers === null) {
      state.leadType.priceModifiers = [];
    }
    state.leadType.priceModifiers.push(modifierToAdd);
  },
  UPDATE_PRICE_MODIFIER(state, priceModifier) {
    const modifierToUpdate = state.leadType.priceModifiers.find(
      (modifierToFind) =>
        modifierToFind.packagePriceModifierId ===
        priceModifier.packagePriceModifierId
    );
    if (modifierToUpdate) {
      modifierToUpdate.price = priceModifier.price;
    } else {
      ADD_PRICE_MODIFIER(state, priceModifier);
    }
  },
  REMOVE_PRICE_MODIFIER(state, priceModifierIdToRemove) {
    if (!state.leadType.priceModifiers) {
      return;
    }
    state.leadType.priceModifiers = state.leadType.priceModifiers.filter(
      (filter) =>
        filter.packagePriceModifierId !==
        priceModifierIdToRemove.packagePriceModifierId
    );
  },
  CLEAR_PRICE_MODIFIERS(state) {
    if (!state.leadType.priceModifiers) {
      return;
    }
    state.leadType.priceModifiers = [];
  },
  SET_INACTIVE_PACKAGE(state, inactivePackage) {
    state.inactivePackage = inactivePackage;
  },
  SET_PACKAGE_BASE_PRICE(state, price) {
    state.packageBasePrice = price;
  },
  SET_SUBSCRIPTION_DESCRIPTION(state, des) {
    state.leadType.subscriptionDescription = des;
  },
  SET_ENABLE_RECORDING(state, newValue) {
    state.leadType.enableRecording = newValue;
  },
  SET_TEMPORARY_PAUSE(state, temporaryPauseModel) {
    state.leadType.temporaryPause = temporaryPauseModel;
  },
  SET_TEMPORARY_PAUSE_DURATION(state, duration) {
    state.temporaryPauseDuration = duration;
  },
  UPDATE_CUSTOM_PACKAGE_COST(state, cost) {
    const customPackage = state.packages.find(
      (p) => p.id === DEFAULT_PACKAGE_ID
    );
    customPackage.cost = cost;
    state.packages = [
      ...state.packages.filter((p) => p.id !== DEFAULT_PACKAGE_ID),
      customPackage,
    ];
  },
  SET_ORIGINAL_PACKAGE_ID(state, id) {
    state.originalPackageId = id;
  },
  SET_BID_FLOOR(state, bf) {
    state.leadType.bidFloor = bf;
  },
  SET_ACCOUNT_REPS(state, reps) {
    state.accountReps = reps;
  },
  SET_UPSELL_REP(state, rep) {
    state.leadType.upsellRep = rep;
  },
  SET_IS_LEG_ANCHOR(state, status) {
    state.leadType.isLegAnchor = status;
  },
  SET_UPSELL_PROMO(state, promo) {
    state.leadType.upsellPromo = promo;
  },
  SET_EPR_MULTIPLIER(state, value) {
    state.leadType.eprMultiplier = value;
  },
  SET_ESTIMATE(state, newValue) {
    state.leadType.estimateAmount = newValue;
  },
  SET_AVAILABLE_LUT_QUOTE_TYPES(state, value) {
    state.availableLutQuoteTypes = value;
  },
  SET_EXCLUDE_LIST(state, value) {
    // hacking for the moment.  This will need to take the string and convert it to an array

    state.leadType.secondaryTerritoryExcludedZipCodes = value;
  },
  SET_EXPANSION_OPTOUT(state, value) {
    state.leadType.expansionOptOut = value;
  },
  SET_STATE_SETTINGS_OVERRIDES(state, newValue) {
    state.stateSettingsOverrides = newValue;
  },
  SET_NEW_WEB_LEAD_SCHEDULE(state, scheduleToLeadTypeSchedule) {
    state.leadType.schedule = scheduleToLeadTypeSchedule;
  },
};

export const actions = {
  async addAllStateCounties(
    { commit, dispatch, state },
    { stateAbbreviation, counties }
  ) {
    const geoMaps = state.leadType.geoMaps.filter(
      (g) => g.type === 'County' && g.stateAbbreviation === stateAbbreviation
    );
    const countiesSet = [...new Set(geoMaps.map((t) => t.county))];

    const toAdd = [];
    counties.forEach((x) => {
      if (countiesSet.indexOf(x.county) === -1) {
        const newTerr = {
          county: x.countyName,
          stateAbbreviation: x.stateAbbr,
          type: 'County',
          zipCode: null,
        };

        toAdd.push(newTerr);
      }
    });

    commit('ADD_TERRITORIES', toAdd);

    // Remove the state all selected entry if it exists.
    dispatch('removeStateAll', stateAbbreviation);
  },
  async addAllCountyZips(
    { commit, dispatch, state },
    { stateAbbreviation, countyName, zips }
  ) {
    const geoMaps = state.leadType.geoMaps.filter(
      (g) =>
        g.type === 'ZipCode' &&
        g.county === countyName &&
        g.stateAbbreviation === stateAbbreviation
    );
    const zipSet = [...new Set(geoMaps.map((t) => t.zipCode))];

    const toAdd = [];
    zips.forEach((x) => {
      if (zipSet.indexOf(x.zipCode) === -1) {
        const newTerr = {
          county: x.countyName,
          stateAbbreviation,
          type: 'ZipCode',
          zipCode: x.zipCode,
        };

        toAdd.push(newTerr);
      }
    });

    commit('ADD_TERRITORIES', toAdd);

    // Remove the county all selected entry if it exists.
    dispatch('removeCountyAll', { stateAbbreviation, countyName });
  },
  async addState({ commit, state }, stateAbbreviation) {
    if (!stateAbbreviation) {
      return;
    }

    // Removes state, counties, and zip codes associated w/ state
    const newTerritories = state.leadType.geoMaps.filter(
      (g) => g.stateAbbreviation !== stateAbbreviation
    );

    // Add state
    newTerritories.push({
      county: null,
      stateAbbreviation,
      type: 'State',
      zipCode: null,
    });

    commit('SET_TERRITORIES', newTerritories);
  },
  async addCounty({ commit, state }, county) {
    if (!county) {
      return;
    }

    const { name, stateAbbr } = county;
    if (!name || !stateAbbr) {
      return;
    }

    // Removes counties and zip codes associated w/ county
    const newTerritories = state.leadType.geoMaps.filter(
      (g) => !(g.stateAbbreviation === stateAbbr && g.county === name)
    );

    // Add county
    newTerritories.push({
      county: name,
      stateAbbreviation: stateAbbr,
      type: 'County',
      zipCode: null,
    });

    commit('SET_TERRITORIES', newTerritories);
  },
  async addZipCode({ commit, state }, { stateAbbreviation, county, zipCode }) {
    // Remove zip code
    const newTerritories = state.leadType.geoMaps.filter(
      (g) =>
        !(
          g.stateAbbreviation === stateAbbreviation &&
          g.county === county &&
          g.zipCode === zipCode
        )
    );

    // Add zip
    newTerritories.push({
      county,
      stateAbbreviation,
      type: 'ZipCode',
      zipCode,
    });

    commit('SET_TERRITORIES', newTerritories);
  },
  async removeZipCode(
    { commit, state },
    { stateAbbreviation, county, zipCode }
  ) {
    // Remove zip code
    const newTerritories = state.leadType.geoMaps.filter(
      (g) =>
        !(
          g.stateAbbreviation === stateAbbreviation &&
          g.county === county &&
          g.zipCode === zipCode
        )
    );

    commit('SET_TERRITORIES', newTerritories);
  },
  async removeState({ commit, state }, stateAbbreviation) {
    // Removes state, counties, and zip codes associated w/ state
    const newTerritories = state.leadType.geoMaps.filter(
      (g) => g.stateAbbreviation !== stateAbbreviation
    );

    commit('SET_TERRITORIES', newTerritories);
  },
  async removeStateAll({ commit, state }, stateAbbreviation) {
    const stateEntries = state.leadType.geoMaps.filter(
      (g) => g.stateAbbreviation === stateAbbreviation && g.type === 'State'
    );
    if (stateEntries && stateEntries.length > 0) {
      const toRemove = stateEntries[0];
      commit('REMOVE_TERRITORY', toRemove);
    }
  },
  async removeCountyAll({ commit, state }, { stateAbbreviation, countyName }) {
    const countyEntries = state.leadType.geoMaps.filter(
      (g) =>
        g.stateAbbreviation === stateAbbreviation &&
        g.county === countyName &&
        g.type === 'County'
    );
    if (countyEntries && countyEntries.length > 0) {
      const toRemove = countyEntries[0];
      commit('REMOVE_TERRITORY', toRemove);
    }
  },
  async removeCounty(
    { commit, dispatch, state },
    { stateAbbreviation, countyName, zips }
  ) {
    // Remove all zips for county and the county (all) entry.
    const existingGeos = state.leadType.geoMaps;

    for (const zip of zips) {
      const zipIndex = existingGeos.findIndex(
        (existing) => existing.type === 'ZipCode' && existing.zipCode === zip
      );

      if (~zipIndex) {
        commit('REMOVE_TERRITORY', zipIndex);
        continue;
      }
    }
    // Remove county all
    const countyIndex = existingGeos.findIndex(
      (existing) => existing.type === 'County' && existing.county === countyName
    );

    if (~countyIndex) {
      commit('REMOVE_TERRITORY', countyIndex);
    }
  },

  async removeTerritoryCounty(
    { commit, state },
    { stateAbbreviation, countyName, zips }
  ) {
    // Remove all zips for county and the county (all) entry.
    const existingGeos = state.leadType.geoMaps;

    for (const zip of zips) {
      const zipIndex = existingGeos.findIndex(
        (existing) => existing.type === 'ZipCode' && existing.zipCode === zip
      );

      if (~zipIndex) {
        commit('REMOVE_TERRITORY', zipIndex);
        continue;
      }
    }
    // Remove county all
    const countyIndex = existingGeos.findIndex(
      (existing) =>
        existing.type === 'County' &&
        existing.county === countyName &&
        existing.stateAbbreviation === stateAbbreviation
    );

    if (~countyIndex) {
      commit('REMOVE_TERRITORY', countyIndex);
    }
  },

  getPackageRestrictions({ commit, state }) {
    const packageId = state.leadType.packageId;
    return axiosInstance
      .get(`/api/LeadType/${packageId}/restrictions`)
      .then((response) => {
        const data = response.data;
        commit('SET_MAX_SOLD', data.maxSold);
        return data;
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  },

  async getLeadType({ commit, dispatch }, clientQuoteTypeId) {
    commit('setLoading', true);

    const response = await axiosInstance.get(
      `/api/LeadType/${clientQuoteTypeId}`
    );

    const { temporaryPause } = response.data;

    if (temporaryPause) {
      const { start, scheduledEnd } = temporaryPause;
      const duration = Date.parse(scheduledEnd) - Date.parse(start);
      commit('SET_TEMPORARY_PAUSE_DURATION', duration);
    }

    response.data.isNew = false;
    commit('SET_LEAD_TYPE', response.data);
    commit('SET_ORIGINAL_PACKAGE_ID', response.data.packageId);

    if (response.data.stateOverrides?.length) {
      // Convert state overrides to state settings
      const stateSettings = response.data.stateOverrides.map(
        (stateOverride) => {
          return {
            stateAbbreviation: stateOverride.state,
            minBid: stateOverride.price.minBid,
            maxBid: stateOverride.price.maxBid,
            // effectiveValue: Not used yet?
            isActivated: stateOverride.isActive,
          };
        }
      );

      commit('SET_STATE_SETTINGS_OVERRIDES', stateSettings);
    } else {
      // Reset state settings
      commit('SET_STATE_SETTINGS_OVERRIDES', []);
    }

    commit('setLoading', false);
    return response.data;
  },
  async getTerritoryDetails({ commit, dispatch }, product) {
    const response = await axiosInstance.get(
      `/api/LeadType/territorydetail/${state.leadType.id}?subsidyProduct=${product}`
    );

    commit('SET_TERRITORY_DETAILS', response.data);
  },
  async clearLeadType({ commit }) {
    commit('CLEAR_LEAD_TYPE');
  },

  async getNewLeadType({ commit }, { serviceType, isAgent, isSignup }) {
    commit('setLoading', true);

    const response = await axiosInstance.get(
      `/api/LeadType/new/${serviceType}?isSignup=${isSignup}`
    );

    if (serviceType.toLowerCase() === 'call' && isAgent) {
      response.data.enableAutoPause = true;
    } else {
      response.data.enableAutoPause = false;
    }

    response.data.isNew = true;
    commit('SET_LEAD_TYPE', response.data);
    commit('SET_PACKAGE_ID', null);
    commit('setLoading', false);
    return response.data;
  },

  async getCrmTypes({ commit }) {
    const response = await axiosInstance
      .get(`/api/LeadType/crmList`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      return;
    }

    commit('SET_CRM_TYPES', response.data);
  },

  async verifyECRM({ commit, dispatch, rootState, state }, crm) {
    // Get email value in either io or existing client
    const email =
      rootState?.signup?.signupInfo?.client?.agencyEmail ||
      rootState?.client?.client?.agencyEmail ||
      '';

    if (!email) {
      dispatch(
        'toastr/error',
        'ECRM cannot be added because Agency Email for client is not set',
        {
          root: true,
        }
      );

      return false;
    }

    // Get string leading up to @ symbol
    const [emailId] = /[^@]*/.exec(email);

    // verify that email is correct
    if (emailId?.toLowerCase() === crm.variables[0].toLowerCase()) {
      return true;
    } else {
      dispatch('toastr/error', 'Invalid ECRM Email', {
        root: true,
      });

      return false;
    }
  },

  async getPackage({ commit, dispatch, state }, { packageId }) {
    const getPackageUrl = `/api/LeadType/package/${packageId}`;

    const response = await axiosInstance.get(getPackageUrl).catch((err) => err);

    if (response instanceof Error) {
      if (!response.response) {
        dispatch('toastr/error', 'Failed to retrieve the package', {
          root: true,
        });
        return;
      }

      dispatch('toastr/error', response.response.data.message, {
        root: true,
      });
      return;
    }
    if (response.data) {
      return response.data;
    }
  },

  async getPackageList(
    { commit, dispatch, rootState, state },
    { productType, parentCompany, addCustom, isCorpClient }
  ) {
    let getPackageListUrl = `/api/LeadType/${parentCompany}/${state.leadType.quoteType}/${productType}`;

    if (state.leadType.serviceType === 'Call') {
      if (
        state.leadType != null &&
        state.leadType.originalPackageId != null &&
        state.leadType.originalPackageId.length > 0
      ) {
        getPackageListUrl = `/api/LeadType/calls/${parentCompany}/${state.leadType.id}`;
      } else if (state.leadType.callType === 'ColdTransfer') {
        getPackageListUrl = `/api/LeadType/calls/taz/${parentCompany}/${state.leadType.quoteType}`;
      } else if (parentCompany === 'STATEFARM') {
        getPackageListUrl = `/api/LeadType/calls/taz/${parentCompany}/${state.leadType.quoteType}`;
      } else {
        getPackageListUrl = `/api/LeadType/calls/quotetype/${parentCompany}/${state.leadType.quoteType}`;
      }
    }

    const response = await dispatch('request/get', {
      url: getPackageListUrl,
      errorMessage: 'Failed to retrieve packages.',
    });

    if (!response) return;

    const packages = response.data;

    let packageBasePrice = 0.0;
    // corp types should have custom package set to 0 price as default
    if (!isCorpClient) {
      switch (state.leadType.quoteType.toLowerCase()) {
        case 'home':
          packageBasePrice = 15.0;
          break;
        case 'renter':
          packageBasePrice = 9.0;
          break;
        case 'health':
          packageBasePrice = 8.0;
          break;
        case 'medsupp':
          packageBasePrice = 12.0;
          break;
        default:
          packageBasePrice = 11.25;
      }
    }

    commit('SET_PACKAGE_BASE_PRICE', packageBasePrice);

    if (addCustom) {
      let filterList = [];

      // Okay this is an adventure.   When you load an existing custom lead type
      // the price can get stomped on.  I'm trying to force it to use the existing one.
      if (state.leadType.packageId === DEFAULT_PACKAGE_ID) {
        filterList = state.leadType.filterList;
      }

      packages.push({
        id: DEFAULT_PACKAGE_ID,
        filters: filterList,
        name: 'Custom',
        cost: state.leadType.payout,
        serviceType: 'LEADS',
      });
    }

    // if package is inactive
    if (state.leadType.packageId) {
      const currentPackageId = state.leadType?.packageId?.toUpperCase();
      if (
        currentPackageId &&
        !packages.some((x) => x.id.toUpperCase() === currentPackageId)
      ) {
        const inactivePackage = await dispatch('getPackage', {
          packageId: currentPackageId,
        });
        commit('SET_INACTIVE_PACKAGE', inactivePackage);
        if (state.inactivePackage) {
          packages.splice(0, 0, inactivePackage);
        }
      }
    }

    // Populate Price Modifiers if they exist on the package & fill out missing info
    // map what is on the channel itself to price modifiers. This will override/set the isSelected and the saved Price
    const channelPackage = packages.find(
      (chanPackage) =>
        chanPackage?.id?.toUpperCase() ===
        state?.leadType?.packageId?.toUpperCase()
    );
    if (state.leadType.packageId) {
      channelPackage.priceModifiers ??= [];
      channelPackage.priceModifiers.forEach((channelPriceModifier) => {
        if (state.leadType.priceModifiers?.length > 0) {
          const foundModifier = state.leadType.priceModifiers.find(
            (foundChannelModifier) =>
              foundChannelModifier.packagePriceModifierId ===
              channelPriceModifier.packagePriceModifierId
          );
          if (foundModifier) {
            // override price to match what is saved and it's existence indicates selection.
            channelPriceModifier.price = foundModifier.price;
            channelPriceModifier.isSelected = true;
          }
        }
      });
    }
    // Sort price modifiers by Name so we have consistent display
    packages.forEach((packageProduct) => {
      packageProduct.priceModifiers?.sort((a, b) => {
        const aName = a?.name ?? '';
        const bName = b?.name ?? '';
        return aName.localeCompare(bName);
      });
    });
    commit('SET_PACKAGES', packages);
  },

  updateCustomPackageFilters({ commit, state }, filters) {
    const customPackage = {
      ...(state.packages.find((p) => p.id === DEFAULT_PACKAGE_ID) || {}),
      filters,
    };

    const packages = state.packages.filter((p) => p.id !== DEFAULT_PACKAGE_ID);
    packages.push(customPackage);

    commit('SET_PACKAGES', packages);
  },

  updatePostTemplate({ commit }) {
    let postTemplateString = 'EMAIL:';

    if (state.leadType.emailDelivery.length > 0) {
      postTemplateString += state.leadType.emailDelivery.join(',');
    }

    if (state.leadType.crmDelivery.length > 0) {
      if (postTemplateString.length > 'EMAIL:'.length) {
        postTemplateString += ',';
      }

      for (const v of state.leadType.crmDelivery) {
        const workingAddress = v.variables.join('|');
        postTemplateString += workingAddress + '@' + v.domain + ',';
      }
    }

    commit('SET_POST_TEMPLATE', postTemplateString);
  },

  async createLeadType({
    commit,
    dispatch,
    rootState,
    state,
    getters: { selectedStates, isCampaign },
    rootGetters,
  }) {
    const createChannel = async (leadType) => {
      const response = await dispatch(
        'request/post',
        {
          url: `/api/leadtype/add/${leadType.serviceType}`,
          data: leadType,
          errorMessage: `Failed to create ${leadType.serviceType.toLowerCase()} channel`,
        },
        {
          root: true,
        }
      );

      if (!response) {
        return false;
      }

      await dispatch('scheduler/saveSchedules', response.data, {
        root: true,
      });

      if (leadType.serviceType.toLowerCase() === 'call') {
        await dispatch('callCorporateFilter/saveFilters', response.data, {
          root: true,
        });
      }

      return true;
    };

    const createCampaign = async (leadType) => {
      const scheduleData = rootGetters['scheduler/marshalledScheduleData'](
        leadType.id
      );

      const filters = [...rootState.callCorporateFilter.filters];

      return await dispatch(
        'request/post',
        {
          url: '/api/campaigns/save',
          data: {
            clientQuoteType: leadType,
            schedule: scheduleData,
            filters,
          },
          errorMessage: `Failed to create ${leadType.serviceType.toLowerCase()} campaign`,
        },
        {
          root: true,
        }
      );
    };

    commit('setLoading', true);
    let leadTypeSchedule = [];

    if (state.leadType.serviceType.toLowerCase() === 'web') {
      leadTypeSchedule = await dispatch(
        'scheduler/saveWebLeadSchedules',
        DEFAULT_PACKAGE_ID,
        {
          root: true,
        }
      );
    }

    const leadType = {
      ...state.leadType,
      id: DEFAULT_PACKAGE_ID,
      clientId: rootState.client.client.id,
      stateOverrides: campaignService.mapStateSettingsToOverrides(
        state.stateSettingsOverrides,
        selectedStates
      ),
      schedule: leadTypeSchedule,
    };

    const result = await (isCampaign
      ? createCampaign(leadType)
      : createChannel(leadType));

    commit('setLoading', false);

    return result;
  },

  selectPackage(
    { commit, dispatch, rootState, state },
    { packageId, isCustomPackage, isCorpClient }
  ) {
    if (state.leadType.packageId !== packageId) {
      commit('SET_PACKAGE_ID', packageId);
      const selectedPackage = state.packages.find((p) => p.id === packageId);

      // if corp type and custom package reset price to 0
      let price = 0;
      if (isCorpClient && isCustomPackage) {
        price = 0;
      } else {
        price = selectedPackage.cost;
      }

      commit('SET_CHANNEL_PRICE', price);

      commit('SET_MAX_PRICE', 0);
      dispatch('getPackageRestrictions');
    }
  },

  async updateLeadType({
    commit,
    dispatch,
    state,
    getters: { selectedStates, isCampaign },
    rootState,
    rootGetters,
  }) {
    const updateChannel = async (leadType) => {
      const queryParams = !!state.leadType.estimateAmount
        ? `?estimateAmount=${state.leadType.estimateAmount}`
        : '';

      const response = await dispatch(
        'request/post',
        {
          url: `/api/LeadType/edit/${leadType.serviceType}${queryParams}`,
          data: leadType,
          errorMessage: `Failed to create ${leadType.serviceType.toLowerCase()} channel`,
        },
        {
          root: true,
        }
      );

      if (!response) {
        return false;
      }

      const saveScheduleResult = await dispatch(
        'scheduler/saveSchedules',
        leadType.id,
        {
          root: true,
        }
      );

      let corpFilterSaveResult = true;
      if (leadType.serviceType.toLowerCase() === 'call') {
        corpFilterSaveResult = await dispatch(
          'callCorporateFilter/saveFilters',
          leadType.id,
          {
            root: true,
          }
        );
      }

      return saveScheduleResult && corpFilterSaveResult;
    };

    const updateCampaign = async (leadType) => {
      const scheduleData = rootGetters['scheduler/marshalledScheduleData'](
        state.leadType.id
      );

      const filters = [...rootState.callCorporateFilter.filters];

      return await dispatch(
        'request/post',
        {
          url: '/api/campaigns/save',
          data: {
            clientQuoteType: leadType,
            schedule: scheduleData,
            filters,
          },
          errorMessage: `Failed to update ${leadType.serviceType.toLowerCase()} campaign`,
        },
        {
          root: true,
        }
      );
    };

    commit('setLoading', true);

    if (state.leadType.serviceType.toLowerCase() === 'web') {
      const schedule = await dispatch(
        'scheduler/saveWebLeadSchedules',
        state.leadType.id,
        {
          root: true,
        }
      );

      commit('SET_NEW_WEB_LEAD_SCHEDULE', schedule);
    }

    const leadType = {
      ...state.leadType,
      stateOverrides: campaignService.mapStateSettingsToOverrides(
        state.stateSettingsOverrides,
        selectedStates
      ),
    };

    const response = await (isCampaign
      ? updateCampaign(leadType)
      : updateChannel(leadType));

    commit('setLoading', false);

    return response;
  },

  async resetEPR({ commit, dispatch, state, rootState }, clientQuoteTypeId) {
    const response = await axiosInstance
      .post(
        `/api/LeadType/${rootState.client.client.id}/${clientQuoteTypeId}/resetepr`
      )
      .catch((err) => err);

    if (response instanceof Error) {
      const message =
        (response.response && response.response.data.message) ||
        'Failed to reset EPR.';
      dispatch('toastr/error', message, {
        root: true,
      });
    }

    commit('SET_EPR', new Date().toISOString());
  },

  async getCancelSavePackages({ commit, dispatch, rootState, state }) {
    if (rootState.client.client) {
      const response = await axiosInstance
        .get(
          `/api/LeadType/${rootState.client.client.parentCompany}/${state.leadType.quoteType}/sourceOnlyPackages`
        )
        .catch((err) => err);

      if (response instanceof Error) {
        if (response.response.status === 401) {
          // 401 means user should not see these.
          // This is an expected response code.
          return;
        }
        const message =
          (response.response && response.response.data.message) ||
          'Could not retrieve source packages';
        dispatch('toastr/error', message, {
          root: true,
        });
      }

      commit('SET_CANCEL_SAVE_PACKAGES', response.data);
    }
  },

  async temporaryPauseLeadType({ commit, dispatch, state }) {
    commit('setLoading', true);

    let durationMs = state.temporaryPauseDuration;

    if (state.temporaryPauseDuration === -1) {
      durationMs = moment().startOf('day').add(1, 'day').diff(moment());
    }

    const clientQuoteTypeId = state.leadType.id;
    const duration = moment()
      .startOf('day')
      .add(durationMs, 'ms')
      .format('HH:mm:ss');

    const response = await axiosInstance
      .post('/api/LeadType/pause', {
        clientQuoteTypeId,
        duration,
      })
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({
        exception: response,
      });
      console.error(response);
      dispatch('toastr/error', 'Failed to pause channel.', {
        root: true,
      });
      commit('setLoading', false);
      return;
    }

    commit('SET_TEMPORARY_PAUSE', response.data);
    commit('SET_STATUS', 'PAUSE');
    commit('setLoading', false);
  },

  async temporaryUnpauseLeadType({ commit, dispatch, state }) {
    commit('setLoading', true);

    const response = await axiosInstance
      .delete(`/api/LeadType/pause/${state.leadType.id}`)
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({
        exception: response,
      });
      console.error(response);
      dispatch('toastr/error', 'Failed to unpause channel.', {
        root: true,
      });
      commit('setLoading', false);
      return;
    }

    commit('SET_TEMPORARY_PAUSE', null);
    commit('SET_TEMPORARY_PAUSE_DURATION', null);
    commit('SET_STATUS', 'ACTIVE');
    commit('setLoading', false);
  },

  async getClientGeoMapChanges({ commit }, { clientQuoteTypeId, changeDate }) {
    const response = await axiosInstance.get(
      `/api/leadType/${clientQuoteTypeId}/clientgeomapchanges`,
      {
        params: {
          changeDate,
        },
      }
    );
    const data = response.data;

    return data;
  },

  async removeGeoMaps({ commit, state }, geoMaps) {
    for (const geo of geoMaps) {
      const existingGeos = state.leadType.geoMaps;

      const zipIndex = existingGeos.findIndex(
        (existing) =>
          existing.type === 'ZipCode' && existing.zipCode === geo.zipCode
      );

      if (~zipIndex) {
        commit('REMOVE_TERRITORY', zipIndex);
        continue;
      }

      const stateIndex = existingGeos.findIndex(
        (existing) =>
          existing.type === 'State' &&
          existing.stateAbbreviation === geo.stateAbbreviation
      );

      if (~stateIndex) {
        const response = await axiosInstance
          .delete('/api/territory/states/remove-zip', {
            data: geo,
          })
          .catch((err) => err);

        commit('REMOVE_TERRITORY', stateIndex);
        commit('ADD_TERRITORIES', response.data);
      }
    }
  },

  async getQuoteTypeFilterChanges(
    { commit },
    { clientQuoteTypeId, changeDate }
  ) {
    const response = await axiosInstance.get(
      `/api/leadType/${clientQuoteTypeId}/getclientquotetypefilterchanges`,
      {
        params: {
          changeDate,
        },
      }
    );
    const data = response.data;

    return data;
  },

  async cloneFrom({ commit, dispatch }, { clientQuoteTypeId, type }) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get(`/api/LeadType/${clientQuoteTypeId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({
        exception: response,
      });
      console.error(response);
      commit('setLoading', false);
      dispatch('toastr/error', 'Failed to clone from lead type.', {
        root: true,
      });
      return;
    }

    const {
      geoMaps,
      dayCapSchedule,
      crmDelivery,
      emailDelivery,
      phoneNumbers,
    } = response.data;

    switch (type) {
      case 'TERRITORY':
        commit('SET_TERRITORIES', geoMaps);
        break;
      case 'DELIVERY':
        if (crmDelivery) {
          commit('SET_CRM_DELIVERY', crmDelivery);
          dispatch('updatePostTemplate');
        }
        if (emailDelivery) {
          commit('SET_EMAIL_DELIVERY', emailDelivery);
          dispatch('updatePostTemplate');
        }
        if (phoneNumbers) {
          commit('SET_PHONE_DELIVERY', phoneNumbers);
        }
        break;
      case 'CAPS':
        commit('SET_DAY_CAPS', dayCapSchedule);
        break;
      default:
        throw new Error('Invalid cloneFrom TYPE');
    }

    commit('setLoading', false);
  },

  resetState({ commit, dispatch }) {
    dispatch('scheduler/resetSchedulerState', null, {
      root: true,
    });
    commit('RESET_STATE');
  },

  updateLeadTypeScheduleForNewChannels({ commit }, schedule) {
    if (state.leadType.schedule === null) {
      commit('SET_NEW_WEB_LEAD_SCHEDULE', schedule);
    }
  },

  async loadAccountRepOptions({ commit, dispatch }) {
    const response = await axiosInstance
      .get(`/api/Clients/ACCOUNT_REPS/options`)
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({
        exception: response,
      });
      console.error(response);
      commit('setLoading', false);
      dispatch('toastr/error', 'Failed to clone from lead type.', {
        root: true,
      });
      return;
    }

    commit('SET_ACCOUNT_REPS', response.data);
    return response.data;
  },

  setLutInfo({ commit }, lutGeoMaps) {
    commit(
      'SET_ESTIMATE',
      lutGeoMaps.reduce((sum, geo) => (sum += geo.estimate), 0)
    );
    commit('SET_TERRITORIES', convertLutGeoMapsToGeoMaps(lutGeoMaps));
  },

  async getAvailableLutQuoteTypes({ commit, dispatch }, parentCompany) {
    const response = await dispatch('request/get', {
      url: `/api/leadtype/quoteTypes/${parentCompany}`,
      loadingMutation: 'leadType/setLoading',
      errorMessage: false,
      onError: (error) => {
        console.error(error);
      },
    });

    if (!response) {
      return;
    }

    commit('SET_AVAILABLE_LUT_QUOTE_TYPES', response.data);
  },

  async setTerritories({ commit }, geoMaps) {
    commit('SET_TERRITORIES', geoMaps);
  },

  async setExcludedZipCodes({ commit }, zipCodes) {
    commit('SET_EXCLUDE_LIST', zipCodes);
  },

  async setExpansionOptOut({ commit }, flag) {
    commit('SET_EXPANSION_OPTOUT', flag);
  },

  setWeekdayCaps({ state, commit }, newWeekdayCap) {
    const days = Object.keys(state.leadType.dayCapSchedule);

    for (const day of days) {
      if (day !== 'weekly') {
        const value = ['saturday', 'sunday'].includes(day) ? 1 : newWeekdayCap;

        commit('SET_DAY_CAP', {
          day,
          value,
        });
      }
    }
  },

  async bulkUpdateOptInSecondaryTerritories({ dispatch }, { form, optIn }) {
    return await axiosInstance
      .post(
        `/api/leadType/bulk-update-secondary-territories-opt-status?optIn=${optIn}`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => response.data)
      .catch((err) => {
        // Use response message or generic
        const message = err?.response?.data || err?.message || 'Network Error';

        dispatch('toastr/error', `Error: ${message}`, { root: true });
      });
  },

  setDayCaps({ commit }, dayCapSchedule) {
    commit('SET_DAY_CAPS', dayCapSchedule);
  },

  setDelivery({ commit }, { emailDelivery, crmDelivery, phoneDelivery }) {
    commit('SET_EMAIL_DELIVERY', emailDelivery);
    commit('SET_CRM_DELIVERY', crmDelivery);
    commit('SET_PHONE_DELIVERY', phoneDelivery);
  },

  setLeadType({ commit }, leadType) {
    commit('SET_LEAD_TYPE', leadType);
  },
};
