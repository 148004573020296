import { axiosInstance } from '@/plugins/axios';
import _ from '@/utils/store-helpers';
import { authComputed } from '@/store/helpers';
import errorTracker from '@/plugins/errorTracker';

export const state = {
  client: _.get('client') || null,
  clientLogs: _.get('clientLogs'),
  currentClientStatus: [],
  clientCreditEligibility: [],
  accountDetails: null,
  loading: false,
  clientSysData: [],
  promoDetails: null,
  contract: null,
  sendHistory: [],
  supressionStatus: [],
  subsidyByCountyDetails: [],
  pagination: {
    currentPage: 1,
    pageSize: 20,
    totalRecords: 0,
  },
  salesPeople: [],
};

export const mutations = {
  SET_CURRENT_CLIENT: _.set('client', false),
  SET_ACCOUNT_DETAILS: _.set('accountDetails'),
  // this sets the client status after an update to the status. Represents the client stats before / after a change to the status
  SET_CLIENT_STATUS(state, status) {
    state.client.status = status;
  },
  // this sets the *current* status of a client after a get. Represents the status of a client as is.
  SET_CURRENT_CLIENT_STATUS(state, status) {
    state.currentClientStatus = status;
  },
  SET_CREDIT_ELIGIBILITY(state, val) {
    state.clientCreditEligibility = val;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_CLIENT_SYS_DATA(state, val) {
    state.clientSysData = val;
  },
  SET_PROMO_DETAILS(state, details) {
    state.promoDetails = details;
  },
  SET_CONTRACT(state, data) {
    state.contract = data;
  },
  SET_SEND_HISTORY(state, data) {
    state.sendHistory = data;
  },
  SET_SUPPRESSION_STATUS(state, data) {
    state.supressionStatus = data;
  },
  SET_SALES_PEOPLE(state, data) {
    state.salesPeople = data;
  },

  setAcctDetailFirstName(state, val) {
    state.accountDetails.firstName = val;
  },
  setAcctDetailLastName(state, val) {
    state.accountDetails.lastName = val;
  },
  setAcctDetailFullName(state, val) {
    state.accountDetails.fullName = val;
  },

  setAcctDetailShortName(state, val) {
    state.accountDetails.shortName = val;
  },

  setAcctDetailLicense(state, val) {
    state.accountDetails.license = val;
  },

  setAcctDetailAddress1(state, val) {
    state.accountDetails.address1 = val;
  },
  setAcctDetailAddress2(state, val) {
    state.accountDetails.address2 = val;
  },
  setAcctDetailCity(state, val) {
    state.accountDetails.city = val;
  },
  setAcctDetailState(state, val) {
    state.accountDetails.state = val;
  },
  setAcctDetailZip(state, val) {
    state.accountDetails.zip = val;
  },

  setAcctDetailPhone(state, val) {
    state.accountDetails.phone = val;
  },
  setAcctDetailEmail(state, val) {
    state.accountDetails.email = val;
  },

  setAcctDetailStatus(state, val) {
    state.accountDetails.status = val;
  },

  setAcctDetailLoyaltyLevel(state, val) {
    state.accountDetails.loyaltyLevel = val;
  },

  setAcctDetailParentCompany(state, val) {
    state.accountDetails.agency = val;
  },

  setAcctDetailAgencyEmail(state, val) {
    state.accountDetails.agencyEmail = val;
  },

  setAcctDetailSalesforceId(state, val) {
    state.accountDetails.salesforceId = val;
  },

  setAcctDetailTimeZone(state, val) {
    state.accountDetails.timeZoneOffset = val;
  },

  setAcctDetailDNCEmail(state, val) {
    state.accountDetails.dncEmail = val;
  },
  SET_SUBSIDY_BY_COUNTY(state, data) {
    state.subsidyByCountyDetails = data;
  },
  setPagination: _.set('pagination'),
  setCurrentPage(state, newValue) {
    state.pagination.currentPage = newValue;
  },
  setPageSize(state, newValue) {
    state.pagination.pageSize = newValue;
  },
  setLeadState(state, val) {
    state.subsidyByCountyDetails.leadState = val;
  },
  setCounty(state, val) {
    state.subsidyByCountyDetails.county = val;
  },
  setProduct(state, val) {
    state.subsidyByCountyDetails.product = val;
  },
  setSubsidy(state, val) {
    state.subsidyByCountyDetails.subsidy = val;
  },
  clearData() {
    state.subsidyByCountyDetails = [];
  },
};

export const getters = {
  client: (state) => state.client,
  clientFirstName: (state) => state.client?.firstName,
  accountDetails: (state) => state.accountDetails,
  loading: (state) => state.loading,
  promoDetails: (state) => {
    return state.promoDetails;
  },
  salesPeople: (state) => state.salesPeople,

  subsidyByCountyDetails: (state) => {
    return state.subsidyByCountyDetails;
  },

  hasClient: (state) => {
    return !!state.client && !!state.client.id;
  },

  clientId: (state, { hasClient }) => {
    return hasClient ? state.client.id : null;
  },

  clientStatus: (state, { hasClient }) => {
    return hasClient ? state.client.status : null;
  },

  statusMap: (state, { hasClient }) => {
    return hasClient ? state.client.statusMap : null;
  },

  clientTimezone: (state, { hasClient }) => {
    return hasClient ? state.client.timeZone : null;
  },

  isCorporate: (state, { hasClient }) => {
    return hasClient ? state.client.clientType === 'CORPORATE' : false;
  },
  isAgent: (state) => {
    return (
      state.client?.clientType == null || state.client.clientType === 'AGENT'
    );
  },

  isFolio: (state, { hasClient }) => {
    return hasClient
      ? state.client.farmersFolio && state.client.parentCompany === 'FARMERS'
      : false;
  },

  isStateFarm: (state, { hasClient }) => {
    return hasClient && state.client.parentCompany === 'STATEFARM';
  },

  supportPhone: (state) => {
    switch (state.client?.salesSupportTeamName) {
      case 'ELITE VIP':
      case 'ELITE MIDWEST':
        return '855-462-5304';
      case 'ELITE WEST':
        return '855-462-2380';
      case 'ELITE EAST':
        return '855-462-5306';
      case 'ELITE SOUTH':
        return '855-462-5307';
      case 'ELITE PRIME':
        return '855-247-3362';
      case 'EAGLE':
        return '855-725-2864';
      case 'WOLF':
        return '855-725-2865';
      case 'BEAR':
        return '855-725-2851';
      case 'DOLPHIN':
        return '855-725-2849';
      case 'MEGA':
        return '855-725-2867';
      default:
        return '866-224-2194';
    }
  },

  supportEmail: (state) => {
    switch (state.client?.salesSupportTeamName) {
      case 'ELITE VIP':
      case 'ELITE WEST':
      case 'ELITE MIDWEST':
      case 'ELITE EAST':
      case 'ELITE SOUTH':
      case 'ELITE PRIME':
        return 'qwelitesupport@lendingtree.com';
      default:
        return 'qwsupport@lendingtree.com';
    }
  },

  billingType: (state) => state.client?.reactivationInfo?.billingType,

  isCcBilling: (state) => state.client?.reactivationInfo?.billingType === 'CC',

  isEligibleForReactivation: (state) =>
    state.client?.reactivationInfo?.isEligibleForReactivation || false,
};

export const actions = {
  async fetchClient({ dispatch, state }, { clientId }) {
    if (state.client && state.client.id === clientId) {
      return state.client;
    }

    const response = await axiosInstance
      .get(`/api/clients/${clientId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      const res = response.response;
      switch (res && res.status) {
        case 401:
          throw res.status;
        case 404:
          dispatch('toastr/error', 'Invalid ClientID', { root: true });
          return;
        default:
          console.error(response);
          throw response;
      }
    }

    const client = response.data;
    dispatch('setClient', client);
    return client;
  },

  async fetchClientAccountDetails({ commit }, clientId) {
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .get(`/api/clients/${clientId}/account`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      commit('SET_LOADING', false);
      console.error(response);
      throw response;
    }

    commit('SET_ACCOUNT_DETAILS', response.data);
    commit('SET_LOADING', false);
    return response.data;
  },

  async updateAccountDetails({ commit, dispatch, state }) {
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .post(`/api/Clients/${state.client.id}/agent`, state.accountDetails)
      .catch((err) => err);

    if (response instanceof Error) {
      const { response: res } = response;
      if (!res) {
        dispatch('toastr/error', 'Error: Network Error', { root: true });
      } else {
        dispatch('toastr/error', res.data, { root: true });
      }

      commit('SET_LOADING', false);
      console.error(response);
      return Promise.reject(response);
    }

    commit('SET_LOADING', false);
    return response.data;
  },

  getClient({ dispatch, rootState }, { clientId }) {
    if (clientId == null) {
      return axiosInstance
        .get(`/api/clients/auth/${rootState.auth.user.idToken.oid}`)
        .then((response) => {
          dispatch('setClient', response.data);
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(err);
        });
    } else {
      return axiosInstance
        .get(`/api/clients/${clientId}`)
        .then((response) => {
          dispatch('setClient', response.data);
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject(err);
        });
    }
  },

  setClient({ commit, rootGetters }, client) {
    if (
      authComputed.isAdmin ||
      authComputed.isClientServices ||
      authComputed.isClientServicesAdmin
    ) {
      axiosInstance.defaults.headers.common.clientId = client.id;
    }

    window.document.title = `${client.fullName} - Client Admin`;

    commit('SET_CURRENT_CLIENT', client);
  },

  clearClient({ commit }) {
    // Undoes 'setClient'
    delete axiosInstance.defaults.headers.common.clientId;
    window.document.title = `Client Admin`;
    commit('SET_CURRENT_CLIENT', {});
  },

  async createDefaultClient({ commit, dispatch }, clientInfo) {
    commit('SET_LOADING', true);

    const response = await axiosInstance
      .get('/api/clients/default', {
        params: {
          firstName: clientInfo.firstName,
          lastName: clientInfo.lastName,
          state: clientInfo.state,
          clientType: clientInfo.clientType,
        },
      })
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch(
        'toastr/error',
        'Failed to create default client. Please contact support.',
        { root: true }
      );
      return null;
    }

    commit('SET_LOADING', false);
    return response.data;
  },

  async setClientStatus({ commit, dispatch, state }, status) {
    const previousStatus = state.client.status;

    function resetStatus(errorMessage) {
      dispatch('toastr/error', errorMessage);

      commit('SET_CLIENT_STATUS', previousStatus);
      console.error(errorMessage);
      return errorMessage;
    }
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .put(`/api/status/${state.client.id}/${status}`, {})
      .catch(resetStatus);

    if (response.status !== 200) {
      resetStatus(
        `Invalid response status or data. Response status: ${response.status}, Data (rows affected): ${response.data}`
      );
    } else {
      commit('SET_CLIENT_STATUS', response.data);
    }
    commit('SET_LOADING', false);
  },

  async getLogs({ commit }, { clientId, pagination }) {
    try {
      const response = await axiosInstance.get(
        `/api/clients/${clientId}/logs`,
        {
          params: {
            currentPage: pagination.currentPage,
            pageSize: pagination.pageSize,
            sortBy: pagination.sortBy,
            startDate: pagination.startDates[0],
            endDate: pagination.startDates[1],
            last45Days: pagination.last45Days,
          },
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      if (error.response) {
        this.dispatch('toastr/error', error.response.data);
      } else {
        this.dispatch('toastr/error', error);
      }
    }
  },

  async getReactivationLogs({ commit }, clientId) {
    const response = await axiosInstance.get(
      `/api/clients/${clientId}/ReactivationLogs`
    );
    const data = response.data;

    return data;
  },

  async updateReactivationLog({ dispatch }, { clientId, reactivationLog }) {
    const response = await axiosInstance.post(
      `/api/clients/${clientId}/ReactivationLog`,
      reactivationLog
    );

    if (response instanceof Error) {
      errorTracker.trackException({
        exception: response,
      });
      console.error(response);

      this.dispatch(
        'toastr/error',
        'Failed to update reactivation attribute.',
        {
          root: true,
        }
      );
    }
  },

  async getSalesPeople({ commit }) {
    const response = await axiosInstance.get(
      '/api/clients/SALES_PEOPLE/options'
    );
    const data = response.data;

    commit('SET_SALES_PEOPLE', data);
  },

  async syncToSalesForce({ commit }, { clientId }) {
    commit('SET_LOADING', true);
    try {
      const response = await axiosInstance.post(
        `/api/clients/salesforce/sync/${clientId}`
      );
      this.dispatch('toastr/success', 'Sync Successful');
      commit('SET_LOADING', false);
      return response;
    } catch (error) {
      console.error(error);
      this.dispatch('toastr/error', error);
      commit('SET_LOADING', false);
    }
  },

  async getClientStatus({ commit, dispatch }, { clientId }) {
    axiosInstance
      .get(`/api/status/${clientId}`)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CURRENT_CLIENT_STATUS', response.data);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch('toastr/error', err);
      });
  },

  async getClientCreditEligibility({ commit, dispatch }, { clientId }) {
    axiosInstance
      .get(`/api/clients/${clientId}/credit_eligibility`)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CREDIT_ELIGIBILITY', response.data);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch('toastr/error', err);
      });
  },

  async getClientContractSnapshot({ commit }, clientId) {
    const response = await axiosInstance
      .get(`/api/clients/contract/${clientId}`)
      .catch((err) => err);

    commit('SET_CONTRACT', response.data || {});
  },

  async downloadContract({ commit, dispatch }, clientId) {
    await axiosInstance
      .get(`/api/clients/contract/download/${clientId}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', 'contract.pdf');

        document.body.appendChild(link);

        link.click();

        link.remove();
      })
      .catch((err) => err);
  },

  async getClientSystemData({ commit, dispatch }, { clientId }) {
    axiosInstance
      .get(`/api/clients/${clientId}/account_sys`)
      .then((response) => {
        if (response.status === 200) {
          commit('SET_CLIENT_SYS_DATA', response.data);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch('toastr/error', err);
      });
  },

  async getStateFarmSubsidy(
    { commit, dispatch },
    { clientId, leadState, pageNum, pageSize, sortBy, county, product, subsidy }
  ) {
    const response = axiosInstance
      .get(`/api/clients/${clientId}/subsidy`, {
        params: {
          clientId,
          leadState,
          pageNum: state.pagination.currentPage,
          pageSize: state.pagination.pageSize,
          sortBy,
          county,
          product,
          subsidy,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit('SET_SUBSIDY_BY_COUNTY', response.data.items || {});
          commit('setPagination', response.data.metaData);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch('toastr/error', err);
      });
    return response.data;
  },

  async getClientPromoDetails(
    { commit, dispatch },
    clientId = state.client.id
  ) {
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .get(`/api/clients/${clientId}/promos`)
      .catch((err) => {
        console.error(err);
        dispatch('toastr/error', err);
        return null;
      });

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      commit('SET_LOADING', false);
      dispatch('toastr/error', 'Failed to get promotion details.');
      return;
    }

    commit('SET_PROMO_DETAILS', response.data);
    commit('SET_LOADING', false);
    return response.data;
  },

  async updateClientPromoDetails({ commit, dispatch }, promoDetails) {
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .post(`/api/Promo/${promoDetails.clientId}/promo`, promoDetails)
      .catch((err) => err);

    if (response instanceof Error) {
      const { response: res } = response;
      if (!res) {
        dispatch('toastr/error', 'Error: Network Error', { root: true });
      } else {
        dispatch('toastr/error', res.data.message, { root: true });
      }

      commit('SET_LOADING', false);
      console.error(response);
      return Promise.reject(response);
    }

    commit('SET_PROMO_DETAILS', response.data);
    commit('SET_LOADING', false);

    return response.data;
  },

  async insertClientPromoDetails({ commit, dispatch }, promoDetails) {
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .post(`/api/Promo/${promoDetails.clientId}/createpromo`, promoDetails)
      .catch((err) => err);

    if (response instanceof Error) {
      const { response: res } = response;
      if (!res) {
        dispatch('toastr/error', 'Error: Network Error', { root: true });
      } else {
        dispatch('toastr/error', res.data.message, { root: true });
      }

      commit('SET_LOADING', false);
      console.error(response);
      return Promise.reject(response);
    }

    commit('SET_PROMO_DETAILS', response.data);
    commit('SET_LOADING', false);

    return response.data;
  },

  async zeroClientPromoAmounts({ commit, dispatch }, promoDetails) {
    commit('SET_LOADING', true);
    const response = await axiosInstance
      .post(`/api/Promo/${promoDetails.clientId}/zeropromo`, promoDetails)
      .catch((err) => err);

    if (response instanceof Error) {
      const { response: res } = response;
      if (!res) {
        dispatch('toastr/error', 'Error: Network Error', { root: true });
      } else {
        if (res.data.message) {
          dispatch('toastr/error', res.data.message, { root: true });
        } else {
          dispatch('toastr/error', res.data, { root: true });

          commit('SET_LOADING', false);
          return Promise.reject(res.data);
        }
      }

      commit('SET_LOADING', false);
      console.error(response);
      return Promise.reject(response);
    }

    commit('SET_PROMO_DETAILS', response.data);
    commit('SET_LOADING', false);

    return response.data;
  },

  async getClientAccountDetails({ commit, dispatch }, { clientId }) {
    const response = await axiosInstance
      .get(`/api/clients/${clientId}/account`)
      .catch((err) => {
        console.error(err);
        dispatch('toastr/error', err);
        return null;
      });
    commit('SET_ACCOUNT_DETAILS', response.data);

    return response.data;
  },

  async getClientSendHistory({ dispatch }, clientId) {
    const response = await dispatch('request/get', {
      url: `/api/clients/${clientId}/emailHistory`,
      errorMessage: 'Failed to get send grid history',
    });

    const issues = [].concat(
      response.data.blockedEmails,
      response.data.bouncedEmails,
      response.data.invalidEmails,
      response.data.spamEmails
    );

    const messages = response.data.messages.map((x) => {
      const item = {
        date: x.last_event_time,
        status: x.status,
        from_email: x.from_email,
        subject: x.subject,
        last_event_time: x.last_event_time,
        clicks_count: x.clicks_count,
        opens_count: x.opens_count,
        msg_id: x.msg_id,
      };

      return item;
    });

    return {
      messages,
      issues,
      email: response.data.emailAddress,
    };
  },

  async getClientEmailStatus({ commit, dispatch }, clientId) {
    const response = await dispatch('request/get', {
      url: `/api/clients/${clientId}/emailStatus`,
      errorMessage: 'Failed to get email status',
    });

    return response.data;
  },

  async getEmailTimeline({ dispatch }, { clientId, messageId }) {
    const response = await dispatch('request/get', {
      url: `/api/clients/${clientId}/emailTimeline/${messageId}`,
      errorMessage: 'Failed to get email timeline',
    });

    return response.data;
  },

  async getSpamReports({ dispatch }, { noCache = null }) {
    const response = await dispatch('request/get', {
      url: `/api/clients/spam-reports`,
      params: { 'no-cache': noCache },
      errorMessage: 'Failed to get Spam Reports',
    });

    return response.data;
  },

  async removeSpamReport({ dispatch }, { emails = [] }) {
    if (emails === null || emails.length === 0) {
      return;
    }

    await dispatch('request/delete', {
      url: `/api/clients/spam-reports`,
      data: { emails },
      errorMessage: 'Failed to remove Spam Reports',
    });
  },

  reset({ commit }) {
    commit('SET_CURRENT_CLIENT', { statusMap: [], timeZone: 0 });
    commit('SET_ACCOUNT_DETAILS', {});
  },
};
