import * as Msal from 'msal';
import { b2cAppId, b2cClientId, b2cRedirectUrl } from '@/app.config';

const b2cLoginUrl =
  'https://qwexternalidp.b2clogin.com/qwexternalidp.onmicrosoft.com';

const authorities = {
  staff: `${b2cLoginUrl}/B2C_1_Everest_Sign_In_v2`,
  signin: `${b2cLoginUrl}/B2C_1_Everest_Agent_SignIn`,
  signup: `${b2cLoginUrl}/B2C_1_Everest_Agent_SignUp`,
  reset: `${b2cLoginUrl}/B2C_1_ResetPasswordPolicy`,
  io: `${b2cLoginUrl}/B2C_1_Everest_IO`,
  ioSignUp: `${b2cLoginUrl}/B2C_1_EverestIO_Signup`,
};

// Set env vars in sessionStorage
window.sessionStorage.b2cAppId = b2cAppId;
window.sessionStorage.b2cClientId = b2cClientId;
window.sessionStorage.b2cRedirectUrl = b2cRedirectUrl;

const scopes = [
  `https://qwexternalidp.onmicrosoft.com/${b2cAppId}/user_impersonation`,
  `https://qwexternalidp.onmicrosoft.com/${b2cAppId}/offline_access`,
];

const msalInstance = new Msal.UserAgentApplication({
  auth: {
    clientId: b2cClientId,
    authority: authorities.staff,
    postLogoutRedirectUri: `${b2cRedirectUrl}/logged-out`,
    redirectUri: `${b2cRedirectUrl}/callback.html`,
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
    knownAuthorities: ['qwexternalidp.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE(),
  },
});

msalInstance.handleRedirectCallback(() => {});

function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); // IE 11

  return msie > 0 || trident > 0;
}

export { scopes, authorities };

export default msalInstance;
