import * as Sentry from '@sentry/vue';
import appConfig from '@/app.config';

const apiUrl = process.env.VUE_APP_API_URL;
const environment = process.env.VUE_APP_EVEREST_ENV;

// These error are taken from sentry documentation.
// It's community compiled list of rules to ignore errors caused by popular extensions
const commonBrowserErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'nenlahapcbofgnanklpelkaejcehkggg',
];

const initSentry = (app, router) => {
  Sentry.init({
    app,
    dsn: 'https://f0d32ef9c1378c0f52e16ef6195eb6f6@o365040.ingest.sentry.io/4506242862874624',
    environment,
    release: appConfig.buildNumber,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllInputs: true,
        maskAllText: false,
        networkDetailAllowUrls: [
          'localhost',
          'admin-api.test.qw-corp.com',
          'admin-api.stage.qw-corp.com',
          'admin-api.quotewizard.com',
        ],
        networkResponseHeaders: ['X-Operation-Id'],
      }),
    ],
    ignoreErrors: [
      ...commonBrowserErrors,
      /ResizeObserver loop completed with undelivered notifications.*/,
      "Cannot read properties of null (reading 'null')",
    ],
    attachStacktrace: true,
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.25, 
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initSentry;
