import { axiosInstance } from '@/plugins/axios';
import _ from '@/utils/store-helpers';
import authService from '@/services/auth.service';
import errorTracker from '@/plugins/errorTracker';
import { setUser } from '@sentry/browser';

export const state = {
  user: _.get('user'),
  roles: _.get('roles'),
  logins: _.get('logins'),
  isLoggedIn: false,
  auth_loading: false,
};

export const mutations = {
  SET_CURRENT_USER: _.set('user', true),
  SET_CURRENT_ROLES: _.set('roles', true),
  SET_ALL_LOGINS: _.set('logins', true),
  SET_IS_LOGGED_IN: _.set('isLoggedIn'),

  SET_LOADING(state, isLoading) {
    state.auth_loading = isLoading;
  },
};

export const getters = {
  userAccount: () => authService.getUser(),

  userEmail: (state) => state.user.email,

  isLoggedIn: (state) => state.isLoggedIn,

  auth_loading: (state) => state.auth_loading,

  userInRole: (state) => (roleName) =>
    Array.isArray(state.roles) &&
    state.roles.some(
      (role) => role === roleName || role.displayName === roleName
    ),

  userInRoles: (state) => (roleNames) =>
    Array.isArray(state.roles) &&
    roleNames.some((role) => state.roles.includes(role)),

  isAdmin: (_, { userInRole }) => userInRole('Everest-Admin'),

  isAgentManager: (_, { userInRole }) => userInRole('Everest-Agent-Manager'),

  isClientServices: (_, { userInRole }) =>
    userInRole('Everest-Client-Services'),

  isClientServicesAdmin: (_, { userInRole }) =>
    userInRole('Everest-Client-Services-Admin'),

  isCallCenterAdmin: (_, { userInRole }) =>
    userInRole('Everest-Call-Center-Admin'),

  isStaff: (_, { userInRole }) => userInRole('Everest-Staff'),

  isProductAdmin: (_, { userInRole }) => userInRole('Everest-Product-Admin'),

  isProductViewer: (_, { userInRole }) => userInRole('Everest-Product'),

  isCancelSave: (_, { userInRole }) => userInRole('Everest-Cancel-Save'),

  isSales: (_, { userInRole }) => userInRole('Everest-Sales'),

  isSalesManager: (_, { userInRole }) => userInRole('Everest-Sales-Manager'),

  isCancelSaveAdmin: (_, { userInRole }) =>
    userInRole('Everest-Cancel-Save-Admin'),

  isCorporateManager: (_, { userInRole }) =>
    userInRole('Everest-Corporate-Manager'),

  isAccountCreator: (_, { userInRole }) =>
    userInRole('Everest-Corporate-Bucket-Account-Creator'),
};

export const actions = {
  resetSettings({ commit, dispatch }) {
    commit('SET_CURRENT_ROLES', null);
    commit('SET_CURRENT_USER', null);
    commit('SET_IS_LOGGED_IN', false);
    dispatch('reset');
  },

  reset() {
    authService.reset();
  },

  login() {
    authService.login();
  },

  loginAndRedirect({ commit }, redirect) {
    authService.loginAndRedirect(redirect);
  },

  logout({ commit }) {
    authService.logout();
    commit('featureFlagStore/reset', null, { root: true });
  },

  async getToken() {
    return authService.getToken();
  },

  async getUser({ commit, dispatch }) {
    const adalUser = authService.getUser();

    if (!adalUser) {
      const error = new Error('Could not get user.');
      errorTracker.trackException({
        error,
      });
      dispatch('resetSettings');
      return null;
    }

    let email = '';

    if (adalUser.idToken.emails && adalUser.idToken.emails.length > 0) {
      email = adalUser.idToken.emails[0];
    }

    const response = await dispatch('request/post', {
      url: `/api/clients/auth`,
      errorMessage: 'Failed to authenticate client.',
    });

    if (!response) return null;

    const user = {
      name: adalUser.idToken.name,
      email,
      lastName: adalUser.idToken.family_name,
      firstName: adalUser.idToken.given_name,
      ...response.data,
    };

    commit('SET_CURRENT_ROLES', user.roles);
    commit('SET_CURRENT_USER', user);
    commit('SET_IS_LOGGED_IN', true);
    setUser({ email: user.email });

    return user;
  },

  async getAllLogins({ commit }, clientId) {
    commit('SET_LOADING', true);

    await axiosInstance
      .get(`/api/login/${clientId}/logins`)
      .then((response) => {
        commit('SET_ALL_LOGINS', response.data);

        commit('SET_LOADING', false);

        return response.data;
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error: Network Error');
        console.error(err);

        commit('SET_LOADING', false);

        return Promise.reject(err);
      });
  },

  async setProperties({ dispatch }, { data, b2cLoginId }) {
    return await axiosInstance
      .put(`/api/login/${b2cLoginId}/properties`, data)
      .then((response) => {
        this.dispatch('toastr/success', 'Success: B2C account updated');
      })
      .catch((err) => {
        const { response } = err;
        if (response && response.status === 400) {
          this.dispatch('toastr/error', response.data);
          this.loading = false;
          return;
        }
        this.dispatch('toastr/error', 'Error: Unable to update B2C account');
        console.error(err);
        return Promise.reject(err);
      });
  },
};
