import { store } from '@/store';
import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress/nprogress'
import routes from './routes'

const router = createRouter({
  routes,
  history: createWebHistory(process.env.VUE_APP_ROOT_URL),
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        left: 0,
        top: 0,
      }
    }
  },
})

const routeRequiresAuth = (route) => {
  // Not all routes have authRequired defined
  // So undefined/null will be treated as true
  return route.meta.authRequired !== false
}

router.beforeEach(async (routeTo, routeFrom, next) => {
  NProgress.start()

  if (!routeRequiresAuth(routeTo)) {
    return next()
  }

  if (!store.getters['auth/isLoggedIn']) {
    await store.dispatch('auth/getUser')
  }

  if (!store.getters['auth/userAccount']) {
    store.dispatch('auth/login')
    return
  }

  const requiredRoles = ['Everest'].concat(routeTo.meta.requiredRoles || [])
  const inRoles = store.getters['auth/userInRoles'](requiredRoles)
  if (!inRoles) {
    next({ name: '403' })
    return
  }

  next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).

  // Check if route requires auth and flags not already loaded in
  if (
    routeRequiresAuth(routeTo) &&
    !store.getters['featureFlagStore/isInit']
  ) {
    await store.dispatch('featureFlagStore/populateFlags')
  }

  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
// eslint-disable-next-line
router.afterEach(async (routeTo) => {
  // Ensure any open Nav Menus have been reset
  await store.dispatch('responsive/closeAllMenus')

  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
