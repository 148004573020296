import axios from 'axios';
import errorTracker from '@/plugins/errorTracker';
import _ from '@/utils/store-helpers';
import { everestApiUrl, environment } from '@/app.config';

const defaultOptions = {
  baseURL: everestApiUrl,
  crossDomain: true,
  headers: {},
};

export const xhrClient = axios.create(defaultOptions);

export const state = {
  errors: _.get('errors', []),
};

export const getters = {
  errorCount: (state) => state.errors.length,
};

/**
 * All actions accept a config object that is an extension of the axios config.
 * The only required property is [url].
 * https://github.com/axios/axios#request-config
 *
 * In addition to the default axios config properties, we use the following extension properties:
 *
 * [errorMessage] - Displayed in a toast message on error. DEFAULT: Request failed. Please try again.
 * [successMessage] - Displayed in a toast message on success. DEFAULT: No action.
 * [loadingMutation] - The mutation path for setting loading. DEFAULT: No action.
 * [onError] - A callback that receives the error object from axios as a parameter. DEFAULT: No action.
 * [authenticate] - A boolean flag for if an access bearer token is required. DEFAULT: true
 *
 * A successful request will return the axios response object.
 * A failed request will return undefined.
 * Additional error logic can be passed via the 'onError' property.
 *
 * Example usage:
 *
 * const response = await dispatch('request/post', {
 *  url: '/api/vacations',
 *  data: {...},
 *  errorMessage: 'Failed to create vacation pause.',
 *  loadingMutation: 'vacations/setLoading',
 *  onError: (err) => {...},
 * });
 *
 * if (!response) {
 *    //...There was an error. Access error object in onError callback.
 * }
 */
export const actions = {
  async get({ dispatch }, config) {
    validateConfig(config);
    return dispatch('_request', { ...config, method: 'GET' });
  },

  async post({ dispatch }, config) {
    validateConfig(config);
    return dispatch('_request', { ...config, method: 'POST' });
  },

  async put({ dispatch }, config) {
    validateConfig(config);
    return dispatch('_request', { ...config, method: 'PUT' });
  },

  async delete({ dispatch }, config) {
    validateConfig(config);
    return dispatch('_request', { ...config, method: 'DELETE' });
  },

  _startLoading({ commit }, mutationPath) {
    if (!mutationPath) return;
    commit(mutationPath, true, { root: true });
  },

  _stopLoading({ commit }, mutationPath) {
    if (!mutationPath) return;
    commit(mutationPath, false, { root: true });
  },

  async _request(
    { dispatch, rootGetters },
    {
      loadingMutation = null,
      errorMessage = 'Request failed. Please try again.',
      successMessage = null,
      onError = () => {},
      authenticate = true,
      headers = {},
      ...restConfig
    }
  ) {
    const token = await dispatch('auth/getToken', null, { root: true });

    if (!token && authenticate) {
      // We don't have a token and it's required.
      dispatch('auth/reset', null, { root: true });
      dispatch('auth/login', null, { root: true });
      return;
    }

    restConfig.headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };

    if (rootGetters['client/clientId'] != null) {
      restConfig.headers.clientId = rootGetters['client/clientId'];
    }

    dispatch('_startLoading', loadingMutation);

    const res = await xhrClient.request(restConfig).catch((err) => err);

    dispatch('_stopLoading', loadingMutation);

    if (res instanceof Error) {
      errorTracker.trackException({
        exception: res,
        properties: restConfig,
      });

      dispatch('_handleError', {
        error: res,
        message: errorMessage,
      });

      onError(res);

      return;
    }

    if (successMessage) {
      dispatch('toastr/success', successMessage, { root: true });
    }

    return res;
  },

  _handleError(
    { commit, dispatch, getters, rootState, state },
    { error, message }
  ) {
    console.error('Request module', error, message);
    commit('setErrors', [...state.errors, error]);

    if (getters.errorCount > 10 && environment === 'production') {
      // A user experienced more than 10 errors in production. Let's log that specifically...
      errorTracker.trackException({
        exception: new Error('Large request error log.'),
        properties: {
          user: rootState.auth.user,
          errors: state.errors,
        },
      });

      // Clear things out before the log gets too big.
      commit('setErrors', []);
    }

    const { response } = error;
    const authHead = response && response.headers && response.headers['www-authenticate'];
    if (authHead === 'Bearer error="invalid_token"') {
      // Oh no! The access token expired! Let's hit b2c...
      dispatch('auth/login', null, { root: true });
      return;
    }

    if (!error.response) {
      // If we don't have a response property then there was a network connection issue.
      dispatch('toastr/error', 'Network error. Please check your connection.', {
        root: true,
      });
    } else if (message) {
      // It wasn't a network issue, so display the provided error message.
      dispatch('toastr/error', message, { root: true });
    }
  },
};

export const mutations = {
  setErrors: _.set('errors', true),
};

function validateConfig(config) {
  if (typeof config !== 'object' || config === null || !config.url) {
    throw new Error('Invalid config format.');
  }
}
