export default {
  data() {
    return {
      clientCancelReasons: {
        LEAVING_COMPANY: 'Leaving The Company',
        CONVERSION_RATIO: 'Conversion Ratio',
        VOLUME_GENERAL: 'Lead Volume',
        QUALITY_GENERAL: 'Lead Quality',
        PRICE_INCREASE: 'Price Increase',
        EXHAUSTED_PROMOTIONAL_MONEY: 'Exhausted Promotional Money',
        CANCELING_ALL_SERVICES: 'Canceling All Internet Lead Services',
        RATES_NOT_COMPETITIVE: 'Companies Rates are not Competitive',
        COMPANY_PROVIDING_LEADS: 'Company is Providing Free Leads',
        COMPANY_SPONSORED_LEAD_PLATFORM: 'Company Sponsored Lead Platform',
        CONTACT_RATIO_VS_OTHERS: 'Contact Ratio vs Other Services',
        CASH_CAPPED: 'Cash Capped',
        RECHARGE_FAILURE: 'Recharge Failure',
        CHARGEBACK: 'Chargeback',
        LEADSAGED: 'Leads Aged',
        DELIVERY_PROBLEMS: 'Lead Delivery Problems',
        SHORT_STAFFED: 'Short Staffed',
        FINANCIAL: 'Financial',
        INACTIVITY: 'Inactivity',
        CALLS_QUALITY: 'WizardCalls Quality',
        CALLS_VOLUME: 'WizardCalls Volume',
        NOT_MEETING_FILTERS: 'Not Meeting Filters',
        SALES_REP: 'Sales Rep',
        OTHER: 'Other',
        OTHER_BLOCK: 'Other - Block',
      },
    }
  },
}
