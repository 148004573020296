import _ from '@/utils/store-helpers';

export const state = {
  campaigns: [],
  campaignSummaries: [],
  loading: false,
  dashboardLoading: false,
  detailLoading: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
  },
  dashboardPagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
  },
};

export const getters = {
  loading: (state) => state.loading,
  detailLoading: (state) => (id) => state.detailLoading.includes(id),
  campaigns: (state) => state.campaigns,
  campaignSummaries: (state) => state.campaignSummaries,
};

export const actions = {
  async getCampaignsList({ commit, dispatch }, clientId) {
    const apiResponse = await dispatch('request/get', {
      url: `/api/campaigns/${clientId}/list`,
      errorMessage: 'Failed to get campaigns.',
      loadingMutation: 'campaigns/SET_DETAIL_LOADING',
    });

    if (!apiResponse?.data) {
      this.dispatch(
        'toastr/error',
        'Oops there was an error retrieving campaigns'
      );

      return;
    }

    const { data: campaigns } = apiResponse;

    commit('SET_CAMPAIGNS', campaigns);
    const pageCount = Math.ceil(campaigns.length / state.pagination.pageSize);
    commit('SET_TOTAL_PAGES', pageCount);

    return campaigns;
  },
  async getCampaignDetail({ commit, dispatch }, { id, clientId }) {
    commit('SET_DETAIL_LOADING', {
      id,
      newValue: true,
    });

    try {
      const apiResponse = await dispatch('request/get', {
        url: `/api/campaigns/${clientId}/${id}/summary`,
        errorMessage: 'Failed to get campaign summary.',
      });

      if (!apiResponse) throw new Error('No API Response');

      const { data: campaign } = apiResponse;
      commit('ADD_CAMPAIGN_SUMMARY', campaign);
    } catch {
      this.dispatch(
        'toastr/error',
        `Oops there was an error gathering details for campaign ${id}`
      );
    } finally {
      commit('SET_DETAIL_LOADING', {
        id,
        newValue: false,
      });
    }
  },
  setDashboardCurrentPage({ commit }, pageNumber) {
    commit('SET_DASHBOARD_CURRENT_PAGE', pageNumber);
  },
};

export const mutations = {
  SET_LOADING: _.set('loading'),
  SET_DETAIL_LOADING(state, { id, newValue }) {
    state.detailLoading = [
      ...state.detailLoading.filter((x) => x !== id),
      ...(newValue ? [id] : []),
    ];
  },
  SET_CAMPAIGNS(state, newValue) {
    state.campaigns = newValue;
  },
  SET_DASHBOARD_CURRENT_PAGE(state, currentPage) {
    state.dashboardPagination.currentPage = currentPage;
  },
  SET_TOTAL_PAGES(state, totalPages) {
    state.pagination.totalPages = totalPages;
  },
  ADD_CAMPAIGN_SUMMARY(state, newSummary) {
    state.campaignSummaries = [
      ...state.campaignSummaries.filter((x) => x.id !== newSummary.id),
      newSummary,
    ];
  },
};
