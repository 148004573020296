export default {
  data() {
    return {
      serviceTypes: [
        {
          value: 'Web',
          label: 'Web',
        },
        {
          value: 'Call',
          label: 'Call',
        },
      ],
    }
  },
}
