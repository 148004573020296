import axiosInstance, { axiosAnonymous } from '@/plugins/axios';
import authService from '@/services/auth.service';
import _ from '@/utils/store-helpers';
import { processTerritory } from '@/components/channels/Territories/TerritoriesUtils';
import moment from 'moment';
import DailySchedule from '@/components/scheduler/DailySchedule';
import IntervalMap from '@/components/scheduler/IntervalMap';

const CALL_TYPE = 2;
const WEB_TYPE = 1;
const defaultDeposits = {
  Web: 0,
  Call: 0,
};
// This is what the server sends when you make a new IO
const defaultRechargeAmount = 100;

function mapIoToMatchPromosData(promos, deposits) {
  return Object.keys(defaultDeposits).reduce((data, productType) => {
    const maximum = deposits[productType] ?? 0;
    const promo = promos?.find((x) => x.productType === productType);
    const promoAmount = promo?.amount ?? 0;
    const percent =
      promoAmount === 0
        ? null
        : promoAmount > maximum
          ? 100
          : (promoAmount / maximum) * 100;
    data[productType] = {
      value: promoAmount,
      maximum,
      percent,
    };
    return data;
  }, {});
}

export const state = {
  signupInfo: {
    client: {
      gravityProfileId: '',
      businessContactFirstName: '',
      businessContactLastName: '',
      fullName: '',
      businessContactAddress1: '',
      businessContactAddress2: '',
      businessContactCity: '',
      businessContactState: '',
      businessContactZip: '',
      businessContactPhone: '',
      businessContactFax: '',
      businessContactEmail: '',
      clientAgreements: '',
      status: '',
      level: '',
      parentCompany: '',
      salesId: '',
      agencyEmail: '',
      timeZone: '',
      salesforceId: '',
      shortName: '',
      licenseNumber: '',
      salesSupportTeamId: '',
      cashcapNoReset: 'false',
    },
    clientQuoteTypes: [],
    clientSchedule: {},
    defaultCardId: 0,
    webRechargeEnabled: true,
    webRechargeAmount: 0,
    callRechargeAmount: 0,
    logins: [],
    chargeCard: true,
    immediateActivation: true,
    activationDate: '',
    discounts: [],
    notes: '',
    callSchedule: null,
    subscriptionGroups: [],
    isElite: false,
    deposits: { ...defaultDeposits },
    matchPromos: {},
  },
  validationResult: {
    isAccountValid: true,
    results: [],
  },
  packageDetails: [],
  loading: false,
  contractExpired: false,
  existingLogin: null,
  pendingSignups: [],
  territories: [],
  parentSpecialProgram: {
    parentCompany: '',
    sfSubsidy: false,
    farmersBMP: false,
    farmersFolio: false,
    sfAutoSubsidy: 0,
    sfHomeSubsidy: 0,
    sfRenterSubsidy: 0,
    stateFarmCombinedUpload: false,
  },
  promotions: [],
  clientIp: 'N/A',
  pagination: {
    currentPage: 1,
    pageSize: 100,
    totalRecords: 0,
  },
  sortBy: '-UPDATED_DTG',
  errors: [
    // Error expected signature:
    // {
    //   step: Number,
    //   header: String,
    //   errors: Array<String>,
    // }
  ],
  isLutVisible: false,
  stepData: [
    {
      step: 0,
      header: 'Client Data',
    },
    {
      step: 1,
      header: 'Create Lead Channels',
    },
    {
      step: 2,
      header: 'Enter Billing Information',
    },
    {
      step: 3,
      header: 'Add Promotion',
    },
    {
      step: 4,
      header: 'Schedule',
    },
    {
      step: 5,
      header: 'Send Contract',
    },
  ],
  depositErrors: {},
  matchPromoErrors: [],
};

export const getters = {
  // errors and validation
  currentErrors: (_, { errorList }) => errorList.filter((e) => e.errors.length),
  validationErrors: (state) => {
    return state.validationResult?.isAccountValid ?? true
      ? []
      : state.validationResult.results
          .filter(
            (x) => !x.isValid && !(x.area === 'BILLING' && x.code === 'NO_CC')
          )
          .map((x) => x.message);
  },
  isAccountValid(_, { validationErrors }) {
    return !validationErrors.length;
  },
  errorList: (
    state,
    {
      validationErrors,
      areChannelsIncomplete,
      incompleteChannels,
      hasCallChannel,
      hasWebChannel,
      areAnySchedulesWithStartIsAfterEnd,
      isStandardBilling,
    }
  ) => {
    let channelErrorMsgs = [];
    if (!hasCallChannel && !hasWebChannel) {
      channelErrorMsgs.push('No channels have been defined');
    } else if (areChannelsIncomplete) {
      channelErrorMsgs = incompleteChannels.map(
        (cqt) => `Channel ${cqt.customName} has not been completed`
      );
    }

    const mapStepErrors = (step, errors) => ({
      ...state.stepData[step],
      errors,
    });

    const channelErrors = channelErrorMsgs.some((x) => x)
      ? [mapStepErrors(1, channelErrorMsgs)]
      : [];

    const depositErrors = [];
    if (isStandardBilling) {
      if (hasWebChannel && state.depositErrors.Web) {
        depositErrors.push(state.depositErrors.Web);
      }
      if (hasCallChannel && state.depositErrors.Call) {
        depositErrors.push(state.depositErrors.Call);
      }
    }

    const hasBillingErrors =
      validationErrors.some((x) => x) || depositErrors.some((x) => x);
    const billingErrors = hasBillingErrors
      ? [mapStepErrors(2, [...validationErrors, ...depositErrors])]
      : [];

    const promoErrors = state.matchPromoErrors.some((x) => x)
      ? [
          mapStepErrors(
            3,
            state.matchPromoErrors.map((x) => x.error)
          ),
        ]
      : [];

    const scheduleErrors = areAnySchedulesWithStartIsAfterEnd
      ? [
          mapStepErrors(4, [
            'One or more schedules have a start time after the end time',
          ]),
        ]
      : [];

    return [
      ...state.errors,
      ...channelErrors,
      ...billingErrors,
      ...promoErrors,
      ...scheduleErrors,
    ];
  },
  hasErrors: (_, { currentErrors }) => currentErrors.some((x) => x),

  // Channels
  hasCallChannel: (state) =>
    state.signupInfo.clientQuoteTypes.some(
      (c) => c.productTypeId === CALL_TYPE
    ),
  hasWebChannel: (state) =>
    state.signupInfo.clientQuoteTypes.some((c) => c.productTypeId === WEB_TYPE),
  clientQuoteTypes(state) {
    return state.signupInfo.clientQuoteTypes;
  },
  subscriptionGroups(state) {
    return state.signupInfo.subscriptionGroups;
  },
  cqtypesChannelOverview(state, { clientQuoteTypes }) {
    return clientQuoteTypes.map((cqt) => ({
      id: cqt.id,
      name: cqt.customName,
      type: cqt.quoteType,
      source: cqt.serviceType,
      date: cqt.createDate,
      price: cqt.payout,
      filters: cqt.filterList ? cqt.filterList.split(',') : [],
      package: state.packageDetails.find((obj) => {
        return obj.id === cqt.packageId;
      }),
      packageName: cqt.packageName,
      maxSold: cqt.maxSold,
      caps:
        cqt.serviceType === 'Web'
          ? {
              ...cqt.dayCapSchedule,
            }
          : { ...cqt.callSchedule },
      zipCodes: cqt.geoMaps.filter((x) => x.type === 'ZipCode').length,
      counties: cqt.geoMaps.filter((x) => x.type === 'County').length,
      states: cqt.geoMaps.filter((x) => x.type === 'State').length,
      geoMaps: cqt.geoMaps,
      status: cqt.status,
      schedule: cqt.schedule,
      priceModifiers: cqt.priceModifiers,
      ioPriceModifiers: cqt.ioPriceModifiers,
      numLeadsToday: 0,
      delivery: cqt.emailDelivery
        .concat(cqt.crmDelivery)
        .concat(cqt.phoneNumbers),
    }));
  },
  incompleteChannels: (state) =>
    state.signupInfo.clientQuoteTypes.filter((x) =>
      x.customName.startsWith('INCOMPLETE')
    ),
  areChannelsIncomplete: (_, { incompleteChannels }) =>
    incompleteChannels.some((x) => x),

  // Account Info
  accountState(_, { client }) {
    return client.businessContactState;
  },
  client(state) {
    return state.signupInfo.client;
  },
  isElite(state) {
    return state.signupInfo.isElite;
  },
  isMinimumDataAvailable(_, { client }) {
    return (
      !!client.parentCompany &&
      !!client.fullName &&
      !!client.businessContactEmail
    );
  },
  parentCompany(_, { client }) {
    return client.parentCompany;
  },
  accountDetails(state, { client }) {
    return {
      firstName: client.businessContactFirstName,
      lastName: client.businessContactLastName,
      fullName: client.fullName,
      address1: client.businessContactAddress1,
      address2: client.businessContactAddress2,
      city: client.businessContactCity,
      state: client.businessContactState,
      zip: client.businessContactZip,
      phone: client.businessContactPhone,
      fax: client.businessContactFax,
      email: client.businessContactEmail,
      status: client.status,
      loyaltyLevel: client.level,
      agency: client.parentCompany,
      salesPersonId: client.salesId,
      agencyEmail: client.agencyEmail,
      timeZone: client.timeZone,
      salesforceId: client.salesforceId,
      shortName: client.shortName,
      license: client.licenseNumber,
      salesSupportTeamId: client.salesSupportTeamId,
      salesPersonEmail: state.signupInfo.salesPersonEmail,
    };
  },
  businessContactZip: (state) => state.signupInfo?.client?.businessContactZip,

  // Billing Info
  chargeCard(state) {
    return state.signupInfo.chargeCard;
  },
  isWebRechargeEnabled: (state) => state.signupInfo.webRechargeEnabled,
  isStandardBilling(_, { client }) {
    return (
      client.specialProgram !== 'COUNTRY_CO_OP' &&
      client.specialProgram !== 'FARMERS_FOLIO'
    );
  },
  getProductDeposit: (state) => (product) =>
    state.signupInfo.deposits[product] ?? 0,

  // promos
  promoId: (state) => state.signupInfo.promoId,
  promotionName(state) {
    return (promoId) => {
      const promotion = state.promotions.find((p) => p.promoId === promoId);
      return promotion ? promotion.promoName : '[None Selected]';
    };
  },
  discounts: (state) => state.signupInfo.discounts,

  // Schedule
  areAnySchedulesWithStartIsAfterEnd: (state) => {
    return (
      Object.values(state.signupInfo.clientSchedule)
        .flatMap((day) => day.dayPartSchedules)
        .filter(
          (schedule) => schedule.dayPartCap.start > schedule.dayPartCap.end
        ).length > 0
    );
  },
};

export const actions = {
  // Channels
  showLut({ commit }) {
    commit('SET_SHOW_LUT', true);
  },
  hideLut({ commit }) {
    commit('SET_SHOW_LUT', false);
  },
  async getZipCodes({ commit, dispatch }, cqtId) {
    commit('setLoading', true);

    const popResponse = await axiosInstance
      .get('/api/salesLeadMap/push-pop')
      .catch((err) => err);

    if (popResponse instanceof Error) {
      commit('setLoading', false);

      if (popResponse.response && popResponse.response.status === 404) {
        dispatch('toastr/info', 'No Zip Codes available.', { root: true });
        return;
      }

      console.error(popResponse);
      return;
    }

    const zipCodesData = {
      data: popResponse.data.zipCodes.join(','),
    };
    const transformResponse = await axiosInstance
      .post(`/api/SalesLeadMap/territory-validate/`, zipCodesData)
      .catch((err) => err);

    if (transformResponse instanceof Error) {
      let message;
      if (!transformResponse.response) {
        message = 'Error: Network Error';
      } else {
        message = transformResponse.response.data.message;
      }

      dispatch('toastr/error', message);
      console.error(transformResponse);
    }

    const territories = [];

    for (const terr of transformResponse.data) {
      const { newTerritory } = processTerritory(terr, []);
      territories.push(newTerritory);
    }

    commit('SET_GEO_MAPS', { cqtId, territories });
    commit('leadType/SET_TERRITORIES', territories, { root: true });
    commit('setLoading', false);
    dispatch('toastr/success', 'Retrieved Zip Codes', { root: true });
  },
  async postZipCodes({ commit, state }) {
    commit('setLoading', true);

    const zips = state.territories.filter((g) => g.zipCode);

    const response = await axiosInstance
      .post('/api/salesLeadMap/push-pop', { zipCodes: zips })
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      commit('setLoading', false);
      return;
    }

    commit('setLoading', false);
  },
  async cloneChannel({ commit, _, getters }, cloneModel) {
    commit('setLoading', true);
    const { clientQuoteTypes } = getters;
    const { serviceType, territories, dayCaps, leadDelivery } = cloneModel;
    let sourceChannel = null;
    const response = await axiosInstance.get(
      `/api/LeadType/new/${serviceType}`
    );

    const destinationChannel = response.data;

    if (territories.clone) {
      if (
        sourceChannel === null ||
        sourceChannel.id === territories.clientQuoteTypeId
      ) {
        sourceChannel = clientQuoteTypes.find((c) => {
          return c.id === territories.clientQuoteTypeId;
        });
      }
      destinationChannel.geoMaps = sourceChannel.geoMaps;
    }

    if (dayCaps.clone) {
      if (
        sourceChannel === null ||
        sourceChannel.id === dayCaps.clientQuoteTypeId
      ) {
        sourceChannel = clientQuoteTypes.find((c) => {
          return c.id === dayCaps.clientQuoteTypeId;
        });
      }
      destinationChannel.dayCapSchedule = sourceChannel.dayCapSchedule;
    }

    if (leadDelivery.clone) {
      if (
        sourceChannel === null ||
        sourceChannel.id === leadDelivery.clientQuoteTypeId
      ) {
        sourceChannel = clientQuoteTypes.find((c) => {
          return c.id === leadDelivery.clientQuoteTypeId;
        });
      }

      if (sourceChannel.serviceType === serviceType) {
        if (sourceChannel.serviceType === 'Web') {
          destinationChannel.emailDelivery = sourceChannel.emailDelivery;
          destinationChannel.crmDelivery = sourceChannel.crmDelivery;
        } else {
          destinationChannel.phoneNumbers = sourceChannel.phoneNumbers;
        }
      }
    }

    destinationChannel.serviceType = serviceType;
    destinationChannel.productTypeId = serviceType === 'Web' ? 1 : 2;

    commit('UPDATE_CLIENT_QUOTE_TYPE', { cqt: destinationChannel });
    commit('setLoading', false);
  },
  async updateClientQuoteType({ commit, dispatch, rootState, rootGetters }) {
    const cqt = rootState.leadType.leadType;
    let filterList;
    let packageName;
    const hasCustomPackage = rootGetters['leadType/hasCustomPackage'];

    const webLeadSchedule = await dispatch(
      'scheduler/saveWebLeadSchedules',
      cqt.id,
      { root: true }
    );

    if (rootState.leadType.leadType.serviceType === 'Web') {
      if (hasCustomPackage) {
        filterList = rootState.leadType.leadType.filterList || [];
        packageName = 'Custom';
      } else {
        const response = await axiosInstance
          .get(`/api/leadType/package/${rootState.leadType.leadType.packageId}`)
          .catch((err) => err);
        filterList = response.data.filters.map((filter) => filter.name);
        packageName = response.data.name;
      }
    } else if (rootState.leadType.leadType.serviceType === 'Call') {
      const callPackages = await axiosInstance
        .get(
          `/api/leadType/calls/${rootState.signup.signupInfo.client.parentCompany}`
        )
        .catch((err) => err);
      if (callPackages instanceof Error || callPackages.status !== 200) {
        dispatch('toastr/error', 'Failed to get packages.', {
          root: true,
        });
      }
      const response = callPackages.data.find(
        (x) => x.id === rootState.leadType.leadType.packageId
      );
      filterList = response.filters.map((filter) => filter.name);
      packageName = response.name;
    }
    const callSchedule = rootGetters['scheduler/updateRequestBody']();
    const phoneNumbers = cqt.phoneNumbers;
    callSchedule.ClientQuoteTypeID = cqt.id;
    filterList = filterList.toString();
    commit('UPDATE_CLIENT_QUOTE_TYPE', {
      cqt,
      callSchedule,
      filterList,
      packageName,
      phoneNumbers,
      maxSold: cqt.maxSold,
      webLeadSchedule,
    });
    if (cqt.isNew) {
      commit('ADD_CHANNEL', cqt);
    }
    commit('leadType/RESET_STATE', null, { root: true });
    dispatch('saveSignupBlob');
    return cqt;
  },
  deleteClientQuoteType({ commit, dispatch, state }, id) {
    const serviceType = state.signupInfo.clientQuoteTypes.find(
      (x) => x.id === id
    ).serviceType;
    const countOfChannelType = state.signupInfo.clientQuoteTypes.filter(
      (x) => x.serviceType === serviceType
    ).length;

    commit('DELETE_CLIENT_QUOTE_TYPE', id);
    if (countOfChannelType === 1) {
      commit('UPDATE_DEPOSIT', { productType: serviceType, value: 0 });

      if (serviceType.toLocaleLowerCase() === 'web') {
        commit('UPDATE_WEB_RECHARGE_AMOUNT', defaultRechargeAmount);
      } else {
        commit('UPDATE_CALL_RECHARGE_AMOUNT', defaultRechargeAmount);
      }
    }

    dispatch('saveSignupBlob');
  },

  // Signup / Account Info
  async getParentSpecialProgram({ commit, state, rootGetters }) {
    const {
      parentCompany,
      agencyEmail,
      businessContactCity,
      businessContactZip,
    } = state.signupInfo.client;
    if (!parentCompany || !agencyEmail) {
      return;
    }

    let uri = `/api/sales/parentProgram/${agencyEmail}/${parentCompany}`;

    const isSubsidyByStateEnabled = rootGetters['featureFlagStore/getFlag'](
      'StateFarmCombinedUpload'
    );
    if (parentCompany === 'STATEFARM' && isSubsidyByStateEnabled) {
      uri = `/api/sales/parentProgram/${agencyEmail}/${parentCompany}/${businessContactZip}/${businessContactCity}`;
    }

    const response = await axiosInstance.get(uri).catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      return;
    }

    const parentSpecialProgram = response.data;
    parentSpecialProgram.stateFarmCombinedUpload = isSubsidyByStateEnabled;

    commit('SET_PARENT_SPECIAL_PROGRAM', parentSpecialProgram);
  },
  async createSignupInfo({ commit, dispatch }, salesForceData) {
    commit('setLoading', true);

    const response = await axiosInstance
      .post(`/api/sales`, salesForceData)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      commit('setLoading', false);
      return;
    }

    commit('setSignupInfo', {
      deposits: {},
      ...response.data,
    });

    const cleanedMatchPromoSettings = mapIoToMatchPromosData(
      response.data.matchPromos,
      response.data.deposits
    );
    dispatch('promos/setMatchPromosFromSource', cleanedMatchPromoSettings, {
      root: true,
    });

    commit('setLoading', false);
  },
  async getSignupInfo({ commit, dispatch, state }, id = '') {
    commit('setLoading', true);

    const response = await axiosInstance
      .get(`/api/sales/${id}`)
      .catch((err) => err);

    if (response instanceof Error) {
      if (response.response && response.response.status === 404) {
        dispatch('deleteSignup', id);
        dispatch(
          'toastr/error',
          'This signup has expired and no longer exists.',
          { root: true }
        );
      }

      console.error(response);
      commit('setLoading', false);
      return Promise.reject(response);
    }

    let { activationDate, discounts } = response.data;

    if (activationDate === '1/1/0001 12:00:00 AM') {
      activationDate = moment().add('days', 1).format('MM-DD-YYYY');
    }

    const newSignupInfo = {
      deposits: { ...defaultDeposits },
      ...response.data,
      activationDate,
      discounts,
    };

    newSignupInfo.deposits ??= { ...defaultDeposits };

    commit('setSignupInfo', newSignupInfo);

    const groups = state.signupInfo.subscriptionGroups;
    dispatch('channelGroups/setGroups', groups, { root: true });

    const cleanedMatchPromoSettings = mapIoToMatchPromosData(
      newSignupInfo.matchPromos,
      newSignupInfo.deposits
    );
    dispatch('promos/setMatchPromosFromSource', cleanedMatchPromoSettings, {
      root: true,
    });

    // if(response.data.clientSchedule){
    //   commit('scheduler/setSchedules', response.data.clientSchedule, { root: true });
    // }

    commit('setLoading', false);

    return response.data.id;
  },
  async getCurrentPromotions({ commit, state }) {
    if (!state.signupInfo.client.parentCompany) return;
    const response = await axiosInstance
      .get(`/api/sales/promotions/${state.signupInfo.client.parentCompany}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      return;
    }

    commit('SET_PROMOTIONS', response.data);
  },
  getProductTypeIdByName(_, productName) {
    return productName.toLocaleLowerCase() === 'web' ? 1 : 2;
  },
  async saveSignupBlob({ commit, state, dispatch }) {
    commit('setLoading', true);
    commit('RESET_VALIDATION_RESULT');

    const cleanedDeposit = {};
    for (const key in state.signupInfo.deposits) {
      if (state.signupInfo.deposits[key] > 0) {
        const newKey = await dispatch('getProductTypeIdByName', key);
        cleanedDeposit[newKey] = state.signupInfo.deposits[key];
      }
    }

    const cleanedInfo = {
      ...state.signupInfo,
      deposits: cleanedDeposit,
    };

    const response = await axiosInstance
      .put(`/api/sales/update`, cleanedInfo)
      .catch((err) => err);

    if (response instanceof Error) {
      if (response?.response?.status === 409) {
        dispatch('toastr/error', 'IO Already Signed', { root: true });
      } else if (response?.response?.status === 400) {
        dispatch(
          'toastr/error',
          `Unable to save IO: ${response.response.data}`,
          { root: true }
        );
      }
      console.error(response);

      commit('setLoading', false);
      throw response;
    }
    commit('SET_VALIDATION_RESULT', response.data);
    commit('setLoading', false);
    return response.data;
  },
  updateRechargeAmountForProduct({ commit }, { product, value }) {
    if (product?.toLocaleLowerCase() === 'web' || product === 1) {
      commit('UPDATE_WEB_RECHARGE_AMOUNT', value);
    } else {
      commit('UPDATE_CALL_RECHARGE_AMOUNT', value);
    }
  },
  updateDepositForProduct({ commit, state }, depositInfo) {
    if (
      state.signupInfo.deposits[depositInfo.productType] !== depositInfo.value
    ) {
      commit('UPDATE_DEPOSIT', depositInfo);
      commit(
        'promos/SET_MATCH_PROMO_MAXIMUM',
        {
          product: depositInfo.productType,
          maximum: depositInfo.value,
        },
        {
          root: true,
        }
      );
    }
  },

  // Signup List
  async getPendingSignups({ commit }, { search }) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get('/api/sales/pending', {
        params: {
          search,
          page: state.pagination.currentPage,
          size: state.pagination.pageSize,
          sortBy: state.sortBy,
        },
      })
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      commit('setLoading', false);
      return;
    }

    commit('setPendingSignups', response.data.items);
    commit('setPagination', response.data.metaData);
    commit('setLoading', false);
  },
  async deleteSignup({ commit }, ioId) {
    commit('DELETE_SIGNUP', ioId);

    const response = await axiosInstance
      .delete(`api/sales/${ioId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
    }
  },

  // Contracts
  async getContractInfo({ commit, dispatch }, id) {
    commit('RESET_VALIDATION_RESULT');
    commit('setLoading', true);

    const response = await axiosAnonymous
      .get(`/api/sales/sign-contract/${id}`)
      .catch((err) => err);

    if (response instanceof Error) {
      let message = 'Failed to load contract info. Please contact support.';

      if (response.response && response.response.status === 400) {
        message = 'Contract signing expired. Please contact support.';
        commit('setContractExpired', true);
      } else {
        // TODO: Fatal error
        console.error(response);
      }

      dispatch('toastr/error', message, { root: true });
      commit('setLoading', false);
      return;
    }

    const packageList = response.data.packageDetails;

    commit('setExistingLogin', response.data.login);
    commit('setSignupInfo', {
      deposits: { ...defaultDeposits },
      ...response.data.clientIO,
    });
    commit('setPackageDetails', packageList);
    commit('setClientIp', response.data.clientIp);
    commit('SET_VALIDATION_RESULT', response.data.validationResult);
    commit('setLoading', false);
  },
  async bindRole() {
    const adalUser = authService.getUser();
    if (adalUser) {
      await axiosInstance.get('/api/login/bind-role').catch((err) => err);
    }
  },
  async sendContract({ dispatch, state }) {
    const response = await axiosInstance
      .post(`/api/sales/${state.signupInfo.id}/send-contract`)
      .catch((err) => err);

    if (response instanceof Error) {
      let message = 'Failed to send contract. Please contact support.';

      if (response.response && response.response.status === 400) {
        message = response.response.data;
      }

      dispatch('toastr/error', message, { root: true });
      console.error(response);
      return false;
    }

    dispatch('toastr/success', 'Contract Sent.', { root: true });
    return true;
  },
  async ioSignUp() {
    authService.setAuthority('ioSignUp');
    authService.login();
  },
  async ioSignIn() {
    authService.setAuthority('io');
    authService.login();
  },
  async signContract({ commit, dispatch, state }) {
    await dispatch('saveSignupBlob');
    commit('setLoading', false);

    const response = await dispatch('request/post', {
      url: `/api/sales/sign-contract/${state.signupInfo.id}`,
      errorMessage: 'Failed to sign contract',
    });

    if (response instanceof Error) {
      return false;
    }

    return true;
  },
  async saveClientApproval({ commit }, { name, initials, clientAgreements }) {
    commit('SET_CLIENT_APPROVAL_INITIALS', initials);
    commit('SET_CLIENT_APPROVAL_NAME', name);
    commit('SET_CLIENT_APPROVAL_DATE', new Date());
    commit('SET_CLIENT_APPROVAL_IP', state.clientIp);
    commit('SET_CLIENT_AGREEMENTS', clientAgreements);
  },

  // Schedule
  addDayPartSchedule({ commit, dispatch, state }, { day, intervalStep }) {
    const lastIndex =
      state.signupInfo.clientSchedule[day].dayPartSchedules.length - 1;
    let newStartTime = '09:00:00';
    let newEndTime = '17:00:00';

    if (lastIndex > -1) {
      const lastDayPartSchedule =
        state.signupInfo.clientSchedule[day].dayPartSchedules[lastIndex];
      newStartTime = IntervalMap.nextTime(
        lastDayPartSchedule.dayPartCap.end,
        intervalStep
      );

      if (newStartTime === '23:45:00' || newStartTime === '24:00:00') {
        dispatch(
          'toastr/error',
          `There's no more time left in the day! You may adjust existing schedules.`,
          {
            root: true,
          }
        );
        return;
      }
      newEndTime = IntervalMap.nextTime(newStartTime, '01:00:00');
    }

    const newDayPartSchedule = new DailySchedule.DayPartSchedule({
      concurrencyCap: 0,
      dayPartCap: new DailySchedule.Cap({
        start: newStartTime,
        end: newEndTime,
        dayIndex: state.signupInfo.clientSchedule[day].dayIndex,
      }),
    });

    commit('addDayPartSchedule', newDayPartSchedule);
  },
  setDayPartSchedule({ commit }, { startOrEnd, day, index, time }) {
    commit('setDayPartSchedule', { startOrEnd, day, index, time });
  },
  removeDayPartSchedule({ commit, dispatch, state }, { day, index }) {
    commit('removeDayPartSchedule', { day, index });
    if (state.signupInfo.clientSchedule[day].dayPartSchedules.length === 0) {
      dispatch('addDayPartSchedule', { day, intervalStep: '01:00:00' });
      commit('setDayActive', { day, isActive: false });
    }
  },
  setDayActive({ commit }, { day, isActive }) {
    commit('setDayActive', { day, isActive });
  },

  // Promos
  async setMatchPromos({ commit, dispatch }, { errors, ...promos }) {
    const cleanedMatchPromos = [];
    for (const key in promos) {
      const amount = parseFloat(promos[key].value);
      if (!isNaN(amount) && amount !== 0) {
        const productType = await dispatch('getProductTypeIdByName', key);
        cleanedMatchPromos.push({
          productType,
          amount,
        });
      }
    }

    commit('SET_MATCH_PROMO_ERRORS', errors);
    commit('SET_MATCH_PROMO_SETTINGS', cleanedMatchPromos);
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setSignupInfo: _.set('signupInfo'),
  setPackageDetails: _.set('packageDetails'),
  setContractExpired: _.set('contractExpired'),
  setExistingLogin: _.set('existingLogin'),
  setPendingSignups: _.set('pendingSignups'),
  setClientIp: _.set('clientIp'),
  setPagination: _.set('pagination'),
  SET_SHOW_LUT: _.set('isLutVisible'),
  SET_DEPOSIT_ERRORS(state, newErrors) {
    state.depositErrors = {
      ...state.depositErrors,
      ...newErrors,
    };
  },
  REMOVE_DEPOSIT_ERRORS(state, keyToRemove) {
    const { [keyToRemove]: _, ...rest } = state.depositErrors;
    state.depositErrors = rest;
  },
  setFirstName(state, val) {
    state.signupInfo.client.businessContactFirstName = val;
  },
  setLastName(state, val) {
    state.signupInfo.client.businessContactLastName = val;
  },
  setFullName(state, val) {
    state.signupInfo.client.fullName = val;
  },
  setLicenseNumber(state, val) {
    state.signupInfo.client.licenseNumber = val;
  },
  setAddress1(state, val) {
    state.signupInfo.client.businessContactAddress1 = val;
  },
  setAddress2(state, val) {
    state.signupInfo.client.businessContactAddress2 = val;
  },
  setCity(state, val) {
    state.signupInfo.client.businessContactCity = val;
  },
  setState(state, val) {
    state.signupInfo.client.businessContactState = val;
  },
  setZip(state, val) {
    state.signupInfo.client.businessContactZip = val;
  },
  setPhone(state, val) {
    state.signupInfo.client.businessContactPhone = val;
  },
  setEmail(state, val) {
    state.signupInfo.client.businessContactEmail = val;
  },
  setAgencyEmail(state, val) {
    state.signupInfo.client.agencyEmail = val;
  },
  setParentCompany(state, val) {
    state.signupInfo.client.parentCompany = val;
  },
  setSalesforceId(state, val) {
    state.signupInfo.client.salesforceId = val;
  },
  setTimeZone(state, val) {
    state.signupInfo.client.timeZone = val;
  },
  setSalesPersonEmail(state, val) {
    state.signupInfo.salesPersonEmail = val;
  },
  setCurrentPage(state, newValue) {
    state.pagination.currentPage = newValue;
  },
  setPageSize(state, newValue) {
    state.pagination.pageSize = newValue;
  },

  // Schedule
  addDayPartSchedule(state, newDayPartSchedule) {
    state.signupInfo.clientSchedule[
      newDayPartSchedule.dayPartCap.dayOfWeek
    ].dayPartSchedules.push(newDayPartSchedule);
  },
  setDayPartSchedule(state, { startOrEnd, day, index, time }) {
    state.signupInfo.clientSchedule[day].dayPartSchedules[index].dayPartCap[
      startOrEnd
    ] = time;
  },
  setDaySchedule(state, { day, start, end, isActive }) {
    const daySchedule = state.signupInfo.clientSchedule[day];
    daySchedule.isActive = isActive;

    daySchedule.dayPartSchedules = [daySchedule.dayPartSchedules[0]];
    start ??= '00:00:00';
    end ??= '24:00:00';

    const dayPart = daySchedule.dayPartSchedules[0].dayPartCap;
    dayPart.start = start;
    dayPart.end = end;
  },
  removeDayPartSchedule(state, { day, index }) {
    state.signupInfo.clientSchedule[day].dayPartSchedules.splice(index, 1);
  },
  setDayActive(state, { day, isActive }) {
    state.signupInfo.clientSchedule[day].isActive = isActive;
  },

  UPDATE_CLIENT_QUOTE_TYPE(
    state,
    {
      cqt,
      callSchedule,
      filterList,
      packageName,
      phoneNumbers,
      maxSold,
      webLeadSchedule,
    }
  ) {
    const { clientQuoteTypes } = state.signupInfo;
    cqt.callSchedule = callSchedule;
    cqt.schedule = webLeadSchedule;
    cqt.packageName = packageName;
    cqt.filterList = filterList;
    cqt.maxSold = maxSold;

    for (let i = 0; i < clientQuoteTypes.length; i++) {
      if (clientQuoteTypes[i].id === cqt.id) {
        clientQuoteTypes[i] = cqt;
        return;
      }
    }
  },
  DELETE_CLIENT_QUOTE_TYPE(state, clientQuoteTypeId) {
    state.signupInfo.clientQuoteTypes = [
      ...state.signupInfo.clientQuoteTypes.filter(
        (x) => x.id !== clientQuoteTypeId
      ),
    ];
  },

  ADD_CHANNEL(state, cqt) {
    cqt.isNew = false;
    state.signupInfo.clientQuoteTypes.push(cqt);
  },

  SET_PARENT_SPECIAL_PROGRAM(state, newValue) {
    state.parentSpecialProgram = newValue;
  },
  UPDATE_GRAVITY_ID(state, newValue) {
    state.signupInfo.client.gravityProfileId = newValue;
  },
  UPDATE_CARD_ID(state, newValue) {
    state.signupInfo.defaultCardId = newValue;
  },
  UPDATE_CASH_CAP(state, newValue) {
    state.signupInfo.cashCapBalance = newValue;
  },
  UPDATE_CASH_CAP_RESET(state, newValue) {
    state.signupInfo.client.cashcapNoReset = newValue;
  },
  UPDATE_LOYALTY_LEVEL(state, newValue) {
    state.signupInfo.client.level = newValue;
  },
  UPDATE_CHARGE_CARD(state, newValue) {
    state.signupInfo.chargeCard = newValue;
  },
  UPDATE_IMMEDIATE_ACTIVATION(state, newValue) {
    state.signupInfo.immediateActivation = newValue;
  },
  UPDATE_SPECIAL_PROGRAM(state, newValue) {
    state.signupInfo.client.specialProgram = newValue;
  },
  UPDATE_DEPOSIT(state, { productType, value }) {
    state.signupInfo.deposits = {
      ...state.signupInfo.deposits,
      [productType]: value,
    };
  },
  UPDATE_WEB_RECHARGE_ENABLED(state, newValue) {
    state.signupInfo.webRechargeEnabled = newValue;
  },
  UPDATE_WEB_RECHARGE_AMOUNT(state, newValue) {
    state.signupInfo.webRechargeAmount = newValue;
  },
  UPDATE_CALL_RECHARGE_AMOUNT(state, newValue) {
    state.signupInfo.callRechargeAmount = newValue;
  },
  SET_PROMOTIONS(state, newValue) {
    state.promotions = newValue;
  },
  SET_PROMO_CODE(state, newValue) {
    state.signupInfo.promoId = newValue;
  },
  SET_DISCOUNTS(state, newValue) {
    state.signupInfo.discounts = newValue;
  },
  UPDATE_ACTIVATION_DATE(state, newValue) {
    state.signupInfo.activationDate = newValue;
  },
  SET_CLIENT_APPROVAL_INITIALS(state, initials) {
    state.signupInfo.clientApprovalInitials = initials;
  },
  SET_CLIENT_APPROVAL_NAME(state, name) {
    state.signupInfo.clientApprovalName = name;
  },
  SET_CLIENT_APPROVAL_DATE(state, date) {
    state.signupInfo.clientApprovalDate = date;
  },
  SET_CLIENT_APPROVAL_IP(state, ip) {
    state.signupInfo.clientApprovalIp = ip;
  },
  SET_CLIENT_AGREEMENTS(state, clientAgreements) {
    state.signupInfo.clientAgreements = clientAgreements;
  },
  SET_NOTES(state, newValue) {
    state.signupInfo.notes = newValue;
  },
  SET_IO_SORT_BY(state, newValue) {
    state.sortBy = newValue;
  },
  SET_CHANNEL_GROUPS(state, newValue) {
    state.signupInfo.subscriptionGroups = newValue;
  },

  DELETE_SIGNUP(state, ioId) {
    state.pendingSignups = state.pendingSignups.filter((s) => {
      return s.blobKey !== ioId;
    });
  },

  SET_VALIDATION_RESULT(state, validationResult) {
    state.validationResult = validationResult;
  },

  RESET_VALIDATION_RESULT(state) {
    state.validationResult = {
      isAccountValid: false,
      results: [],
    };
  },

  SET_GEO_MAPS(state, { cqtId, territories }) {
    const { clientQuoteTypes } = state.signupInfo;
    for (let i = 0; i < clientQuoteTypes.length; i++) {
      if (clientQuoteTypes[i].id === cqtId) {
        clientQuoteTypes[i].geoMaps = territories;
        break;
      }
    }
  },

  SET_ACCOUNT_ERRORS(state, errors) {
    state.errors = [
      ...state.errors.filter((x) => x.step !== 0),
      {
        ...state.stepData[0],
        errors,
      },
    ];
  },

  SET_MATCH_PROMO_ERRORS: _.set('matchPromoErrors'),
  SET_MATCH_PROMO_SETTINGS(state, settings) {
    state.signupInfo.matchPromos = settings;
  },
};
