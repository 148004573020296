export default {
  data() {
    return {
      upsellCodes: [
        {
          value: 'Added territory',
          key: 'CSUP_ADD_TERR',
          requiresChannel: true,
        },
        {
          value: 'Added new lead type',
          key: 'CSUP_ADD_TYPE',
          requiresChannel: true,
        },
        {
          value: 'Increased day caps',
          key: 'CSUP_RAISE_CAP',
          requiresChannel: true,
        },
        {
          value: 'Weekly Cap',
          key: 'CSUP_WEEKLY_CAP',
          requiresChannel: true,
        },
        {
          value: 'Removed a filter',
          key: 'CSUP_REM_FILT',
          requiresChannel: true,
        },
        {
          value: 'Pre-charge',
          key: 'CSUP_PC',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $150',
          key: 'CSUP_PC150',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $250',
          key: 'CSUP_PC250',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $350',
          key: 'CSUP_PC350',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $400',
          key: 'CSUP_PC400',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $500',
          key: 'CSUP_PC500',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $650',
          key: 'CSUP_PC650',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $750',
          key: 'CSUP_PC750',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $1000',
          key: 'CSUP_PC1000',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $1500',
          key: 'CSUP_PC1500',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $2150',
          key: 'CSUP_PC2150',
          requiresChannel: false,
        },
        {
          value: 'Pre-charge $2500',
          key: 'CSUP_PC2500',
          requiresChannel: false,
        },
        {
          value: 'Cancel Save',
          key: 'CSUP_CANCEL_SAVE',
          requiresChannel: false,
        },
        {
          value: 'Re-activated from Cash Cap',
          key: 'CSUP_REACTIVATE_CASHCAP',
          requiresChannel: false,
        },
        {
          value: 'WizardMail',
          key: 'CSUP_WIZARD_MAIL',
          requiresChannel: false,
        },
        {
          value: 'Schedule 10 Plus Hours',
          key: 'CSUP_SCHED_10_PLUS_HOURS',
          requiresChannel: false,
        },
        {
          value: 'Removed Discount Window',
          key: 'CSUP_REM_DISCOUNT_WINDOW',
          requiresChannel: true,
        },
      ],
      inOutBoundValues: [
        { value: 'Inbound Call', key: 'INBOUND' },
        { value: 'Outbound Call', key: 'OUTBOUND' },
      ],
      promoCosts: [
        { key: '0', value: 'No Cost' },
        { key: '25', value: '25' },
        { key: '50', value: '50' },
        { key: '75', value: '75' },
        { key: '100', value: '100' },
        { key: '150', value: '150' },
        { key: '200', value: '200' },
        { key: '250', value: '250' },
        { key: '350', value: '350' },
        { key: '400', value: '400' },
        { key: '500', value: '500' },
        { key: '750', value: '750' },
        { key: '1000', value: '1000' },
        { key: '1500', value: '1500' },
        { key: '2150', value: '2150' },
        { key: '2500', value: '2500' },
        { key: 'CUSTOM', value: 'Custom Amount' },
      ],
    }
  },
}
