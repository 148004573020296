const defaultMatchPromos = {
  Call: {
    percent: null,
    value: 0,
    maximum: 0,
  },
  Web: {
    percent: null,
    value: 0,
    maximum: 0,
  },
  errors: [],
};

export const state = {
  matchPromos: { ...defaultMatchPromos },
};

export const getters = {
  getMatchPromoValueByProduct: (state) => (product) =>
    state.matchPromos[product].value,
  getMatchPromoPercentByProduct: (state) => (product) =>
    state.matchPromos[product].percent,
  getMatchPromoMaximumByProduct: (state) => (product) =>
    state.matchPromos[product].maximum,
  getErrors: (state) => (category) => state[category].errors,
  getMatchPromos: (state) => {
    const { errors: _, ...promos } = state.matchPromos;
    return promos;
  },
};

export const actions = {
  setMatchPromosFromSource({ commit, state }, matchPromos) {
    const cleanedPromos = { ...defaultMatchPromos };
    for (const key in matchPromos) {
      cleanedPromos[key] = {
        ...defaultMatchPromos[key],
        ...matchPromos[key],
      };
    }
    commit('SET_MATCH_PROMOS', cleanedPromos);
  },
};

export const mutations = {
  SET_MATCH_PROMO(state, { product, ...promoValues }) {
    state.matchPromos[product] = {
      ...state.matchPromos[product],
      ...promoValues,
    };
  },
  ADD_ERROR(state, { category, ...newError }) {
    state[category].errors = [
      ...state[category].errors.filter((x) => x.product !== newError.product),
      newError,
    ];
  },
  REMOVE_ERROR(state, { category, product }) {
    state[category].errors = [
      ...state[category].errors.filter((x) => x.product !== product),
    ];
  },
  SET_MATCH_PROMOS(state, matchPromos) {
    state.matchPromos = matchPromos;
  },
  RESET_MATCH_PROMOS(state) {
    state.matchPromos = { ...defaultMatchPromos };
  },
  SET_MATCH_PROMO_MAXIMUM(state, { product, maximum }) {
    // values need to reset when the maximum is changed
    state.matchPromos[product] = {
      ...defaultMatchPromos[product],
      maximum,
    };
  },
};
