<template>
  <el-row class="mt-7 pb-9">
    <el-col class="mb-2 text-center">
      <i class="el-icon-warning py-4" style="font-size: 48px; color: red" />
      <h2>{{ clientFirstName }}, you are not eligible for reactivation.</h2>
      <h5>Please contact Support or Customer Service by emailing <a :href="'mailto:' + supportEmail" class="text-nowrap">{{ supportEmail }}</a> or calling <a :href="'tel:' + supportPhone" class="text-nowrap">{{ supportPhone }}.</a></h5>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomeIneligible',
  computed: {
    ...mapGetters('client', ['clientFirstName', 'supportEmail', 'supportPhone']),
  }
}
</script>

<style scoped>
</style>
