<template>
  <div class="el-container vertical">
    <reactivation />
    <notification-banner />
    <notification-dialog />
    <slot />
    <toastr />
  </div>
</template>

<script>
import NotificationBanner from '@/components/notifications/types/NotificationBanner.vue';
import NotificationDialog from '@/components/notifications/types/NotificationDialog.vue';
import Toastr from '@/components/Toastr.vue';
import Reactivation from '@/components/reactivation/Index.vue';

export default {
  name: 'LayoutRoot',
  components: {
    NotificationBanner,
    NotificationDialog,
    Toastr,
    Reactivation,
  },
};
</script>

<style scoped>
.vertical {
  flex-direction: column;
}
</style>

<style>
/* Responsive menu capabilities */
html.scroll-lock,
html.scroll-lock body {
  overflow: auto;
  height: 100% !important;
}
</style>
