import { axiosInstance } from '@/plugins/axios';
import moment from 'moment';

// initial state
export const state = {
  isSubsidyTypePickStep: true,
  importType: '',
  importTypeFriendly: '',
  isUploading: false,
  isUpload: false,
  isUploadComplete: false,
  isScheduledUpload: false,
  isFarmersVendor: false,
  fileSheets: [],
  subsidyFile: '',
  postedFileName: '',
  isSheetPick: false,
  selectedSheet: '',
  isPreProcessing: false,
  isApplyingRules: false,
  toBeAdded: 0,
  toBeUpdated: 0,
  toBeDeleted: 0,
  isApplyRules: false,
  processSuccess: false,
  isFarmersBillingDone: false,
  nextProcessingDtg: moment()
    .add(1, 'M')
    .startOf('month')
    .format('YYYY-MM-DD hh:mm:ss'),
};

// getters
export const getters = {};

// actions
export const actions = {
  submitSubsidyFile({ commit }, { formData }) {
    commit('SET_UPLOADING_STATUS', true);
    return axiosInstance
      .post(`/api/subsidy/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          commit('SET_UPLOADING_STATUS', false);
          commit('SET_UPLOAD_STATUS', false);
          commit('SET_FILE_NAME', response.data.fileName);
          commit('SET_SHEET_PICK', true);
        } else {
          this.$store.dispatch(
            'toastr/error',
            'Error(s): ' + response.data.message
          );
        }
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error(s): ' + err);
      });
  },

  async uploadSFSubsidyFile({ commit }, { formData }) {
    commit('SET_SCHEDULED_UPLOAD', true);

    return axiosInstance
      .post(`/api/sfsubsidyimport/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        commit('SET_UPLOAD_STATUS', false);
        if (response.data.isSuccess) {
          commit('SET_UPLOAD_COMPLETE', true);
          commit('SET_FILE_NAME', response.data.fileName);
        } else {
          this.$store.dispatch(
            'toastr/error',
            'Error(s): ' + response.data.message
          );
        }
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error(s): ' + err);
      });
  },

  async importSFSubsidyFile({ commit }, { formData }) {
    commit('SET_SCHEDULED_UPLOAD', false);

    return axiosInstance
      .post(`/api/sfsubsidyimport/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        commit('SET_UPLOAD_STATUS', false);
        if (response.data.isSuccess) {
          commit('SET_UPLOAD_COMPLETE', true);
          commit('SET_FILE_NAME', response.data.fileName);
        } else {
          this.$store.dispatch(
            'toastr/error',
            'Error(s): ' + response.data.message
          );
        }
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error(s): ' + err);
      });
  },

  async uploadSFGeoFile({ commit, dispatch }, { formData }) {
    commit('SET_SCHEDULED_UPLOAD', false);

    try {
      const response = await axiosInstance.post(
        `/api/sfsubsidyimport/upload-geo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      commit('SET_UPLOAD_STATUS', false);
      if (response.data.isSuccess) {
        commit('SET_UPLOAD_COMPLETE', true);
        commit('SET_FILE_NAME', response.data.fileName);
      } else {
        dispatch(
          'toastr/error',
          'Error(s): ' + response.data.message,
          { root: true }
        );
      }
    } catch (err) {
      dispatch('toastr/error', 'Error(s): ' + err?.response?.data, { root: true });
    }
  },

  async submitFarmersBillingFile({ commit }, { formData }) {
    commit('SET_UPLOADING_STATUS', true);

    try {
      var response = await axiosInstance.post(
        `/api/farmersBillingRecords/farmers-billing-records-import`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.isSuccess) {
        commit('SET_UPLOADING_STATUS', false);
        commit('SET_UPLOAD_STATUS', false);
        // commit('SET_FILE_NAME', response.data.fileName);
        commit('SET_FARMERS_BILLING_DONE', true);
      } else {
        this.$store.dispatch(
          'toastr/error',
          'Error(s): ' + response.data.message
        );
      }
    } catch (error) {
      if (!error.response) {
        // network error
        this.dispatch('toastr/error', 'Error: Network Error');
      } else {
        this.dispatch(
          'toastr/error',
          'toastr/error',
          'Error(s): ' + error.response.data
        );
      }
      console.error(error);
    }
  },

  getFileSheets({ commit }, { type }) {
    commit('SET_IS_PRE_PROCESSING', true);
    return axiosInstance
      .get(`/api/subsidy/getsheets`, {
        params: {
          t: type,
          fileName: state.postedFileName,
        },
      })
      .then((response) => {
        var data = response.data;
        commit('SET_FILESHEETS_LIST', data);
        commit('SET_IS_PRE_PROCESSING', false);
        return data;
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error(s): ' + err);
      });
  },
  execPreProcess({ commit }, { sheet, type }) {
    commit('SET_IS_PRE_PROCESSING', true);
    return axiosInstance
      .get(`/api/subsidy/preprocess`, {
        params: {
          t: type,
          fileName: state.postedFileName,
          sheetname: sheet,
        },
      })
      .then((response) => {
        commit('SET_IS_PRE_PROCESSING', false);
        if (response.status === 200) {
          commit('SET_SHEET_PICK', false);
          commit('SET_IS_APPLY_RULES', true);
          commit('SET_TO_BE_ADDED', response.data.toBeAdded);
          commit('SET_TO_BE_UPDATED', response.data.toBeUpdated);
          commit('SET_TO_BE_DELETED', response.data.toBeDeleted);
        } else {
          this.$store.dispatch(
            'toastr/error',
            'Error(s): ' + response.reasonPhrase // TODO= right thing to expose?
          );
        }
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error(s): ' + err);
      });
  },
  execApplyRules({ commit }, { type }) {
    commit('SET_IS_APPLYING_RULES', true);
    return axiosInstance
      .get(`/api/subsidy/applyrules`, {
        params: {
          t: type,
        },
      })
      .then((response) => {
        commit('SET_IS_APPLYING_RULES', false);
        if (response.status === 200) {
          commit('SET_IS_PROCESS_SUCCESS', true);
          commit('SET_IS_APPLY_RULES', false);
        } else {
          this.$store.dispatch(
            'toastr/error',
            'Error(s): ' + response.reasonPhrase
          );
        }
      })
      .catch((err) => {
        this.$store.dispatch('toastr/error', 'Error(s): ' + err);
      });
  },
};

// mutations
export const mutations = {
  SET_FILESHEETS_LIST(state, newValue) {
    state.fileSheets = newValue;
  },
  SET_IS_SUBSIDY_TYPE_PICK(state, newValue) {
    state.isSubsidyTypePickStep = newValue;
  },
  SET_UPLOADING_STATUS(state, newValue) {
    state.isUploading = newValue;
  },

  SET_FARMERS_BILLING_DONE(state, newValue) {
    state.isFarmersBillingDone = newValue;
  },
  SET_UPLOAD_STATUS(state, newValue) {
    state.isUpload = newValue;
  },
  SET_UPLOAD_COMPLETE(state, newValue) {
    state.isUploadComplete = newValue;
  },
  SET_FILE_NAME(state, newValue) {
    state.postedFileName = newValue;
  },
  SET_SHEET_PICK(state, newValue) {
    state.isSheetPick = newValue;
  },
  SET_IS_PRE_PROCESSING(state, newValue) {
    state.isPreProcessing = newValue;
  },
  SET_IS_APPLY_RULES(state, newValue) {
    state.isApplyRules = newValue;
  },
  SET_IS_APPLYING_RULES(state, newValue) {
    state.isApplyingRules = newValue;
  },
  SET_IS_PROCESS_SUCCESS(state, newValue) {
    state.processSuccess = newValue;
  },
  SET_SCHEDULED_UPLOAD(state, newValue) {
    state.isScheduledUpload = newValue;
  },
  SET_SUBSIDY_TYPE(state, newValue) {
    state.importType = newValue;
    switch (state.importType) {
      case 'StateFarmImportManager':
        state.importTypeFriendly = '(State Farm)';
        break;
      case 'FarmersBillingManager':
        state.importTypeFriendly = '(Farmers Billing)';
        break;
      case 'FarmersVendorManager':
        state.importTypeFriendly = '(Farmers Agent)';
        break;
    }
    state.isSubsidyTypePickStep = false;
    if (state.importType === 'FarmersVendorManager') {
      state.isFarmersVendor = true;
    } else {
      state.isUpload = true;
    }
  },
  SET_TO_BE_ADDED(state, newValue) {
    state.toBeAdded = newValue;
  },
  SET_TO_BE_UPDATED(state, newValue) {
    state.toBeUpdated = newValue;
  },
  SET_TO_BE_DELETED(state, newValue) {
    state.toBeDeleted = newValue;
  },
  SET_DEFAULT_STATE(state) {
    state.isSubsidyTypePickStep = true;
    state.importType = '';
    state.importTypeFriendly = '';
    state.isUploading = false;
    state.isUpload = false;
    state.fileSheets = [];
    state.subsidyFile = '';
    state.postedFileName = '';
    state.isSheetPick = false;
    state.selectedSheet = '';
    state.isPreProcessing = false;
    state.isApplyingRules = false;
    state.toBeAdded = 0;
    state.toBeUpdated = 0;
    state.toBeDeleted = 0;
    state.isApplyRules = false;
    state.processSuccess = false;
    state.isFarmersVendor = false;
  },
};
