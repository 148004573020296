import { createStore } from 'vuex'
import modules from './modules'

export const store = createStore({
  modules,
  // plugins: [
  //   process.env.NODE_ENV !== 'production' ? VuePursue : () => {}
  // ],
  strict: process.env.NODE_ENV !== 'production',
})

export default {
  install(vue, options) {
    vue.use(store);

    vue.$store = store;
    Object.defineProperty(vue.config.globalProperties, '$store', {
      get: () => vue.$store,
      set: (val) => {
        vue.$store = val
      },
    });

    // Automatically run the `init` action for every module, if one exists.
    for (const moduleName of Object.keys(modules)) {
      if (modules[moduleName].actions.init) {
        store.dispatch(`${moduleName}/init`)
      }
    }
  },
}
