import _ from '@/utils/store-helpers';
import { axiosInstance } from '@/plugins/axios';
import moment from 'moment';
import { maxPauseHours } from '@/app.config';

export const state = {
  vacationPauses: [],
  hasUnlimited: false,
  loading: false,
  maxPauseHours,
  hoursUsed: 0,
  hoursAvailable: 0,
};

export const getters = {
  hoursUsed(state) {
    let totalHours = 0;
    for (const pause of state.vacationPauses) {
      const end = pause.actualEndTime || pause.endTime;
      const start = pause.actualStartTime || pause.startTime;
      totalHours += moment
        .duration(Date.parse(end) - Date.parse(start))
        .asHours();
    }
    return Math.ceil(totalHours);
  },

  hoursLeft(state, { hoursUsed }) {
    return state.maxPauseHours - hoursUsed;
  },

  upcomingPauses(state) {
    const upcoming = state.vacationPauses.filter((p) => {
      return p.status === 'PENDING';
    });
    return upcoming;
  },

  activePause(state) {
    const active = state.vacationPauses.find((p) => {
      return p.status === 'ACTIVE';
    });
    return active || null;
  },

  pastPauses(state) {
    var now = Date.now();
    const past = state.vacationPauses.filter((p) => {
      return now > Date.parse(p.actualEndTime);
    });
    return past;
  },
};

export const actions = {
  async getVacationPauses({ commit, dispatch, rootState }) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get(`/api/vacations/${rootState.client.client.id}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Post back to server side log?
      commit('setLoading', false);
      dispatch('toastr/error', 'Failed to get scheduled vacations!', {
        root: true,
      });
      return;
    }

    commit('setVacationPauses', response.data.vacationPauses);
    commit('setHasUnlimited', response.data.hasUnlimited);
    commit('setHoursUsed', response.data.hoursSummary.hoursUsed);
    commit('setHoursAvailable', response.data.hoursSummary.hoursAvailable);
    commit('setLoading', false);
  },

  async resetVacationHours({ commit, dispatch, rootState }) {
    commit('setLoading', true);

    const response = await axiosInstance
      .post(`/api/vacations/${rootState.client.client.id}/reset`)
      .catch((err) => err);

    if (response instanceof Error) {
      commit('setLoading', false);
      dispatch('toastr/error', 'Failed to replenish vacation hours!', {
        root: true,
      });
      return false;
    }

    commit('setVacationPauses', response.data.vacationPauses);
    commit('setHasUnlimited', response.data.hasUnlimited);
    commit('setHoursUsed', response.data.hoursSummary.hoursUsed);
    commit('setHoursAvailable', response.data.hoursSummary.hoursAvailable);

    commit('setLoading', false);
    return true;
  },

  async upsertVacationPause({ commit, dispatch, rootState }, vacationPause) {
    commit('setLoading', true);

    vacationPause.clientId = rootState.client.client.id;

    const method = vacationPause.id ? 'put' : 'post';

    const response = await axiosInstance[method](
      '/api/vacations',
      vacationPause
    ).catch((err) => err);

    if (response instanceof Error) {
      commit('setLoading', false);
      dispatch(
        'toastr/error',
        `Failed to create vacation pause. Reason: ${response.response.data}`,
        {
          root: true,
        }
      );
      return;
    }

    if (method === 'put') {
      commit('updateVacationPause', vacationPause);
      commit('setLoading', false);
      return;
    }

    commit('client/SET_CLIENT_STATUS', response.data.clientStatus, {
      root: true,
    });
    commit('addVacationPause', response.data);
    commit('setLoading', false);
  },

  async cancelVacationPause(
    { commit, dispatch, rootState, state },
    vacationPauseId
  ) {
    commit('setLoading', true);

    const vacationPause = state.vacationPauses.find(
      (v) => v.id === vacationPauseId
    );

    if (vacationPause.status === 'ACTIVE') {
      commit('completeVacationPause', vacationPauseId);
    } else {
      commit('cancelVacationPause', vacationPauseId);
    }

    const response = await axiosInstance
      .delete(`/api/Vacations/${vacationPauseId}`, {
        data: vacationPause,
      })
      .catch((err) => err);

    if (response instanceof Error) {
      commit('uncancelVacationPause', vacationPauseId);
      commit('setLoading', false);
      dispatch(
        'toastr/error',
        `Failed to cancel vacation. Reason: ${response.response.data}`,
        {
          root: true,
        }
      );
      return;
    }

    commit('client/SET_CLIENT_STATUS', response.data.clientStatus, {
      root: true,
    });
    commit('setLoading', false);
  },
};

export const mutations = {
  setVacationPauses: _.set('vacationPauses'),
  setHasUnlimited: _.set('hasUnlimited'),
  setLoading: _.set('loading'),

  addVacationPause(state, vacationPause) {
    state.vacationPauses.push(vacationPause);

    // update used / remaining time
    var usedHours = moment
      .duration(
        Date.parse(vacationPause.endTime) - Date.parse(vacationPause.startTime)
      )
      .asHours();

    state.hoursUsed = Math.round((state.hoursUsed + usedHours) * 100) / 100;
    state.hoursAvailable =
      Math.round((state.hoursAvailable - usedHours) * 100) / 100;
  },

  updateVacationPause(state, vacationPause) {
    // Figure out time used in updated vacation
    var usedHoursNew = moment
      .duration(
        Date.parse(vacationPause.endTime) - Date.parse(vacationPause.startTime)
      )
      .asHours();

    for (let i = 0; i < state.vacationPauses.length; i++) {
      if (state.vacationPauses[i].id === vacationPause.id) {
        // Get current vacation time used

        var usedHoursCurrent = moment
          .duration(
            Date.parse(state.vacationPauses[i].endTime) -
              Date.parse(state.vacationPauses[i].startTime)
          )
          .asHours();

        // replace current time with new time
        if (usedHoursNew > usedHoursCurrent) {
          var increasedTime = usedHoursNew - usedHoursCurrent;

          state.hoursAvailable -= increasedTime;
          state.hoursUsed += increasedTime;
        } else {
          var decreasedTime = usedHoursCurrent - usedHoursNew;

          state.hoursAvailable += decreasedTime;
          state.hoursUsed -= decreasedTime;
        }

        state.vacationPauses[i] = vacationPause;
        return;
      }
    }
  },

  cancelVacationPause(state, vacationPauseId) {
    const pause = state.vacationPauses.find((v) => v.id === vacationPauseId);
    pause.status = 'CANCELED';
  },

  completeVacationPause(state, vacationPauseId) {
    const pause = state.vacationPauses.find((v) => v.id === vacationPauseId);
    pause.actualEndTime = moment().utc().toISOString();
    pause.status = 'COMPLETED';
  },

  uncancelVacationPause(state, vacationPauseId) {
    const pause = state.vacationPauses.find((v) => v.id === vacationPauseId);
    pause.actualEndTime = undefined;
    pause.status = 'ACTIVE';
  },

  setHoursUsed(state, val) {
    state.hoursUsed = val;
  },
  setHoursAvailable(state, val) {
    state.hoursAvailable = val;
  },
};
