export const state = {
  message: {},
}

export const getters = {}

export const actions = {
  show({ commit }, config) {
    commit('setMessage', new ToastMessage(config))
  },
  success({ dispatch }, message) {
    dispatch('show', { message, type: ToastMessage.Types.success })
  },
  error({ dispatch }, message) {
    dispatch('show', { message, type: ToastMessage.Types.error })
  },
  warning({ dispatch }, message) {
    dispatch('show', { message, type: ToastMessage.Types.warning })
  },
  info({ dispatch }, message) {
    dispatch('show', { message, type: ToastMessage.Types.info })
  },
  clearMessage({ commit }) {
    commit('setMessage', {})
  },
}

export const mutations = {
  setMessage(state, toastMessage) {
    state.message = toastMessage
  },
}

export class ToastMessage {
  constructor({ message, type = ToastMessage.Types.info, ...config }) {
    this.message = message
    this.config = config
    this.type = type
  }

  static get Types() {
    return {
      default: 'alert',
      success: 'success',
      error: 'error',
      warning: 'warning',
      info: 'info',
    }
  }
}
