<template>
  <div></div>
</template>

<script>
import { notificationCheckTime } from '@/app.config';

export default {
  name: 'NotificationDisplayManager',
  props: { isInactive: Boolean },
  data() {
    return {
      isPolling: false,
      notificationTimeoutHandle: null,
      checkTime: 0,
    };
  },
  created() {
    this.isPolling = true;
    this.handleFetchNotifications();
  },
  beforeDestroy() {
    if (this.notificationTimeoutHandle) {
      clearTimeout(this.notificationTimeoutHandle);
    }

    this.isPolling = false;
  },
  methods: {
    handleFetchNotifications() {
      if (this.notificationTimeoutHandle) {
        clearTimeout(this.notificationTimeoutHandle);
        this.notificationTimeoutHandle = null;
      }

      if (!this.isPolling || this.isInactive) {
        return;
      }

      this.$store
        .dispatch('notifications/fetchClientNotifications')
        .then(() => {
          this.notificationTimeoutHandle = setTimeout(() => {
            this.checkTime = notificationCheckTime;
            this.handleFetchNotifications();
          }, this.checkTime);
        });
    },
  },
};
</script>
