export default {
  set: (key, persist) => (state, value) => {
    state[key] = value;

    if (persist) {
      if (value == null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    }
  },

  get(key, defaultValue = null) {
    var item = localStorage.getItem(key);

    if (item == null || typeof item === 'undefined') {
      return defaultValue;
    }

    return JSON.parse(item);
  },
};
