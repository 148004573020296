import axiosInstance from '@/plugins/axios';

export const getFeatureFlags = async (flagNames, errorCallback) => {
  const queryString = flagNames.reduce(
    (result, next, i) => `${result}${i > 0 ? ',' : ''}${next}`,
    ''
  );

  const response = await axiosInstance
    .get(`api/feature/flags?flags=${queryString}`)
    .catch((err) => err);

  if (response instanceof Error || response.data === '') {
    console.error(response);
    if (errorCallback) errorCallback(new Error('Failed to get feature flag'));

    return {};
  }

  return response.data;
};

export const getFeatureFlag = async (
  flagName,
  carrier,
  clientType,
  clientId,
  errorCallback
) => {
  const path = `${flagName}/${carrier}-${clientType}/${clientId}`;

  const response = await axiosInstance
    .get(`api/feature/${path}`)
    .catch((err) => err);
  if (response instanceof Error || response.data === '') {
    console.error(response);
    if (errorCallback)
      errorCallback(new Error('Failed to get feature flag for client'));

    return false;
  }
  return response.data;
};

export default {
  getFeatureFlags,
  getFeatureFlag,
};
