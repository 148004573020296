<template>
  <div class="px-1">
    <div v-for="notification in trayNotifications" :key="notification.id">
      <div
:class="{ clickable: !!notification.url }"
          @click="handleClick(notification.url)"
        >
          <el-alert
            :type="notification.alertType.toLowerCase()"
            show-icon
            @close="handleClose(notification.id)">

              <template #title>
                <div v-if="!!notification.headline">
                  {{ notification.headline }}
                </div>
              </template>

              <!-- eslint-disable-next-line vue/no-v-html -->
              <div @click="handlePropagation" v-html="notification.content" />
          </el-alert>
      </div>
    </div>

    <div v-if="trayNotifications.length === 0">
      <div class="text-white text-center">No Notifications</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotificationTray',
  computed: {
    ...mapGetters('notifications', ['trayNotifications']),
  },
  methods: {
    ...mapActions('notifications', ['dismissNotification']),

    handleClick(url) {
      if (!url) {
        return
      }

      this.$router.push(url)
    },

    handleClose(notificationId) {
      this.dismissNotification(notificationId)
    },

    handlePropagation(e) {
      e.stopPropagation()
    },
  },
}
</script>
