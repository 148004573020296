<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label class="form-label"
            >First Name
            <el-input
              v-model="card.firstName"
              autocomplete="off"
              autofocus
              required
              @change="setFullName"
            ></el-input>
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label class="form-label"
            >Last Name
            <el-input
              v-model="card.lastName"
              autocomplete="off"
              required
              @change="setFullName"
            ></el-input>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label class="form-label"
            >Card Number
            <el-form-item>
              <el-input
                v-model="card.cardNumberDisplay"
                :disabled="editing"
                maxlength="16"
                required
                @change="setCardNumber"
              ></el-input>
            </el-form-item>
          </label>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label">
            Expiration Date
            <el-form-item>
              <el-date-picker
                v-model="card.expiration"
                :shortcuts="datePickerOptions && datePickerOptions.shortcuts"
                :disabled-date="
                  datePickerOptions && datePickerOptions.disabledDate
                "
                :cell-class-name="
                  datePickerOptions && datePickerOptions.cellClassName
                "
                format="MM/YY"
                placeholder="MM/YY"
                required
                type="month"
              ></el-date-picker>
            </el-form-item>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="form-label"
            >Company Name
            <el-input
              v-model="card.company"
              autocomplete="off"
              type="text"
            ></el-input>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="form-label"
            >Address
            <el-input
              v-model="card.address1"
              autocomplete="off"
              required
            ></el-input>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label"
            >City
            <el-input
              v-model="card.city"
              autocomplete="off"
              required
            ></el-input>
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="form-label"
            >State
            <el-select
              v-model="card.state"
              class="state-selection"
              autocomplete="off"
              filterable
              placeholder
            >
              <el-option
                v-for="state in states"
                :key="state.abbreviation"
                :label="state.abbreviation"
                :value="state.abbreviation"
                required
              ></el-option>
            </el-select>
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="form-label"
            >Postal Code
            <el-input
              v-model="card.zip"
              autocomplete="off"
              maxlength="5"
              pattern="^[0-9]{5}"
              required
            ></el-input>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { states } from '@/utils/mixins';

export default {
  name: 'CardFormPartial',
  mixins: [states],
  props: ['cardData', 'isEditCardMode'],
  data() {
    const now = new Date();
    return {
      now: now,
      card: this.cardData,
      editing: this.isEditCardMode,
      datePickerOptions: {
        disabledDate(date) {
          return date < now;
        },
      },
    };
  },
  computed: {
    ...mapState('client', ['client']),
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      if (!this.editing) {
        this.card.dateAdded = new Date();
        this.card.cardType = 0;
      }
      this.card.clientId = this.client.id;
    },
    setCardNumber() {
      // using cardNumberDisplay value because in add mode we overwrite this property with return value from authorize.net.  Also in edit mode cardNumber is not editable
      if (!this.editing) {
        this.card.cardNumber = this.card.cardNumberDisplay;
      }
    },
    setFullName() {
      this.card.fullName = this.card.firstName + ' ' + this.card.lastName;
    },
  },
};
</script>

<style scoped>
.state-selection {
  display: block;
}
</style>
