import AzureInstance, { authorities, scopes } from './AzureInstance';
import errorTracker from '@/plugins/errorTracker';
import { useStore } from 'vuex';

export const rootErrors = [];

export default {
  login() {
    const { sessionStorage } = window;
    /**
     * We could be in an infinite loop, so we want to be able to track, log, and break the loop.
     * sessionStorage.callbackCount is initialized in main.js, so it should always exist in sessionStorage.
     * This will be removed once we diagnose why people are getting stuck in redirect loops.
     */
    if (sessionStorage.callbackCount > 3) {
      sessionStorage.callbackCount = 0;
      errorTracker.trackException({
        exception: new Error('Login Redirect Loop'),
      });
      this.reset();
    }

    sessionStorage.callbackCount++;
    sessionStorage.redirectFrom =
      window.location.pathname + window.location.search;
    AzureInstance.loginRedirect({ scopes });
  },
  loginAndRedirect(redirect) {
    const { sessionStorage } = window;
    /**
     * We could be in an infinite loop, so we want to be able to track, log, and break the loop.
     * sessionStorage.callbackCount is initialized in main.js, so it should always exist in sessionStorage.
     * This will be removed once we diagnose why people are getting stuck in redirect loops.
     */
    if (sessionStorage.callbackCount > 3) {
      sessionStorage.callbackCount = 0;
      errorTracker.trackException({
        exception: new Error('Login Redirect Loop'),
      });
      this.reset();
    }

    sessionStorage.callbackCount++;
    sessionStorage.redirectFrom = redirect;
    AzureInstance.loginRedirect({ scopes });
  },

  logout() {
    AzureInstance.logout();
  },

  getUser() {
    return AzureInstance.getAccount();
  },

  setAuthority(authorityKey) {
    AzureInstance.authority = authorities[authorityKey];
  },

  async getToken() {
    const account = AzureInstance.getAccount();
    const loginHint = account.idTokenClaims.emails[0];
    try {
      const res = await AzureInstance.ssoSilent({
        scopes,
        loginHint,
      });
      return res.idToken.rawIdToken;
    } catch (error) {
      if (error.message === 'User login is required.') {
        this.login();
        return;
      }

      AzureInstance.acquireTokenRedirect({ scopes });
    }
  },

  reset() {
    const { sessionStorage } = window;
    AzureInstance.clearCache();
    const storageKeys = Object.keys(sessionStorage);
    for (const key in storageKeys) {
      if (key.startsWith('msal.')) {
        delete sessionStorage[key];
      }
    }
  },
};
