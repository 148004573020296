export default {
  methods: {
    getRange(low, high, step = 1) {
      const list = []
      for (let i = low; i <= high; i += step) {
        list.push(i)
      }
      return list
    },
  },
}
