import _ from '@/utils/store-helpers';
import {
  getFeatureFlags,
  getFeatureFlag,
} from '../../apiServices/featureFlagService';

export const state = {
  flags: {},
  isInit: false,
};

export const getters = {
  flags: (state) => state.flags,
  getFlag: (state) => (flagName) => (state.flags[flagName] ?? false) === true,
  isInit: (state) => state.isInit,
};

export const actions = {
  async populateFlags({ commit }) {
    const flags = await getFeatureFlags([
      'CorporateChannelList',
      'FarmersBillingUpload',
      'TerritoryExpansion',
      'EliteAgentDisplay',
      'AutomatedCancelation',
      'StateFarmCombinedUpload',
      'EnableWebLeadPriceModifiers',
    ]);

    commit('setFlags', flags);
    commit('setIsInit', true);

    return flags;
  },

  async checkFlagForClient(_, { flagName, carrier, clientType, clientId }) {
    return await getFeatureFlag(flagName, carrier, clientType, clientId);
  },

  reset({ commit }) {
    commit('setFlags', {});
    commit('setIsInit', false);
  },
};

export const mutations = {
  setFlags: _.set('flags'),
  setIsInit: _.set('isInit'),
};
