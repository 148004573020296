import _ from '@/utils/store-helpers';
import { v1 as uuidv1 } from 'uuid';
import { ChannelGroupService } from '../../apiServices/channelGroupService';

const CALL_TYPE = 2;
export const state = {
  groups: [],
  isLoading: false,
  // TODO: remove assignment and initialize elsewhere
  isSignup: false,
  channelGroupService: new ChannelGroupService(),
};

export const getters = {
  groups: (state) => state.groups,
  isLoading: (state) => state.isLoading,
  isSignup: (state) => state.isSignup,
  groupById: (state) => (subscriptionGroupId) => {
    return state.groups.find((group) => {
      return group.subscriptionGroupId === subscriptionGroupId;
    });
  },

  callChannelsNotInGroup(state, getters, rootState) {
    if (!state.groups) {
      return [];
    }

    let channels = [];

    if (state.isSignup) {
      channels = rootState.signup.signupInfo.clientQuoteTypes;
    } else {
      channels = rootState.channels.channels;
    }

    const subscriptions = state.groups.reduce((subscriptions, group) => {
      return subscriptions.concat(group.subscriptions);
    }, []);

    if (state.isSignup) {
      const subscriptionIds = subscriptions.map((sub) => sub.clientQuoteTypeId);
      const ungroupedCallChannels = channels.filter((channel) => {
        return (
          !subscriptionIds.includes(channel.id) &&
          channel.productTypeId === CALL_TYPE
        );
      });
      return ungroupedCallChannels;
    } else {
      const subscriptionIds = subscriptions.map((sub) => sub.clientQuoteTypeId);
      const ungroupedCallChannels = channels.filter((channel) => {
        return (
          !subscriptionIds.includes(channel.id) && channel.source === 'Call'
        );
      });
      return ungroupedCallChannels;
    }
  },

  callChannelEditOptions: (state, getters, rootState) => (editingGroup) => {
    const groupId = editingGroup.subscriptionGroupId;
    let channels = [];
    if (state.isSignup) {
      channels = rootState.signup.signupInfo.clientQuoteTypes;
    } else {
      channels = rootState.channels.channels;
    }

    const ungroupedCallChannels = getters.callChannelsNotInGroup;

    if (!groupId) {
      return ungroupedCallChannels;
    }

    const editingGroupSubIds = editingGroup.subscriptions.map((sub) => {
      return sub.clientQuoteTypeId;
    });

    const editingChannels = channels.filter((channel) => {
      return editingGroupSubIds.includes(channel.id);
    });

    return [...ungroupedCallChannels, ...editingChannels];
  },

  isGroupsAvailable(state, getters, rootState, rootGetters) {
    let hasCallChannels = false;
    if (state.isSignup) {
      hasCallChannels = rootGetters['signup/hasCallChannel'];
    } else {
      hasCallChannels = rootGetters['channels/hasCallChannel'];
    }
    return hasCallChannels;
  },

  groupByClientQuoteTypeId: (state) => (clientQuoteTypeId) => {
    return state.groups.find((group) => {
      return group.subscriptions.some((subscription) => {
        return subscription.clientQuoteTypeId === clientQuoteTypeId;
      });
    });
  },
  newGroup(state, getters, rootState, rootGetters) {
    const id = uuidv1();
    const scheduleInfo = rootGetters['scheduler/scheduleInfo'];
    const group = {
      subscriptionGroupId: id,
      groupName: '',
      subscriptions: [],
      dailySchedule: [],
      monthlyCap: [],
      intervalCapEnabled: false,
      scheduleInfo: scheduleInfo,
    };

    return group;
  },
  fetchGroup: (state, getters, rootState, rootGetters) => (id) => {
    const group = state.groups.find((group) => {
      return group.subscriptionGroupId === id;
    });

    return group;
  },
};

export const actions = {
  setIsSignup({ commit }) {
    commit('SET_IS_SIGNUP', true);
  },
  resetGroups({ commit }) {
    commit('SET_IS_SIGNUP', false);
    commit('setGroups', []);
  },
  setGroups({ commit }, groups) {
    commit('setGroups', groups);
  },

  setGroupSchedule({ commit }, { group, schedule }) {
    commit('SET_GROUP_SCHEDULE', { group, schedule });
  },

  async saveGroup(
    { commit, dispatch, getters, rootState, state },
    { group, channelIds, callback }
  ) {
    commit('setLoading', true);

    // TODO: fix checking for duplicate name without subscriptiongroupid for signup
    const isNameExists = state.groups.some((g) => {
      return (
        g.id !== group.id &&
        g.groupName.toLowerCase() === group.groupName.toLowerCase()
      );
    });
    const isChannelGrouped = state.groups.some((g) => {
      return (
        g.subscriptionGroupId !== group.subscriptionGroupId &&
        g.subscriptions.some((sub) => {
          return sub.clientQuoteTypeId === group.clientQuoteTypeId;
        })
      );
    });

    if (isNameExists) {
      if (callback) {
        callback(new Error('Name already exists.'));
        return false;
      }
      return false;
    }

    if (isChannelGrouped) {
      if (callback) {
        callback(new Error('Channel already in group.'));
        return false;
      }
      return false;
    }

    if (state.isSignup) {
      commit('UPDATE_CHANNEL_GROUP', group);
      commit('signup/SET_CHANNEL_GROUPS', state.groups, { root: true });
    } else {
      const id = group.subscriptionGroupId;
      const oldGroup = getters.groupById(id);
      const client = rootState.client.client;

      await state.channelGroupService.upsertSubscriptionGroup(
        client,
        group,
        channelIds,
        oldGroup
      );
      const groups = await state.channelGroupService.fetchGroups(client.id);
      commit('setGroups', groups);
    }

    commit('setLoading', false);
    return true;
  },

  async deleteSubscriptionGroup({ commit, dispatch }, groupId) {
    // TODO: move API functions into separate service class.
    commit('setLoading', true);
    commit('deleteSubscriptionGroup', groupId);
    commit('setLoading', false);
  },

  async fetchGroups(
    { commit, dispatch, state, rootState },
    clientId = rootState.client.client.id
  ) {
    commit('setLoading', true);
    const groups = await state.channelGroupService.fetchGroups(clientId);
    // const groups = results.map(group=> ({ ...group, id: 'subscriptionGroupId' }));

    commit('setGroups', groups);
    commit('setLoading', false);
  },

  async deleteChannelFromGroup({ commit, dispatch }, groupId, subscriptionId) {
    commit('setLoading', true);
    commit('deleteChannelFromGroup', groupId, subscriptionId);
    commit('setLoading', false);
  },
};

export const mutations = {
  setLoading: _.set('isLoading'),
  setGroups: _.set('groups'),

  SET_IS_SIGNUP(state, isSignup) {
    state.isSignup = isSignup;
  },

  SET_GROUP_SCHEDULE(state, { group, schedule }) {
    group.rawSchedule = schedule;
  },

  SET_GROUPS(state, apiGroups) {
    const groups = apiGroups.map((group) => {
      return { ...group, subscriptionGroupId: group.id };
    });
    state.groups = groups;
  },

  UPDATE_CHANNEL_GROUP(state, channelGroup) {
    const id = channelGroup.subscriptionGroupId;
    const index = state.groups.findIndex(
      (group) => group.subscriptionGroupId === id
    );

    if (index > -1) {
      state.groups[index] = channelGroup;
    } else {
      state.groups.push(channelGroup);
    }
    state.groups = [...state.groups];
  },

  // TODO: make case consistent in mutations.

  deleteSubscriptionGroup(state, id) {
    state.groups = state.groups.filter((group) => {
      return group.subscriptionGroupId !== id;
    });
  },

  deleteChannelFromGroup(state, { groupId, subscriptionId }) {
    const group = state.groups.find((group) => {
      return group.id === groupId;
    });
    group.subscriptions = group.subscriptions.filter((sub) => {
      return sub.subscriptionId !== subscriptionId;
    });
  },
};
