<template>
  <el-dialog
    id="reactivationDialog"
    v-model="showDialog"
    :before-close="hideDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="dialogWidth"
  >
    <WelcomeEligible v-show="isCorrectStep(welcomeStep)" @click-reactivate="startActivationProcess" />
    <WelcomeIneligible v-show="isCorrectStep(ineligibleStep)"  />
    <ChannelSelection
      v-if="isCorrectStep(channelSelectionStep)"
      @click-next="goTo(depositStep)"
      @request-close="setShowDialog(false)"
    />
    <ReactivationDepositStep
      v-if="isCorrectStep(depositStep)"
      @click-back="startActivationProcess"
      @card-needed="goTo(addCardStep)"
      @reactivation-attempted="verifyReactivation"
    />
    <AddPaymentMethod
      v-if="isCorrectStep(addCardStep)"
      :client-id="clientId"
      @close="goTo(depositStep)"
    />
    <ReactivationComplete v-show="isCorrectStep(successStep)" @click-finish="completeReactivation" />
    <ReactivationFailed v-show="isCorrectStep(failureStep)" @retry-requested="startActivationProcess"/>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WelcomeEligible from './steps/WelcomeEligible.vue';
import WelcomeIneligible from './steps/WelcomeIneligible.vue';
import ChannelSelection from './steps/ChannelSelection.vue';
import ReactivationComplete from './steps/ReactivationComplete.vue';
import ReactivationDepositStep from './steps/ReactivationDepositStep.vue';
import ReactivationFailed from './steps/ReactivationFailed.vue';
import AddPaymentMethod from './steps/AddPaymentMethod.vue';

export default {
  name: 'Reactivation',
  components: {
    WelcomeEligible,
    WelcomeIneligible,
    ChannelSelection,
    ReactivationDepositStep,
    ReactivationComplete,
    ReactivationFailed,
    AddPaymentMethod,
   },
  data: () => ({
    currentStep: 'welcome',
    welcomeStep: 'welcome',
    ineligibleStep: 'ineligible',
    channelSelectionStep: 'channelSelection',
    depositStep: 'payment',
    addCardStep: 'addCard',
    successStep: 'success',
    failureStep: 'failure',
  }),
  computed: {
    ...mapGetters('client', ['clientId', 'isEligibleForReactivation']),
    ...mapGetters('reactivation', ['showDialog', 'hasBeenReactivated']),
    ...mapGetters('responsive', ['isXS', 'isMD']),

    dialogWidth() {
      if (this.isXS) {
        return '100%';
      }
      if (this.isMD) {
        return '75%';
      }
      return '50%';
    },
    isCorrectStep() {
      return (desiredStep) => desiredStep === this.currentStep;
    },
  },
  watch: {
    showDialog(show) {
      if (show) {
        this.updatePaymentInfo();
      }
    },
  },
  async mounted() {
    this.currentStep = this.isEligibleForReactivation
      ? this.welcomeStep
      : this.ineligibleStep;
    await this.updatePaymentInfo();
  },
  methods: {
    ...mapActions('reactivation', ['setShowDialog', 'getReactivationPaymentInfoAsync']),

    async updatePaymentInfo() {
      await this.getReactivationPaymentInfoAsync(this.clientId);
    },
    hideDialog() {
      this.setShowDialog(false);
      if (this.hasBeenReactivated) {
        this.$router.go();
      }
    },
    goTo(step) {
      this.currentStep = step;
    },
    startActivationProcess() {
      this.goTo(this.channelSelectionStep);
    },
    verifyReactivation() {
      this.currentStep = this.hasBeenReactivated
        ? this.successStep
        : this.failureStep;
    },
    completeReactivation() {
        this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
