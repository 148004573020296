export default {
  ALLSTATE: [
    '5B4619CA-1725-4605-8759-8575D8D85557',
    'F07F9F09-31C6-4EB6-82FE-7FEB750EA553',
    '2412F222-B2FE-4508-A085-2C9FC79E8E10',
    '4825C4B6-3DEB-4A63-A5FF-A1F6F1AD371F',
    '49D00F8E-0AA4-46EC-BE56-710FFEC8B205',
    '956D66BB-EE6E-417E-A7DA-F108EA7F95C3',
    'E20F87C7-0B80-48A2-8CEE-81BB135C1F76',
    'A54979A4-D483-4712-82BD-FF92B19CD9DF',
    '3B6E7382-7644-40C9-8363-6BEB90E865C6',
    '472E5688-4B54-4A37-803A-581BB9FF9BA1',
    'DDDA39FF-8F77-478A-8CBE-1177740599FE',
    'A5113427-CF70-4431-B17F-27D38805AB58',
    '1C3D57B9-CC3D-451D-A105-8FABB947050C',
    '8599F4AC-4820-45CF-9ABB-9D488EB940BD',
    '32EB6191-8B5B-40DB-86AF-5E4CE51D8248',
    'A023E056-0F7C-4136-A5B9-6F0148C66E82',
    '70CD2EF7-B04E-4C8D-9D2D-2F1E6064ED13',
    '4E4247FF-7661-41F9-8B8C-D9606BF1C097',
    '022DEB49-CC3E-4527-95BA-220FEBADB5BC',
    '0165DABF-1A38-497D-B82C-440BCE6B109E',
    '37EDD44F-28C8-4BE0-AB09-23C89B81C2AA',
    '9B300149-9A62-4A66-AF93-5AE6CB5B41FD',
    '72669A15-3A23-4809-8099-359792C15598',
    '0C4398CE-9942-4754-BEF7-D7745779A617',
    '6D6DFE06-9DCF-4264-813C-46ABE4401B1A',
    'EECDB7F8-16C5-44ED-B76C-1191D38D1118',
    '20DA10AD-0B2A-4BDF-8564-6AA0DFC38AFC',
    '17A924E3-F1E9-44D0-B8A4-B6FE0CADA6FF',
    'D6E894FB-D324-44DD-85AB-7F38A0E10CC0',
  ],
  FARMERS: [
    '5F4A6439-50EC-473B-86C0-7E0B6137E89B',
    'D8D160D0-CFA3-4184-AC8A-91F357D0A83E',
    '9EF6C405-64F6-40F6-A9C2-D83CFEC909A8',
    'CA65F78C-D8C1-479A-B58A-4A80A289D870',
    'D6A571CF-671C-4046-8280-4E064BDE2736',
    'E312995C-EC21-426B-A470-222FF5763F68',
    '29451BC0-D75D-49E4-AF61-799A446A7AC7',
    '696B13A6-2130-4B7C-A29E-314EE4BB4A17',
    '7118E9DB-FB8B-4CB8-8264-32A142EC67B1',
    '23A51349-2AC8-42D5-900D-3F982FF31CFE',
    '32B74D95-07F8-4E0F-8B1B-9E785D61937F',
    '5C6FFE44-EAE0-425E-A9C8-505D7B4E962A',
    '110305AA-4449-4CF1-A5B6-47BC39BB74FA',
    '43BC3437-89E3-49C1-9413-115B54A1A14D',
    'F2286EB2-9FE7-4FB8-9055-CD895389D172',
    '9DAD077F-2380-480B-8EFC-941F9A10AC28',
    '49962113-95C6-4700-9B1B-66FD27E0546D',
    '96D2317C-70DE-4A67-AB97-30FA1DDAE031',
    '60DBE025-01B2-489F-918E-CA5751DC0EE0',
    'B7C85E06-DBD9-4644-9C98-4F45D2F60F16',
    '0947EAE7-7C3C-407D-9060-3EEDB700AADF',
    'F9BD7800-DCB0-481A-B715-CDCA530F1B1C',
    'A0F0A45C-9A9F-404B-9A9F-725DAF32AB53',
    'ACEA5617-6038-45D7-8E09-B1296DD3A684',
    '18A233CC-FBB5-4090-B42D-F4F6D52E4054',
    '6931A123-11C9-416C-A9BC-D2D99C446D5C',
    'F2875609-5351-498D-9628-5238D41775C9',
  ],
  STATEFARM: [
    '7246E420-D37A-4BC5-9926-89AAE0E4C157',
    '088DA645-BD15-4BF5-B8B0-936A4AE8BF21',
    'D3F40147-6179-4158-AD9A-9A0B56E217A2',
    '65D7719D-4562-4AC8-A237-33712D19C1EA',
    '2F1C6E8A-EAD8-42F3-9744-B39D3010BDA1',
    '5B0F1427-8C2A-42F8-AA68-83FD6FD69C58',
    'C105807C-1A07-48A3-AC37-22944B692AAE',
    'DD1830AA-2D51-4C0B-AAED-FFEE82A69E43',
    '0DCCA47E-283D-42ED-9F44-B175895EDADF',
    '7B8A61C1-3207-448A-ACDA-B865C9051DB0',
    '48BE876D-BA40-48DE-BDDC-79F9932A45E0',
    '12EBEBE5-54EE-4829-9D64-2CC054E4926B',
    'AFDD5F02-E504-4BD5-B2C6-FC04406A0FBA',
    '06A8F1CB-FBDC-487A-8DEF-7B8C237BE593',
    '44683CCC-EC82-40FF-BD45-8E66578A0B82',
    '551EFA23-A998-4B45-A7F3-AF3D60A7D2BC',
    '0367869F-99C9-4944-B94C-06E703E6677A',
  ],
}
