export default {
  data() {
    return {
      dayOfWeek: [
        {
          value: '0',
          label: 'Sunday',
        },
        {
          value: '1',
          label: 'Monday',
        },
        {
          value: '2',
          label: 'Tuesday',
        },
        {
          value: '3',
          label: 'Wednesday',
        },
        {
          value: '4',
          label: 'Thursday',
        },
        {
          value: '5',
          label: 'Friday',
        },
        {
          value: '6',
          label: 'Saturday',
        },
      ],
    }
  },
}
