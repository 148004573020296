import _ from '@/utils/store-helpers';

export const state = {
  all: [],
  top: [],
  loading: false,
};

export const getters = {
  vendors: (state) => state.all,

  topVendors: (state) => state.top,
};

export const actions = {
  async fetchAllVendors({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/Vendors',
      errorMessage: 'Failed to get vendors.',
      loadingMutation: 'vendors/setLoading',
    });

    if (!response) return;

    commit('setAll', response.data);
  },

  async fetchTopVendors({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/Vendors/top',
      errorMessage: 'Failed to get top vendors.',
      loadingMutation: 'vendors/setLoading',
    });

    if (!response) return;

    commit('setTop', response.data);
  },

  reset({ commit }) {
    commit('setAll', []);
    commit('setTop', []);
  },
};

export const mutations = {
  setAll: _.set('all'),

  setTop: _.set('top'),

  setLoading: _.set('loading'),
};
