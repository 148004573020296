export function consolidateGeoMapEstimates(
  packages,
  selectedEstimates,
  addedGeosMap,
  removedGeosMap
) {
  const consolidated = {}

  for (const pkg of packages) {
    const regionMap = selectedEstimates[pkg.id] || {}
    const regions = Object.keys(regionMap)
    const selectedGeos = Object.values(regionMap).flatMap((x) => x)
    const addedGeos = addedGeosMap[pkg.id] || []
    const removedGeos = removedGeosMap[pkg.id] || []
    const uniqueGeos = dedupeGeos(selectedGeos)
    const allGeos = combineGeos(uniqueGeos, addedGeos, removedGeos)

    if (allGeos.length === 0) continue

    consolidated[pkg.id] = {
      package: pkg,
      regions: regions,
      geos: pruneHierarchy(allGeos),
    }
  }

  return consolidated
}

function dedupeGeos(geoMaps) {
  return geoMaps.filter((x, i, self) => {
    const firstOccurence = self.findIndex((y) => {
      return (
        x.zipCode === y.zipCode &&
        x.countyFipsCode === y.countyFipsCode &&
        x.stateCode === y.stateCode
      )
    })
    return i === firstOccurence
  })
}

function combineGeos(selected, added, removed) {
  return [...selected, ...added].filter(
    (geo) => !removed.some((removed) => removed.zipCode === geo.zipCode)
  )
}

function pruneHierarchy(geos) {
  // We don't want ZIP geos that fall within a County geo to be used.
  const counties = geos.filter((x) => !x.zipCode && !!x.countyFipsCode)
  const states = geos.filter((x) => !x.countyFipsCode)
  const zips = geos.filter((x) => !!x.zipCode)

  const prunedZips = zips.filter(
    (zip) =>
      !counties.some(
        (county) => county.countyFipsCode === zip.countyFipsCode
      ) && !states.some((state) => state.stateCode === zip.stateCode)
  )

  const prunedCounties = counties.filter(
    (county) => !states.some((state) => state.stateCode === county.stateCode)
  )

  return [...prunedZips, ...prunedCounties, ...states]
}
