import { axiosInstance } from '@/plugins/axios';
import _ from '@/utils/store-helpers';

export const state = {
  isLoading: false,
};

export const getters = {
  isLoading: (state) => state.isLoading,
};

export const mutations = {
  setHealthLoading: _.set('isLoading', false),
};

export const actions = {
  flushRedisCache({ commit }, { additionalPrefix }) {
    commit('setHealthLoading', true);
    return axiosInstance
      .post(`/api/health/flushCache/${additionalPrefix}`)
      .then((response) => {
        commit('setHealthLoading', false);
        const data = response.data;
        return data;
      })
      .catch((error) => {
        console.error(error);
        commit('setHealthLoading', false);
      });
  },
};
