import WebLeadIntervalMap from '@/components/webLeadScheduler/WebLeadIntervalMap';

export default class WebLeadScheduleManager {
  constructor(schedule = []) {
    this.schedule = schedule;
    this.scheduleMap = this.createScheduleMap(schedule);
  }

  createScheduleMap(schedule) {
    const scheduleMap = {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };
    const defaultStartTime = '00:00:00';
    const defaultEndTime = '24:00:00';

    schedule.forEach((item) => {
      const checkTime = WebLeadIntervalMap.parseTime(item.endTime);
      if (
        (item.endTime.includes('23:59') || item.endTime.includes('00:00:00')) &&
        item.startTime.includes('00:00:00')
      ) {
        scheduleMap[item.dayOfWeek].push({
          dayOfWeek: item.dayOfWeek,
          startTime: defaultStartTime,
          endTime: defaultEndTime,
          isActive: item.isActive,
        });
      } else if (checkTime.hours !== 23 && checkTime.minutes === 59) {
        // This case is for schedules that set endTime to an hour that in UTC is next day at 12:00AM
        const updateEndTime = `${checkTime.hours + 1}:00:00`;
        scheduleMap[item.dayOfWeek].push({
          dayOfWeek: item.dayOfWeek,
          startTime: item.startTime,
          endTime: updateEndTime,
          isActive: item.isActive,
        });
      } else {
        scheduleMap[item.dayOfWeek].push({
          dayOfWeek: item.dayOfWeek,
          startTime: item.startTime,
          endTime: item.endTime.includes('23:59')
            ? defaultEndTime
            : item.endTime,
          isActive: item.isActive,
        });
      }
    });
    return scheduleMap;
  }

  getDefaultSchedule() {
    const defaultSchedule = [
      {
        dayOfWeek: 'Monday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
      {
        dayOfWeek: 'Tuesday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
      {
        dayOfWeek: 'Wednesday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
      {
        dayOfWeek: 'Thursday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
      {
        dayOfWeek: 'Friday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
      {
        dayOfWeek: 'Saturday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
      {
        dayOfWeek: 'Sunday',
        startTime: '00:00:00',
        endTime: '23:59:59',
        isActive: false,
      },
    ];

    const defaultScheduleMap = this.createScheduleMap(defaultSchedule);

    // Merge the default schedule with the existing schedule if it exist
    for (const dayOfWeek in defaultScheduleMap) {
      if (this.scheduleMap[dayOfWeek].length === 0) {
        this.scheduleMap[dayOfWeek] = [
          ...(this.scheduleMap[dayOfWeek] || []), // Existing schedule or empty array if none
          ...defaultScheduleMap[dayOfWeek], // Default schedule
        ];
      }
    }

    return this.scheduleMap;
  }

  addNewActiveDefaultScheduleDay(dayOfWeek, webLeadScheduleDay) {
    const scheduleLength = webLeadScheduleDay.length;
    const recentSchedule = webLeadScheduleDay[scheduleLength - 1];
    const nextStartTime = WebLeadIntervalMap.nextTime(
      recentSchedule.endTime,
      '00:30:00'
    );
    const nextEndTime = WebLeadIntervalMap.nextTime(nextStartTime, '00:30:00');

    return {
      dayOfWeek,
      startTime: nextStartTime,
      endTime: nextEndTime,
      isActive: true,
    };
  }

  convertWebLeadScheduleToLeadTypeSchedule(
    webLeadSchedules,
    clientQuoteTypeId
  ) {
    const schedule = [];
    for (const dayOfWeek in webLeadSchedules) {
      const schedules = webLeadSchedules[dayOfWeek];
      schedules.forEach((scheduleItem) => {
        if (scheduleItem.isActive) {
          const endTime = scheduleItem.endTime.includes('24:00:00')
            ? '23:59:59.99'
            : scheduleItem.endTime;
          schedule.push({
            clientQuoteTypeId,
            dayOfWeek: scheduleItem.dayOfWeek,
            startTime: scheduleItem.startTime,
            endTime,
            isActive: true,
          });
        } else if (
          !schedule.some((item) => item.dayOfWeek === scheduleItem.dayOfWeek)
        ) {
          schedule.push({
            clientQuoteTypeId,
            dayOfWeek: scheduleItem.dayOfWeek,
            startTime: '00:00:00.00',
            endTime: '00:00:00.00',
            isActive: false,
          });
        }
      });
    }
    return schedule;
  }
}
