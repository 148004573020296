import IntervalMap from './IntervalMap'

export default class DailySchedule {
  /**
   * @param {Number} dayIndex
   * @param {Cap} dailyCap
   * @param {Boolean} isActive
   * @param {Array<DayPartSchedule>} dayPartSchedules
   */
  constructor({ dayIndex, dailyCap, isActive, dayPartSchedules = [] }) {
    this.dayIndex = dayIndex
    this.dailyCap = dailyCap
    this.isActive = isActive
    this.dayPartSchedules = dayPartSchedules
    this.dayOfWeek = DailySchedule.days[this.dayIndex]
  }

  static get DayPartSchedule() {
    return DayPartSchedule
  }

  static get Cap() {
    return Cap
  }

  static get days() {
    return [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
  }

  static defaultInstance(dayIndex, active = false) {
    return new DailySchedule({
      dayIndex: dayIndex,
      isActive: active,
      dailyCap: Cap.defaultInstance(dayIndex),
      dayPartSchedules: [DayPartSchedule.defaultInstance(dayIndex)],
    })
  }

  static parseCallResponse(responseData) {
    const rawSchedules = responseData.subscriptionDailySchedules
    const isIntervalEnabled = responseData.isIntervalCapEnabled
    const monthlyCapLimit =
      responseData.subscriptionMonthlySchedule.subscriptionMonthlyCap.capLimit

    const { parsedSchedules, intervalStep } =
      DailySchedule.parseDayPartSchedules(rawSchedules)

    return {
      parsedSchedules,
      intervalStep,
      isIntervalEnabled,
      monthlyCapLimit,
    }
  }

  static parseClientResponse(responseData) {
    const rawSchedules = responseData
    const isIntervalEnabled = false
    const monthlyCapLimit = []

    const { parsedSchedules, intervalStep } =
      DailySchedule.parseClientDayPartSchedules(rawSchedules)

    return {
      parsedSchedules,
      intervalStep,
      isIntervalEnabled,
      monthlyCapLimit,
    }
  }

  static parseDayPartSchedules(dayParts) {
    const parsedSchedules = {
      sunday: DailySchedule.defaultInstance(0),
      monday: DailySchedule.defaultInstance(1),
      tuesday: DailySchedule.defaultInstance(2),
      wednesday: DailySchedule.defaultInstance(3),
      thursday: DailySchedule.defaultInstance(4),
      friday: DailySchedule.defaultInstance(5),
      saturday: DailySchedule.defaultInstance(6),
    }

    let intervalStep = null

    for (const schedule of dayParts) {
      let dayPartSchedules = []
      if (schedule.subscriptionDayPartSchedules) {
        dayPartSchedules = schedule.subscriptionDayPartSchedules.map(
          (dayPartSchedule) => {
            const intervalCaps = (
              dayPartSchedule.subscriptionIntervalCaps || []
            ).map((intervalCap) => {
              const newCap = new Cap({
                limit: intervalCap.capLimit,
                start: intervalCap.startTime,
                end: intervalCap.endTime,
                dayIndex: schedule.dayOfWeek,
              })
              if (intervalStep === null) {
                intervalStep = IntervalMap.calculateStep(newCap)
              }
              return newCap
            })

            return new DayPartSchedule({
              concurrencyCap: dayPartSchedule.concurrencyCap,
              intervalCaps: intervalCaps,
              dayPartCap: new Cap({
                limit: dayPartSchedule.scheduleDayPartCap.capLimit,
                start: dayPartSchedule.scheduleDayPartCap.startTime,
                end: dayPartSchedule.scheduleDayPartCap.endTime,
                dayIndex: schedule.dayOfWeek,
              }),
            })
          }
        )
      }

      const dailySchedule = new DailySchedule({
        dayIndex: schedule.dayOfWeek,
        isActive: schedule.isActive,
        dayPartSchedules: dayPartSchedules,
        dailyCap: new Cap({
          limit: schedule.scheduleDailyCap.capLimit,
          start: schedule.scheduleDailyCap.startTime,
          end: schedule.scheduleDailyCap.endTime,
          dayIndex: schedule.dayOfWeek,
        }),
      })

      parsedSchedules[dailySchedule.dayOfWeek] = dailySchedule
    }

    intervalStep = intervalStep || '00:15:00'

    return { parsedSchedules, intervalStep }
  }

  static parseClientDayPartSchedules(dayParts) {
    const parsedSchedules = {
      sunday: DailySchedule.defaultInstance(0),
      monday: DailySchedule.defaultInstance(1),
      tuesday: DailySchedule.defaultInstance(2),
      wednesday: DailySchedule.defaultInstance(3),
      thursday: DailySchedule.defaultInstance(4),
      friday: DailySchedule.defaultInstance(5),
      saturday: DailySchedule.defaultInstance(6),
    }

    let intervalStep = 0

    for (const schedule of dayParts) {
      let dayPartSchedules = []
      if (schedule.subscriptionDayPartSchedules) {
        dayPartSchedules = schedule.subscriptionDayPartSchedules.map(
          (dayPartSchedule) => {
            const intervalCaps = (
              dayPartSchedule.subscriptionIntervalCaps || []
            ).map((intervalCap) => {
              const newCap = new Cap({
                limit: intervalCap.capLimit,
                start: intervalCap.startTime,
                end: intervalCap.endTime,
                dayIndex: schedule.dayOfWeek,
              })
              if (intervalStep === null) {
                intervalStep = IntervalMap.calculateStep(newCap)
              }
              return newCap
            })

            return new DayPartSchedule({
              concurrencyCap: dayPartSchedule.concurrencyCap,
              intervalCaps: intervalCaps,
              dayPartCap: new Cap({
                limit: dayPartSchedule.scheduleDayPartCap.capLimit,
                start: dayPartSchedule.scheduleDayPartCap.startTime,
                end: dayPartSchedule.scheduleDayPartCap.endTime,
                dayIndex: schedule.dayOfWeek,
              }),
            })
          }
        )
      }

      const dailySchedule = new DailySchedule({
        dayIndex: schedule.dayOfWeek,
        isActive: schedule.isActive,
        dayPartSchedules: dayPartSchedules,
        dailyCap: new Cap({
          limit: schedule.scheduleDailyCap.capLimit,
          start: schedule.scheduleDailyCap.startTime,
          end: schedule.scheduleDailyCap.endTime,
          dayIndex: schedule.dayOfWeek,
        }),
      })

      parsedSchedules[dailySchedule.dayOfWeek] = dailySchedule
    }

    return { parsedSchedules, intervalStep }
  }

  static buildRequestFromResponseCache({
    responseCache,
    isIntervalEnabled,
    dailySchedules,
    monthlyCapLimit,
  }) {
    responseCache.isIntervalCapEnabled = isIntervalEnabled
    responseCache.subscriptionMonthlySchedule.subscriptionMonthlyCap = {
      capLimit: monthlyCapLimit,
      startTime: '00:00:00',
      endTime: '00:00:00',
    }
    const oldDailySchedules = responseCache.subscriptionDailySchedules
    responseCache.subscriptionDailySchedules = []

    for (const schedule of dailySchedules) {
      const oldDailySchedule = oldDailySchedules.find(
        (s) => s.dayOfWeek === schedule.dayIndex
      )
      let scheduleDailyCap = {
        capLimit: null,
        startTime: '00:00:00',
        endTime: '23:59:59',
      }
      if (oldDailySchedule && oldDailySchedule.scheduleDailyCap) {
        scheduleDailyCap = oldDailySchedule.scheduleDailyCap
      }
      const subscriptionDailySchedule = {
        dayOfWeek: schedule.dayIndex,
        isActive: schedule.isActive,
        scheduleDailyCap: scheduleDailyCap,
        subscriptionDayPartSchedules: [],
      }

      for (const dayPart of schedule.dayPartSchedules) {
        const subscriptionDayPartSchedule = {
          concurrencyCap: dayPart.concurrencyCap,
          subscriptionIntervalCaps: [],
          scheduleDayPartCap: {
            capLimit: dayPart.dayPartCap.limit,
            startTime: dayPart.dayPartCap.start,
            endTime: dayPart.dayPartCap.end,
          },
        }

        for (const intervalCap of dayPart.intervalCaps) {
          subscriptionDayPartSchedule.subscriptionIntervalCaps.push({
            capLimit: intervalCap.limit,
            startTime: intervalCap.start,
            endTime: intervalCap.end,
          })
        }

        subscriptionDailySchedule.subscriptionDayPartSchedules.push(
          subscriptionDayPartSchedule
        )
      }

      responseCache.subscriptionDailySchedules.push(subscriptionDailySchedule)
    }

    return responseCache
  }

  static buildClientRequestFromResponseCache({
    responseCache,
    clientId,
    dailySchedules,
  }) {
    responseCache = []

    for (const schedule of dailySchedules) {
      const scheduleDailyCap = {
        capLimit: null,
        startTime: '00:00:00',
        endTime: '23:59:59',
      }

      const subscriptionDailySchedule = {
        dayOfWeek: schedule.dayIndex,
        isActive: schedule.isActive,
        scheduleDailyCap: scheduleDailyCap,
        subscriptionDayPartSchedules: [],
      }

      for (const dayPart of schedule.dayPartSchedules) {
        const subscriptionDayPartSchedule = {
          concurrencyCap: dayPart.concurrencyCap,
          subscriptionIntervalCaps: [],
          scheduleDayPartCap: {
            capLimit: dayPart.dayPartCap.limit,
            startTime: dayPart.dayPartCap.start,
            endTime: dayPart.dayPartCap.end,
          },
        }

        subscriptionDailySchedule.subscriptionDayPartSchedules.push(
          subscriptionDayPartSchedule
        )
      }

      responseCache.push(subscriptionDailySchedule)
    }

    return { responseCache: responseCache, clientId: clientId }
  }

  static buildRequest({ isIntervalEnabled, dailySchedules, monthlyCapLimit }) {
    const requestBody = {
      isIntervalCapEnabled: isIntervalEnabled,
      subscriptionMonthlySchedule: {
        subscriptionMonthlyCap: {
          capLimit: monthlyCapLimit,
          startTime: '00:00:00',
          endTime: '00:00:00',
        },
      },
      subscriptionDailySchedules: !dailySchedules
        ? []
        : dailySchedules.map((schedule) => ({
            dayOfWeek: schedule.dayIndex,
            isActive: schedule.isActive,
            scheduleDailyCap: {
              capLimit: null,
              startTime: '00:00:00',
              endTime: '23:59:59',
            },
            subscriptionDayPartSchedules: !schedule.dayPartSchedules
              ? []
              : schedule.dayPartSchedules.map((dayPart) => ({
                  concurrencyCap: dayPart.concurrencyCap,
                  scheduleDayPartCap: {
                    capLimit: dayPart.dayPartCap.limit,
                    startTime: dayPart.dayPartCap.start,
                    endTime: dayPart.dayPartCap.end,
                  },
                  subscriptionIntervalCaps: !dayPart.intervalCaps
                    ? []
                    : dayPart.intervalCaps.map((intervalCap) => ({
                        capLimit: intervalCap.limit,
                        startTime: intervalCap.start,
                        endTime: intervalCap.end,
                      })),
                })),
          })),
    }

    return requestBody
  }
}

export class DayPartSchedule {
  /**
   * @param {Cap} concurrencyCap
   * @param {Cap} dayPartCap
   * @param {Array<Cap>} intervalCaps
   */
  constructor({ concurrencyCap, dayPartCap, intervalCaps }) {
    this.concurrencyCap = concurrencyCap
    this.dayPartCap = dayPartCap
    this.intervalCaps = intervalCaps
  }

  static defaultInstance(dayIndex) {
    return new DayPartSchedule({
      concurrencyCap: 1,
      dayPartCap: Cap.defaultInstance(dayIndex),
      intervalCaps: [],
    })
  }
}

export class Cap {
  constructor({ limit, start = '00:00:00', end = '00:00:00', dayIndex = 0 }) {
    this.limit = limit
    this.start = start
    this.end = end
    this.dayIndex = dayIndex
  }

  get dayOfWeek() {
    return DailySchedule.days[this.dayIndex]
  }

  static defaultInstance(dayIndex) {
    return new Cap({
      limit: 0,
      start: '09:00:00',
      end: '17:00:00',
      dayIndex: dayIndex,
    })
  }
}
