export const WarmTransfer = Object.freeze({
  value: 'WarmTransfer',
  label: 'Warm Transfer',
})

export const ColdTransfer = Object.freeze({
  value: 'ColdTransfer',
  label: 'Direct Transfer',
})

export default {
  data() {
    return {
      callTypes: [{ ...WarmTransfer }, { ...ColdTransfer }],
    }
  },
}
