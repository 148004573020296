import { axiosInstance } from '@/plugins/axios';

import _ from '@/utils/store-helpers';

// initial state
export const state = {
  promos: [],
  promotions: [],
  loading: false,
};

// getters
export const getters = {};

// mutations
export const mutations = {
  setPromos: _.set('promos', false),
  setPromotions: _.set('promotions'),

  SET_PROMOTIONS(state, newValue) {
    state.promotions = newValue;
  },

  promotionList(state) {
    return state.promotions;
  },

  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
};

// actions
export const actions = {
  async getPromos({ commit }) {
    try {
      var results = await axiosInstance.get(`/api/clients/promos`);
      commit('setPromos', results.data);
      return results.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getCurrentPromotions({ commit, state }, parentCompany) {
    const response = await axiosInstance
      .get(`/api/sales/promotions/${parentCompany}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      return;
    }

    commit('SET_PROMOTIONS', response.data);
  },
  async insertPendingPromo({ commit, dispatch }, promoDetails) {
    commit('SET_LOADING', true);
  },
};
