<template>
  <el-form v-if="card" id="cardForm" autocomplete="off" method="POST">
    <div v-show="isStaff" class="alert alert-warning text-center" role="alert">
      <i class="fal fa-phone-volume"></i> <b>Important!</b> Pause your call
      recording through the Five9 widget when taking payment information.
      Remember to resume recording directly after taking personal card
      information.
    </div>

    <div>
      <div>Enter your card information:</div>
      <CardFormPartial
        :card-data="card"
        :is-edit-card-mode="isEditCardMode"
      ></CardFormPartial>
    </div>
    <div v-if="showPrimarySelect" class="row">
      <div class="col-md-12">
        <div class="form-group">
          <el-checkbox
            v-model="isDefaultPaymentCard"
            class
            label="Use this card as primary payment method."
          ></el-checkbox>
        </div>
      </div>
    </div>

    <div class="text-right pb-4">
      <el-button @click="handleClose">Cancel</el-button>
      <el-button
        v-if="!isEditCardMode"
        :disabled="addBtnDisabled"
        :loading="isSaving"
        type="primary"
        @click="addCreditCard(card)"
        >Save</el-button
      >
      <el-button
        v-else
        :disabled="addBtnDisabled"
        :loading="isSaving"
        type="primary"
        @click="editCreditCard()"
        >Save</el-button
      >
    </div>
  </el-form>
</template>

<script>
/* global Accept */
import { axiosInstance } from '@/plugins/axios';
import { authComputed } from '@/store/helpers';
import EventBus from '@/plugins/event-bus';
import CardFormPartial from '@/components/billing/CardFormPartial';
// import moment from 'moment';

export default {
  name: 'CardForm',
  components: {
    CardFormPartial,
  },
  props: {
    isEditCardMode: {
      type: Boolean,
      required: true,
    },
    showPrimarySelect: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      card: null,
      isSaving: false,
      isDefaultPaymentCard: false,
    };
  },
  computed: {
    ...authComputed,
    addBtnDisabled() {
      return (
        typeof this.card.firstName === 'undefined' ||
        this.card.firstName === '' ||
        typeof this.card.lastName === 'undefined' ||
        this.card.lastName === '' ||
        (!this.isEditCardMode &&
          (typeof this.card.cardNumberDisplay === 'undefined' ||
            this.card.cardNumberDisplay === '')) ||
        typeof this.card.expiration === 'undefined' ||
        this.card.expiration === '' ||
        typeof this.card.address1 === 'undefined' ||
        this.card.address1 === '' ||
        typeof this.card.city === 'undefined' ||
        this.card.city === '' ||
        typeof this.card.state === 'undefined' ||
        this.card.state === '' ||
        typeof this.card.zip === 'undefined' ||
        this.card.zip === ''
      );
    },
  },
  methods: {
    setCard(card) {
      this.card = card;

      if (this.isEditCardMode) {
        card.cardNumberDisplay = card.cardNumber; // displaying CC card number in a separate value
      }

      this.setAsDefaultPaymentCard();
    },

    handleClose() {
      this.card = null;

      this.cancelCallback();
    },
    setCardDefaults() {
      if (this.isDefaultPaymentCard === true) {
        this.card.status = 1; // 1 = ACTIVE
      }
    },
    cancelCallback() {
      this.$emit('cancel');
    },
    saveCardCallback(updatedCard) {
      this.$emit('save', updatedCard);
    },
    editCreditCard() {
      this.isSaving = true;

      this.setCardDefaults();

      // Set exp date
      let monthExpires = '';
      let yearExpires = '';

      if (this.card.expiration !== null && this.card.expiration !== '') {
        const expirationDate = new Date(this.card.expiration);
        monthExpires = expirationDate.getMonth() + 1;
        monthExpires = monthExpires.toString().padStart(2, '0');
        yearExpires = expirationDate.getFullYear();

        this.card.monthExpires = monthExpires;
        this.card.yearExpires = yearExpires;

        this.card.expMonthYear =
          this.card.monthExpires +
          '/' +
          this.card.yearExpires.toString().substr(2, 4);
      }

      return axiosInstance
        .put(`/api/Billing/card`, this.card)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('toastr/success', 'Card successfully updated');
            this.isSaving = false;
            EventBus.$emit('billingProductUpdated');

            this.saveCardCallback(response.data.card);
          } else {
            this.$store.dispatch(
              'toastr/error',
              'Oops there was an error updating your card information: ' +
                response.data
            );
            this.isSaving = false;
          }
          return response.data;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            this.$store.dispatch('toastr/error', 'Error: Network Error');
          } else {
            this.$store.dispatch('toastr/error', err.response.data);
          }
          this.isSaving = false;
          return Promise.reject(err);
        });
    },
    async addCreditCard(card) {
      this.isSaving = true;
      this.setCardDefaults();

      await this.$store.dispatch('billing/getAuthNetData').then((data) => {
        const authData = {
          clientKey: data.clientKey,
          apiLoginID: data.apiLoginID,
        };
        const cardData = {};
        cardData.cardNumber = card.cardNumber;
        const expirationDate = new Date(this.card.expiration);
        let monthExpires = '';
        let yearExpires = '';
        monthExpires = expirationDate.getMonth() + 1;
        monthExpires = monthExpires.toString().padStart(2, '0');
        yearExpires = expirationDate.getFullYear();
        this.card.monthExpires = monthExpires;
        this.card.yearExpires = yearExpires;

        cardData.month = this.card.monthExpires;
        cardData.year = this.card.yearExpires;
        cardData.zip = this.card.zip;

        const secureData = {};
        secureData.authData = authData;
        secureData.cardData = cardData;

        Accept.dispatchData(secureData, this.sendTokenDataToQW);
      });
    },
    sendTokenDataToQW(response) {
      if (response.messages.resultCode === 'Error') {
        let i = 0;
        let errorMessages = '';
        while (i < response.messages.message.length) {
          errorMessages +=
            response.messages.message[i].code +
            ': ' +
            response.messages.message[i].text;
          i = i + 1;
        }
        this.$store.dispatch('toastr/error', i + ' Error(s): ' + errorMessages);
        this.isSaving = false;
      } else {
        this.addCardFormUpdate(response.opaqueData);
      }
    },
    addCardFormUpdate(opaqueData) {
      this.card.cardNumber = opaqueData.dataValue;

      return axiosInstance
        .post(`/api/billing/card`, this.card)
        .then((response) => {
          if (response.status === 200) {
            // refresh cards list
            this.$store.dispatch('billing/getCards', {
              id: this.card.clientId,
            });
            this.$store.dispatch('toastr/success', 'Card successfully added');
            this.isSaving = false;
            EventBus.$emit('billingProductUpdated');

            this.saveCardCallback(response.data.card);
          } else {
            this.$store.dispatch(
              'toastr/error',
              'Oops there was an error adding your card: ' +
                response.data.message
            );
            this.isSaving = false;
          }
          return response.data;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            this.$store.dispatch('toastr/error', 'Error: Network Error');
          } else {
            this.$store.dispatch('toastr/error', err.response.data);
          }
          this.isSaving = false;
          return Promise.reject(err);
        });
    },
    setAsDefaultPaymentCard() {
      if (
        !this.isEditCardMode ||
        this.card.status === 'ACTIVE' ||
        this.card.status === '1'
      ) {
        this.isDefaultPaymentCard = true;
        return;
      }
      this.isDefaultPaymentCard = false;
    },
  },
};
</script>
