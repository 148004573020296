import axiosInstance from '@/plugins/axios';

export class SchedulerService {
  async saveChannelGroupSchedules(group, callback) {
    const response = await axiosInstance
      .post(`/api/LeadType/calls/subscriptionGroup/setSchedule`, group)
      .catch((err) => err);

    if (response instanceof Error) {
      console.error(response);
      if (callback) callback(new Error('Failed to update schedule'));
    }

    // dispatch('updateChannelGroupSchedulesResponseCache', groupId);
    // this.updateChannelGroupSchedulesResponseCache(group);
  }

  async updateChannelGroupSchedulesResponseCache(group) {
    const groupId = group.responseCache.subscriptionGroupId;
    const response = await axiosInstance
      .get(`/api/LeadType/calls/SubscriptionGroupSchedule/${groupId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Nothing to do but log details to an api for later review...
      console.error(response);
    }

    // TODO: set response cache result
    // commit('setResponseCache', response.data);
  }
}
