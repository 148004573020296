<template>
  <el-dialog
    v-if="hasNotification"
    :model-value="visible"
    :before-close="handleClose"
    width="30%"
  >
    <template #header>
      <span v-if="priorityNotification.headline">
        {{ priorityNotification.headline }}
      </span>
    </template>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="priorityNotification.content" />
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NotificationDialog',
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    ...mapGetters('notifications', ['dialogNotifications']),

    hasNotification() {
      return this.dialogNotifications && this.dialogNotifications.length > 0
    },

    priorityNotification() {
      return this.dialogNotifications && this.dialogNotifications[0]
    },
  },
  watch: {
    priorityNotification() {
      this.visible = true
    },
  },
  methods: {
    ...mapActions('notifications', ['dismissNotification']),

    handleClose() {
      this.visible = false
      this.dismissNotification(this.priorityNotification.id)
    },
  },
}
</script>
