import * as yup from 'yup';

const addCustomValidators = () => {
  yup.addMethod(yup.string, 'qwEmail', function () {
    return this.test({
      name: 'qwEmail',
      message: 'Invalid email format',
      test: (value) => {
        if (!value) return true;
        const splits = value.split('@');
        if (splits.length !== 2) return false;

        if (splits[0].length < 1) return false;

        const domainSplits = splits[1].split('.');

        if (domainSplits.length < 2) return false;

        if (domainSplits[0].length < 1) return false;

        // 2 or more characters for domain are required
        if (!/^[a-zA-Z ]{2,}$/.test(domainSplits[domainSplits.length - 1]))
          return false;

        return true;
      },
    });
  });

  yup.addMethod(yup.string, 'accountName', function () {
    return this.test({
      name: 'accountName',
      message: ({ label }) => `${label || 'Value'} may only contain letters, numbers, space, or these special characters: ().-_'`,
      test: (value) => {
        return /^([A-Z0-9 \-.'()_]+)$/i.test(value);
      },
    });
  })
};

export default addCustomValidators;
