<template>
  <LayoutRoot class="layout-root">
    <header class="qw-header dark" :class="headerContainerClass">
      <HamburgerMenuIcon v-if="isAtMostMD" @clicked="handleMenuOpenState" />
      <div class="brand-box">
        <router-link class="header-brand" to="/">
          <img
            alt="QuoteWizard logo"
            class="header-brand-img"
            src="@/assets/qw-lt-white.svg"
          />
        </router-link>
      </div>
      <slot v-if="!isAtMostMD" name="hidable-header" />
      <slot name="header" />
      <div
        v-if="isAtMostMD"
        :class="navDrawerDisplayClass"
        class="nav-drawer"
        @click.self="handleMenuOpenState"
      >
        <slot name="nav-drawer"></slot>
      </div>
    </header>
    <div class="page-content">
      <slot />
    </div>
  </LayoutRoot>
</template>

<script>
import HamburgerMenuIcon from '@/components/Navigation/HamburgerMenuIcon.vue';
import LayoutRoot from '@/layouts/root.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderedPageLayout',
  components: {
    HamburgerMenuIcon,
    LayoutRoot,
  },
  computed: {
    ...mapGetters('responsive', [
      'isXS',
      'isMD',
      'isXL',
      'isNavDrawerOpen',
      'isAtMostMD',
    ]),
    headerContainerClass() {
      return {
        min: this.isAtMostMD,
        wide: this.isMD,
      };
    },
    navDrawerDisplayClass() {
      return this.isNavDrawerOpen ? 'active' : '';
    },
  },
  methods: {
    handleMenuOpenState() {
      this.$store.dispatch('responsive/flipNavMenuOpenState');
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-root {
  position: relative;
  margin-top: 60px;
}

.qw-header {
  padding: 0 2.5rem;
  flex-shrink: 0;
  display: flex;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;

  &.min {
    position: fixed;
    height: 4.5rem;
    width: 100%;
    z-index: 99;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 7.5vw 1fr min-content;
    grid-gap: 1.5vw;
    align-items: center;
    padding: 0 20px;

    &.wide {
      height: 4rem;
      padding: 0 3.5vw;

      & + .page-content {
        margin-top: 4rem;
      }

      .header-brand {
        margin: 0.5rem 0;
      }
    }

    & + .page-content {
      margin-top: 4.5rem;
    }

    &:deep() .el-menu {
      display: contents;

      &::before,
      &::after {
        content: unset;
      }
    }

    &:deep() .el-submenu__title {
      box-sizing: content-box;
      border: none;
    }

    .header-brand {
      margin: 1rem 0;

      .header-brand-img {
        margin-right: 0;
        top: unset;
        transform: unset;
      }
    }
  }

  &:deep() .el-menu + .el-menu {
    padding-left: 1.25rem;
  }

  .brand-box {
    flex-grow: 1;

    .header-brand-img {
      margin: 0;
      top: calc(100% - 0.5rem);
      transform: translateY(-100%);
      position: relative;
    }
  }
}

.page-content {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
}

.nav-drawer {
  height: calc(100vh - 100%);
  width: 100%;
  position: absolute;
  top: 100%;
  left: -100%;
  padding: 2rem;
  background: rgba(39, 61, 80, 0.75);
  z-index: 5;
  transition: left linear 0.5s;

  &.active {
    left: 0;
    overflow-y: auto;
  }
}
</style>
