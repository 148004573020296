const mapStateSettingsToOverrides = (settings, selectedStates) => {
  return (
    settings
      ?.filter((override) =>
        selectedStates.includes(override.stateAbbreviation)
      )
      ?.map((override) => ({
        price: { minBid: override.minBid, maxBid: override.maxBid },
        state: override.stateAbbreviation,
        isActive: override.isActivated,
      })) ?? []
  );
};

const campaignService = {
  mapStateSettingsToOverrides,
};

export default campaignService;
