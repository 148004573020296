import axiosInstance from '@/plugins/axios';

const defaultState = Object.freeze({
  showDialog: true,
  paymentInfo: {
    creditCards: [],
    reactivationProducts: [],
  },
  selectedReactivationOptions: {
    // Web: {
    //   cardId: 0,
    //   depositAmount: 0,
    //   channels: {
    //     [id]: bool // Is active
    //   }
    // },
  },
  isProcessingReactivation: false,
  hasBeenReactivated: null,
});

/**
 * @typedef {{showDialog: boolean, paymentInfo: ReactivationPaymentInfo, selectedReactivationOptions: {}, isProcessingReactivation: boolean, hasBeenReactivated: boolean}} ReactivationState
 */
/**
 * @typedef {{creditCards: {id: number, display: string}[], reactivationProducts: ReactivationProduct[]}} ReactivationPaymentInfo
 */
/**
 * @typedef {{cardId: number, channels: ReactivationChannel[], productType: 'Web'|'Call'}} ReactivationProduct
 */
/**
 * @typedef {{id: string, name: string, price: number, hasActivePackage: boolean}} ReactivationChannel
 */

/**
 * @type {ReactivationState}
 */
export const state = {
  ...defaultState,
};

const reactivationChannelSortFunction = (a, b, activeChannelsObj) => {
  const getSortFromBools = (x, y) => (x === y ? 0 : x ? -1 : 1);
  const isChannelActive = (id) => !!activeChannelsObj[id];

  const activePkg = getSortFromBools(a.hasActivePackage, b.hasActivePackage);
  if (activePkg !== 0) {
    return activePkg;
  }

  const reactivating = getSortFromBools(
    isChannelActive(a.id),
    isChannelActive(b.id)
  );
  return reactivating !== 0
    ? reactivating
    : (a.name > b.name) - (a.name < b.name);
};

export const mutations = {
  SET_SHOW_DIALOG(state, show) {
    state.showDialog = show;
    sessionStorage.setItem('showReactivationDialog', show);
  },
  /**
   * @param {ReactivationState} state
   * @param {ReactivationPaymentInfo} info
   */
  SET_PAYMENT_INFO(state, info) {
    state.paymentInfo = info;

    const emptyOptions = {
      cardId: 0,
      depositAmount: 0,
      channels: {},
    };

    const filteredOptions = Object.fromEntries(
      info.reactivationProducts.map((x) => [
        x.productType,
        {
          ...emptyOptions,
          cardId: x.cardId,
          channels: Object.fromEntries(
            x.channels.map((x) => [x.id, x.isActive])
          ),
        },
      ])
    );

    // Make sure selected reactivation options doesn't include any options for products no longer available for reactivation
    for (const product in state.selectedReactivationOptions) {
      if (info.reactivationProducts.some((p) => p.productType === product)) {
        filteredOptions[product] = Object.assign(
          filteredOptions[product],
          state.selectedReactivationOptions[product]
        );
      }
    }

    state.selectedReactivationOptions = filteredOptions;

    for (const product of state.paymentInfo.reactivationProducts) {
      product.channels.sort((a, b) =>
        reactivationChannelSortFunction(
          a,
          b,
          state.selectedReactivationOptions[product.productType].channels
        )
      );
    }
  },
  SET_HAS_BEEN_REACTIVATED(state, newValue) {
    state.hasBeenReactivated = newValue;
  },
  SET_IS_PROCESSING_REACTIVATION(state, newValue) {
    state.isProcessingReactivation = newValue;
  },
  SET_SELECTED_REACTIVATION_OPTIONS(state, reactivationOptions) {
    state.selectedReactivationOptions = reactivationOptions;
  },
  SET_SELECTED_REACTIVATION_OPTIONS_FOR_PRODUCT(
    state,
    { product, depositDetails }
  ) {
    state.selectedReactivationOptions[product] = {
      ...state.selectedReactivationOptions[product],
      ...depositDetails,
    };
  },
  SET_SELECTED_ACTIVE_CHANNEL(state, { productType, channelId, isActive }) {
    state.selectedReactivationOptions[productType].channels[channelId] =
      isActive;

    state.paymentInfo.reactivationProducts
      .find((x) => x.productType === productType)
      .channels.sort((a, b) =>
        reactivationChannelSortFunction(
          a,
          b,
          state.selectedReactivationOptions[productType].channels
        )
      );
  },
  CLEAR_STATE(state) {
    state = {
      ...defaultState,
      showDialog: state.showDialog,
    };
  },
};

export const getters = {
  showDialog: (state) => state.showDialog,
  paymentInfo: (state) => state.paymentInfo,
  creditCards: (state) => state.paymentInfo.creditCards,
  /**
   * @param {ReactivationState} state
   */
  productTypes: (state) => {
    // Get selected products
    const activeProducts = Object.entries(state.selectedReactivationOptions)
      .filter(([_, product]) => Object.values(product.channels).some((x) => x))
      .map(([productType, _]) => productType);

    return activeProducts;
  },
  products: (state) => state.paymentInfo.reactivationProducts,
  /**
   * @param {ReactivationState} state
   */
  webProduct: (state) =>
    state.paymentInfo.reactivationProducts.find((p) => p.productType === 'Web'),
  /**
   * @param {ReactivationState} state
   */
  callProduct: (state) =>
    state.paymentInfo.reactivationProducts.find(
      (p) => p.productType === 'Call'
    ),
  selectedReactivationOptions: (state) => state.selectedReactivationOptions,
  selectedReactivationOptionsForProduct: (state, product) => {
    return {
      cardId: 0,
      depositAmount: 0,
      ...(state.selectedReactivationOptions[product] || {}),
    };
  },
  isProcessingReactivation: (state) => state.isProcessingReactivation,
  hasBeenReactivated: (state) => state.hasBeenReactivated,
  isChannelActive: (state) => (productType, id) => {
    return !!state.selectedReactivationOptions[productType]?.channels[id];
  },
  noChannelsSelected: (state) => {
    for (const product of Object.values(state.selectedReactivationOptions)) {
      for (const isActive of Object.values(product.channels)) {
        if (isActive) {
          return false;
        }
      }
    }

    return true;
  },
  getActiveChannelCountForProduct: (state) => (productType) => {
    return Object.values(
      state.selectedReactivationOptions[productType].channels
    ).filter((x) => x).length;
  },
  getWebChannelCount: (state) =>
    state.paymentInfo?.reactivationProducts?.find(
      (p) => p.productType === 'Web'
    )?.channels.length ?? 0,
  getCallChannelCount: (state) =>
    state.paymentInfo?.reactivationProducts?.find(
      (p) => p.productType === 'Call'
    )?.channels.length ?? 0,
};

export const actions = {
  async init({ commit, rootGetters }) {
    const isStaff = rootGetters['auth/isStaff'];
    const showDialog = sessionStorage.getItem('showReactivationDialog');

    if (showDialog === 'false' || isStaff) {
      commit('SET_SHOW_DIALOG', false);
    }
  },

  setShowDialog({ commit }, show) {
    commit('SET_SHOW_DIALOG', show);
  },

  async getReactivationPaymentInfoAsync({ commit, dispatch }, clientId) {
    const response = await axiosInstance
      .get(`api/reactivation/${clientId}/reactivation-payment-info`)
      .catch((err) => err);

    if (response instanceof Error) {
      dispatch(
        'toastr/error',
        'Failed to retrieve billing info for reactivation: ' + response.message,
        { root: true }
      );
      return;
    }

    commit('SET_PAYMENT_INFO', response.data);
  },

  async sendReactivationRequestAsync(
    { commit, dispatch, state },
    clientDetails
  ) {
    await commit('SET_IS_PROCESSING_REACTIVATION', true);

    const transformedRequest = Object.fromEntries(
      Object.entries(state.selectedReactivationOptions)
        .map(([productType, product]) => {
          // Just get ids for active channels
          const channels = Object.entries(product.channels)
            .filter(([_id, isActive]) => isActive)
            .map(([id, _isActive]) => id);

          if (!channels.length) {
            return null;
          } else {
            return [
              productType,
              {
                ...product,
                channels,
              },
            ];
          }
        })
        .filter((x) => x)
    );

    const response = await axiosInstance
      .post(`api/reactivation/begin`, {
        ...clientDetails,
        products: transformedRequest,
      })
      .catch((err) => err);

    if (response instanceof Error) {
      dispatch('toastr/error', 'Failed to Reactivate: ' + response.data, {
        root: true,
      });
    } else {
      commit('SET_HAS_BEEN_REACTIVATED', response.data);
    }

    await commit('SET_IS_PROCESSING_REACTIVATION', false);
  },

  async updatePaymentIdForProductAsync({ commit, state }, { product, cardId }) {
    const depositDetails = {
      ...(state.selectedReactivationOptions[product] || {}),
      cardId,
    };

    commit('SET_SELECTED_REACTIVATION_OPTIONS_FOR_PRODUCT', {
      product,
      depositDetails,
    });
  },

  async updateDepositAmountForProductAsync(
    { commit, state },
    { product, depositAmount }
  ) {
    const depositDetails = {
      ...(state.selectedReactivationOptions[product] || {}),
      depositAmount,
    };

    await commit('SET_SELECTED_REACTIVATION_OPTIONS_FOR_PRODUCT', {
      product,
      depositDetails,
    });
  },

  async setProductsForNonCcClientsAsync({ commit }, products) {
    for (const product in products) {
      await commit('SET_SELECTED_REACTIVATION_OPTIONS_FOR_PRODUCT', {
        product,
        depositDetails: null,
      });
    }
  },

  async setChannelActiveForProduct({ commit }, productChannelInfo) {
    commit('SET_SELECTED_ACTIVE_CHANNEL', productChannelInfo);
  },
};
