import moment from 'moment';

export default class Notifications {
  static get types() {
    return {
      BANNER: 'Banner',
      DIALOG: 'Dialog',
      TRAY: 'Tray',
    };
  }

  static get alertTypes() {
    return {
      INFO: 'info',
      WARNING: 'warning',
      ERROR: 'error',
      SUCCESS: 'success',
    };
  }

  static get intervalTypes() {
    return {
      DATERANGE: 'DateRange',
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
    };
  }

  static get visibilityTypes() {
    return {
      ALL: 'All',
      AGENT: 'Agent',
      COMPANY: 'Company',
      STATE: 'State',
      STATUS: 'Status',
    };
  }

  static dismiss(notificationId) {
    const meta = JSON.parse(window.localStorage.notificationsMeta);
    meta[notificationId].isDismissed = true;
    window.localStorage.notificationsMeta = JSON.stringify(meta);
  }

  static filterSeen(notifications) {
    if (!window.localStorage.notificationsMeta) {
      window.localStorage.notificationsMeta = '{}';
    }

    const notificationsMeta = JSON.parse(window.localStorage.notificationsMeta);

    const unseenNotifications =
      notifications?.filter((n) => {
        const { lastSeen, seenCount, isDismissed, showNotification } =
          Notifications._incrementNotificationMeta(
            n.intervalType,
            n.interval,
            notificationsMeta[n.id]
          );

        notificationsMeta[n.id] = {
          lastSeen,
          seenCount,
          isDismissed,
        };

        return !isDismissed || showNotification;
      }) || [];

    window.localStorage.notificationsMeta = JSON.stringify(notificationsMeta);

    return unseenNotifications;
  }

  static _incrementNotificationMeta(intervalType, interval, notificationsMeta) {
    if (!notificationsMeta) {
      return {
        lastSeen: Date.now(),
        seenCount: 1,
        showNotification: true,
      };
    }

    let { lastSeen, seenCount, isDismissed } = notificationsMeta;
    let showNotification = false;

    const today = moment().startOf('day');
    const lastDay = moment(lastSeen).startOf('day');
    const thisWeek = moment(today).startOf('week');
    const thisMonth = moment(today).startOf('month');

    switch (intervalType) {
      case Notifications.intervalTypes.DATERANGE:
        // Once a day, for a specified number of subsequent days.
        if (lastDay < today) {
          lastSeen = Date.now();
          if (seenCount < interval) {
            isDismissed = false;
            seenCount++;
            showNotification = true;
          }
        }
        break;
      case Notifications.intervalTypes.DAILY:
        // Every day, for a specified number of times per day, with at least 1 hour in between displays.
        if (lastDay < today) {
          isDismissed = false;
          seenCount = 1;
          lastSeen = Date.now();
          showNotification = true;
        } else if (
          seenCount < interval &&
          lastSeen < Date.now() - 1 /* Hour */ * 60 * 60 * 1000
        ) {
          isDismissed = false;
          seenCount++;
          lastSeen = Date.now();
          showNotification = true;
        }
        break;
      case Notifications.intervalTypes.WEEKLY:
        // Every week, once per day, for a specified number of subsequent days.
        if (lastSeen < thisWeek) {
          isDismissed = false;
          seenCount = 1;
          lastSeen = Date.now();
          showNotification = true;
        } else if (lastDay < today && seenCount < interval) {
          isDismissed = false;
          seenCount++;
          lastSeen = Date.now();
          showNotification = true;
        }
        break;
      case Notifications.intervalTypes.MONTHLY:
        // Every month, once per week, for a specified number of subsequent weeks.
        if (lastSeen < thisMonth) {
          isDismissed = false;
          seenCount = 1;
          lastSeen = Date.now();
          showNotification = true;
        } else if (lastDay < thisWeek && seenCount < interval) {
          isDismissed = false;
          seenCount++;
          lastSeen = Date.now();
          showNotification = true;
        }
        break;
      default:
        throw new Error('Unhandled notification interval type.');
    }

    return { lastSeen, seenCount, isDismissed, showNotification };
  }
}
