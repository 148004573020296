import _ from '@/utils/store-helpers';
import { axiosInstance } from '@/plugins/axios';

export const state = {
  leads: [],
  metrics: {
    web: {},
    calls: {},
  },
  deadLetterQueueMessages: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
  },
  sortBy: '-DELIVERY_DATETIME_UTC',
  loading: false,
  visibleDateRange: [
    /* From */
    dateRangeStart(),
    /* To */
    dateRangeEnd(),
  ],
};

function dateRangeStart() {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
}

function dateRangeEnd() {
  var d = new Date();
  d.setHours(23, 59, 59, 999);
  return d;
}

export const getters = {
  delivered(state) {
    return {
      web: state.metrics.web.leadCount,
      calls: state.metrics.calls.leadCount,
    };
  },
  deadLetterQueueMessages: (state) => state.deadLetterQueueMessages,
};

export const actions = {
  async getLeads({ commit }, { clientId, search, from, to }) {
    commit('setLoading', true);
    const response = await axiosInstance
      .get(`/api/leads/${clientId}/tracking/web`, {
        params: {
          pageNum: state.pagination.currentPage,
          pageSize: state.pagination.pageSize,
          sortBy: state.sortBy,
          search,
          from,
          to,
        },
      })
      .catch((err) => {
        console.error(err);
        commit('setLoading', false);
        return Promise.reject(err);
      });

    commit('setLeads', response.data.items || []);
    commit('setPagination', response.data.metaData);

    commit('setLoading', false);
    return response.data;
  },

  async getLeadMetrics({ commit }, clientId) {
    try {
      const from = dateRangeStart();
      const today = new Date();
      const [callMetrics, webMetrics] = await Promise.all([
        axiosInstance.get(`/api/leads/${clientId}/metrics/call`, {
          params: {
            from,
            to: today,
          },
        }),
        axiosInstance.get(`/api/leads/${clientId}/metrics/web`, {
          params: {
            from,
            to: today,
          },
        }),
      ]);
      commit('setLeadMetrics', {
        type: 'calls',
        data: callMetrics.data,
      });
      commit('setLeadMetrics', {
        type: 'web',
        data: webMetrics.data,
      });
    } catch (error) {
      console.error(error);
    }
  },

  async getDeadLetterQueueMessages({ commit }) {
    try {
      const response = await axiosInstance.get(
        `/api/leads/getDeadLetterQueueMessages`
      );

      commit('setDeadLetterQueueMessages', response.data);
    } catch (error) {
      console.error(error);
    }
  },

  setCurrentPage({ commit, dispatch }, { pageNumber, getLeadsData }) {
    commit('setCurrentPage', pageNumber);
    dispatch('getLeads', getLeadsData);
  },

  setSortBy({ commit, dispatch }, { sortBy, getLeadsData }) {
    commit('setSortBy', sortBy || '-DELIVERY_DATETIME_UTC');
    dispatch('getLeads', getLeadsData);
  },

  setVisibleDateRange({ commit }, dateRange) {
    commit('setVisibleDateRange', dateRange);
  },
  reset({ commit }) {
    commit('setLeads', []);
    commit('setLeadMetrics', {
      type: 'calls',
      data: {},
    });
    commit('setLeadMetrics', {
      type: 'web',
      data: {},
    });
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setLeads: _.set('leads'),
  setPagination: _.set('pagination'),
  setSortBy: _.set('sortBy'),
  setVisibleDateRange: _.set('visibleDateRange'),
  setDeadLetterQueueMessages: _.set('deadLetterQueueMessages'),
  setCurrentPage(state, newValue) {
    state.pagination.currentPage = newValue;
  },

  setPageSize(state, newValue) {
    state.pagination.pageSize = newValue;
  },

  setLeadMetrics(state, { type, data }) {
    state.metrics[type] = data;
  },
};
