export default {
  data() {
    return {
      billingTerms: {
        '': {
          label: 'No terms selected',
        },
        CC0: {
          label: 'Charge Card on 1st',
        },
        'CC0-05': {
          label: 'Charge Card on 5th',
        },
        'CC0-15': {
          label: 'Charge Card on 15th',
        },
        'CC0-20': {
          label: 'Charge Card on 20th',
        },
        'SPC-ARR': {
          label: 'Special Arrangement',
        },
        SCK: {
          label: 'Payment By Check',
        },
        RCHRG_50: {
          label: 'Recharge $50',
          value: 50,
        },
        RCHRG_100: {
          label: 'Recharge $100',
          value: 100,
        },
        RCHRG_150: {
          label: 'Recharge $150',
          value: 150,
        },
        RCHRG_250: {
          label: 'Recharge $250',
          value: 250,
        },
        RCHRG_350: {
          label: 'Recharge $350',
          value: 350,
        },
        RCHRG_500: {
          label: 'Recharge $500',
          value: 500,
        },
        RCHRG_750: {
          label: 'Recharge $750',
          value: 750,
        },
        RCHRG_1000: {
          label: 'Recharge $1,000',
          value: 1000,
        },
        RCHRG_2500: {
          label: 'Recharge $2,500',
          value: 2500,
        },
        RCHRG_5000: {
          label: 'Recharge $5,000',
          value: 5000,
        },
        RCHRG_10000: {
          label: 'Recharge $10,000',
          value: 10000,
        },
        RCHRG_20000: {
          label: 'Recharge $20,000',
          value: 20000,
        },
        BILL_CLIENT: {
          label: 'Client Billing',
        },
        BILL_GROUP: {
          label: 'Group Billing',
        },
        BILL_ANCHOR: {
          label: 'Anchor Billing',
        },
        BILL_SFSUBS: {
          label: 'SF Subsidy',
        },
        BILL_FARMERSBMP: {
          label: 'Farmers BMP',
        },
      },
    }
  },
}
