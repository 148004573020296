<template>
  <el-row class="mt-7 pb-9">
    <el-col class="mb-2 text-center">
      <img class="py-4" src="/favicon.ico" alt="QuoteWizard Icon">
      <h2>Welcome back, {{ clientFirstName }}!</h2>
      <h5>Finish signing up to start getting leads.</h5>
      <el-button
        class="my-2"
        type="primary"
        @click="$emit('click-reactivate')"
      >
        Reactivate Your Membership
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomeEligible',
  computed: {
    ...mapGetters('client', ['clientFirstName']),
  }
}
</script>

<style scoped>
</style>
