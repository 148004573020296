import _ from '@/utils/store-helpers';

// initial state
export const state = {
  parentCompanyLoading: false,
  parentCompanyOptions: [],
  statusLoading: false,
  statusOptions: [],
  loyaltyLevelLoading: false,
  loyaltyLevelOptions: [],
};

// actions
export const actions = {
  async getParentCompanyOptions({ commit, dispatch }) {
    if (
      this.parentCompanyOptions === undefined ||
      this.parentCompanyOptions.length === 0
    ) {
      const parentCompanies = await dispatch('request/get', {
        url: `/api/Clients/AGENCY/options`,
        errorMessage: 'Failed to get parent companies',
        loadingMutation: 'options/setParentCompanyLoading',
      });
      commit('setParentCompanies', parentCompanies.data);
    }
    return this.parentCompanyOptions;
  },
  async getClientStatusOptions({ commit, dispatch }) {
    if (this.statusOptions === undefined || this.statusOptions.length === 0) {
      const statuses = await dispatch('request/get', {
        url: `/api/Clients/CLIENT_STATUS/options`,
        errorMessage: 'Failed to get statuses',
        loadingMutation: 'options/setStatusLoading',
      });

      commit('setStatusOptions', statuses.data);
    }
    return this.statusOptions;
  },

  async getLoyaltyLevelOptions({ commit, dispatch }) {
    if (
      this.loyaltyLevelOptions === undefined ||
      this.loyaltyLevelOptions.length === 0
    ) {
      const levels = await dispatch('request/get', {
        url: `/api/Clients/CLIENT_LEVEL/options`,
        errorMessage: 'Failed to get levels',
        loadingMutation: 'options/setLoyaltyLevelLoading',
      });

      commit('setLoyaltyLevelOptions', levels.data);
    }
    return this.loyaltyLevelOptions;
  },
};

// mutations
export const mutations = {
  setParentCompanyLoading: _.set('parentCompanyLoading'),
  setParentCompanies: _.set('parentCompanyOptions'),
  setStatusLoading: _.set('setStatusLoading'),
  setStatusOptions: _.set('statusOptions'),
  setLoyaltyLevelLoading: _.set('loyaltyLevelLoading'),
  setLoyaltyLevelOptions: _.set('loyaltyLevelOptions'),
};

export const getters = {
  parentCompanyOptions: (state) => state.parentCompanyOptions,
  parentCompanyLoading: (state) => state.parentCompanyLoading,
  clientStatusOptions: (state) => state.statusOptions,
  clientStatusLoading: (state) => state.statusLoading,
  loyaltyLevelOptions: (state) => state.loyaltyLevelOptions,
  loyaltyLevelLoading: (state) => state.statusLoading,
};
