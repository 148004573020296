import { axiosInstance } from '@/plugins/axios';

export const state = {
  upsells: [],
};

export const getters = {
  upsellSets: (state) => {
    const groups = {};
    state.upsells.forEach((item) => {
      const groupKey = JSON.stringify({
        clientQuoteTypeId: item.clientQuoteTypeId,
        repId: item.repId,
        upsellDate: item.upsellDate,
        upsellType: item.upsellType,
        upsellPromo: item.upsellPromo,
      });
      groups[groupKey] = groups[groupKey] || [];
      groups[groupKey].push(item);
    });

    return Object.keys(groups)
      .map((group) => {
        return {
          ...JSON.parse(group),
          upsells: groups[group],
        };
      })
      .sort((a, b) => {
        const d1 = Date.parse(a.upsellDate);
        const d2 = Date.parse(b.upsellDate);
        return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
      });
  },
};

export const mutations = {
  UPDATE_UPSELL(state, { upsell }) {
    state.upsells = [
      ...state.upsells.filter((u) => u.id !== upsell.id),
      upsell,
    ];
  },
  SET_UPSELLS(state, upsells) {
    state.upsells = upsells;
  },
};

export const actions = {
  async getUpsellsList({ commit, dispatch, rootState }) {
    const response = await axiosInstance
      .get(`/api/Clients/upsells/${rootState.client.client.id}`)
      .catch((err) => err);

    if (response instanceof Error) {
      if (!response.response) {
        dispatch('toastr/error', 'Network Error', {
          root: true,
        });
        return;
      }

      dispatch('toastr/error', response.response.data.message, {
        root: true,
      });
      return;
    }

    const upsells = response.data;
    commit('SET_UPSELLS', upsells);
  },
};
