import axiosInstance from '@/plugins/axios';
import _ from '@/utils/store-helpers';
import moment from 'moment';
import errorTracker from '@/plugins/errorTracker';

export const state = {
  loading: false,
  pagedLeads: [],
  pagedQueryData: {
    page: 1,
    pageSize: 10,
    totalRecords: 0,
    sortByColumn: 'REQUEST_DTG',
    sortOrder: 'DESC',
    start: moment().subtract(1, 'month').toDate(),
    end: moment().toDate(),
    searchTerm: null,
  },
  leadCreditDetails: null,
  topCreditReasons: [],
  lifetimeCreditRate: 0,
};

export const getters = {
  loading: (state) => state.loading,

  pagedLeads: (state) => state.pagedLeads,

  pageData: (state) => ({
    page: state.pagedQueryData.page,
    pageSize: state.pagedQueryData.pageSize,
    totalRecords: state.pagedQueryData.totalRecords,
  }),

  sortingData: (state) => ({
    sortByColumn: state.pagedQueryData.sortByColumn,
    sortOrder: state.pagedQueryData.sortOrder,
  }),

  searchData: (state) => ({
    start: state.pagedQueryData.start,
    end: state.pagedQueryData.end,
    searchTerm: state.pagedQueryData.searchTerm,
  }),

  leadCreditDetails: (state) => state.leadCreditDetails,

  topCreditReasons: (state) => state.topCreditReasons,

  lifetimeCreditRate: (state) => state.lifetimeCreditRate,
};

export const actions = {
  async getPagedLeads({ commit, dispatch, state }) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get('/api/LeadCredit', {
        params: state.pagedQueryData,
      })
      .catch((err) => err);

    if (response === null || response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', 'Failed to get pending lead credits.', {
        root: true,
      });
      commit('setLoading', false);
      return;
    }

    const { items, metaData } = response.data;

    commit('setPagedLeads', items);
    commit('setPagedQueryData', {
      ...state.pagedQueryData,
      totalRecords: metaData.totalRecords,
    });
    commit('setLoading', false);
  },

  async getLeadCreditDetails({ commit, dispatch }, sellId) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get(`/api/LeadCredit/${sellId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', 'Failed to get lead credit details.', {
        root: true,
      });
      commit('setLoading', false);
      return;
    }

    const lead = await dispatch(
      'leadDetail/getLead',
      {
        leadId: sellId,
        leadType: response.data.serviceType,
      },
      { root: true }
    );

    if (response.data.serviceType.toLowerCase() === 'web') {
      await dispatch(
        'leadDetail/getLeadPacket',
        { sessionId: lead.sessionId },
        { root: true }
      );
    } else {
      await dispatch(
        'leadDetail/getCallLeadPacket',
        { outboundCallSid: response.data.outboundCallSid },
        { root: true }
      );
    }

    commit('setLeadCreditDetails', response.data);
    commit('setLoading', false);
  },

  async processPendingCreditLeads(
    { commit, dispatch },
    { pendingCredits, action, reason, explanation, callback }
  ) {
    if (!['approve', 'deny'].includes(action)) {
      throw new Error('Invalid action provided.');
    }

    commit('setLoading', true);

    const response = await axiosInstance
      .post('/api/LeadCredit/decision', {
        pendingCredits,
        denialReason: reason,
        denialDescription: explanation,
        isApproved: action === 'approve',
      })
      .catch((err) => err);

    if (response instanceof Error) {
      errorTracker.trackException({ exception: response });
      console.error(response);
      dispatch('toastr/error', `Failed to ${action} pending credit leads.`, {
        root: true,
      });
      commit('setLoading', false);
      callback && callback(response);
      return;
    }

    commit('setLeadCreditDetails', null);

    dispatch('leadDetail/setLead', null, { root: true });

    dispatch('leadDetail/setLeadPacket', null, { root: true });

    dispatch('getPagedLeads');

    commit('setLoading', false);

    callback && callback();
  },

  async fetchTopCreditRequestReasonsAsync({ commit, dispatch }) {
    const response = await dispatch('request/get', {
      url: '/api/LeadCredit/top',
      errorMessage: 'Failed to get top credit request reasons.',
      loadingMutation: 'leadCredit/setLoading',
    });

    if (!response) return;

    commit('setTopCreditReasons', response.data);
  },

  updatePagedData({ commit, dispatch, state }, { page, pageSize }) {
    commit('setPagedQueryData', {
      ...state.pagedQueryData,
      page,
      pageSize,
    });

    dispatch('getPagedLeads');
  },

  updateSearchData({ commit, dispatch, state }, { start, end, searchTerm }) {
    commit('setPagedQueryData', {
      ...state.pagedQueryData,
      start,
      end,
      searchTerm,
    });

    dispatch('getPagedLeads');
  },

  updateSortingData({ commit, dispatch, state }, { sortByColumn, sortOrder }) {
    commit('setPagedQueryData', {
      ...state.pagedQueryData,
      sortByColumn,
      sortOrder,
    });

    dispatch('getPagedLeads');
  },

  reset({ commit }) {
    commit('setPagedLeads', []);
    commit('setLifetimeCreditRate', 0);
    commit('setTopCreditReasons', []);
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setPagedLeads: _.set('pagedLeads'),
  setPagedQueryData: _.set('pagedQueryData'),
  setLeadCreditDetails: _.set('leadCreditDetails'),
  setLifetimeCreditRate: _.set('lifetimeCreditRate'),
  setTopCreditReasons: _.set('topCreditReasons'),
};
