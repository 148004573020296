import { createApp } from 'vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import moment from 'moment';

// element plus styles override
import './styles/index.scss';
import ElementPlus from 'element-plus';
import LoadScript from 'vue-plugin-load-script';
import VueClipboard from 'vue-clipboard2';
import Toasted from 'vue-toasted';
import ClientAdminApp from './App.vue';
import errorHandler from './errorHandlers.js';
import * as filters from '@/plugins/filters';
import initSentry from '@/services/sentry';
import addCustomValidators from '@/plugins/yupCustomValidators';

export const app = createApp(ClientAdminApp)
  .use(store)
  .use(router)
  .use({
    install(vue) {
      // Polyfill for Vue 2 -> Vue 3
      vue.prototype = vue.config.globalProperties;

      // Add error handler + filters
      vue.config.errorHandler = errorHandler;
    },
  })
  .use(ElementPlus)
  .provide('moment', moment)
  .use(LoadScript)
  .use(Toasted, {
    iconPack: 'fontawesome', // set your iconPack, defaults to material. material|fontawesome|custom-class
  })
  .use(VueClipboard);
app.config.globalProperties.$filters = {};
for (const key of Object.keys(filters)) {
  app.config.globalProperties.$filters[key] = filters[key];
}

addCustomValidators();
initSentry(app, router);
app.mount('#app');
