import { axiosInstance } from '@/plugins/axios';
import _ from '@/utils/store-helpers';
import DailySchedule from '@/components/scheduler/DailySchedule';
import IntervalMap from '@/components/scheduler/IntervalMap';
import WebLeadIntervalMap from '@/components/webLeadScheduler/WebLeadIntervalMap';
import WebLeadScheduleManager from '@/components/webLeadScheduler/WebLeadScheduleManager';

const getInitialState = () => ({
  isIntervalEnabled: false,
  intervalMap: new IntervalMap(),
  responseCache: null,
  loading: false,
  dirty: true,
  monthlyCapLimit: null,
  schedules: {
    sunday: DailySchedule.defaultInstance(0),
    monday: DailySchedule.defaultInstance(1, true),
    tuesday: DailySchedule.defaultInstance(2, true),
    wednesday: DailySchedule.defaultInstance(3, true),
    thursday: DailySchedule.defaultInstance(4, true),
    friday: DailySchedule.defaultInstance(5, true),
    saturday: DailySchedule.defaultInstance(6),
  },
  webLeadSchedules: [],
  webLeadIntervalMap: new WebLeadIntervalMap(),
});

export const state = {
  isIntervalEnabled: false,
  intervalMap: null,
  responseCache: null,
  loading: true,
  dirty: true,
  monthlyCapLimit: 0,
  schedules: null,
  webLeadSchedules: [],
  webLeadIntervalMap: null,
};

export const getters = {
  schedules: (state) => state.schedules,
  responseCache: (state) => state.responseCache,
  webLeadSchedules: (state) => state.webLeadSchedules,
  webLeadIntervalMap: (state) => state.webLeadInterval,
  scheduleInfo: (state) => {
    return {
      intervalMap: state.intervalMap,
      isIntervalEnabled: state.isIntervalEnabled,
      responseCache: state.responseCache,
      monthlyCapLimit: state.monthlyCapLimit,
    };
  },

  weekdayZeroDayCapCounts: (state) => {
    const countZeroDayCaps = Object.values(state.schedules)
      .flatMap((day) => day.dayPartSchedules)
      .filter(
        (schedule) =>
          schedule.dayPartCap.limit === 0 &&
          schedule.dayPartCap.dayIndex > 0 &&
          schedule.dayPartCap.dayIndex < 6
      ).length;

    return countZeroDayCaps;
  },

  areAnySchedulesWithStartIsAfterEnd: (state) => {
    const schedules =
      Object.values(state.schedules)
        .flatMap((day) => day.dayPartSchedules)
        .filter(
          (schedule) => schedule.dayPartCap.start > schedule.dayPartCap.end
        ).length > 0;

    return schedules;
  },

  weekdayZeroConcurrencyCapCounts: (state) => {
    const countZeroConcurrency = Object.values(state.schedules)
      .flatMap((day) => day.dayPartSchedules)
      .filter(
        (schedule) =>
          schedule.concurrencyCap === 0 &&
          schedule.dayPartCap.dayIndex > 0 &&
          schedule.dayPartCap.dayIndex < 6
      ).length;

    return countZeroConcurrency;
  },

  weekendZeroConcurrencyCapCounts: (state) => {
    const countZeroConcurrency = Object.values(state.schedules)
      .flatMap((day) => day.dayPartSchedules)
      .filter(
        (schedule) =>
          schedule.concurrencyCap === 0 &&
          (schedule.dayPartCap.dayIndex === 0 ||
            schedule.dayPartCap.dayIndex === 6)
      ).length;

    return countZeroConcurrency;
  },

  saturdayDayCaps: (state) => {
    const saturdayCaps = state.schedules.saturday.dayPartSchedules.filter(
      (obj) => obj.dayPartCap.limit !== 0 || obj.dayPartCap.limit === null
    );

    return state.schedules.saturday.isActive && saturdayCaps.length > 0;
  },

  sundayDayCaps: (state) => {
    const sundayCaps = state.schedules.sunday.dayPartSchedules.filter(
      (obj) => obj.dayPartCap.limit !== 0 || obj.dayPartCap.limit === null
    );

    return state.schedules.sunday.isActive && sundayCaps.length > 0;
  },

  updateRequestBody(state) {
    return (useCache = false) => {
      const args = {
        responseCache: state.responseCache,
        isIntervalEnabled: state.isIntervalEnabled,
        dailySchedules: Object.values(state.schedules),
        monthlyCapLimit: state.monthlyCapLimit,
      };

      if (useCache) {
        return DailySchedule.buildRequestFromResponseCache(args);
      } else {
        delete args.responseCache;
        return DailySchedule.buildRequest(args);
      }
    };
  },

  isWebLeadScheduleValid(state) {
    let isScheduleValid = true;
    for (const dayOfWeek in state.webLeadSchedules) {
      const schedules = state.webLeadSchedules[dayOfWeek];
      const scheduleLength = schedules.length;

      // isScheduleValid handles 2 cases one where someone sets an end time before start time and where someone sets up and all day and day part schedule for same day
      if (scheduleLength > 0) {
        schedules.forEach((scheduleItem) => {
          if (
            scheduleItem.startTime > scheduleItem.endTime ||
            (scheduleLength > 1 &&
              scheduleItem.startTime === scheduleItem.endTime)
          ) {
            isScheduleValid = false;
          }
        });
      }
    }
    return isScheduleValid;
  },

  marshalledScheduleData: (state, _, rootState) => (clientQuoteTypeId) => {
    const buildData = {
      responseCache: state.responseCache,
      isIntervalEnabled: state.isIntervalEnabled,
      dailySchedules: Object.values(state.schedules),
      monthlyCapLimit: state.monthlyCapLimit,
    };

    const data = state.responseCache
      ? DailySchedule.buildRequestFromResponseCache(buildData)
      : DailySchedule.buildRequest(buildData);

    const { leadType } = rootState.leadType;

    return {
      ...JSON.parse(JSON.stringify(data)),
      clientQuoteTypeId,
      clientId: leadType.clientId,
      upsellRep: leadType.upsellRep,
      upsellPromo: leadType.upsellPromo,
    };
  },
};

export const actions = {
  init({ dispatch }) {
    dispatch('resetSchedulerState');
  },

  setSchedules({ commit, dispatch }, group) {
    commit('setSchedules', group.rawSchedule);
    commit('setMonthlyCapLimit', group.monthlyCapLimit);
    commit('setIsIntervalEnabled', group.isIntervalEnabled);
    // commit('setLoading', false);
    dispatch('setIntervalMap', group.intervalStep);
  },

  async setGroupSchedule({ state }, group) {
    const buildData = {
      dailySchedules: Object.values(state.schedules),
      isIntervalEnabled: state.isIntervalEnabled,
      monthlyCapLimit: state.monthlyCapLimit,
      responseCache: state.responseCache,
    };

    const postData = state.responseCache
      ? DailySchedule.buildRequestFromResponseCache(buildData)
      : DailySchedule.buildRequest(buildData);

    group.subscriptionDailySchedules = postData.subscriptionDailySchedules;
    group.subscriptionMonthlySchedule = postData.subscriptionMonthlySchedule;
    group.rawSchedule = state.schedules;
    group.responseCache = state.responseCache;

    group.isIntervalEnabled = buildData.isIntervalEnabled;
    group.isIntervalCapEnabled = group.isIntervalEnabled;
    group.monthlyCapLimit = buildData.monthlyCapLimit;
  },

  async fetchCallSchedule({ commit, dispatch, rootState }, clientQuoteTypeId) {
    commit('setLoading', true);

    const { leadType } = rootState.leadType;

    if (!leadType.callType) {
      throw new Error(
        'Fetching call schedules requires lead type module be populated.'
      );
    }

    const response = await axiosInstance
      .get(
        `/api/LeadType/calls/SubscriptionSchedule/${leadType.callType}/${clientQuoteTypeId}`
      )
      .catch((err) => err);

    if (response instanceof Error) {
      dispatch(
        'toastr/error',
        `Failed to get schedules. Please check your connection and try again.`,
        { root: true }
      );
      commit('setLoading', false);
      commit('setDirty', false);
      return;
    }

    dispatch('processCallSchedule', response.data);
  },

  getWebLeadSchedule({ commit }, leadType) {
    const leadTypeSchedule = leadType.schedule ?? [];
    const scheduleManager = new WebLeadScheduleManager(leadTypeSchedule);
    const schedules = scheduleManager.getDefaultSchedule();

    commit('setWebLeadSchedules', schedules);
  },

  async cloneCallSchedule({ commit, dispatch }, clientQuoteTypeId) {
    // Just overwrites existing
    await dispatch('fetchCallSchedule', clientQuoteTypeId);
    commit('setDirty', true);
  },

  getChannelGroupSchedule({ commit, dispatch }, group) {
    dispatch('resetSchedulerState');
    commit('setLoading', true);
    dispatch('processCallSchedule', group);
    commit('setLoading', false);
  },

  async fetchChannelGroupSchedule({ commit, dispatch }, channelGroupId) {
    dispatch('resetSchedulerState');
    commit('setLoading', true);

    const response = await axiosInstance
      .get(`/api/LeadType/calls/SubscriptionGroupSchedule/${channelGroupId}`)
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Error handling
      console.error(response);
      commit('setLoading', false);
      commit('setDirty', false);
      dispatch('toastr/error', 'Failed to retrieve schedule.', { root: true });
      return;
    }

    dispatch('processCallSchedule', response.data);
  },

  resetSchedulerState({ commit }) {
    commit('resetSchedulerState');
  },

  async getClientSchedule({ commit, dispatch }, clientId) {
    commit('setLoading', true);

    const response = await axiosInstance
      .get(`/api/Clients/${clientId}/account_schedule`)
      .catch((err) => err);

    if (response instanceof Error) {
      dispatch(
        'toastr/error',
        'Failed to get schedule. Please check your connection and try again.',
        { root: true }
      );
      // TODO: This should dispatch an action that will log this error.
      commit('setLoading', false);
      return;
    }

    const { parsedSchedules } = DailySchedule.parseClientResponse(
      response.data
    );

    commit('setSchedules', parsedSchedules);
    commit('setResponseCache', Object.seal(response.data));
    commit('setLoading', false);
  },

  processCallSchedule({ commit, dispatch }, callResponse) {
    const {
      intervalStep,
      isIntervalEnabled,
      monthlyCapLimit,
      parsedSchedules,
    } = DailySchedule.parseCallResponse(callResponse);

    commit('setSchedules', parsedSchedules);
    commit('setMonthlyCapLimit', monthlyCapLimit);
    commit('setIsIntervalEnabled', isIntervalEnabled);
    commit('setResponseCache', Object.seal(callResponse));
    commit('setLoading', false);
    dispatch('setIntervalMap', intervalStep);
    commit('setDirty', false);
  },

  async saveSchedules(
    { commit, dispatch, state, rootState, getters: { marshalledScheduleData } },
    clientQuoteTypeId
  ) {
    let success = true;
    const { leadType } = rootState.leadType;

    // Exit early if not call lead type
    if (!state.dirty || leadType?.serviceType !== 'Call') return success;

    commit('setLoading', true);

    if (!leadType.callType) {
      throw new Error(
        'Saving call schedules requires lead type module be populated.'
      );
    }

    const response = await axiosInstance
      .post(
        `/api/LeadType/schedule/call/${leadType.clientId}/${leadType.callType}/${leadType.id}`,
        marshalledScheduleData(clientQuoteTypeId)
      )

      .catch((err) => err);

    if (response instanceof Error || response.status !== 200) {
      success = false;
      dispatch(
        'toastr/error',
        'Failed to save schedules. Please check your connection and try again.',
        { root: true }
      );
      commit('setLoading', false);
      return success;
    }

    dispatch('updateSchedulesResponseCache', clientQuoteTypeId);
    commit('setLoading', false);
    commit('setDirty', false);
    return success;
  },

  saveWebLeadSchedules({ state, rootState }, clientQuoteTypeId) {
    if (rootState.leadType?.leadType?.serviceType !== 'Web') return;
    const webLeadSchedule = new WebLeadScheduleManager();
    const schedule = webLeadSchedule.convertWebLeadScheduleToLeadTypeSchedule(
      state.webLeadSchedules,
      clientQuoteTypeId
    );
    return schedule;
  },

  async saveClientSchedules({ commit, dispatch, state }, clientId) {
    if (!state.dirty) return;

    commit('setLoading', true);

    const postData = DailySchedule.buildClientRequestFromResponseCache({
      responseCache: state.responseCache,
      clientId,
      dailySchedules: Object.values(state.schedules),
    });

    const response = await axiosInstance
      .post(`/api/Clients/clientschedule`, postData)
      .catch((err) => err);

    if (response instanceof Error || response.status !== 200) {
      dispatch(
        'toastr/error',
        'Failed to save schedules. Please check your connection and try again.',
        {
          root: true,
        }
      );
      commit('setLoading', false);
      return;
    }

    dispatch('getClientSchedule', clientId);

    commit('setLoading', false);
    commit('setDirty', false);
  },

  async updateSchedulesResponseCache(
    { commit, state },
    clientQuoteTypeId = state.responseCache.clientQuoteTypeId
  ) {
    const response = await axiosInstance
      .get(
        `/api/LeadType/calls/SubscriptionSchedule/WarmTransfer/${clientQuoteTypeId}`
      )
      .catch((err) => err);

    if (response instanceof Error) {
      // TODO: Nothing to do but log details to an api for later review...
      console.error(response);
      return;
    }

    commit('setResponseCache', response.data);
  },

  resetSchedules({ commit, dispatch, state }) {
    if (!state.responseCache) {
      dispatch('resetSchedulerState');
      return;
    }

    const {
      parsedSchedules,
      isIntervalEnabled,
      intervalStep,
      monthlyCapLimit,
    } = DailySchedule.parseCallResponse(state.responseCache);

    commit('setSchedules', parsedSchedules);
    commit('setIsIntervalEnabled', isIntervalEnabled);
    commit('setMonthlyCapLimit', monthlyCapLimit);
    dispatch('setIntervalMap', intervalStep);
    commit('setDirty', false);
  },

  async addDayPartSchedule(
    { commit, dispatch, state },
    { day, intervalStep = null }
  ) {
    if (intervalStep === null) {
      intervalStep = state.intervalMap.step;
    }

    const lastIndex = state.schedules[day].dayPartSchedules.length - 1;
    let newStartTime = '09:00:00';
    let newEndTime = '17:00:00';

    if (lastIndex > -1) {
      const lastDayPartSchedule =
        state.schedules[day].dayPartSchedules[lastIndex];

      newStartTime = IntervalMap.nextTime(
        lastDayPartSchedule.dayPartCap.end,
        intervalStep
      );

      if (newStartTime === '23:45:00' || newStartTime === '24:00:00') {
        dispatch(
          'toastr/error',
          `There's no more time left in the day! You may adjust existing schedules.`,
          {
            root: true,
          }
        );
        return;
      }
      newEndTime = IntervalMap.nextTime(newStartTime, '01:00:00');
    }

    const newDayPartSchedule = new DailySchedule.DayPartSchedule({
      concurrencyCap: 0,
      dayPartCap: new DailySchedule.Cap({
        count: 0,
        limit: 0,
        start: newStartTime,
        end: newEndTime,
        dayIndex: state.schedules[day].dayIndex,
      }),
      intervalCaps: [],
    });

    commit('addDayPartSchedule', newDayPartSchedule);
    dispatch('setIntervalMap', intervalStep);
    commit('setDirty', true);
  },

  removeDayPartSchedule({ commit, dispatch, state }, { day, index }) {
    commit('removeDayPartSchedule', {
      day,
      index,
    });
    if (state.schedules[day].dayPartSchedules.length === 0) {
      dispatch('addDayPartSchedule', { day });
      commit('setDayActive', {
        day,
        isActive: false,
      });
    }
    dispatch('setIntervalMap', state.intervalMap.step);
    commit('setDirty', true);
  },

  removeWebLeadDayPartSchedule({ commit }, { dayOfWeek, index }) {
    if (index === 0) {
      const isActive = false;
      commit('setWebLeadScheduleActive', { dayOfWeek, isActive });
      return;
    }

    commit('removeWebLeadDayPartSchedule', { dayOfWeek, index });
  },

  addWebLeadDayPartSchedule({ commit, dispatch, state }, { dayOfWeek }) {
    const scheduleLength = state.webLeadSchedules[dayOfWeek].length;
    const dayPartEndTime =
      state.webLeadSchedules[dayOfWeek][scheduleLength - 1].endTime;
    const dayPartStartTime =
      state.webLeadSchedules[dayOfWeek][scheduleLength - 1].startTime;

    if (
      scheduleLength >= 1 &&
      (dayPartEndTime === '24:00:00' ||
        (dayPartEndTime === '23:30:00' && dayPartStartTime !== '00:00:00') ||
        dayPartStartTime === dayPartEndTime)
    ) {
      // This prevents people from adding another day part schedule if they have reached the end of the current day
      dispatch(
        'toastr/error',
        `There's no more time left in the day! You may adjust existing schedules.`,
        {
          root: true,
        }
      );
      return;
    }

    const newWebLeadScheduleDayPart = new WebLeadScheduleManager();
    const newScheduleRecord =
      newWebLeadScheduleDayPart.addNewActiveDefaultScheduleDay(
        dayOfWeek,
        state.webLeadSchedules[dayOfWeek]
      );

    if (newScheduleRecord.length === 0) {
      // This indicates there is no time left on your schedule when you combine all schedules for that day
      dispatch(
        'toastr/error',
        `There's no more time left in the day! You may adjust existing schedules.`,
        {
          root: true,
        }
      );
      return;
    }

    commit('addWebLeadDayPartSchedule', newScheduleRecord);
  },

  setDayPartSchedule(
    { commit, dispatch, state },
    { startOrEnd, day, index, time }
  ) {
    commit('setDayPartSchedule', {
      startOrEnd,
      day,
      index,
      time,
    });
    dispatch('setIntervalMap', state.intervalMap.step);
    commit('setDirty', true);
  },

  setWebLeadDayPartSchedule(
    { commit },
    { startOrEnd, dayOfWeek, index, time }
  ) {
    commit('setWebLeadDayPartSchedule', { startOrEnd, dayOfWeek, index, time });
  },

  setDayActive({ commit, dispatch, state }, { day, isActive }) {
    commit('setDayActive', {
      day,
      isActive,
    });
    dispatch('setIntervalMap', state.intervalMap.step);
    commit('setDirty', true);
  },

  setMonthlyCapLimit({ commit }, capLimit) {
    commit('setMonthlyCapLimit', capLimit);
    commit('setDirty', true);
  },

  setPartCap({ commit }, { day, index, limit }) {
    commit('setPartCap', {
      day,
      index,
      limit,
    });
    commit('setDirty', true);
  },
  setWebLeadScheduleActive({ commit }, { dayOfWeek, isActive }) {
    commit('setWebLeadScheduleActive', { dayOfWeek, isActive });
  },

  setConcurrencyCap({ commit }, { day, index, limit }) {
    commit('setConcurrencyCap', {
      day,
      index,
      limit,
    });
    commit('setDirty', true);
  },

  setIntervalStep({ commit, dispatch }, step) {
    commit('resetIntervalCaps');
    dispatch('setIntervalMap', step);
    commit('setDirty', true);
  },

  setIntervalMap({ commit, state }, step) {
    const intervalMap = new IntervalMap(Object.values(state.schedules), step);
    commit('setIntervalMap', intervalMap);
  },

  setIntervalCap({ commit }, { limit, dayIndex, start }) {
    commit('setIntervalCap', {
      limit,
      dayIndex,
      start,
    });
    commit('setDirty', true);
  },

  setIsIntervalEnabled({ commit }, isEnabled) {
    commit('setIsIntervalEnabled', isEnabled);
    commit('setDirty', true);
  },
};

export const mutations = {
  setSchedules: _.set('schedules'),
  setIsIntervalEnabled: _.set('isIntervalEnabled'),
  setIntervalMap: _.set('intervalMap'),
  setMonthlyCapLimit: _.set('monthlyCapLimit'),
  setResponseCache: _.set('responseCache'),
  setLoading: _.set('loading'),

  setDayPartSchedule(state, { startOrEnd, day, index, time }) {
    state.schedules[day].dayPartSchedules[index].dayPartCap[startOrEnd] = time;
  },

  setDaySchedule(state, { day, start, end, isActive }) {
    const daySchedule = state.schedules[day];
    daySchedule.isActive = isActive;

    daySchedule.dayPartSchedules = [daySchedule.dayPartSchedules[0]];
    start ??= '00:00:00';
    end ??= '24:00:00';

    const dayPart = daySchedule.dayPartSchedules[0].dayPartCap;
    dayPart.start = start;
    dayPart.end = end;
  },

  setWebLeadDayPartSchedule(state, { startOrEnd, dayOfWeek, index, time }) {
    if (startOrEnd === 'start') {
      state.webLeadSchedules[dayOfWeek][index].startTime = time;
    } else {
      state.webLeadSchedules[dayOfWeek][index].endTime = time;
    }
  },

  addDayPartSchedule(state, newDayPartSchedule) {
    state.schedules[
      newDayPartSchedule.dayPartCap.dayOfWeek
    ].dayPartSchedules.push(newDayPartSchedule);
  },

  removeDayPartSchedule(state, { day, index }) {
    state.schedules[day].dayPartSchedules.splice(index, 1);
  },

  removeWebLeadDayPartSchedule(state, { dayOfWeek, index }) {
    state.webLeadSchedules[dayOfWeek].splice(index, 1);
  },

  addWebLeadDayPartSchedule(state, newWebLeadDayPartSchedule) {
    state.webLeadSchedules[newWebLeadDayPartSchedule.dayOfWeek].push(
      newWebLeadDayPartSchedule
    );
  },

  setDayActive(state, { day, isActive }) {
    state.schedules[day].isActive = isActive;
  },

  setWebLeadScheduleActive(state, { dayOfWeek, isActive }) {
    state.webLeadSchedules[dayOfWeek].forEach((item) => {
      item.isActive = isActive;
    });
  },

  updateWebLeadScheduleToLeadTypeSchedule(state, scheduleToLeadTypeSchedule) {
    state.leadType.schedule = scheduleToLeadTypeSchedule;
  },

  setPartCap(state, { day, index, limit }) {
    state.schedules[day].dayPartSchedules[index].dayPartCap.limit = limit;
  },

  setConcurrencyCap(state, { day, index, limit }) {
    state.schedules[day].dayPartSchedules[index].concurrencyCap = limit;
  },

  setDirty(state, isDirty) {
    state.dirty = isDirty;
  },

  setWebLeadSchedules(state, schedules) {
    state.webLeadSchedules = schedules;
  },

  setIntervalCap(state, { limit, dayIndex, start }) {
    state.schedules[DailySchedule.days[dayIndex]].dayPartSchedules.forEach(
      (dayPart) => {
        const intervalCap = dayPart.intervalCaps.find(
          (cap) => cap.start === start
        );
        if (intervalCap) {
          intervalCap.limit = limit;
        } else {
          dayPart.intervalCaps.push(
            new DailySchedule.Cap({
              limit,
              start,
              end: IntervalMap.nextTime(start, state.intervalMap.step),
              dayIndex,
            })
          );
        }
      }
    );
  },

  resetIntervalCaps(state) {
    DailySchedule.days.forEach((day) => {
      if (!state.schedules[day] || !state.schedules[day].dayPartSchedules)
        return;
      state.schedules[day].dayPartSchedules.forEach((dayPart) => {
        dayPart.intervalCaps = [];
      });
    });
  },

  resetSchedulerState(state) {
    const initialState = getInitialState();
    Object.keys(state).forEach((key) => {
      state[key] = initialState[key];
    });
  },
};
