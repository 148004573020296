import { axiosInstance } from '@/plugins/axios';

// initial state
export const state = {
  loading: false,
  lead: null,
  packet: null,
};

// getters
export const getters = {
  loading: (state) => state.loading,

  lead: (state) => state.lead,

  packet: (state) => state.packet,

  // Deprecated. Use 'lead' getter.
  getlead: (state) => state.lead,
};

// actions
export const actions = {
  getLead({ commit }, { leadId, leadType }) {
    if (leadId === '0') {
      return null;
    }

    commit('SET_LOADING', true);
    return axiosInstance
      .get(`/api/leads/${leadId}?leadType=${leadType}`)
      .then((response) => {
        commit('SET_LOADING', false);
        commit('SET_LEAD', response.data);
        return response.data;
      })
      .catch((err) => {
        commit('SET_LOADING', false);
        console.error(err);
        return Promise.reject(err);
      });
  },
  async getLeadPacket({ commit }, { sessionId }) {
    var packetResponse = await axiosInstance.get(
      `/api/leads/${sessionId}/packet`
    );

    commit('SET_PACKET', packetResponse.data);
    return packetResponse.data;
  },
  async getCallLeadPacket({ commit }, { outboundCallSid }) {
    var packetResponse = await axiosInstance.get(
      `/api/leads/calls/${outboundCallSid}/packet`
    );

    commit('SET_PACKET', packetResponse.data);
    return packetResponse.data;
  },

  async getLeadNotes(_, leadId) {
    const response = await axiosInstance.get(`/api/leads/${leadId}/notes`);

    return response.data;
  },
  async saveLeadNote({ dispatch }, { sessionId, leadNote }) {
    let errMsg = '';

    try {
      const response = await axiosInstance.post(
        `/api/Leads/${sessionId}/notes`,
        leadNote
      );

      if (response.status === 200) {
        if (response.data) {
          return true;
        } else {
          errMsg =
            'Issue saving data. If issue persists please contact customer service';
        }
      } else {
        errMsg = response.data.description;
      }
    } catch (err) {
      errMsg = err?.response?.data ?? 'Error: Network Error';
    }

    dispatch('toastr/error', errMsg, { root: true });
    return false;
  },

  async deleteLeadNote({ dispatch }, leadNoteId) {
    var errMsg = '';

    try {
      const response = await axiosInstance.delete(
        `/api/leads/notes/${leadNoteId}`
      );

      if (response.status === 200) {
        if (response.data) {
          return true;
        } else {
          errMsg =
            'Issue deleting note. If issue persists please contact customer service';
        }
      } else {
        errMsg = response.data.description;
      }
    } catch (err) {
      errMsg = err?.response?.data ?? 'Error: Network Error';
    }

    dispatch('toastr/error', errMsg, { root: true });
    return false;
  },

  setLead({ commit }, lead) {
    commit('SET_LEAD', lead);
  },

  setLeadPacket({ commit }, packet) {
    commit('SET_PACKET', packet);
  },
};

// mutations
export const mutations = {
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },

  SET_LEAD(state, newValue) {
    state.lead = newValue;
  },

  SET_PACKET(state, packet) {
    state.packet = packet;
  },

  SET_LEADSTAGE(state, newValue) {
    state.lead.leadStage = newValue;
  },

  SET_LEADCREDITREQUEST(
    state,
    { reasonText, description, requestDate, requestedBy }
  ) {
    state.lead.creditRequestReason = reasonText;
    state.lead.creditRequestDesc = description;
    state.lead.creditApprovalDtg = requestDate;
    state.lead.creditRequestBy = requestedBy;
  },
};
