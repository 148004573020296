<template>
  <el-row :class="stepClass">
    <el-col>
      <VForm
        v-slot="{ meta }"
        as="el-form"
        :validation-schema="validationSchema"
        :initial-values="initialValues"
        :disabled="!isFormReady"
        data-vv-scope="ssr-deposit"
        validate-on-mount
      >
        <div class="title-area">
          <h1 class="page-title">{{ title }}</h1>
        </div>
        <div v-loading="isLoading" class="input-area">
          <ul>
            <li
              :class="{
                'list-header': true,
                'half': !isCcBilling
              }"
            >
              <div><h4>Product Name</h4></div>
              <div><h4>Payment Method</h4></div>
              <div v-if="isCcBilling"><h4>Credit Amount</h4></div>
            </li>
            <li
              v-for="(product, i) in productTypes" :key="i"
              :class="{
                'product-line': true,
                'half': !isCcBilling
              }"
            >
              <div><h4 class="product-title">{{ getProductName(product) }}</h4></div>
              <div>
                <Field
                  v-if="isCcBilling"
                  v-slot="{ value, field, errorMessage }"
                  as="div"
                  class="stack"
                  :name="product.toLocaleLowerCase() + 'PaymentId'"
                >
                  <el-select
                    v-bind="field"
                    :model-value="value"
                    :class="{
                      'alert-danger': !!errorMessage,
                      'cc-select': true,
                    }"
                    :data-vv-as="`${product} Leads Selected Payment Method`"
                    placeholder="Please select a payment option."
                    @change="updateCcForProduct(product, $event)"
                    @blur="updateFocus('PaymentId', product)"
                  >
                    <el-option
                      v-show="isSelectedPaymentIdValid(selectedCc[product])"
                      :value="0"
                      :selected="isSelectedPaymentIdValid(selectedCc[product])"
                      :label="'Please select a payment option.'"
                      disabled
                    >Please select a payment option.</el-option>
                    <el-option
                      v-for="(option) in creditCards"
                      :key="option.id"
                      :label="option.display"
                      :value="option.id"
                      :selected="selectedCc[product] === option.id"
                      required
                    />
                  </el-select>
                  <el-alert
                    v-show="showErrorForField(product, 'PaymentId', errorMessage)"
                    :closable="false"
                    :title="errorMessage"
                    type="error"
                    show-icon
                    class="mb-2"
                  />
                </Field>
                <h4 v-else>{{ billingTypeDisplay }}</h4>
              </div>
              <Field
                v-if="isCcBilling"
                v-slot="{ value, field, errorMessage }"
                as="div"
                class="amount"
                :name="product.toLocaleLowerCase() + 'Amount'"
              >
                <div class="stack">
                  <el-input
                    :key="product + 'Amount'"
                    v-bind="field"
                    :model-value="value"
                    :class="{ 'alert-danger': !!errorMessage }"
                    type="text"
                    :data-vv-as="`${product} Leads Amount`"
                    :placeholder="100"
                    required
                    @change="updateDepositForProduct(product, $event)"
                    @blur="updateFocus('Amount', product)"
                  >
                    <template #prefix>
                      <span class="amount-prefix">$</span>
                    </template>
                  </el-input>
                  <el-alert
                    v-show="showErrorForField(product, 'Amount', errorMessage)"
                    :closable="false"
                    :title="errorMessage"
                    type="error"
                    show-icon
                    class="mb-2"
                  />
                </div>
              </Field>
            </li>
          </ul>
        </div>
        <div class="button-area">
          <div class="left-button">
            <el-button
              class="my-2"
              type="primary"
              style="float: left;"
              @click="handleBack"
            >Back</el-button>
          </div>

          <div class="right-button">
            <el-button
              v-if="isCcBilling"
              class="my-2"
              type="secondary"
              @click="$emit('card-needed')"
            >Add New Credit Card</el-button>
            <el-button
              :disabled="!meta.valid"
              class="my-2"
              type="primary"
              @click="handleReactivation"
            >Reactivate Now</el-button>
          </div>
        </div>
      </VForm>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { Field, Form as VForm } from "vee-validate";
import * as yup from 'yup';

export default {
  name: "ReactivationDepositStep",
  components: {
    VForm,
    Field
  },
  data: () => ({
    selectedCc: {
      Web: 0,
      Call: 0,
    },
    depositAmount: {
      Web: null,
      Call: null,
    },
    initialValues: {
      webPaymentId: 0,
      webAmount: 0,
      callPaymentId: 0,
      callAmount: 0
    },
    hasBeenFocused: {
      PaymentId: {
        Web: false,
        Call: false,
      },
      Amount: {
        Web: false,
        Call: false,
      },
    },
  }),
  computed: {
    ...mapGetters('reactivation', [
      'creditCards',
      'selectedReactivationOptions',
      'isProcessingReactivation',
      'productTypes',
      'webProduct',
      'callProduct',
    ]),
    ...mapGetters('client', [
      'clientId',
      'isCcBilling',
      'billingType',
    ]),
    ...mapGetters('responsive', ['isAtMostMD', 'isAtLeastLG']),

    validationSchema() {
      const validations = [];

      for (const product of this.productTypes) {
        validations.push(
          [
            product.toLocaleLowerCase() + 'PaymentId',
            yup
              .number()
              .required()
              .integer()
              .test(
                'notZero',
                `${this.getProductName(product)} payment method must be selected`,
                (x) => !!x
              )
              .typeError(`${this.getProductName(product)} payment method must be a number`)
              .label(`${this.getProductName(product)} payment method`)
          ],
          [
            product.toLocaleLowerCase() + 'Amount',
            yup
              .number()
              .required()
              .test(
                'twoDecimalPlaces',
                `${this.getProductName(product)} deposit amount cannot be more than two decimal places`,
                (x) => /^\d+([.]\d{0,2})?$/.test(x)
              )
              .min(100)
              .typeError(`${this.getProductName(product)} deposit amount must be a number`)
              .label(`${this.getProductName(product)} deposit amount`)
          ]
        );
      }

      return yup.object(Object.fromEntries(validations));
    },

    title() {
      if (!this.isCcBilling) {
        return 'Please confirm your reactivation details';
      }

      const plural = this.productTypes.length > 1
        ? 's'
        : '';
      return `Select your payment method${plural} and credit amount${plural}`;
    },
    isFormReady() {
      return !this.isProcessingReactivation && this.productTypes.length > 0;
    },
    stepClass() {
      return {
        'reactivation-payment-step': true,
        wide: this.isAtLeastLG,
        narrow: this.isAtMostMD,
        half: !this.isCcBilling,
      };
    },
    isSelectedPaymentIdValid() {
      return paymentId => {
        return !this.creditCards.includes(x => x.id === paymentId);
      };
    },
    billingTypeDisplay() {
      switch (this.billingType) {
        case 'CountryGroup': return 'COUNTRY Group Billing';
        case 'FarmersFolio': return 'Farmers Folio Billing'
      }
      // Should only be used in the above scenarios
      return null;
    },
    isLoading() {
      return this.productTypes.length === 0;
    },
  },
  watch: {
    products: function (newValue) {
      for (const product of newValue) {
        const cardMatchesSelected =
          this.selectedCc[product.productType] !== product.cardId;
        if (!cardMatchesSelected) {
          const cardExists = this.creditCards.includes(
            (x) => x.id === product.cardId
          );
          this.selectedCc[product.productType] = cardExists
            ? product.cardId
            : 0;
        }
      }
    },
    productTypes: async function (newValue) {
      if (!this.isCcBilling) {
        await this.setProductsForNonCcClientsAsync(newValue);
      }
    }
  },
  created() {
    this.selectedCc.Web = this.selectedReactivationOptions.Web?.cardId
      || this.webProduct?.cardId
      || 0;
    this.selectedCc.Call = this.selectedReactivationOptions.Call?.cardId
      || this.callProduct?.cardId
      || 0;

    this.depositAmount.Web = this.selectedReactivationOptions.Web?.depositAmount || null;
    this.depositAmount.Call = this.selectedReactivationOptions.Call?.depositAmount || null;

    // Set initial values in form
    this.initialValues.webPaymentId = this.selectedCc.Web;
    this.initialValues.webAmount = this.depositAmount.Web;
    this.initialValues.callPaymentId = this.selectedCc.Call;
    this.initialValues.callAmount = this.depositAmount.Call;
  },
  methods: {
    ...mapActions('reactivation', [
      'updatePaymentIdForProductAsync',
      'updateDepositAmountForProductAsync',
      'sendReactivationRequestAsync',
      'setProductsForNonCcClientsAsync',
    ]),

    getProductName: (product) => {
      return product === 'Web'
        ? "Web Leads"
        : "Call Leads";
    },
    async updateCcForProduct(product, paymentId) {
      this.selectedCc[product] = paymentId;

      await this.updatePaymentIdForProductAsync({
        product,
        cardId: this.selectedCc[product],
      });
    },
    async updateDepositForProduct(product, amount) {
      this.depositAmount[product] = amount;

      await this.updateDepositAmountForProductAsync({
        product,
        depositAmount: this.depositAmount[product],
      });
    },
    updateFocus: debounce(function (field, product) {
      this.hasBeenFocused[field][product] = true;
    }, 350),
    async handleReactivation() {
      await this.sendReactivationRequestAsync({
        clientId: this.clientId,
        billingType: this.billingType,
      });
      this.$emit('reactivation-attempted');
    },
    handleBack() {
      this.$emit('click-back');
    },
    showErrorForField(productType, fieldName, errorMessage) {
      return errorMessage && this.hasBeenFocused[fieldName][productType];
    },
  },
}
</script>

<style lang="scss" scoped>
h4.product-title {
  margin: 0;
}

.reactivation-payment-step {
  padding: 0 5em 5em 5em;
  // Part of the close button is not clickable without margin-top.
  margin-top: 5em;

  &.wide {
    padding: 0 5vw 5em 5vw;
  }

  &.narrow {
    padding: 0 0 2em 0;

    .product-line {
      grid-auto-flow: row;
      grid-template-columns: unset;
    }
  }

  &.half {
    display: grid;
    justify-content: center;
  }
}

div.stack {
  flex-direction: column;
  width: 100%;

  .el-alert {
    margin-top: 7px;
  }
}

.title-area {
  margin-bottom: 5em;

  .page-title {
    margin-bottom: 25px;
  }
}

.input-area {
  margin-bottom: 3em;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &>li>div {
      display: flex;
      align-items: center;
    }
  }

  .product-line,
  .list-header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 3fr 2fr;
    gap: 20px;

    &.half {
      grid-template-columns: 1fr 1fr;
    }
  }

  .list-header {
    margin-bottom: 15px;
  }

  .product-line {
    border-top: 1px solid rgb(220, 223, 230);
    padding: 20px 0px;

    &:last-child {
      border-bottom: 1px solid rgb(220, 223, 230);
    }
  }

  .amount .amount-prefix {
    height: 100%;
    display: flex;
    align-items: center;
  }

  & .alert-danger :deep(input) {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
  }
}

.cc-select {
  width: 100%;
}

.button-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.button-area .left-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.button-area .right-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
</style>
