export default {
  data() {
    return {
      clientStatusMap: {
        ACTIVE: 'Active',
        CANCEL: 'Cancelled',
        CANCEL_BADDEBT: 'Cancelled - Bad Debt',
        'cash cap': 'Cash Capped',
        COLLECTION: 'Collection',
        INACTIVE: 'Inactive',
        PAUSE: 'Paused',
        PAUSE_AR: 'Paused - Accounts Receivable',
        CORPORATE: 'Corporate',
        PAUSE_RECHARGE: 'Recharge',
        PAUSE_SCHED: 'Paused - Schedule',
        PENDING_CANCEL: 'Pending Cancellation',
        PENDING_IO: 'Pending IO',
        PENDING_VAL: 'Pending Validation',
        PAUSE_VAC: 'Paused - Vacation',
      },
    }
  },
}
