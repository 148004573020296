<template>
  <div v-if="!isClientActive">
    <el-alert type="error" :closable="false" show-icon center>
      Client is currently inactive.
      <span class="hyperlink-color" @click="showReactivationDialog"
        >Click here</span
      >
      to begin self-service reactivation.
    </el-alert>

    <reactivation-dialog />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReactivationDialog from '@/components/reactivation/ReactivationDialog.vue';

export default {
  name: 'Reactivation',
  components: {
    ReactivationDialog,
  },
  computed: {
    ...mapGetters('client', ['client', 'hasClient']),
    isClientActive() {
      return !this.hasClient || this.client.isActive;
    },
  },
  methods: {
    ...mapActions('reactivation', ['setShowDialog']),

    showReactivationDialog() {
      this.setShowDialog(true);
    },
  },
};
</script>
