import _ from '@/utils/store-helpers';
import { axiosInstance } from '@/plugins/axios';
import { creditCallUrl } from '@/app.config';

export const state = {
  loading: false,
  leadCount: 0,
  countLoading: false,
  lead: {},
};

export const getters = {};

export const actions = {
  async getLead(
    { commit, dispatch },
    {
      lowCR,
      highCR,
      lowCost,
      highCost,
      creditReason,
      parentCompany,
      leadType,
      clientId,
      devPhoneNumber,
    }
  ) {
    commit('setLoading', true);
    try {
      var leadData = null;

      var response = await axiosInstance.get(`/api/leads/getCreditDialerLead`, {
        params: {
          lowCR,
          highCR,
          lowCost,
          highCost,
          creditReason,
          parentCompany,
          leadType,
          clientId,
        },
      });

      leadData = response.data;

      if (process.env.VUE_APP_CREDIT_DIALER_LIVE !== 'true') {
        leadData.primaryPhone = devPhoneNumber;
        leadData.bizContactPhone = devPhoneNumber;
      }

      commit('setLead', leadData);

      dispatch('getLeadCount', {
        lowCR,
        highCR,
        lowCost,
        highCost,
        creditReason,
        parentCompany,
        leadType,
        clientId,
      });

      commit('setLoading', false);

      return leadData;
    } catch (err) {
      if (!err.response) {
        // network error
        this.dispatch('toastr/error', 'Error: Network Error');
      } else {
        this.dispatch(
          'toastr/error',
          'Oops there was an error: ' + err.response.data
        );
      }
      console.error(err);
      commit('setLoading', false);
      return {};
    }
  },
  async getLeadCount(
    { commit },
    {
      lowCR,
      highCR,
      lowCost,
      highCost,
      creditReason,
      parentCompany,
      leadType,
      clientId,
    }
  ) {
    commit('setCountLoading', true);
    try {
      var response = await axiosInstance.get(
        `/api/leads/getCreditDialerLeadCount`,
        {
          params: {
            lowCR,
            highCR,
            lowCost,
            highCost,
            creditReason,
            parentCompany,
            leadType,
            clientId,
          },
        }
      );

      commit('setLeadCount', response.data);
      commit('setCountLoading', false);
    } catch (err) {
      if (!err.response) {
        // network error
        this.dispatch('toastr/error', 'Error: Network Error');
      } else {
        this.dispatch(
          'toastr/error',
          'Oops there was an error getting the lead count: ' + err.response.data
        );
      }
      console.error(err);
      commit('setCountLoading', false);
      commit('setLeadCount', 0);
    }
  },
  async approveCreditRequest({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await axiosInstance.post(`/api/leads/${id}/approvecredit`);
    } catch (err) {
      console.error(err);
      commit('setLoading', false);
      return false;
    }

    commit('setLoading', false);
    return true;
  },
  async denyCreditRequest({ commit }, { id, description, reason }) {
    commit('setLoading', true);
    try {
      await axiosInstance.post(`/api/leads/${id}/denycredit`, {
        reason,
        description,
      });
    } catch (err) {
      console.error(err);
      commit('setLoading', false);
      return false;
    }

    commit('setLoading', false);
    return true;
  },
  async callCreditCaller({ commit }, { phone }) {
    try {
      await axiosInstance.post(
        `${creditCallUrl}/Dialer/MakeOutGoingCallToCaller?callerPhoneNumber=${phone}`
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  async callLead({ commit }, { fromPhone, toPhone }) {
    try {
      await axiosInstance.post(
        `${creditCallUrl}/Dialer/MakeOutGoingCallToConsumer?fromPhoneNumber=${fromPhone}&toPhoneNumber=${toPhone}`
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  async hangup({ commit }, { fromPhone, toPhone }) {
    try {
      await axiosInstance.post(
        `${creditCallUrl}/Dialer/HangUpCall?fromPhoneNumber=${fromPhone}&toPhoneNumber=${toPhone}`
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  async getCallStatus({ commit }, { phone }) {
    try {
      var response = await axiosInstance.get(
        `${creditCallUrl}/Dialer/${phone}/GetCallStatus`
      );

      return response.data;
    } catch (err) {
      console.error(err);
      return 'unknown';
    }
  },
  async ping({ commit }) {
    try {
      commit('setLoading', true);
      var response = await axiosInstance.get(`${creditCallUrl}/dialer/ping`);
      commit('setLoading', false);
      return response.data;
    } catch (err) {
      commit('setLoading', false);
      console.error(err);
      return err;
    }
  },
};

export const mutations = {
  setLoading: _.set('loading'),
  setLeadCount: _.set('leadCount'),
  setLead: _.set('lead'),
  setCountLoading: _.set('countLoading'),
};
